export const isEqual = {
  search: (a, b) => {
    if (!a) {
      return true;
    }
    if (a?.toLowerCase().trim().length) {
      return b.toLowerCase().trim().includes(a?.toLowerCase()?.trim());
    }
    return true;
  },
  date: (a, b) => {
    if (!a) {
      return true;
    }
    const date = new Date(Number(b) || b);

    return date.setHours(0, 0, 0, 0) === a.setHours(0, 0, 0, 0);
  },
  status: (a, b) => {
    if (!a) {
      return true;
    }

    return a === b;
  },
};

export default (filters, item) =>
  Object.keys(filters).every((key) => {
    switch (key) {
      case 'search':
        return (
          isEqual.search(filters[key], String(item?.number)) ||
          isEqual.search(filters[key], item?.project?.name) ||
          isEqual.search(filters[key], item?.client?.name)
        );
      case 'date':
        return isEqual.date(filters[key], item?.createdAt);
      case 'status':
        return isEqual.status(filters[key], item?.status);
      default:
        return true;
    }
  });
