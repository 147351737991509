import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button';
import {
  resetSideFilled,
  setElementRes,
  setOrder,
  setTypeModel,
} from '../../../store/reducers/Project/ProjectSlice';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import {
  setShowWarningModalRestart,
  setShowSelectModalProduct,
  setSelectedProject,
} from '../../../store/reducers/User/UserSlice';
import './style.scss';

const WarningModalRestart = () => {
  const dispatch = useDispatch();
  const { showWarningModalRestart, expertMode } = useSelector(
    (state) => state.user
  );
  const {
    source,
    //  selectedTypeModel,
    // typeModelRemove
  } = useSelector((state) => state.project);
  // const { showSelectModalProduct } = useSelector((state) => state.user);

  // useEffect(() => {
  //   console.log(showSelectModalProduct, 'showSelectModalProduct');
  // }, [showSelectModalProduct]);

  const handleClose = () => dispatch(setShowWarningModalRestart(false));

  const restart = () => {
    if (!source) return;
    source.funcRestart();
    handleClose();
    dispatch(setOrder());
    dispatch(setSelectedProject(null));

    if (expertMode) {
      console.log(showWarningModalRestart);
      source.clearGlobalValues();
      // dispatch(resetSideFilled());
      // if (typeModelRemove !== null) {
      source?.funcSelectType(showWarningModalRestart);
      dispatch(setTypeModel(showWarningModalRestart));
      dispatch(resetSideFilled());
      dispatch(setElementRes(undefined));
      // } else {
      //   source?.funcSelectType(null);
      //   dispatch(setTypeModel(null));
      //   dispatch(resetSideFilled());
      //   dispatch(setElementRes(undefined));
      // }
    } else {
      dispatch(setTypeModel(null));
      dispatch(setShowSelectModalProduct(true));
    }
  };

  return (
    <ModalWrapper
      show={showWarningModalRestart}
      close={handleClose}
      customClass="wrapper-warning-modal"
    >
      <ModalHeader title="Warnung!" close={handleClose} />
      <div className="container-warning-modal">
        <div className="description-container-warning-modal">
          Sie sind dabei, die gesamte Konfiguration zurückzusetzen. Sind Sie
          sicher, dass Sie neu beginnen möchten? Der Konfigurator fordert Sie
          erneut auf, den Abdeckungstyp auszuwählen.
        </div>
        <div className="container-btnStart-warning-modal">
          <Button className="btn-warning-modal" onClick={restart}>
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default WarningModalRestart;
