export default class AddElCommand {
  constructor(app, object, funcUpdatePrice) {
    this.app = app;
    this.object = object;
    this.funcUpdatePrice = funcUpdatePrice;
  }

  execute() {
    this.app.elements.push(this.object);
    this.app.viewer.scene.add(this.object);

    this.object.bb.children[0].material.color.set(0x61c38b);
    this.object.bb.children[0].material.opacity = 0.2;
    this.object.bb.children[1].material.color.set(0x61c38b);
    this.object.bb.children[1].material.opacity = 0.2;
    this.object.bb.children[2].material.color.set(0x61c38b);
    this.object.bb.children[2].material.opacity = 0.2;
    this.object.bb.children[3].material.color.set(0x61c38b);
    this.object.bb.children[3].material.opacity = 0.2;
    this.object.bb.children[4].material.color.set(0x61c38b);
    this.object.bb.children[4].material.opacity = 0.2;

    this.app.newTargetControls();
    this.funcUpdatePrice();
  }

  undo() {
    this.app.funcRmEl(this.object);
    this.funcUpdatePrice();
  }
}
