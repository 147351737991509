import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

export default class Details {
  louverBase = null;
  louverBeam = null;
  louverColumn = null;
  louverRoof = null;

  louver2Base = null;
  louver2Column = null;
  louver2Roof = null;
  louver2Beam = null;

  louver3Base = null;
  louver3Column = null;
  louver3Roof = null;
  louver3RoofClip1 = null;
  louver3RoofClip2 = null;
  louver3Beam = null;
  louver3Solar = null;

  zipPlaneSklSide = null;

  zipPlaneSglTop = null;
  zipPlaneSglSide = null;

  zipColumn = null;
  zipTop = null;
  zipBottom = null;

  zipHolder = null;
  zipHolder2 = null;
  zipTop2 = null;
  zipColumn2 = null;
  zipBottom2 = null;
  zipTop3 = null;
  zipColumn3 = null;
  zipBottom3 = null;

  zipTop4 = null;

  zipColumn5 = null;
  zipTop5 = null;
  zipBottom5 = null;

  zipCenter2 = null;
  zipCenter3 = null;

  lamellen = null;
  lamellenRailTop = null;
  lamellenRailBottom = null;
  lamellenFrameColumn = null;
  lamellenFrameBase = null;

  windowBorder = null;
  windowFrame = null;
  windowGlass = null;
  windowHandle = null;
  windowPartition = null;
  windowGlassB = null;
  windowGlassC = null;
  windowFrameB2 = null;
  windowFrameB1 = null;
  windowFrameT = null;

  windowSglBorder = null;
  windowSglFrame = null;
  windowSglGlass = null;
  windowSglHandle = null;
  windowSglPartition = null;
  windowSglGlassB = null;
  windowSglGlassC = null;
  windowSglFrameB2 = null;
  windowSglFrameB1 = null;
  windowSglFrameT = null;

  glassBase = null;
  glassBeam = null;
  glassColumn = null;
  glassRoofL = null;
  glassRoofT = null;
  glassRoofP = null;
  glassGlassRoof = null;
  glasscolumnMid = null;
  glasscompoundMid = null;

  constructor(threejsApp) {
    this.threejsApp = threejsApp;
  }

  loaderValue(progressValue) {
    document.getElementById('procent-loader-value').innerHTML = progressValue;
  }

  async initDitailsModels() {
    this.loaderValue(1);
    ///// louver model
    this.louverBase = (
      await new GLTFLoader().loadAsync('models/louver/base.gltf')
    ).scene;
    this.louverBase.children[0].name = 'louverBase';

    this.louver2Base = (
      await new GLTFLoader().loadAsync('models/louver2/base.gltf')
    ).scene;
    this.louver2Base.children[0].name = 'louver2Base';

    this.louver3Base = (
      await new GLTFLoader().loadAsync('models/louver3/base.glb')
    ).scene;
    this.louver3Base.children[0].name = 'louver3Base';

    this.louverBeam = (
      await new GLTFLoader().loadAsync('models/louver/beam.gltf')
    ).scene;
    this.louverBeam.children[0].name = 'louverBeam';

    this.louver2Beam = (
      await new GLTFLoader().loadAsync('models/louver2/beam.gltf')
    ).scene;
    this.louver2Beam.children[0].name = 'louver2Beam';

    this.louver2Beam.children[1].material =
      this.louver2Beam.children[0].material;

    this.louver3Beam = (
      await new GLTFLoader().loadAsync('models/louver3/beam.glb')
    ).scene;
    this.louver3Beam.children[0].name = 'louver3Beam';

    this.loaderValue(4);

    this.louverColumn = (
      await new GLTFLoader().loadAsync('models/louver/column.gltf')
    ).scene;
    this.louverColumn.children[0].name = 'louverColumn';

    this.louver2Column = (
      await new GLTFLoader().loadAsync('models/louver2/column.gltf')
    ).scene;
    this.louver2Column.children[0].name = 'louver2Column';

    this.louver3Column = (
      await new GLTFLoader().loadAsync('models/louver3/column.glb')
    ).scene;
    this.louver3Column.children[0].name = 'louver3Column';

    this.louverRoof = (
      await new GLTFLoader().loadAsync('models/louver/roof.gltf')
    ).scene;
    this.louverRoof.children[0].name = 'louverRoof';

    this.louver2Roof = (
      await new GLTFLoader().loadAsync('models/louver2/roof.gltf')
    ).scene;
    this.louver2Roof.children[0].name = 'louver2Roof';

    this.louver3Roof = (
      await new GLTFLoader().loadAsync('models/louver3/roof.glb')
    ).scene;
    this.louver3Roof.children[0].name = 'louver3Roof';

    this.louver3RoofClip1 = (
      await new GLTFLoader().loadAsync('models/louver3/roofClip1.glb')
    ).scene;
    this.louver3RoofClip1.children[0].name = 'louver3RoofClip1';

    this.louver3RoofClip2 = (
      await new GLTFLoader().loadAsync('models/louver3/roofClip2.glb')
    ).scene;
    this.louver3RoofClip2.children[0].name = 'louver3RoofClip2';

    this.loaderValue(7);

    this.louver3Solar = (
      await new GLTFLoader().loadAsync('models/louver3/solar.glb')
    ).scene;
    this.louver3Roof.children[0].name = 'louver3Solar';

    /////glass model
    this.glassBase = (
      await new GLTFLoader().loadAsync('models/glass/base.gltf')
    ).scene;
    this.glassBase.children[0].name = 'glassBase';
    this.glassBeam = (
      await new GLTFLoader().loadAsync('models/glass/beam.gltf')
    ).scene;
    this.glassBeam.children[0].name = 'glassBeam';
    this.glassColumn = (
      await new GLTFLoader().loadAsync('models/glass/column.gltf')
    ).scene;
    this.glassColumn.children[0].name = 'glassColumn';
    this.glassRoofL = (
      await new GLTFLoader().loadAsync('models/glass/roofL.gltf')
    ).scene;
    this.glassRoofL.children[0].name = 'glassRoofL';

    this.loaderValue(12);

    this.glassRoofT = (
      await new GLTFLoader().loadAsync('models/glass/roofT.gltf')
    ).scene;
    this.glassRoofT.children[0].name = 'glassRoofT';
    this.glassRoofT.children[1].material = this.glassRoofT.children[0].material;
    this.glassRoofP = (
      await new GLTFLoader().loadAsync('models/glass/roofP.gltf')
    ).scene;
    this.glassRoofP.children[0].name = 'glassRoofP';

    this.glassGlassRoof = (
      await new GLTFLoader().loadAsync('models/glass/glassroof.gltf')
    ).scene;
    this.glassGlassRoof.children[0].name = 'glassGlassRoof';
    this.glasscolumnMid = (
      await new GLTFLoader().loadAsync('models/glass/column-mid.gltf')
    ).scene;
    this.glasscolumnMid.children[0].name = 'glasscolumnMid';
    this.glasscompoundMid = (
      await new GLTFLoader().loadAsync('models/glass/compound-mid.gltf')
    ).scene;
    this.glasscompoundMid.children[0].name = 'glasscompoundMid';

    this.loaderValue(28);

    //lamellen

    this.lamellen = (
      await new GLTFLoader().loadAsync('models/lamellen/lamellen.gltf')
    ).scene;
    this.lamellen.children[0].name = 'lamellen';
    this.lamellenRailTop = (
      await new GLTFLoader().loadAsync('models/lamellen/lamellenRailTop.gltf')
    ).scene;
    this.lamellenRailTop.children[0].name = 'lamellenRailTop';
    this.lamellenRailTop.children[2].material =
      this.lamellenRailTop.children[1].material =
        this.lamellenRailTop.children[0].material;

    this.loaderValue(35);
    this.lamellenRailBottom = (
      await new GLTFLoader().loadAsync(
        'models/lamellen/lamellenRailBottom.gltf'
      )
    ).scene;
    this.lamellenRailBottom.children[0].name = 'lamellenRailBottom';
    this.lamellenRailBottom.children[2].material =
      this.lamellenRailBottom.children[1].material =
        this.lamellenRailBottom.children[0].material;
    this.lamellenFrameColumn = (
      await new GLTFLoader().loadAsync(
        'models/lamellen/lamellenFrameColumn.gltf'
      )
    ).scene;
    this.lamellenFrameColumn.children[0].name = 'lamellenFrameColumn';
    this.lamellenFrameBase = (
      await new GLTFLoader().loadAsync('models/lamellen/lamellenFrameBase.gltf')
    ).scene;
    this.lamellenFrameBase.children[0].name = 'lamellenFrameBase';

    this.loaderValue(42);

    ////zip
    this.zipPlaneSklSide = (
      await new GLTFLoader().loadAsync('models/zip/planezip.glb')
    ).scene;
    this.zipPlaneSklSide.children[0].name = 'zipPlaneSklSide';

    this.zipPlaneSglTop = (
      await new GLTFLoader().loadAsync('models/zip/planezip.glb')
    ).scene;
    this.zipPlaneSglTop.children[0].name = 'zipPlaneSglTop';

    this.zipPlaneSglSide = (
      await new GLTFLoader().loadAsync('models/zip/planezip.glb')
    ).scene;
    this.zipPlaneSglSide.children[0].name = 'zipPlaneSglSide';

    this.zipColumn = (
      await new GLTFLoader().loadAsync('models/zip/columnzip.gltf')
    ).scene;
    this.zipColumn.children[0].name = 'zipColumn';
    this.zipColumn5 = (
      await new GLTFLoader().loadAsync('models/zip/columnzip.gltf')
    ).scene;
    this.zipColumn5.children[0].name = 'zipColumn5';
    this.loaderValue(53);
    this.zipColumn2 = (
      await new GLTFLoader().loadAsync('models/zip/zipcolumn2.gltf')
    ).scene;
    this.zipColumn2.children[0].name = 'zipColumn2';
    this.zipColumn3 = (
      await new GLTFLoader().loadAsync('models/zip/zipcolumn3.gltf')
    ).scene;
    this.zipColumn3.children[0].name = 'zipColumn3';
    this.zipTop = (
      await new GLTFLoader().loadAsync('models/zip/topzip.gltf')
    ).scene;
    this.zipTop.children[0].name = 'zipTop';
    this.zipTop5 = (
      await new GLTFLoader().loadAsync('models/zip/topzip.gltf')
    ).scene;
    this.zipTop5.children[0].name = 'zipTop5';
    this.zipTop4 = (
      await new GLTFLoader().loadAsync('models/zip/ziptop4.gltf')
    ).scene;
    this.zipTop4.children[0].name = 'zipTop4';
    this.zipTop2 = (
      await new GLTFLoader().loadAsync('models/zip/ziptop2.gltf')
    ).scene;
    this.zipTop2.children[0].name = 'zipTop2';
    this.zipTop3 = (
      await new GLTFLoader().loadAsync('models/zip/ziptop3.gltf')
    ).scene;
    this.zipTop3.children[0].name = 'zipTop3';

    this.loaderValue(67);

    this.zipBottom = (
      await new GLTFLoader().loadAsync('models/zip/bottomzip.gltf')
    ).scene;
    this.zipBottom.children[0].name = 'zipBottom';
    this.zipBottom5 = (
      await new GLTFLoader().loadAsync('models/zip/bottomzip.gltf')
    ).scene;
    this.zipBottom5.children[0].name = 'zipBottom5';
    this.zipBottom2 = (
      await new GLTFLoader().loadAsync('models/zip/zipbottom2.gltf')
    ).scene;
    this.zipBottom2.children[0].name = 'zipBottom2';
    this.zipBottom3 = (
      await new GLTFLoader().loadAsync('models/zip/zipbottom3.gltf')
    ).scene;
    this.zipBottom3.children[0].name = 'zipBottom3';

    this.zipHolder = (
      await new GLTFLoader().loadAsync('models/zip/zipholder.gltf')
    ).scene;
    this.zipHolder.children[0].name = 'zipHolder';

    this.zipHolder2 = (
      await new GLTFLoader().loadAsync('models/zip/zipholder2.gltf')
    ).scene;
    this.zipHolder2.children[0].name = 'zipHolder2';

    this.zipCenter2 = (
      await new GLTFLoader().loadAsync('models/zip/zipcenter2.gltf')
    ).scene;
    this.zipCenter2.children[0].name = 'zipCenter2';
    this.zipCenter3 = (
      await new GLTFLoader().loadAsync('models/zip/zipcenter3.gltf')
    ).scene;
    this.zipCenter3.children[0].name = 'zipCenter3';

    this.loaderValue(73);

    //window
    this.windowBorder = (
      await new GLTFLoader().loadAsync('models/window/wborde.gltf')
    ).scene;
    this.windowBorder.children[0].name = 'windowBorder';
    this.windowFrame = (
      await new GLTFLoader().loadAsync('models/window/wframe.gltf')
    ).scene;
    this.windowFrame.children[0].name = 'windowFrame';
    this.windowPartition = (
      await new GLTFLoader().loadAsync('models/window/wpartition.gltf')
    ).scene;
    this.windowPartition.children[0].name = 'windowPartition';
    this.windowPartition.children[1].material =
      this.windowPartition.children[0].material;
    this.windowGlass = (
      await new GLTFLoader().loadAsync('models/window/wglass.glb')
    ).scene;
    this.windowGlass.children[0].name = 'windowGlass';
    this.windowHandle = (
      await new GLTFLoader().loadAsync('models/window/whandle.glb')
    ).scene;
    this.windowHandle.children[0].name = 'windowHandle';

    this.loaderValue(76);

    this.windowGlassB = (
      await new GLTFLoader().loadAsync('models/window/wframe-glass-bottom.gltf')
    ).scene;
    this.windowGlassB.children[0].name = 'windowGlassB';
    this.windowGlassC = (
      await new GLTFLoader().loadAsync(
        'models/window/wframe-glasse-collumn.gltf'
      )
    ).scene;
    this.windowGlassC.children[0].name = 'windowGlassC';
    this.windowFrameB2 = (
      await new GLTFLoader().loadAsync('models/window/wframe2-bottom2.gltf')
    ).scene;
    this.windowFrameB2.children[0].name = 'windowFrameB2';
    this.windowFrameB1 = (
      await new GLTFLoader().loadAsync('models/window/wframe2-bottoom1.gltf')
    ).scene;
    this.windowFrameB1.children[0].name = 'windowFrameB1';
    this.windowFrameT = (
      await new GLTFLoader().loadAsync('models/window/wframe2-top.gltf')
    ).scene;
    this.windowFrameT.children[0].name = 'windowFrameT';
    this.windowFrameT.children[1].material =
      this.windowFrameT.children[0].material;

    this.loaderValue(88);

    this.windowSglBorder = (
      await new GLTFLoader().loadAsync('models/window/wborde.gltf')
    ).scene;
    this.windowSglBorder.children[0].name = 'windowSglBorder';
    this.windowSglFrame = (
      await new GLTFLoader().loadAsync('models/window/wframe.gltf')
    ).scene;
    this.windowSglFrame.children[0].name = 'windowSglFrame';
    this.windowSglPartition = (
      await new GLTFLoader().loadAsync('models/window/wpartition.gltf')
    ).scene;
    this.windowSglPartition.children[0].name = 'windowSglPartition';
    this.windowSglPartition.children[1].material =
      this.windowSglPartition.children[0].material;
    this.windowSglGlass = (
      await new GLTFLoader().loadAsync('models/window/wglass.glb')
    ).scene;
    this.windowSglGlass.children[0].name = 'windowSglGlass';
    this.windowSglHandle = (
      await new GLTFLoader().loadAsync('models/window/whandle.glb')
    ).scene;
    this.windowSglHandle.children[0].name = 'windowSglHandle';

    this.loaderValue(91);

    this.windowSglGlassB = (
      await new GLTFLoader().loadAsync('models/window/wframe-glass-bottom.gltf')
    ).scene;
    this.windowSglGlassB.children[0].name = 'windowSglGlassB';
    this.windowSglGlassC = (
      await new GLTFLoader().loadAsync(
        'models/window/wframe-glasse-collumn.gltf'
      )
    ).scene;
    this.windowSglGlassC.children[0].name = 'windowSglGlassC';
    this.windowSglFrameB2 = (
      await new GLTFLoader().loadAsync('models/window/wframe2-bottom2.gltf')
    ).scene;
    this.windowSglFrameB2.children[0].name = 'windowSglFrameB2';
    this.windowSglFrameB1 = (
      await new GLTFLoader().loadAsync('models/window/wframe2-bottoom1.gltf')
    ).scene;
    this.windowSglFrameB1.children[0].name = 'windowSglFrameB1';
    this.windowSglFrameT = (
      await new GLTFLoader().loadAsync('models/window/wframe2-top.gltf')
    ).scene;
    this.windowSglFrameT.children[0].name = 'windowSglFrameT';
    this.windowSglFrameT.children[1].material =
      this.windowSglFrameT.children[0].material;

    this.loaderValue(95);

    let textureSkl = new THREE.TextureLoader().load(
      'models/zipMapSklSide/001010_grey_charcoal.jpg'
    );
    textureSkl.userData = 'models/zipMapSklSide/001010_grey_charcoal.jpg';
    textureSkl.wrapS = textureSkl.wrapT = THREE.MirroredRepeatWrapping;
    textureSkl.repeat.set(1, 5);

    let textureSglTop = new THREE.TextureLoader().load(
      'models/zipMapSglTop/314_660_Sage.jpg'
    );
    textureSglTop.userData = 'models/zipMapSglTop/314_660_Sage.jpg';
    textureSglTop.wrapS = textureSglTop.wrapT = THREE.MirroredRepeatWrapping;
    textureSglTop.repeat.set(1, 5);

    let textureSglSide = new THREE.TextureLoader().load(
      'models/zipMapSglSide/0102.jpg'
    );
    textureSglSide.userData = 'models/zipMapSglSide/0102.jpg';
    textureSglSide.wrapS = textureSglSide.wrapT = THREE.MirroredRepeatWrapping;
    textureSglSide.repeat.set(1, 5);

    let textureAllMapSgl = new THREE.TextureLoader().load(
      'models/mapSglAll/DB_703_seidenglanz.jpg'
    );
    textureAllMapSgl.userData = 'models/mapSglAll/DB_703_seidenglanz.jpg';
    textureAllMapSgl.wrapS = textureAllMapSgl.wrapT =
      THREE.MirroredRepeatWrapping;
    textureAllMapSgl.repeat.set(1, 5);

    let textureAllMapSkl = new THREE.TextureLoader().load(
      'models/mapSklAll/RAL_7016_Antrazithgrau_feinstruktur.jpg'
    );
    textureAllMapSkl.userData =
      'models/mapSklAll/RAL_7016_Antrazithgrau_feinstruktur.jpg';
    textureAllMapSkl.wrapS = textureAllMapSkl.wrapT =
      THREE.MirroredRepeatWrapping;
    textureAllMapSkl.repeat.set(1, 5);

    let textureLamellenMapSkl = new THREE.TextureLoader().load(
      'models/mapSklAll/Starlight_G.jpg'
    );
    textureLamellenMapSkl.userData = 'models/mapSklAll/Starlight_G.jpg';
    textureLamellenMapSkl.wrapS = textureLamellenMapSkl.wrapT =
      THREE.MirroredRepeatWrapping;
    textureLamellenMapSkl.repeat.set(1, 5);

    this.loaderValue(98);

    this.zipPlaneSklSide.children[0].material.map = textureSkl;
    this.zipPlaneSklSide.children[0].material.map.rotation = Math.PI / 2;
    this.zipPlaneSklSide.children[0].material.color.set(0xffffff);
    this.zipPlaneSklSide.children[0].material.needsUpdate = true;
    this.zipPlaneSglTop.children[0].material.map = textureSglTop;
    this.zipPlaneSglTop.children[0].material.map.rotation = Math.PI / 2;
    this.zipPlaneSglTop.children[0].material.color.set(0xffffff);
    this.zipPlaneSglTop.children[0].material.needsUpdate = true;
    this.zipPlaneSglSide.children[0].material.map = textureSglSide;
    this.zipPlaneSglSide.children[0].material.map.rotation = Math.PI / 2;
    this.zipPlaneSglSide.children[0].material.color.set(0xffffff);
    this.zipPlaneSglSide.children[0].material.needsUpdate = true;

    const allMapSgl = [];
    allMapSgl.push(this.zipTop3);
    allMapSgl.push(this.zipColumn3);
    allMapSgl.push(this.zipCenter3);
    allMapSgl.push(this.zipBottom3);
    allMapSgl.push(this.zipTop2);
    allMapSgl.push(this.zipColumn2);
    allMapSgl.push(this.zipCenter2);
    allMapSgl.push(this.zipBottom2);
    allMapSgl.push(this.zipHolder);
    allMapSgl.push(this.zipHolder2);
    allMapSgl.push(this.zipTop4);
    allMapSgl.push(this.zipBottom5);
    allMapSgl.push(this.zipTop5);
    allMapSgl.push(this.zipColumn5);

    allMapSgl.push(this.glassBase);
    allMapSgl.push(this.glassBeam);
    allMapSgl.push(this.glassColumn);
    allMapSgl.push(this.glassRoofL);
    allMapSgl.push(this.glassRoofP);
    allMapSgl.push(this.glassRoofT);
    allMapSgl.push(this.glasscolumnMid);
    allMapSgl.push(this.glasscompoundMid);

    allMapSgl.push(this.windowSglBorder);
    allMapSgl.push(this.windowSglFrame);
    allMapSgl.push(this.windowSglGlass);
    allMapSgl.push(this.windowSglHandle);
    allMapSgl.push(this.windowSglPartition);
    allMapSgl.push(this.windowSglGlassB);
    allMapSgl.push(this.windowSglGlassC);
    allMapSgl.push(this.windowSglFrameB2);
    allMapSgl.push(this.windowSglFrameB1);
    allMapSgl.push(this.windowSglFrameT);

    const lammelenMapSkl = [];
    lammelenMapSkl.push(this.louverRoof);
    lammelenMapSkl.push(this.louver2Roof);
    lammelenMapSkl.push(this.lamellen);

    const allMapSkl = [];
    allMapSkl.push(this.louverBase);
    allMapSkl.push(this.louver2Base);
    allMapSkl.push(this.louver3Base);
    allMapSkl.push(this.louverBeam);
    allMapSkl.push(this.louver2Beam);
    allMapSkl.push(this.louver3Beam);
    allMapSkl.push(this.louverColumn);
    allMapSkl.push(this.louver2Column);
    allMapSkl.push(this.louver3Column);
    allMapSkl.push(this.louver3Roof);
    allMapSkl.push(this.louver3RoofClip1);
    allMapSkl.push(this.louver3RoofClip2);
    allMapSkl.push(this.zipColumn);
    allMapSkl.push(this.zipTop);
    allMapSkl.push(this.zipBottom);

    allMapSkl.push(this.windowBorder);
    allMapSkl.push(this.windowFrame);
    allMapSkl.push(this.windowHandle);
    allMapSkl.push(this.windowPartition);
    allMapSkl.push(this.windowGlassB);
    allMapSkl.push(this.windowGlassC);
    allMapSkl.push(this.windowFrameB2);
    allMapSkl.push(this.windowFrameB1);
    allMapSkl.push(this.windowFrameT);

    allMapSkl.push(this.lamellenRailTop);
    allMapSkl.push(this.lamellenRailBottom);
    allMapSkl.push(this.lamellenFrameColumn);
    allMapSkl.push(this.lamellenFrameBase);

    allMapSgl.forEach((item) => {
      item.children[0].material.map = textureAllMapSgl;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    allMapSkl.forEach((item) => {
      item.children[0].material.map = textureAllMapSkl;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    lammelenMapSkl.forEach((item) => {
      item.children[0].material.map = textureLamellenMapSkl;
      item.children[0].material.map.rotation = Math.PI / 2;
      item.children[0].material.color.set(0xffffff);
      item.children[0].material.needsUpdate = true;
    });

    this.loaderValue(100);
    document.querySelector('.loader').classList.add('hidden');
    setTimeout(() => {
      document.querySelector('.loader').classList.add('hidden2');
    }, 1000);
  }
}
