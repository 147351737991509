import { createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../../services/user.service';

export const getCrmInfo = createAsyncThunk(
  'user/GetCrmInfo',
  async (email, thunkAPI) => {
    try {
      return await UserService.getCrmInfo(email);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const initialization = createAsyncThunk(
  'user/initialization',
  async (_, thunkAPI) => {
    try {
      const user = await UserService.initialization();

      if (user) {
        thunkAPI.dispatch(getCrmInfo());
      }

      return user;
    } catch (error) {
      if (error.statusCode === 409 || error.statusCode === 401) {
        await UserService.signOut();
      }
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const signIn = createAsyncThunk(
  'user/SignIn',
  async (params, thunkAPI) => {
    try {
      const user = await UserService.signIn(params);

      if (user) {
        thunkAPI.dispatch(getCrmInfo());
      }

      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const signUp = createAsyncThunk(
  'user/SignUp',
  async (params, thunkAPI) => {
    try {
      const user = await UserService.signUp(params);

      if (user) {
        thunkAPI.dispatch(getCrmInfo());
      }

      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/ResetPassword',
  async (params, thunkAPI) => {
    try {
      return await UserService.resetPassword(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const changePassword = createAsyncThunk(
  'user/ChangePassword',
  async (params, thunkAPI) => {
    try {
      return await UserService.changePassword(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const sendResetPasswordMail = createAsyncThunk(
  'user/SendResetPasswordMail',
  async (params, thunkAPI) => {
    try {
      return await UserService.sendResetPasswordMail(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const signOut = createAsyncThunk(
  'user/SignOut',
  async (params, thunkAPI) => {
    try {
      return await UserService.signOut();
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
export const setProfile2 = createAsyncThunk(
  'user/setProfile2',
  async (data, { rejectWithValue }) => {
    try {
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (params, thunkAPI) => {
    try {
      return await UserService.update(params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
