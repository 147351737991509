import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import ReactDOM from 'react-dom';

import { toast } from 'react-toastify';
import {
  setShowSaveProjectModal,
  setSelectedProject,
} from '../../../store/reducers/User/UserSlice';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import Button from '../../ui/Button';
// import saveIcon from '../../../assets/save-icon.png';
import InputText from '../../ui/Input';
import {
  useAddProjectMutation,
  useUpdateProjectMutation,
} from '../../../services/projects.service';

import './style.scss';

const SaveProjectModalContent = () => {
  const dispatch = useDispatch();
  const { profile, selectedProject } = useSelector((state) => state.user);
  const { source } = useSelector((state) => state.project);
  const [addProject, { isLoading: isCreating }] = useAddProjectMutation();
  const [updateProject, { isLoading: isUpdating }] = useUpdateProjectMutation();
  const [name, setName] = useState(selectedProject?.name || '');
  const [isExporting, setIsExporting] = useState(false);

  if (!profile) {
    return null;
  }

  const handleSave = async () => {
    if (name.length < 4) {
      toast.warn('At least 4 characters long!');
      return;
    }
    setIsExporting(true);

    const config = source.funcSaveProject();
    config.name = name;
    try {
      if (!selectedProject) {
        const { data } = await addProject(config);
        dispatch(setSelectedProject(data));
      } else {
        config._id = selectedProject._id;
        updateProject(config);
      }
      setIsExporting(false);
      dispatch(setShowSaveProjectModal(false));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="save-project-modal__content">
      <div className="save-project-modal__input">
        {isExporting ? (
          'Das Projekt wird gespeichert, bitte schließen Sie die Seite nicht!'
        ) : (
          <InputText
            type="text"
            placeholder="Mein Projekt"
            value={name ?? ''}
            onChange={(e) => setName(e.target.value)}
          />
        )}
      </div>
      <Button
        className="green big"
        disabled={isExporting || isCreating || isUpdating}
        onClick={handleSave}
      >
        Speichern
      </Button>
    </div>
  );
};

const SaveProjectModal = () => {
  const dispatch = useDispatch();
  const { showSaveProjectModal } = useSelector((state) => state.user);

  const handleClose = () => {
    dispatch(setShowSaveProjectModal(false));
  };

  return (
    <ModalWrapper
      show={showSaveProjectModal}
      close={handleClose}
      customClass="save-project-modal"
    >
      <ModalHeader title="Name vom  Projekt" close={handleClose} />
      <SaveProjectModalContent close={handleClose} />
    </ModalWrapper>
  );
};

export default SaveProjectModal;
