import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import UserService from 'services/user.service';
import Button from 'components/ui/Button';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import './style.scss';

const SiteRedirectModal = () => {
  const { showSiteRedirectModal } = useSelector((state) => state.user);

  const [email, setEmail] = useState('');

  const validationEmail = (contact) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(contact).toLowerCase());
  };

  const writeEmail = async (val) => {
    try {
      await UserService.writeEmail(val);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const redirect = () => {
    const res = validationEmail(email);
    if (!res) {
      toast.warn('Email not valid!');
    } else {
      toast
        .promise(writeEmail(email), {
          pending: 'Loading...',
        })
        .then((status) => {
          if (status) {
            setEmail('');
            window.location.assign(
              'https://cpq.sdesign.ch/integration?Run=SGL&BarColorActive=%2370cc97&BarColor=%23c2c2c2&Brand=TG'
            );
          } else {
            toast.warn('Error');
          }
        });
    }
  };

  return (
    <ModalWrapper
      show={showSiteRedirectModal}
      cursor={false}
      customClass="wrapper-site-redirect-modal"
    >
      <ModalHeader
        title="Glasdach Konfigurator"
        customClass="close-modal-none"
      />
      <div className="container-site-redirect-modal">
        <div className="description-container-site-redirect-modal">
          Bitte geben Sie Ihre E-Mail-Adresse ein:
        </div>
        <div className="site-redirect-modal-input">
          <input
            type="text"
            placeholder="E-Mail-Adresse"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>

        <div className="container-btnStart-site-redirect-modal">
          <Button
            className="btn-site-redirect-modal"
            onClick={() => {
              redirect();
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SiteRedirectModal;
