import React, { useMemo, useState } from 'react';
import CustomSimpleBar from 'components/CustomSimpleBar';
import './style.scss';

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CheckSvg } from 'assets/check-white.svg';
import { ReactComponent as TrashSvg } from 'assets/delete-black.svg';
import {
  useDeleteProjectMutation,
  useGetAllProjectsQuery,
} from '../../../../../services/projects.service';
import Loading from '../../../../Loading';
import Empty from '../../../../Empty';
import Button from '../../../../ui/Button';

import { setOpeningProject } from '../../../../../store/reducers/Project/ProjectSlice';
import {
  setSelectedProject,
  setShowAccountModal,
} from '../../../../../store/reducers/User/UserSlice';


const columnHelper = createColumnHelper();

const Projects = () => {
  const dispatch = useDispatch();
  const { data, isLoading: isLoadingQuery } = useGetAllProjectsQuery();
  const { source } = useSelector((state) => state.project);
  const [deleteProject, { isLoading: isDeleting }] = useDeleteProjectMutation();
  const [rowSelection, setRowSelection] = useState({});
  const [sorting, setSorting] = useState([]);
  const isLoading = isLoadingQuery || isDeleting;

  const handleDeleteProject = async (id) => {
    if (isLoading) return null;
    await deleteProject(id);
    return setRowSelection({});
  };

  const handleOpenProject = (project) => {
    if (isLoading) return null;
    dispatch(setOpeningProject(false));
    dispatch(setSelectedProject(project));
    source?.funcOpenProject(project);

    setTimeout(() => {
      source?.funcUpdatePrice();
    }, 500);

    setTimeout(() => {
      dispatch(setOpeningProject(true));
    }, 3000);

    return dispatch(setShowAccountModal(false));
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.name, {
        enableSorting: false,
        id: 'name',
        header: () => <span>Projektname</span>,
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.createdAt, {
        id: 'date',
        header: () => <span>Date</span>,
        cell: (info) => format(new Date(info.getValue()), 'H:m dd-MM-yyyy'),
      }),
      columnHelper.accessor((row) => row, {
        enableSorting: false,
        id: '_id',
        header: () => <span>Öffnen/Löschen</span>,
        cell: (info) => {
          const row = info.getValue();
          return (
            <div className="projects-table__actions">
              <Button
                className="x-small green"
                onClick={() => handleOpenProject(row)}
              >
                <CheckSvg />
              </Button>
              <Button
                className="x-small"
                onClick={() => handleDeleteProject(row._id)}
              >
                <TrashSvg />
              </Button>
            </div>
          );
        },
      }),
    ],
    [isLoading]
  );

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageSize: 12,
      },
    },
    state: {
      rowSelection,
      sorting,
    },
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (isLoadingQuery) {
    return <Loading />;
  }

  if (!data || !data.length) {
    return <Empty />;
  }

  return (
    <>
      <div className="projects-table">
        <CustomSimpleBar style={{ maxHeight: 'calc(90dvh - 350px)', width: '100%' }}>
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      <div
                        role="presentation"
                        {...{
                          className: header.column.getCanSort()
                            ? 'can-sort'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </CustomSimpleBar>
      </div>
      <div className="projects-table__pagination">
        <button
          type="button"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>
        <strong>
          {table.getState().pagination.pageIndex + 1} ... {table.getPageCount()}
        </strong>
        <button
          type="button"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
      </div>
    </>
  );
};

export default Projects;
