import {
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PlaneGeometry,
  TextureLoader,
} from 'three';

import pngEdit from '../../assets/edit.png';
import pngDelete from '../../assets/delete.png';

import pngWindow1 from '../../assets/window1.png';

import pngWindow2 from '../../assets/window2.png';
import pngZip from '../../assets/zip.png';
import pngZipRoof from '../../assets/dachbeschattung.png';
import pngLamellen from '../../assets/lamellen.png';

const createBtnWindow1Menu = (raycasterMenuMesh) => {
  const window1Menu = new Object3D();
  const planeEdit = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngEdit),
      side: 2,
    })
  );
  planeEdit.position.set(-0.3, 0.4, 0);
  planeEdit.renderOrder = 4;
  planeEdit.name = 'window1MenuEdit';
  raycasterMenuMesh.push(planeEdit);
  window1Menu.add(planeEdit);

  const planeDelete = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngDelete),
      side: 2,
    })
  );
  planeDelete.position.set(0.3, 0.4, 0);
  planeDelete.renderOrder = 4;
  planeDelete.name = 'window1MenuDelete';
  raycasterMenuMesh.push(planeDelete);
  window1Menu.add(planeDelete);

  const planeText = new Mesh(
    new PlaneGeometry(1.1, 0.18),
    new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      map: new TextureLoader().load(pngWindow1),
      side: 2,
    })
  );
  planeText.renderOrder = 4;
  window1Menu.add(planeText);
  planeEdit.material.map.encoding = 3001;
  planeDelete.material.map.encoding = 3001;
  planeText.material.map.encoding = 3001;

  return window1Menu;
};

const createBtnWindow2Menu = (raycasterMenuMesh) => {
  const window2Menu = new Object3D();
  const planeEdit = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngEdit),
      side: 2,
    })
  );
  planeEdit.position.set(-0.3, 0.4, 0);
  planeEdit.renderOrder = 4;
  planeEdit.name = 'window2MenuEdit';
  raycasterMenuMesh.push(planeEdit);
  window2Menu.add(planeEdit);

  const planeDelete = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngDelete),
      side: 2,
    })
  );
  planeDelete.position.set(0.3, 0.4, 0);
  planeDelete.renderOrder = 4;
  planeDelete.name = 'window2MenuDelete';
  raycasterMenuMesh.push(planeDelete);
  window2Menu.add(planeDelete);

  const planeText = new Mesh(
    new PlaneGeometry(1.1, 0.36),
    new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      map: new TextureLoader().load(pngWindow2),
      side: 2,
    })
  );
  planeText.renderOrder = 4;
  planeText.position.y = -0.08;
  window2Menu.add(planeText);
  planeEdit.material.map.encoding = 3001;
  planeDelete.material.map.encoding = 3001;
  planeText.material.map.encoding = 3001;

  return window2Menu;
};

const createBtnLamellenMenu = (raycasterMenuMesh) => {
  const lamellenMenu = new Object3D();
  const planeEdit = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngEdit),
      side: 2,
    })
  );
  planeEdit.position.set(-0.3, 0.4, 0);
  planeEdit.renderOrder = 4;
  planeEdit.name = 'lamellenMenuEdit';
  raycasterMenuMesh.push(planeEdit);
  lamellenMenu.add(planeEdit);

  const planeDelete = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngDelete),
      side: 2,
    })
  );
  planeDelete.position.set(0.3, 0.4, 0);
  planeDelete.renderOrder = 4;
  planeDelete.name = 'lamellenMenuDelete';
  raycasterMenuMesh.push(planeDelete);
  lamellenMenu.add(planeDelete);

  const planeText = new Mesh(
    new PlaneGeometry(1.1, 0.18),
    new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      map: new TextureLoader().load(pngLamellen),
      side: 2,
    })
  );
  planeText.renderOrder = 4;
  lamellenMenu.add(planeText);
  planeEdit.material.map.encoding = 3001;
  planeDelete.material.map.encoding = 3001;
  planeText.material.map.encoding = 3001;

  return lamellenMenu;
};

const createBtnZipMenu = (raycasterMenuMesh) => {
  const zipMenu = new Object3D();
  const planeEdit = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngEdit),
      side: 2,
    })
  );
  planeEdit.position.set(-0.3, 0.4, 0);
  planeEdit.renderOrder = 4;
  planeEdit.name = 'zipMenuEdit';
  raycasterMenuMesh.push(planeEdit);
  zipMenu.add(planeEdit);

  const planeDelete = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngDelete),
      side: 2,
    })
  );

  planeDelete.position.set(0.3, 0.4, 0);
  planeDelete.renderOrder = 4;
  planeDelete.name = 'zipMenuDelete';
  raycasterMenuMesh.push(planeDelete);
  zipMenu.add(planeDelete);

  const planeText = new Mesh(
    new PlaneGeometry(1.1, 0.18),
    new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      map: new TextureLoader().load(pngZip),
      side: 2,
    })
  );
  planeText.renderOrder = 4;
  zipMenu.add(planeText);
  planeEdit.material.map.encoding = 3001;
  planeDelete.material.map.encoding = 3001;
  planeText.material.map.encoding = 3001;

  return zipMenu;
};

const createBtnZipRoofMenu = (raycasterMenuMesh) => {
  const zipRoofMenu = new Object3D();
  const planeEdit = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngEdit),
      side: 2,
    })
  );
  planeEdit.position.set(-0.3, 0.4, 0);
  planeEdit.renderOrder = 4;
  planeEdit.name = 'zipRoofMenuEdit';
  raycasterMenuMesh.push(planeEdit);
  zipRoofMenu.add(planeEdit);

  const planeDelete = new Mesh(
    new PlaneGeometry(0.512, 0.512),
    new MeshBasicMaterial({
      transparent: true,
      color: 0xffffff,
      map: new TextureLoader().load(pngDelete),
      side: 2,
    })
  );

  planeDelete.position.set(0.3, 0.4, 0);
  planeDelete.renderOrder = 4;
  planeDelete.name = 'zipRoofMenuDelete';
  raycasterMenuMesh.push(planeDelete);
  zipRoofMenu.add(planeDelete);

  const planeText = new Mesh(
    new PlaneGeometry(1.1, 0.18),
    new MeshBasicMaterial({
      color: 0xffffff,
      transparent: true,
      map: new TextureLoader().load(pngZipRoof),
      side: 2,
    })
  );
  planeText.renderOrder = 4;
  zipRoofMenu.add(planeText);
  planeEdit.material.map.encoding = 3001;
  planeDelete.material.map.encoding = 3001;
  planeText.material.map.encoding = 3001;

  return zipRoofMenu;
};

const createMenu = {
  createBtnWindow1Menu,
  createBtnLamellenMenu,
  createBtnWindow2Menu,
  createBtnZipMenu,
  createBtnZipRoofMenu,
};

export default createMenu;
