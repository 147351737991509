export default class ChangeMapElCommand {
  constructor(arr, prevMap, nextMap) {
    this.arr = arr;
    this.prevMap = prevMap;
    this.nextMap = nextMap;
  }

  execute() {
    this.arr.forEach((item) => (item.children[0].material.map = this.nextMap));
  }

  undo() {
    this.arr.forEach((item) => (item.children[0].material.map = this.prevMap));
  }
}
