import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/ui/Button';
import {
  setSelectedTypeModel,
  setTypeModel,
} from 'store/reducers/Project/ProjectSlice';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import {
  setShowSearchModalProduct,
  setShowSelectModalProduct,
  setShowWarningModalRestart,
  setShowTypeLamellendachModal,
} from '../../../store/reducers/User/UserSlice';

import option1 from '../../../assets/search-product/1.jpg';
import option2 from '../../../assets/search-product/2.jpg';
import option3 from '../../../assets/search-product/3.jpg';
import option4 from '../../../assets/search-product/4.jpg';
import option5 from '../../../assets/search-product/5.jpg';
import option6 from '../../../assets/search-product/6.jpg';
import option7 from '../../../assets/search-product/7.jpg';
import option8 from '../../../assets/search-product/8.jpg';
import option9 from '../../../assets/search-product/9.jpg';
import option10 from '../../../assets/search-product/10.jpg';
import option11 from '../../../assets/search-product/11.jpg';
import option12 from '../../../assets/search-product/12.jpg';

import lamellendach from '../../../assets/search-product/lamellendach.jpg';
import glasdach from '../../../assets/search-product/glasdach.jpg';
import recommendation from '../../../assets/search-product/recommendation.png';

import star from '../../../assets/search-product/star.png';
import starfill from '../../../assets/search-product/star-fill.png';

import price from '../../../assets/search-product/price.png';

import './style.scss';

const SearchProduct = () => {
  const dispatch = useDispatch();
  const expertMode = useSelector((state) => state.user.expertMode);
  const { showSearchModalProduct } = useSelector((state) => state.user);
  const { source, typeModel } = useSelector((state) => state.project);

  const [steap, setSteap] = useState(0);

  const [counterLamellendach, setCounterLamellendach] = useState(0);
  const [countergGlasdach, setCountergGlasdach] = useState(0);

  const [weatherProtection, setWeatherProtection] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });
  const [season, setSeason] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });
  const [adventure, setAdventure] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  });

  const product = (next) => {
    setSteap(steap + next);
  };

  useEffect(() => {
    const countL =
      (weatherProtection.option1 ? 5 : 0) +
      (weatherProtection.option2 ? 4 : 0) +
      (weatherProtection.option3 ? 4 : 0) +
      (weatherProtection.option4 ? 4 : 0) +
      (season.option1 ? 4 : 0) +
      (season.option2 ? 5 : 0) +
      (season.option3 ? 5 : 0) +
      (season.option4 ? 3 : 0) +
      (adventure.option1 ? 5 : 0) +
      (adventure.option2 ? 5 : 0) +
      (adventure.option3 ? 4 : 0) +
      (adventure.option4 ? 3 : 0);
    const countG =
      (weatherProtection.option1 ? 5 : 0) +
      (weatherProtection.option2 ? 5 : 0) +
      (weatherProtection.option3 ? 5 : 0) +
      (weatherProtection.option4 ? 5 : 0) +
      (season.option1 ? 5 : 0) +
      (season.option2 ? 4 : 0) +
      (season.option3 ? 4 : 0) +
      (season.option4 ? 5 : 0) +
      (adventure.option1 ? 3 : 0) +
      (adventure.option2 ? 3 : 0) +
      (adventure.option3 ? 5 : 0) +
      (adventure.option4 ? 5 : 0);
    setCounterLamellendach(countL);
    setCountergGlasdach(countG);
  }, [steap]);

  const selectType = (type) => {
    if (!type) {
      toast.warn('Produkt auswählen');
      return;
    }

    if(type === 'louver') {
      dispatch(setShowSearchModalProduct(false));
   
      setSteap(0);
      setCounterLamellendach(0);
      setCountergGlasdach(0);
      setWeatherProtection({
        option1: false,
        option2: false,
        option3: false,
        option4: false,
      });
      setSeason({
        option1: false,
        option2: false,
        option3: false,
        option4: false,
      });
      setAdventure({
        option1: false,
        option2: false,
        option3: false,
        option4: false,
      });
      dispatch(setShowTypeLamellendachModal(true))
      return
    }



    if (expertMode) {
      if (typeModel === null) {
        dispatch(setTypeModel(type));
        source?.funcSelectType(type);
      } else {
        dispatch(setSelectedTypeModel(type));
        dispatch(setShowWarningModalRestart(true));
      }
    } else {
      dispatch(setTypeModel(type));
      source?.funcSelectType(type);
      window.openVideoTutorial();
    }

    
    dispatch(setShowSearchModalProduct(false));
    setSteap(0);
    setCounterLamellendach(0);
    setCountergGlasdach(0);
    setWeatherProtection({
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    });
    setSeason({
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    });
    setAdventure({
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    });
  };




  const handleClose = () => {


    dispatch(setShowSearchModalProduct(false));
  


    setSteap(0);
    setCounterLamellendach(0);
    setCountergGlasdach(0);
    setWeatherProtection({
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    });
    setSeason({
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    });
    setAdventure({
      option1: false,
      option2: false,
      option3: false,
      option4: false,
    });

    dispatch(setShowSelectModalProduct(true));

  }


  return (
    <ModalWrapper
      show={showSearchModalProduct}
      customClass={
        steap === 3 ? 'wrapper-search-product max' : 'wrapper-search-product'
      }
      close={handleClose}
      cursor={false}
    >
      <ModalHeader title="PRODUKTFINDER"   close={handleClose} />


      {steap === 0 ? (
        <div className="container-search-product">
          <div className="title-content-search-product">
            Welchen Schutz möchte ich?
          </div>

          <div className="container-options-search-product">
            <Button
              className={
                weatherProtection.option1
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setWeatherProtection((prev) => ({
                  ...prev,
                  option1: !prev.option1,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option1}
              />
              <div className="title-item-option-search-product">Sonne</div>
            </Button>
            <Button
              className={
                weatherProtection.option2
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setWeatherProtection((prev) => ({
                  ...prev,
                  option2: !prev.option2,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option2}
              />
              <div className="title-item-option-search-product">Regen</div>
            </Button>
            <Button
              className={
                weatherProtection.option3
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setWeatherProtection((prev) => ({
                  ...prev,
                  option3: !prev.option3,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option3}
              />
              <div className="title-item-option-search-product">Wind</div>
            </Button>
            <Button
              className={
                weatherProtection.option4
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setWeatherProtection((prev) => ({
                  ...prev,
                  option4: !prev.option4,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option4}
              />
              <div className="title-item-option-search-product">Schnee</div>
            </Button>
          </div>

          <Button
            className="btn-search-product"
            onClick={() => {
              product(1);
            }}
          >
            Weiter
          </Button>
        </div>
      ) : null}

      {steap === 1 ? (
        <div className="container-search-product">
          <div className="title-content-search-product">
            In welcher Jahreszeit möchte ich mich geschützt draussen erholen?
          </div>
          <div className="container-options-search-product">
            <Button
              className={
                season.option1
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setSeason((prev) => ({
                  ...prev,
                  option1: !prev.option1,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option5}
              />
              <div className="title-item-option-search-product">Frühling</div>
            </Button>
            <Button
              className={
                season.option2
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setSeason((prev) => ({
                  ...prev,
                  option2: !prev.option2,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option6}
              />
              <div className="title-item-option-search-product">Sommer</div>
            </Button>
            <Button
              className={
                season.option3
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setSeason((prev) => ({
                  ...prev,
                  option3: !prev.option3,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option7}
              />
              <div className="title-item-option-search-product">Herbst</div>
            </Button>
            <Button
              className={
                season.option4
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setSeason((prev) => ({
                  ...prev,
                  option4: !prev.option4,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option8}
              />
              <div className="title-item-option-search-product">Winter</div>
            </Button>
          </div>
          <Button
            className="btn-search-product"
            onClick={() => {
              product(1);
            }}
          >
            Weiter
          </Button>
        </div>
      ) : null}

      {steap === 2 ? (
        <div className="container-search-product">
          <div className="title-content-search-product">
            Was möchte ich draussen erleben?
          </div>
          <div className="container-options-search-product">
            <Button
              className={
                adventure.option1
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setAdventure((prev) => ({
                  ...prev,
                  option1: !prev.option1,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option9}
              />
              <div className="title-item-option-search-product">
                Schattenspiel
              </div>
            </Button>
            <Button
              className={
                adventure.option2
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setAdventure((prev) => ({
                  ...prev,
                  option2: !prev.option2,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option10}
              />
              <div className="title-item-option-search-product">
                Luftzirkulation
              </div>
            </Button>
            <Button
              className={
                adventure.option3
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setAdventure((prev) => ({
                  ...prev,
                  option3: !prev.option3,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option11}
              />
              <div className="title-item-option-search-product">
                Lichteinfall
              </div>
            </Button>
            <Button
              className={
                adventure.option4
                  ? 'item-option-search-product active'
                  : 'item-option-search-product'
              }
              onClick={() => {
                setAdventure((prev) => ({
                  ...prev,
                  option4: !prev.option4,
                }));
              }}
            >
              <img
                className="img-item-option-search-product"
                alt=""
                src={option12}
              />
              <div className="title-item-option-search-product">
                Sternenhimmel
              </div>
            </Button>
          </div>
          <Button
            className="btn-search-product"
            onClick={() => {
              product(1);
            }}
          >
            Weiter
          </Button>
        </div>
      ) : null}

      {steap === 3 ? (
        <div className="container-search-product">
          <div className="title-content-search-product">Unsere Voschläge</div>

          <div className="select-img-product-search">
            <div className="buffer-img-product-search"> </div>

            <div className="container-img-product-search">
              <div className="bg-gray-product-search"> </div>
              <img src={lamellendach} alt="" />

              {counterLamellendach > countergGlasdach ? (
                <img
                  className="recommendation-search-product"
                  src={recommendation}
                  alt=""
                />
              ) : null}

              <div className="title-container-img-product-search">
                Lamellendach
              </div>
            </div>
            <div className="container-img-product-search">
              <div className="bg-gray-product-search"> </div>
              <img src={glasdach} alt="" />
              {counterLamellendach < countergGlasdach ? (
                <img
                  className="recommendation-search-product"
                  src={recommendation}
                  alt=""
                />
              ) : null}
              <div className="title-container-img-product-search">Glasdach</div>
            </div>
          </div>

          <div className="container-info-search-product">
            <div className="item-info-search-product">
              <div className="title-item-info-search-product">Wetterschutz</div>
              <div className="options-items-info-search-product">
                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Sonnen</div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />
                  </div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option1 ? starfill : star}
                      alt=""
                    />
                  </div>
                </div>
                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Regen</div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />
                  </div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option2 ? starfill : star}
                      alt=""
                    />
                  </div>
                </div>
                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Wind</div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />{' '}
                  </div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option3 ? starfill : star}
                      alt=""
                    />
                  </div>
                </div>
                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Schnee</div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />
                  </div>
                  <div className="star-item-info-search-product">
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />{' '}
                    <img
                      src={weatherProtection.option4 ? starfill : star}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="item-info-search-product">
              <div className="title-item-info-search-product">Saison</div>
              <div className="options-items-info-search-product">
                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Frühling</div>
                  <div className="star-item-info-search-product">
                    <img src={season.option1 ? starfill : star} alt="" />
                    <img src={season.option1 ? starfill : star} alt="" />
                    <img src={season.option1 ? starfill : star} alt="" />
                    <img src={season.option1 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={season.option1 ? starfill : star} alt="" />
                    <img src={season.option1 ? starfill : star} alt="" />
                    <img src={season.option1 ? starfill : star} alt="" />
                    <img src={season.option1 ? starfill : star} alt="" />
                    <img src={season.option1 ? starfill : star} alt="" />
                  </div>
                </div>

                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Sommer</div>
                  <div className="star-item-info-search-product">
                    <img src={season.option2 ? starfill : star} alt="" />
                    <img src={season.option2 ? starfill : star} alt="" />
                    <img src={season.option2 ? starfill : star} alt="" />
                    <img src={season.option2 ? starfill : star} alt="" />
                    <img src={season.option2 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={season.option2 ? starfill : star} alt="" />
                    <img src={season.option2 ? starfill : star} alt="" />
                    <img src={season.option2 ? starfill : star} alt="" />
                    <img src={season.option2 ? starfill : star} alt="" />
                  </div>
                </div>

                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Herbst</div>
                  <div className="star-item-info-search-product">
                    <img src={season.option3 ? starfill : star} alt="" />
                    <img src={season.option3 ? starfill : star} alt="" />
                    <img src={season.option3 ? starfill : star} alt="" />
                    <img src={season.option3 ? starfill : star} alt="" />
                    <img src={season.option3 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={season.option3 ? starfill : star} alt="" />
                    <img src={season.option3 ? starfill : star} alt="" />
                    <img src={season.option3 ? starfill : star} alt="" />
                    <img src={season.option3 ? starfill : star} alt="" />
                  </div>
                </div>

                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Winter</div>
                  <div className="star-item-info-search-product">
                    <img src={season.option4 ? starfill : star} alt="" />
                    <img src={season.option4 ? starfill : star} alt="" />
                    <img src={season.option4 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={season.option4 ? starfill : star} alt="" />
                    <img src={season.option4 ? starfill : star} alt="" />
                    <img src={season.option4 ? starfill : star} alt="" />
                    <img src={season.option4 ? starfill : star} alt="" />
                    <img src={season.option4 ? starfill : star} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="item-info-search-product">
              <div className="title-item-info-search-product">Erlebniss</div>
              <div className="options-items-info-search-product">
                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">
                    Schattenspiel
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option1 ? starfill : star} alt="" />
                    <img src={adventure.option1 ? starfill : star} alt="" />
                    <img src={adventure.option1 ? starfill : star} alt="" />
                    <img src={adventure.option1 ? starfill : star} alt="" />
                    <img src={adventure.option1 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option1 ? starfill : star} alt="" />
                    <img src={adventure.option1 ? starfill : star} alt="" />
                    <img src={adventure.option1 ? starfill : star} alt="" />
                  </div>
                </div>

                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">
                    Luftzirkulation
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option2 ? starfill : star} alt="" />
                    <img src={adventure.option2 ? starfill : star} alt="" />
                    <img src={adventure.option2 ? starfill : star} alt="" />
                    <img src={adventure.option2 ? starfill : star} alt="" />
                    <img src={adventure.option2 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option2 ? starfill : star} alt="" />
                    <img src={adventure.option2 ? starfill : star} alt="" />
                    <img src={adventure.option2 ? starfill : star} alt="" />
                  </div>
                </div>

                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">
                    Lichteinfall
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option3 ? starfill : star} alt="" />
                    <img src={adventure.option3 ? starfill : star} alt="" />
                    <img src={adventure.option3 ? starfill : star} alt="" />
                    <img src={adventure.option3 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option3 ? starfill : star} alt="" />
                    <img src={adventure.option3 ? starfill : star} alt="" />
                    <img src={adventure.option3 ? starfill : star} alt="" />
                    <img src={adventure.option3 ? starfill : star} alt="" />
                    <img src={adventure.option3 ? starfill : star} alt="" />
                  </div>
                </div>

                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">
                    Sternenhimmel
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option4 ? starfill : star} alt="" />
                    <img src={adventure.option4 ? starfill : star} alt="" />
                    <img src={adventure.option4 ? starfill : star} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={adventure.option4 ? starfill : star} alt="" />
                    <img src={adventure.option4 ? starfill : star} alt="" />
                    <img src={adventure.option4 ? starfill : star} alt="" />
                    <img src={adventure.option4 ? starfill : star} alt="" />
                    <img src={adventure.option4 ? starfill : star} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="item-info-search-product">
              <div className="title-item-info-search-product">Kosten</div>
              <div className="options-items-info-search-product">
                <div className="option-item-info-search-product">
                  <div className="name-item-info-search-product">Preis</div>
                  <div className="star-item-info-search-product">
                    <img src={price} alt="" />
                    <img src={price} alt="" />
                    <img src={price} alt="" />
                    <img src={price} alt="" />
                  </div>
                  <div className="star-item-info-search-product">
                    <img src={price} alt="" />
                    <img src={price} alt="" />
                    <img src={price} alt="" />
                    <img src={price} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="select-btns-product-search">
            <div className="buffer-btns-product-search"> </div>

            <div className="container-btn-product-search">
              <Button
                className="btn-konfigurieren-product"
                onClick={() => {
                  selectType('louver');
                }}
              >
                Konfigurieren
              </Button>
            </div>
            <div className="container-btn-product-search">
              <Button
                className="btn-konfigurieren-product"
                onClick={() => {
                  selectType('glass');
                }}
              >
                Konfigurieren
              </Button>
            </div>
          </div>
        </div>
      ) : null}
    </ModalWrapper>
  );
};

export default SearchProduct;
