import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import './style.scss';
import { ReactComponent as LockSvg } from 'assets/lock.svg';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import {
  setShowLoginModal,
  setShowRegistrationModal,
  setShowResetPasswordModal,
  signIn,
} from '../../../store/reducers/User/UserSlice';
import { validateEmail } from '../../../utils/validation';
import InputText from '../../ui/Input';

import Button from '../../ui/Button';

const initState = {
  email: '',
  password: '',
  captcha: false,
};

const LoginModal = () => {
  const dispatch = useDispatch();
  const { showLoginModal, isLoading } = useSelector((state) => state.user);
  const [values, setValues] = useState(initState);

  const handleClose = () => dispatch(setShowLoginModal(false));
  const handleSubmit = () => {
    if (!validateEmail(values.email)) {
      return toast.warn('Email not valid!');
    }

    return toast
      .promise(
        dispatch(
          signIn({
            email: values.email,
            password: values.password,
          })
        ),
        {
          pending: 'Loading...',
          error: 'Error!',
        }
      )
      .then((state) => {
        if (!state?.error) {
          setValues(initState);
          handleClose();
        }
      });
  };
  const handleShowRegistration = () => {
    handleClose();
    dispatch(setShowRegistrationModal(true));
  };
  const handleShowResetPassword = () => {
    handleClose();
    dispatch(setShowResetPasswordModal(true));
  };

  return (
    <ModalWrapper
      show={showLoginModal}
      close={handleClose}
      customClass="login-modal"
    >
      <ModalHeader title="Login" close={handleClose} />
      <div className="login-modal__content">
        <p>
          Bitte melden Sie sich an, um Ihr Projekt zu speichern und den Preis
          anzufragen.
        </p>
        <div className="login-modal__input">
          <InputText
            type="email"
            placeholder="E-Mail"
            autoComplete="on"
            onChange={(e) => setValues({ ...values, email: e.target.value })}
          />
        </div>
        <div className="login-modal__input">
          <InputText
            type="password"
            placeholder="Passwort"
            onChange={(e) => setValues({ ...values, password: e.target.value })}
          />
        </div>
        <div className="login-modal__submit">
          <p>
            Sie haben kein Konto? Melden Sie sich{' '}
            <Button onClick={handleShowRegistration} className="accent-color">
              hier an.
            </Button>
          </p>
          <Button
            className="login-modal__btn big green"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Anmeldung
          </Button>
        </div>
        <div className="login-modal__footer">
          <span>
            Passwort vergessen? Setzen Sie Ihr Passwort hier zurück.{' '}
            <Button className="accent-color" onClick={handleShowResetPassword}>
              hier{' '}
            </Button>
            zurück.
          </span>
          <LockSvg />
        </div>
      </div>
    </ModalWrapper>
  );
};

export default LoginModal;
