import { Object3D, Mesh, Group, Material, Scene } from 'three';

const deepClone = (object) => {
  let newObject;
  if (object instanceof Mesh)
    // @ts-ignore
    newObject = new Mesh(object.geometry, object.material);
  else if (object instanceof Group) newObject = new Group();
  // else if (object instanceof PointLight)
  //   newObject = new PointLight(object.color, object.intensity);
  else if (object instanceof Scene) newObject = new Scene();
  else if (object instanceof Object3D) newObject = new Object3D();
  else return new Object3D();

  newObject.name = object.name;

  newObject.up.copy(object.up);

  newObject.position.copy(object.position);
  newObject.quaternion.copy(object.quaternion);
  newObject.scale.copy(object.scale);

  newObject.matrix.copy(object.matrix);
  newObject.matrixWorld.copy(object.matrixWorld);

  newObject.matrixAutoUpdate = object.matrixAutoUpdate;
  newObject.matrixWorldNeedsUpdate = object.matrixWorldNeedsUpdate;

  newObject.visible = object.visible;

  newObject.castShadow = object.castShadow;
  newObject.receiveShadow = object.receiveShadow;

  newObject.frustumCulled = object.frustumCulled;

  newObject.userData = JSON.parse(JSON.stringify(object.userData));

  if (newObject instanceof Mesh) {
    if (object.material instanceof Material) {
      newObject.material = object.material.clone();

      if (object.material.map) {
        newObject.material.map.name = `${object.material.map.userData
          .split('/')
          .pop()}`;
      }
    }
    if (object.material instanceof Array) {
      newObject.material = object.material.map((material) => material.clone());
    }
    newObject.geometry = object.geometry.clone();
  }

  newObject.children.slice().forEach((child) => newObject.remove(child));
  object.children.forEach((child) => newObject.add(deepClone(child)));
  return newObject;
};

export { deepClone };
