import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setShowInfoModal } from 'store/reducers/User/UserSlice';
import Button from 'components/ui/Button';
import InfoButton from '../Elemente/InfoButton';
import infoIcon from '../../../assets/info.svg';
import { ReactComponent as Arrow } from '../../../assets/arrow-top.svg';

const Extras = () => {
  const expertMode = useSelector((state) => state.user.expertMode);
  const { source } = useSelector((state) => state.project);

  const [toggleDropDown, setToggleDropDown] = useState(false);

  const dispatch = useDispatch();

  const [onSiteMeasurements, setOnSiteMeasurements] = useState(false);
  const [constructionSiteSearch, setConstructionSiteSearch] = useState(false);
  const [planning, setPlanning] = useState(false);
  const [installationPlanning, setInstallationPlanning] = useState(false);
  const [fundamente, setFundamente] = useState(false);
  const [anpassungen, setAnpassungen] = useState(false);

  const [colFundamente, setColFundamente] = useState(0);
  const [colPlattenpassen, setColPlattenpassen] = useState(0);

  useEffect(() => {
    if (source) {
      source.funcChangeExtras(
        onSiteMeasurements,
        constructionSiteSearch,
        planning,
        installationPlanning,
        fundamente,
        anpassungen,
        +colFundamente,
        +colPlattenpassen
      );
    }
  }, [
    onSiteMeasurements,
    constructionSiteSearch,
    planning,
    installationPlanning,
    fundamente,
    anpassungen,
    colFundamente,
    colPlattenpassen,
  ]);

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('extras', (data) => {
      setOnSiteMeasurements(data.extras.servicesTG.aufmassVorOrt);
      setConstructionSiteSearch(data.extras.servicesTG.baugensuch);
      setPlanning(data.extras.servicesTG.planung);
      setInstallationPlanning(data.extras.servicesTG.montagePlanung);
      setFundamente(data.extras.externalServicesTG.fundamente);
      setAnpassungen(data.extras.externalServicesTG.plattenpassen);

      setColFundamente(data.extras.externalServicesTG.colFundamente);
      setColPlattenpassen(data.extras.externalServicesTG.colPlattenpassen);
    });
  }, [source]);

  return (
    <>
      <div style={{ marginTop: '24px' }} className="accordion-item">
        <div className="accordion-item-title">
          Dienstleistungen TG{' '}
          {expertMode ? (
            <Button
              className="drop-down-dienstleistungen"
              onClick={() => {
                setToggleDropDown((prev) => !prev);
              }}
            >
              <Arrow className={toggleDropDown ? 'rotate-open' : null} />
            </Button>
          ) : null}
          <InfoButton
            title="Dienstleistungen TG"
            text="Wünschen Sie, dass wir Ihr Baugesuch fixfertig für Sie vorbereiten? Wir begleiten Sie von der Beratung bis zur Montage vollumfänglich."
            link="https://www.thermogreen.ch/projektplanung/"
          />
          <Button
            className="info-icon-btn"
            onClick={() => {
              dispatch(
                setShowInfoModal({
                  title: 'Dienstleistungen TG',
                  text: 'Wünschen Sie, dass wir Ihr Baugesuch fixfertig für Sie vorbereiten? Wir begleiten Sie von der Beratung bis zur Montage vollumfänglich.',
                  link: 'https://www.thermogreen.ch/projektplanung/',
                })
              );
            }}
          >
            <img src={infoIcon} alt="infoIcon" />
          </Button>
        </div>

        <div
          style={{ marginTop: '26px' }}
          className={
            toggleDropDown || !expertMode
              ? 'accordion-item-content'
              : 'accordion-item-content not-visible'
          }
        >
          <div className="wrapper-accordion-option-checkbox">
            <label
              htmlFor="accordion-input4"
              className="accordion-option-checkbox"
            >
              <input
                id="accordion-input4"
                type="checkbox"
                checked={onSiteMeasurements}
                onChange={(e) => {
                  setOnSiteMeasurements(e.target.checked);
                }}
              />
              <span className="checkmark"> </span>
              Aufmass vor Ort
            </label>
          </div>
          <div className="wrapper-accordion-option-checkbox">
            <label
              htmlFor="accordion-input5"
              className="accordion-option-checkbox"
              style={{ marginBottom: '26px' }}
            >
              <input
                id="accordion-input5"
                type="checkbox"
                checked={constructionSiteSearch}
                onChange={(e) => {
                  setConstructionSiteSearch(e.target.checked);
                }}
              />
              <span className="checkmark"> </span>
              Baugesuch
            </label>
          </div>
          {/* скрытый элемент */}
          <div
            className="wrapper-accordion-option-checkbox"
            style={{ display: 'none' }}
          >
            <label
              htmlFor="accordion-input6"
              className="accordion-option-checkbox"
            >
              <input
                id="accordion-input6"
                type="checkbox"
                checked={planning}
                onChange={(e) => {
                  setPlanning(e.target.checked);
                  // if (e.target.checked) {
                  //   setInstallationPlanning(false);
                  // }
                }}
              />
              <span className="checkmark"> </span>
              Nur Planung
            </label>
          </div>
          <div className="wrapper-accordion-option-checkbox">
            <label
              htmlFor="accordion-input7"
              className="accordion-option-checkbox"
            >
              <input
                id="accordion-input7"
                type="checkbox"
                checked={installationPlanning}
                onChange={(e) => {
                  setInstallationPlanning(e.target.checked);
                  // if (e.target.checked) {
                  //   setPlanning(false);
                  // }
                }}
              />
              <span className="checkmark"> </span>
              Planung und Montage
            </label>
          </div>
        </div>
      </div>

      {/* скрытый элемент */}
      <div className="accordion-item " style={{ display: 'none' }}>
        <div className="accordion-item-title">
          Fremdleistungen durch TG organisiert
          <InfoButton
            title="Fremdleistungen durch TG organisiert"
            text="Dürfen wir Fremdleistungen für Sie organisieren? Gerne koordinieren wir für Sie, damit Sie den Kopf für andere Dinge frei haben."
            link={undefined}
          />
          <Button
            className="info-icon-btn"
            onClick={() => {
              dispatch(
                setShowInfoModal({
                  title: 'Fremdleistungen durch TG organisiert',
                  text: 'Dürfen wir Fremdleistungen für Sie organisieren? Gerne koordinieren wir für Sie, damit Sie den Kopf für andere Dinge frei haben.',
                  link: '',
                })
              );
            }}
          >
            <img src={infoIcon} alt="infoIcon" />
          </Button>
        </div>

        <input
          className={fundamente ? 'colFundamente' : 'colFundamente disable'}
          required
          type="number"
          value={colFundamente}
          onBlur={(e) => {
            if (e.target.value <= 0) {
              setColFundamente(0);
            }
          }}
          onChange={(e) => setColFundamente(e.target.value)}
        />

        <input
          className={
            anpassungen ? 'colPlattenpassen' : 'colPlattenpassen disable'
          }
          required
          type="number"
          value={colPlattenpassen}
          onBlur={(e) => {
            if (e.target.value <= 0) {
              setColPlattenpassen(0);
            }
          }}
          onChange={(e) => setColPlattenpassen(e.target.value)}
        />

        <div className="accordion-item-content column">
          <div className="wrapper-accordion-option-checkbox ">
            <label
              htmlFor="accordion-input8"
              className="accordion-option-checkbox"
            >
              <input
                id="accordion-input8"
                type="checkbox"
                checked={fundamente}
                onChange={(e) => {
                  setFundamente(e.target.checked);
                }}
              />
              <span className="checkmark"> </span>
              Einzelfundamente
            </label>
          </div>
          <div className="wrapper-accordion-option-checkbox width">
            <label
              htmlFor="accordion-input9"
              className="accordion-option-checkbox"
            >
              <input
                id="accordion-input9"
                type="checkbox"
                checked={anpassungen}
                onChange={(e) => {
                  setAnpassungen(e.target.checked);
                }}
              />
              <span className="checkmark"> </span>
              Platten Anpassungen
            </label>
          </div>
        </div>
      </div>
    </>
  );
};

export default Extras;
