import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';

export const ModalHeader = ({
  title,
  close,
  customClass = '',
  color = '#000',
  bg = '#eee',
}) => {
  const onKeyDown = (event) => {
    event.preventDefault();
    if (event.key === 'Escape') {
      close();
    }
  };

  return (
    <div className="ModalWrapper__header" style={{ color, background: bg }}>
      <span>{title}</span>
      <div
        role="button"
        tabIndex={0}
        aria-label="Close"
        className={`ModalWrapper__close ${customClass}`}
        onClick={close}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

const ModalWrapper = ({
  show,
  close,
  children,
  customClass = '',
  cursor = true,
}) => {
  if (!show) {
    return null;
  }

  const onKeyDown = (event) => {
    event.preventDefault();
    if (event.key === 'Escape') {
      close();
    }
  };

  return ReactDOM.createPortal(
    <div className="ModalWrapper">
      <div
        role="button"
        tabIndex={0}
        aria-label="Close"
        className={cursor ? 'ModalWrapper__bg' : 'ModalWrapper__bg default'}
        onClick={close}
        onKeyDown={onKeyDown}
      />
      <div className={`ModalWrapper__content ${customClass}`}>{children}</div>
    </div>,
    document.body
  );
};

export default ModalWrapper;
