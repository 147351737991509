import { useCallback } from 'react';
import CustomSimpleBar from 'components/CustomSimpleBar';
import { useDispatch, useSelector } from 'react-redux';
import FarbaOpt2 from '../../Accordion/FarbaOpt2';
import SidePanelFooter from '../SidePanelFooter';
import {
  decrementStep,
  incrementStep,
} from '../../../store/reducers/User/UserSlice';
import './style.scss';


const Step5 = ({ totalVal, isLoading }) => {
  const activeStep = useSelector((state) => state.user.activeStep);
  const dispatch = useDispatch();

  const handleNext = useCallback(() => {
    dispatch(incrementStep());
  }, [dispatch]);

  const handleBack = useCallback(() => {
    dispatch(decrementStep());
  }, [dispatch]);

  return (
    <>
      <div className="step5">
        <CustomSimpleBar style={{ maxHeight: '100%', width: '100%' }}>
          <div className="step5__farbaWrap">
            <FarbaOpt2 expertMode />
          </div>
        </CustomSimpleBar>
      </div>

      <SidePanelFooter
        isLoading={isLoading}
        totalVal={totalVal}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
      />
    </>
  );
};

export default Step5;
