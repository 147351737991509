import axios from '../utils/axios';

const UserService = {
  async initialization() {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      const response = await axios.get('/api/v1/users/profile');
      return response.data.user;
    }

    return null;
  },
  async signIn(body) {
    const response = await axios.post('/api/v1/auth/sign-in', body);

    if (response.data?.accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
      localStorage.setItem('accessToken', response.data.accessToken);
    }

    return response.data?.user;
  },
  async signUp(body) {
    const response = await axios.post('/api/v1/auth/sign-up', body);

    if (response.data?.accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
      localStorage.setItem('accessToken', response.data.accessToken);
    }

    return response.data?.user;
  },
  async resetPassword(body) {
    const response = await axios.post('/api/v1/auth/reset-password', body);

    return response.data;
  },
  async changePassword(body) {
    const response = await axios.post('/api/v1/auth/change-password', body);

    return response.data;
  },
  async sendResetPasswordMail(body) {
    const response = await axios.post(
      '/api/v1/auth/init-reset-password-by-mail',
      body
    );

    return response.data;
  },
  async signOut() {
    axios.defaults.headers.common.Authorization = undefined;
    localStorage.removeItem('accessToken');
    return true;
  },
  async update(body) {
    const response = await axios.put('/api/v1/auth/update', body);

    if (response.data?.accessToken) {
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.accessToken}`;
      localStorage.setItem('accessToken', response.data.accessToken);
    }

    return response.data?.user;
  },
  async getCrmInfo(email) {
    const response = await axios.get(`/api/v1/crm/products/?email=${email}`);

    return response.data.products;
  },

  async writeEmail(val) {
    await axios.post(`/api/write-file`, {
      email: val,
    });
  },

  async sendOrder(body) {
    await axios.post(`/api/v1/users/mail/order`, body);
  },
  async sendEmail(body) {
    await axios.post(`/api/v1/users/mail/email`, body);
  },

  async postDelivery(body) {
    const response = await axios.post(`/api/v1/users/delivery`, body);
    return response.data;
  },
};

export default UserService;
