import React, { useState, useEffect } from 'react';
import CustomSimpleBar from 'components/CustomSimpleBar';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/ui/Button';
import InfoButton from 'Layouts/Accordion/Elemente/InfoButton';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { setShowLedModal } from '../../../store/reducers/User/UserSlice';

import color1 from '../../../assets/options/ledwhite.jpg';
import color2 from '../../../assets/options/ledrgb.jpg';

import seiten1 from '../../../assets/options/led2side.jpg';
import seiten2 from '../../../assets/options/led4side.jpg';

import './style.scss';

const LedModal = () => {
  const dispatch = useDispatch();

  const { showLedModal } = useSelector((state) => state.user);
  const { source } = useSelector((state) => state.project);

  const [color, setColor] = useState('white');
  const [seiten, setSeiten] = useState('seiten-4');

  const [valueSpotsMax, setValueSpotsMax] = useState(null);
  const [valueSpotsMin, setValueSpotsMin] = useState(null);
  const [valueSpots, setValueSpots] = useState('2');

  const [valueSparrenMax, setValueSparrenMax] = useState(null);
  const [valueSparrenMin, setValueSparrenhMin] = useState(null);
  const [valueSparren, setValueSparren] = useState('0');

  useEffect(() => {
    setValueSpotsMax(6);
    setValueSpotsMin(2);

    setValueSparrenhMin(0);
  }, []);

  useEffect(() => {
    source?.addEventListener('led-variable', (data) => {
      setValueSparrenMax(data.sparren - 2);

      if (!data?.led[0]?.type) {
        setColor('white');
        setSeiten('seiten-4');
        setValueSpots('2');
        setValueSparren('0');
        return;
      }

      setColor(data.led[0].color);
      setSeiten(data.led[0].seiten);
      setValueSpots(data.led[0].spots);
      if (data.led[0].sparren > data.sparren) {
        setValueSparren(data.sparren);
      } else {
        setValueSparren(data.led[0].sparren);
      }
    });
  }, [source]);

  const handleClose = () => {
    dispatch(setShowLedModal(false));
    setColor(null);
    setSeiten(null);
  };

  const select = () => {
    if (!color || !seiten) {
      toast.warn('Optionen wählen');
      return;
    }
    dispatch(setShowLedModal(false));
    source.funcToggleLed(undefined, {
      type: showLedModal,
      color,
      seiten,
      spots: valueSpots,
      sparren: valueSparren,
    });
  };

  const cancel = () => {
    dispatch(setShowLedModal(false));
    source.funcToggleLed(undefined, undefined);
  };

  return (
    <ModalWrapper
      show={showLedModal}
      close={handleClose}
      customClass="wrapper-led-modal"
    >
      <ModalHeader title="LED Einstellungen" close={handleClose} />

      <div className="container-led-modal">
        {showLedModal === 'direct' || showLedModal === 'inderect' ? (
          <CustomSimpleBar style={{ maxHeight: '50dvh', width: '100%' }}>
            <div className="section-led-modal">
              <div className="title-section-led-modal">
                LED Farbe
                <InfoButton
                  title="LED Farbe"
                  text="Wünschen Sie eher warmweisses Licht oder RGB Farben? Ganz nach Ihrem Geschmack können Sie die Farbe frei wählen."
                  link={undefined}
                />
              </div>
              <div className="content-section-led-modal">
                <div className="led-modal-options-img">
                  <Button
                    className={
                      color === 'white'
                        ? 'led-modal-options-content active'
                        : 'led-modal-options-content'
                    }
                    onClick={() => {
                      setColor('white');
                    }}
                  >
                    <img src={color1} alt="" />
                  </Button>
                  <div className="led-modal-options-title">Warmweiss</div>
                </div>
                <div className="led-modal-options-img">
                  <Button
                    className={
                      color === 'rgb'
                        ? 'led-modal-options-content active'
                        : 'led-modal-options-content'
                    }
                    onClick={() => {
                      setColor('rgb');
                    }}
                  >
                    <img src={color2} alt="" />
                  </Button>
                  <div className="led-modal-options-title">RGB Farbig</div>
                </div>
              </div>
            </div>

            <div className="section-led-modal">
              <div className="title-section-led-modal">
                LED Seiten{' '}
                <InfoButton
                  title="LED Seiten"
                  text="Auf welchen/wievielen Seiten soll das LED-Licht angebracht werden? Sie können individuell entscheiden, auf welchen Seiten Sie die LED-Streifen wünschen."
                  link={undefined}
                />
              </div>
              <div className="content-section-led-modal">
                <div className="led-modal-options-img">
                  <Button
                    className={
                      seiten === 'seiten-4'
                        ? 'led-modal-options-content active'
                        : 'led-modal-options-content'
                    }
                    onClick={() => {
                      setSeiten('seiten-4');
                    }}
                  >
                    <img src={seiten2} alt="" />
                  </Button>
                  <div className="led-modal-options-title">4-Seiten</div>
                </div>
                <div className="led-modal-options-img">
                  <Button
                    className={
                      seiten === 'seiten-2'
                        ? 'led-modal-options-content active'
                        : 'led-modal-options-content'
                    }
                    onClick={() => {
                      setSeiten('seiten-2');
                    }}
                  >
                    <img src={seiten1} alt="" />
                  </Button>
                  <div className="led-modal-options-title">2-Seiten</div>
                </div>
              </div>
            </div>
          </CustomSimpleBar>
        ) : null}

        {showLedModal === 'spots' ? (
          <>
            <div className="section-led-modal">
              <div className="title-section-led-modal">
                LED Spots pro Sparre
                <InfoButton
                  title="LED Spots pro Sparre"
                  text="Wie viele LED Spots wünschen Sie pro Sparren? Wählen Sie individuell."
                  link={undefined}
                />
              </div>

              <div className="input-type-lamellen-modal">
                <input
                  type="range"
                  min={valueSpotsMin}
                  max={valueSpotsMax}
                  onChange={(e) => setValueSpots(e.target.value)}
                  style={{
                    backgroundSize: `${
                      ((valueSpots - valueSpotsMin) /
                        (valueSpotsMax - valueSpotsMin)) *
                      100
                    }% 100%`,

                    margin: 0,
                  }}
                  value={valueSpots}
                />
                <div className="titles-input-type-lamellen-modal">
                  {Array(valueSpotsMax + 1 - valueSpotsMin)
                    .fill()
                    .map((_, i) => i + valueSpotsMin)
                    .map((item) => (
                      <span
                        key={item}
                        className={
                          item === valueSpotsMax || item === valueSpotsMin
                            ? item === +valueSpots
                              ? 'input-type-lamellen-modal-value-g active'
                              : 'input-type-lamellen-modal-value-g'
                            : item === +valueSpots
                            ? 'input-type-lamellen-modal-value-w active'
                            : 'input-type-lamellen-modal-value-w'
                        }
                      >
                        {item}
                      </span>
                    ))}
                </div>
              </div>
            </div>

            {valueSparrenMax ? (
              <div className="section-led-modal">
                <div className="title-section-led-modal">
                  Anzahl Sparren mit LED
                  <InfoButton
                    title="Anzahl Sparren mit LED"
                    text="Wie viele Sparren wünschen Sie mit LED Spots? Wählen Sie individuell."
                    link={undefined}
                  />
                </div>
                <div className="input-type-lamellen-modal">
                  <input
                    type="range"
                    min={valueSparrenMin}
                    max={valueSparrenMax}
                    onChange={(e) => setValueSparren(e.target.value)}
                    style={{
                      backgroundSize: `${
                        ((valueSparren - valueSparrenMin) /
                          (valueSparrenMax - valueSparrenMin)) *
                        100
                      }% 100%`,

                      margin: 0,
                    }}
                    value={valueSparren}
                  />
                  <div className="titles-input-type-lamellen-modal">
                    {Array(valueSparrenMax + 1 - valueSparrenMin)
                      .fill()
                      .map((_, i) => i + valueSparrenMin)
                      .map((item) => (
                        <span
                          key={item}
                          className={
                            item === valueSparrenMax || item === valueSparrenMin
                              ? item === +valueSparren
                                ? 'input-type-lamellen-modal-value-g active'
                                : 'input-type-lamellen-modal-value-g'
                              : item === +valueSparren
                              ? 'input-type-lamellen-modal-value-w active'
                              : 'input-type-lamellen-modal-value-w'
                          }
                        >
                          {item}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        <div className="container-btnStart-led-modal">
          <Button
            className="btn-led-modal"
            style={{ backgroundColor: '#bb0303' }}
            onClick={cancel}
          >
            löschen
          </Button>
          <Button
            className="btn-led-modal"
            style={{ backgroundColor: '#61c38b' }}
            onClick={select}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default LedModal;
