import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoginModal from '../LoginModal';
import RegistrationModal from '../RegistrationModal';
import InfoModal from '../InfoModal';
import AccountModal from '../AccountModal';
import DeleteModal from '../DeleteModal';
import SaveProjectModal from '../SaveProjectModal';
import SelectProduct from '../SelectProduct';
import VerglasungModal from '../VerglasungModal';
import ModelSizeModal from '../ModelSizeModal';
import WarningModalRestart from '../WarningModalRestart';
import ResetPasswordModal from '../ResetPasswordModal/indext';
import SiteRedirectModal from '../SiteRedirectModal';
import TypeZipModal from '../TypeZipModal';
import LedModal from '../LedModal';
import TypeZipRoofModal from '../TypeZipRoofModal';
import SearchProduct from '../SearchProduct';
import TypeLamellenModal from '../TypeLamellenModal';
import IframeModal from '../IframeModal';
import { setShowSelectModalProduct } from '../../../store/reducers/User/UserSlice';
import TypeLamellendachModal from '../TypeLamellendachModal';
import SolarModal from '../SolarModal';

// eslint-disable-next-line no-unused-vars
const TestModal = () => {
  const { productsCost } = useSelector((state) => state.user);

  const style = {
    width: 250,
    maxHeight: 'calc(100vh - 160px)',
    position: 'absolute',
    right: 0,
    top: 80,
    zIndex: 1,
    fontSize: 8,
    overflowY: 'auto',
  };

  if (!productsCost) {
    return null;
  }

  return (
    <div style={style}>
      <pre>{JSON.stringify(productsCost, null, 2)}</pre>
    </div>
  );
};

const RenderModal = ({ expertMode, typeModel }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeModel === null && !expertMode) {
      dispatch(setShowSelectModalProduct(true));
    }
  }, [expertMode, typeModel]);
  return (
    <>
      <LoginModal />
      <RegistrationModal />
      <ResetPasswordModal />
      <AccountModal />
      <SaveProjectModal />
      {!expertMode && typeModel === null && <SelectProduct />}
      <VerglasungModal />
      <ModelSizeModal />
      <WarningModalRestart />
      <SiteRedirectModal />
      <TypeZipModal />
      <TypeZipRoofModal />
      <LedModal />
      <SearchProduct />
      <TypeLamellenModal />
      <IframeModal />
      <InfoModal />
      <DeleteModal />
      <TypeLamellendachModal />
      <SolarModal />
      {/* <TestModal /> */}
    </>
  );
};

export default RenderModal;
