import './style.scss';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSimpleBar from 'components/CustomSimpleBar';
import Button from 'components/ui/Button';
import InfoButton from 'Layouts/Accordion/Elemente/InfoButton';
import infoIcon from '../../../assets/info.svg';
import editIcon from '../../../assets/edit-black.svg';
import editIcon2 from '../../../assets/edit.svg';
import delIcon from '../../../assets/delete-black.svg';
import PopUpDel from '../../../components/ui/PopUpDel';
import Elemente from '../../Accordion/Elemente';
import {
  decrementStep,
  incrementStep,
  setShowDeleteModal,
  setShowInfoModal,
} from '../../../store/reducers/User/UserSlice';
import SidePanelFooter from '../SidePanelFooter';
// eslint-disable-next-line import/named
import {
  setSelectedSideModel,
  setSideFilledBulk,
  updateSideFilled,
} from '../../../store/reducers/Project/ProjectSlice';
import Zubehor from '../../Accordion/Zubehor';

const editBtnArray = [
  {
    position: 'top',
    side: 'sideD',
  },
  {
    position: 'right',
    side: 'sideC',
  },
  {
    position: 'bottom',
    side: 'sideB',
  },
  {
    position: 'left',
    side: 'sideA',
  },
  {
    position: 'up',
    side: 'sideRoof',
  },
];
const Step4 = ({ totalVal, isLoading }) => {
  const dispatch = useDispatch();
  const activeStep = useSelector((state) => state.user.activeStep);
  const { source, typeModel, sideFilled, prevSizes } = useSelector(
    (state) => state.project
  );
  const [activeBtn, setActibeBtn] = useState(null);
  const [activeDellBtn, setActibeDellBtn] = useState(null);
  const [openDelPopup, setOpenDelPopup] = useState(false);
  const [sideWall2, setSideWall2] = useState(false);

  useEffect(() => {
    if (source) {
      if (
        source &&
        source.currentModel &&
        source.currentModel.bb &&
        source.currentModel.bb.children
      ) {
        const { bb } = source.currentModel;
        const { parent } = source.current.parent;
        const sides = ['sideA', 'sideB', 'sideC', 'sideD', 'sideRoof'];
        const newSideFilled = {};

        sides.forEach((side) => {
          newSideFilled[side] = false;
        });

        ['arrElectro', 'arrLamellen', 'arrWindow', 'arrZip'].forEach(
          (arrName) => {
            if (parent[arrName] && parent[arrName].length > 0) {
              parent[arrName].forEach((item) => {
                if (item.side && sides.includes(item.side)) {
                  newSideFilled[item.side] = true;
                }
              });
            }
          }
        );

        bb.children.forEach((child) => {
          if (child.userData.wall && child.userData.wall.parent !== null) {
            const { name } = child;
            if (sides.includes(name)) {
              newSideFilled[name] = true;
            }
          }
        });

        dispatch(setSideFilledBulk(newSideFilled));
      }
    }
  }, []);

  const handleToggleSideFilled = (side, bool) => {
    dispatch(updateSideFilled(side, bool));
  };

  const hasSizesChanged = () => {
    const currentDepth = Number(source.globalDepth);
    const currentHeight1 = Number(source.globalHeight1);
    const currentHeight2 = Number(source.globalHeight2);
    const currentWidth = Number(source.globalWidth);

    return (
      currentDepth !== Number(prevSizes.depth) ||
      currentHeight1 !== Number(prevSizes.height1) ||
      currentHeight2 !== Number(prevSizes.height2) ||
      currentWidth !== Number(prevSizes.width)
    );
  };

  useEffect(() => {
    if (Object.values(prevSizes).some((value) => value === null)) {
      return;
    }

    if (hasSizesChanged()) {
      const updateSizes = async () => {
        await source.funcEditEl(
          Number(prevSizes.width),
          Number(prevSizes.depth),
          Number(prevSizes.height1),
          Number(prevSizes.height2)
        );
      };

      updateSizes();
    }
  }, [source]);

  const handleCurrentSide = (side) => {
    source.findSide(side);
  };

  const handleEditBtn = (index, side) => {
    setOpenDelPopup(false);
    if (activeBtn === index) {
      setActibeBtn(null);
      handleCurrentSide(null);
    } else {
      setActibeBtn(index);
      dispatch(setSelectedSideModel(side));
      handleCurrentSide(side);
    }
  };

  const handleDellBtn = (index) => {
    setOpenDelPopup(true);
    if (activeDellBtn === index) {
      setActibeDellBtn(null);
      setOpenDelPopup(false);
    } else {
      setActibeDellBtn(index);
    }
  };

  const handleDelBtn = (side) => {
    source.delEl(side);
    setOpenDelPopup(false);
  };
  const handleNext = () => {
    setActibeBtn(null);
    source.findSide(null);
    dispatch(incrementStep());
    source.updEl();
  };

  const handleBack = () => {
    dispatch(decrementStep());
  };
  const isSideFilled = (side) => sideFilled[side];

  return (
    <>
      <div className="step4">
        <div className="step4__editModel">
          <div className="step4__editModel-title">
            Seite auswählen
            <InfoButton
              title="Seite auswählen"
              text="Bitte wählen Sie eine der Dachseiten aus."
              link={undefined}
            />
          </div>
          <div className="step4__editModel-editWrap">
            <div className="step4__editModel-editWrap-edit">
              {editBtnArray
                .slice(0, typeModel === 'glass' ? editBtnArray.length : -1)
                .map((item, index) => (
                  <div
                    key={item.side}
                    className={`step4__editModel-editWrap-edit-btnWrap ${
                      item.position
                    } ${index === activeDellBtn ? 'btnWrapActive' : ''}`}
                  >
                    <button
                      type="button"
                      onClick={() => {
                        handleEditBtn(index, item.side);
                        source.funcRotationModel(item.side);
                      }}
                      className={`step4__editModel-editWrap-edit-btnWrap-editBtn ${
                        activeBtn === index ? 'editBtnActive' : ''
                      }`}
                    >
                      <img
                        src={activeBtn === index ? editIcon2 : editIcon}
                        alt="editIcon"
                      />
                    </button>
                    {isSideFilled(item.side) && (
                      <div className="step4__editModel-editWrap-edit-btnWrap-delBtnWrap">
                        <button
                          type="button"
                          className="step4__editModel-editWrap-edit-btnWrap-delBtn"
                          onClick={() => {
                            source.funcRotationModel(item.side);
                            if (index === activeBtn) {
                              handleDellBtn(index);
                            } else {
                              handleEditBtn(index, item.side);
                              handleDellBtn(index);
                            }
                          }}
                        >
                          <img src={delIcon} alt="delIcon" />
                        </button>

                        <Button
                          className="step4__delete-icon-btn"
                          onClick={() => {
                            dispatch(
                              setShowDeleteModal({
                                setOpenDelPopup,
                                setSideFilled: handleToggleSideFilled,
                                sideWall: sideWall2,
                                setSideWall: setSideWall2,
                                item,
                              })
                            );
                          }}
                        >
                          <img src={delIcon} alt="delIcon" />
                        </Button>

                        {activeDellBtn === index && openDelPopup && (
                          <PopUpDel
                            title="Löschen?"
                            setOpenDelPopup={setOpenDelPopup}
                            setSideFilled={handleToggleSideFilled}
                            sideWall={sideWall2}
                            setSideWall={setSideWall2}
                            item={item}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelBtn(item.side);
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="step4__line" />

        <CustomSimpleBar style={{ maxHeight: '100%', width: '100%' }}>
          <div className="step4__elementWrap">
            <div className="step4__editModel mob">
              <div className="step4__editModel-title">
                Seite auswählen
                <InfoButton
                  title="Seite auswählen"
                  text="Bitte wählen Sie eine der Dachseiten aus."
                  link={undefined}
                />
                <Button
                  className="info-icon-btn"
                  onClick={() => {
                    dispatch(
                      setShowInfoModal({
                        title: 'Seite auswählen',
                        text: 'Bitte wählen Sie eine der Dachseiten aus.',
                        link: '',
                      })
                    );
                  }}
                >
                  <img src={infoIcon} alt="infoIcon" />
                </Button>
              </div>
              <div className="step4__editModel-editWrap">
                <div className="step4__editModel-editWrap-edit">
                  {editBtnArray
                    .slice(0, typeModel === 'glass' ? editBtnArray.length : -1)
                    .map((item, index) => (
                      <div
                        key={item.side}
                        className={`step4__editModel-editWrap-edit-btnWrap ${
                          item.position
                        } ${index === activeDellBtn ? 'btnWrapActive' : ''}`}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            handleEditBtn(index, item.side);
                            source.funcRotationModel(item.side);
                          }}
                          className={`step4__editModel-editWrap-edit-btnWrap-editBtn ${
                            activeBtn === index ? 'editBtnActive' : ''
                          }`}
                        >
                          <img
                            src={activeBtn === index ? editIcon2 : editIcon}
                            alt="editIcon"
                          />
                        </button>
                        {isSideFilled(item.side) && (
                          <div className="step4__editModel-editWrap-edit-btnWrap-delBtnWrap">
                            <button
                              type="button"
                              className="step4__editModel-editWrap-edit-btnWrap-delBtn"
                              onClick={() => {
                                if (index === activeBtn) {
                                  handleDellBtn(index);
                                } else {
                                  handleEditBtn(index, item.side);
                                  handleDellBtn(index);
                                }
                              }}
                            >
                              <img src={delIcon} alt="delIcon" />
                            </button>

                            <Button
                              className="step4__delete-icon-btn"
                              onClick={() => {
                                source.funcRotationModel(item.side);
                                if (index === activeBtn) {
                                  handleDellBtn(index);
                                } else {
                                  handleEditBtn(index, item.side);
                                  handleDellBtn(index);
                                }
                                dispatch(
                                  setShowDeleteModal({
                                    setOpenDelPopup,
                                    setSideFilled: handleToggleSideFilled,
                                    sideWall: sideWall2,
                                    setSideWall: setSideWall2,
                                    item,
                                  })
                                );
                              }}
                            >
                              <img src={delIcon} alt="delIcon" />
                            </Button>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="step4__line mob" />

            <Elemente
              setSideFilled={handleToggleSideFilled}
              sideWall2={sideWall2}
              setSideWall2={setSideWall2}
            />
            <Zubehor />
          </div>
        </CustomSimpleBar>
      </div>
      <SidePanelFooter
        isLoading={isLoading}
        totalVal={totalVal}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
      />
    </>
  );
};

export default Step4;
