import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/ui/Button';
import { ReactComponent as Info } from '../../../assets/info.svg';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { setShowTypeLamellenModal } from '../../../store/reducers/User/UserSlice';

import './style.scss';

const TypeLamellenModal = () => {
  const dispatch = useDispatch();

  const { showTypeLamellenModal } = useSelector((state) => state.user);
  const { source, typeModel } = useSelector((state) => state.project);

  const [valueRsection, setValueRsection] = useState('0');
  const [valueLsection, setValueLsection] = useState('0');
  const [valueLength, setValueLength] = useState('100');

  const [valueSectionMax, setValueSectionMax] = useState(null);
  const [valueSectionMin, setValueSectionMin] = useState(null);

  const [valueLengthMax, setValueLengthMax] = useState(null);
  const [valueLengthMin, setValueLengthMin] = useState(null);

  useEffect(() => {
    setValueSectionMax(10);
    setValueSectionMin(0);

    setValueLengthMin(20);
    setValueLengthMax(140);
  }, []);

  const handleClose = () => {
    dispatch(setShowTypeLamellenModal(false));
  };

  const [state, setState] = useState(null);

  useEffect(() => {
    if (!source) return;

    source?.addEventListener('model-size-lam', (data) => {
      let columW = 0;
      let columD = 0;
      if (data.typeModel === 'louver') {
        columW = 400;
        columD = 200;
      }
      if (data.typeModel === 'louver2') {
        columW = 300;
        columD = 100;
      }
      if (data.typeModel === 'louver3') {
        columW = 260;
        columD = 260;
      }

      setState({
        typeModel: data.typeModel,
        w: +data.size.w - columW,
        d: +data.size.d - columD,
        h1: +data.size.h1,
        h2: +data.size.h2,
        side: data.side,
      });

      setValueRsection(String(+data.sectionLamellenR));
      setValueLsection(String(+data.sectionLamellenL));
      setValueLength(String(+data.lamellenWidth * 100));
    });
  }, [source, showTypeLamellenModal]);

  useEffect(() => {
    if (!state) return;
    if (valueLength < 20) return;
    if (state.side === 'sideB' || state.side === 'sideD') {
      setValueSectionMax(Math.floor(state.w / valueLength / 10));
      if (valueLsection > Math.floor(state.w / valueLength / 10)) {
        setValueLsection(Math.floor(state.w / valueLength / 10));
      }
      if (valueRsection > Math.floor(state.w / valueLength / 10)) {
        setValueRsection(Math.floor(state.w / valueLength / 10));
      }
    }
    if (state.side === 'sideA' || state.side === 'sideC') {
      setValueSectionMax(Math.floor(state.d / valueLength / 10));

      setValueSectionMax(Math.floor(state.d / valueLength / 10));
      if (valueLsection > Math.floor(state.d / valueLength / 10)) {
        setValueLsection(Math.floor(state.d / valueLength / 10));
      }
      if (valueRsection > Math.floor(state.d / valueLength / 10)) {
        setValueRsection(Math.floor(state.d / valueLength / 10));
      }
    }
  }, [state, valueLength]);

  const select = () => {
    if (+valueRsection === 0 && +valueLsection === 0) return;

    const data = source.funcToggleLamellen(
      undefined,
      undefined,
      'lamellenType1',
      +valueLsection,
      +valueRsection,
      +valueLength / 100
    );

    if (data === undefined) {
      toast.warn('es ist verboten');
      return;
    }

    handleClose();
  };

  return (
    <ModalWrapper
      show={showTypeLamellenModal}
      close={handleClose}
      customClass="wrapper-type-lamellen-modal"
    >
      <ModalHeader title="Schiebeladen Einstellungen" close={handleClose} />
      <div className="container-type-lamellen-modal">
        <div className="section-type-lamellen-modal">
          {typeModel === 'louver' ||
          typeModel === 'louver2' ||
          typeModel === 'louver3' ? (
            <>
              {typeModel === 'louver' || typeModel === 'louver3' ? (
                <div className="title-section-type-lamellen-modal">
                  Ladenelement Anzahl Schiene 1 <Info />
                </div>
              ) : null}
              {typeModel === 'louver2' ? (
                <div className="title-section-type-lamellen-modal">
                  Anzahl Ladenelemete <Info />
                </div>
              ) : null}

              <div className="input-type-lamellen-modal">
                <input
                  type="range"
                  min={valueSectionMin}
                  max={valueSectionMax}
                  onChange={(e) => setValueLsection(e.target.value)}
                  style={{
                    backgroundSize: `${
                      ((valueLsection - valueSectionMin) /
                        (valueSectionMax - valueSectionMin)) *
                      100
                    }% 100%`,

                    margin: 0,
                  }}
                  value={valueLsection}
                />
                <div className="titles-input-type-lamellen-modal">
                  {Array(valueSectionMax + 1 - valueSectionMin)
                    .fill()
                    .map((_, i) => i + valueSectionMin)
                    .map((item) => (
                      <span
                        key={item}
                        className={
                          item === valueSectionMax || item === valueSectionMin
                            ? item === +valueLsection
                              ? 'input-type-lamellen-modal-value-g active'
                              : 'input-type-lamellen-modal-value-g'
                            : item === +valueLsection
                            ? 'input-type-lamellen-modal-value-w active'
                            : 'input-type-lamellen-modal-value-w'
                        }
                      >
                        {item}
                      </span>
                    ))}
                </div>
              </div>
            </>
          ) : null}

          {typeModel === 'louver' || typeModel === 'louver3' ? (
            <>
              <div className="title-section-type-lamellen-modal">
                Ladenelement Anzahl Schiene 2<Info />
              </div>

              <div className="input-type-lamellen-modal">
                <input
                  type="range"
                  min={valueSectionMin}
                  max={valueSectionMax}
                  onChange={(e) => setValueRsection(e.target.value)}
                  style={{
                    backgroundSize: `${
                      ((valueRsection - valueSectionMin) /
                        (valueSectionMax - valueSectionMin)) *
                      100
                    }% 100%`,

                    margin: 0,
                  }}
                  value={valueRsection}
                />
                <div className="titles-input-type-lamellen-modal">
                  {Array(valueSectionMax + 1 - valueSectionMin)
                    .fill()
                    .map((_, i) => i + valueSectionMin)
                    .map((item) => (
                      <span
                        key={item}
                        className={
                          item === valueSectionMax || item === valueSectionMin
                            ? item === +valueRsection
                              ? 'input-type-lamellen-modal-value-g active'
                              : 'input-type-lamellen-modal-value-g'
                            : item === +valueRsection
                            ? 'input-type-lamellen-modal-value-w active'
                            : 'input-type-lamellen-modal-value-w'
                        }
                      >
                        {item}
                      </span>
                    ))}
                </div>
              </div>
            </>
          ) : null}

          <div className="title-section-type-lamellen-modal">
            Ladenbreite <Info />
          </div>
          <div className="section-model-size-modal">
            <div className="input-model-size-modal lamellen">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize2"
                  min={valueLengthMin}
                  max={valueLengthMax}
                  step={1}
                  onChange={(e) => setValueLength(e.target.value)}
                  style={{
                    backgroundSize: `${
                      valueLength <= valueLengthMin
                        ? 0
                        : ((valueLength - valueLengthMin) /
                            (valueLengthMax - valueLengthMin)) *
                          100
                    }% 100%`,
                  }}
                  value={valueLength}
                />

                <label
                  htmlFor="rangeSize2"
                  style={{
                    transform: `translateX(${
                      valueLength <= valueLengthMin
                        ? 0
                        : ((valueLength - valueLengthMin) /
                            (valueLengthMax - valueLengthMin)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < valueLengthMin) {
                          setValueLength(valueLengthMin);
                        }
                      }}
                      value={valueLength}
                      onChange={(e) =>
                        setValueLength(
                          e.target.value >= valueLengthMax
                            ? valueLengthMax
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span
                  className="input-model-size-modal-start"
                  style={{ paddingLeft: '20px' }}
                >
                  {valueLengthMin}cm
                </span>
                <span
                  className="input-model-size-modal-end"
                  style={{ paddingRight: '20px' }}
                >
                  {valueLengthMax}cm
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="container-btnStart-type-lamellen-modal">
          <Button
            className={
              +valueRsection === 0 && +valueLsection === 0
                ? 'btn-type-lamellen-modal disable'
                : 'btn-type-lamellen-modal '
            }
            onClick={select}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TypeLamellenModal;
