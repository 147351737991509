import { useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import './style.scss'

const CustomSimpleBar = ({children, style = {}}) => {

    const [mobile, setMobile] = useState(undefined);

    useEffect(() => {
      setMobile(isMobileOnly);
    }, []);

    if(mobile) 
        return <SimpleBar style={style}>{children}</SimpleBar>
    return <div className='custom-simple-bar' style={style}>{children}</div> 
}
export default CustomSimpleBar;