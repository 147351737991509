import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button';
import { setElementRes } from 'store/reducers/Project/ProjectSlice';

import { setShowDeleteModal } from 'store/reducers/User/UserSlice';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import './style.scss';

const DeleteModal = () => {
  const dispatch = useDispatch();
  const { showDeleteModal } = useSelector((state) => state.user);

  const { source, selectedSideModel } = useSelector((state) => state.project);

  const handleClose = () => dispatch(setShowDeleteModal(false));

  return (
    <ModalWrapper
      show={showDeleteModal}
      close={handleClose}
      customClass="wrapper-delete-modal"
    >
      <ModalHeader
        title="Löschen?"
        close={handleClose}
        color="#000"
        bg="#FFE0E0"
        customClass="header-delete-modal"
      />
      <div className="container-delete-modal">
        <div className="description-container-delete-modal">
          Welches Produkt oder welche Produkte möchten Sie löschen? Bitte
          auswählen:
        </div>

        <div className="container-btnStart-delete-modal">
          <Button
            className="btn-delete-modal"
            onClick={() => {
              showDeleteModal.setOpenDelPopup(false);
              showDeleteModal.setSideFilled(selectedSideModel, false);
              dispatch(setElementRes(undefined));
              source?.funcToggleWall(undefined, false);
              showDeleteModal.setSideWall(false);
              dispatch(setShowDeleteModal(false));
              source.delEl(showDeleteModal.item.side);
            }}
          >
            Löschen
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default DeleteModal;
