export default class RemoteControlElCommand {
  constructor(el, data, funcUpdatePrice, funcCheckSide, app) {
    this.el = el;
    this.data = data;
    this.funcUpdatePrice = funcUpdatePrice;
    this.app = app;
    this.funcCheckSide = funcCheckSide;
  }
  execute() {
    this.el.toggleRemoteControl(this.el, this.data.next);
    if (this.app.current) {
      this.funcCheckSide(this.app.current);
    } else {
      this.funcCheckSide(undefined);
    }
    this.funcUpdatePrice();
  }

  undo() {
    this.el.toggleRemoteControl(this.el, this.data.prev);
    if (this.app.current) {
      this.funcCheckSide(this.app.current);
    } else {
      this.funcCheckSide(undefined);
    }
    this.funcUpdatePrice();
  }
}
