export default class ZipElCommand {
  constructor(
    side,
    data,
    funcUpdatePrice,
    zipMenu,
    zipRoofMenu,
    funcCheckSide,
    app
  ) {
    this.app = app;
    this.side = side;
    this.data = data;
    this.zipMenu = zipMenu;
    this.zipRoofMenu = zipRoofMenu;
    this.funcUpdatePrice = funcUpdatePrice;
    this.funcCheckSide = funcCheckSide;
  }
  execute() {
    this.side.parent.parent.toggleZip(this.side, this.data.next);

    this.side.parent.parent.deleteMenu(this.zipMenu);
    this.side.parent.parent.deleteMenu(this.zipRoofMenu);

    // this.side.parent.parent.update(this.side.parent.parent.model, () => {});
    if (this.data.next && this.side.parent.visible) {
      if (this.side.name === 'sideRoof') {
        this.side.parent.parent.attachMenu(
          this.zipRoofMenu,
          this.side.name,
          true
        );
      }

      if (this.side.name !== 'sideRoof') {
        this.side.parent.parent.attachMenu(this.zipMenu, this.side.name);
      }
    }

    if (this.app.current) {
      this.funcCheckSide(this.app.current);
    } else {
      this.funcCheckSide(undefined);
    }
    this.funcUpdatePrice();
  }

  undo() {
    this.side.parent.parent.toggleZip(this.side, this.data.prev);

    this.side.parent.parent.deleteMenu(this.zipMenu);
    this.side.parent.parent.deleteMenu(this.zipRoofMenu);

    // this.side.parent.parent.update(this.side.parent.parent.model, () => {});
    if (this.data.prev && this.side.parent.visible) {
      if (this.side.name === 'sideRoof') {
        this.side.parent.parent.attachMenu(
          this.zipRoofMenu,
          this.side.name,
          true
        );
      }

      if (this.side.name !== 'sideRoof') {
        this.side.parent.parent.attachMenu(this.zipMenu, this.side.name);
      }
    }

    if (this.app.current) {
      this.funcCheckSide(this.app.current);
    } else {
      this.funcCheckSide(undefined);
    }

    this.funcUpdatePrice();
  }
}
