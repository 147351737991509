import React from 'react';
import './style.scss';
import ApiSelect from 'react-select';

const Select = ({ value, onChange, options = [], label }) => {
  const handleChange = (selected) => {
    onChange(selected.value);
  };

  return (
    <div className="app-select__wrapper">
      {label ? <div className="app-select__label">{label}</div> : null}
      <ApiSelect
        className="app-select"
        classNamePrefix="app-select"
        value={options.find((item) => item.value === value)}
        options={options}
        onChange={handleChange}
      />
    </div>
  );
};

export default Select;
