import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../utils/axios';

// Create our baseQuery instance
const baseQuery = axiosBaseQuery();

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 6 });

export const api = createApi({
  reducerPath: 'splitApi',
  baseQuery: baseQueryWithRetry,
  tagTypes: ['Projects', 'Quotes', 'Product'],
  endpoints: () => ({}),
});
