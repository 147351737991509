const config = {
  dev: process.env.NODE_ENV === 'development',
  debug: false, // process.env.NODE_ENV === 'development',
  apiUrl: window.location.origin.includes('localhost')
    ? 'http://localhost:31340'
    : window.location.origin,
  googleCaptchaKey: '6Lcqe6oiAAAAAH8s2p-drO1HU2kcBaUD5lGE9d9G', // '6LeEA2IiAAAAAPpY08MOmn81s8MTHaqYWr_K8NUJ'
};

export default config;
