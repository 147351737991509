import React, { useState, useRef } from 'react';
import PopUpInfo from 'components/ui/PopUpInfo';
import Button from 'components/ui/Button';
import { ReactComponent as Info } from '../../../assets/info.svg';

const InfoButton = ({ ...props }) => {
  const ref = useRef();
  const [position, setPosition] = useState(null);

  const click = () => {
    const { x, y } = ref.current.getBoundingClientRect();
    setPosition({ x, y });

    document.querySelectorAll('.simplebar-track').forEach((element) => {
      element.classList.add('custom-zindex');
    });

    let closeScroll = null;

    const close = (e) => {
      if (e.target.classList.value.includes('popup-info')) {
        return;
      }
      setPosition(null);
      document.body.removeEventListener('mousedown', close);
      window.removeEventListener('scroll', closeScroll);

      document.querySelectorAll('.simplebar-track').forEach((element) => {
        element.classList.remove('custom-zindex');
      });
    };

    closeScroll = () => {
      setPosition(null);
      document.body.removeEventListener('mousedown', close);
      window.removeEventListener('scroll', closeScroll);

      document.querySelectorAll('.simplebar-track').forEach((element) => {
        element.classList.remove('custom-zindex');
      });
    };

    window.addEventListener('scroll', closeScroll, true);
    document.body.addEventListener('mousedown', close);
  };
  return (
    <div className="info-icon-container" ref={ref}>
      <Button
        onClick={click}
        style={
          position
            ? { pointerEvents: 'none', display: 'flex' }
            : { display: 'flex' }
        }
      >
        <Info />
      </Button>

      {position && <PopUpInfo position={position} {...props} />}
    </div>
  );
};

export default InfoButton;
