export default class RmWallCommand {
  constructor(side) {
    this.side = side;
  }

  execute() {
    this.side?.parent?.parent?.toggleWall(this.side, false);
  }

  undo() {
    this.side?.parent?.parent?.toggleWall(this.side, true);
  }
}
