import './style.scss';
import React, { useEffect, useState } from 'react';

const step = 1;

const Range = ({
  title,
  value,
  sizeModel,
  setHasChanged,
  setSize,
  handleChangeSize,
}) => {
  const [writeValue, setWriteValue] = useState(value);
  const [prevValue, setPrevValue] = useState(value);

  const handleMouseUp = () => {
    if (prevValue !== value) {
      setHasChanged(true);
    }
  };

  useEffect(() => {
    setWriteValue(value);
  }, [value]);

  const handleRangeChange = (e) => {
    setWriteValue(value);
    setPrevValue(value);
    setSize(e.target.value);
    setHasChanged(true);
    handleChangeSize();
  };

  return (
    <div className="rangeWrapper">
      <div className="rangeWrapper__title">{title}</div>

      <input
        className="rangeWrapper__value"
        type="number"
        value={writeValue}
        onBlur={(e) => {
          if (e.target.value < sizeModel?.min) {
            setWriteValue(sizeModel.min);
            setSize(sizeModel.min);
            setHasChanged(true);
            handleChangeSize();
          }
        }}
        onChange={(e) => {
          setWriteValue(
            e.target.value >= sizeModel.max ? sizeModel.max : e.target.value
          );
          setSize(
            e.target.value >= sizeModel.max ? sizeModel.max : e.target.value
          );
          setHasChanged(true);
          handleChangeSize();
        }}
      />

      <div className="range">
        <div className="range__content">
          <input
            type="range"
            id="rangeSize1"
            min={sizeModel?.min}
            max={sizeModel?.max}
            step={step}
            onMouseUp={handleMouseUp}
            onChange={handleRangeChange}
            style={{
              backgroundSize: `${
                value <= sizeModel?.min
                  ? 0
                  : ((value - sizeModel.min) /
                      (sizeModel.max - sizeModel.min)) *
                    100
              }% 100%`,
            }}
            value={value}
          />

          <label
            htmlFor="rangeSize1"
            style={{
              transform: `translateX(${
                value <= sizeModel.min
                  ? 0
                  : ((value - sizeModel.min) /
                      (sizeModel.max - sizeModel.min)) *
                    100
              }%)`,
            }}
          >
            <span
              style={{
                pointerEvents: 'auto',
              }}
            />
          </label>
        </div>
        <div className="range__infoValues">
          <span className="range__infoValues-value">{sizeModel.min}</span>
          <span className="range__infoValues-value">{sizeModel.max}</span>
        </div>
      </div>
    </div>
  );
};
export default Range;
