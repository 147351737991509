import './style.scss';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setElementRes } from '../../../store/reducers/Project/ProjectSlice';

const PopUpDel = ({
  title,
  setOpenDelPopup,
  item,
  setSideFilled,
  setSideWall,
}) => {
  const dispatch = useDispatch();
  const { source, selectedSideModel } = useSelector((state) => state.project);
  return (
    <div className="popUpDel">
      <div className="popUpDel__triangle">.</div>
      <div className="popUpDel__titleWrap">
        <div className="popUpDel__titleWrap-title">{title}</div>
      </div>
      <div className="popUpDel__info">
        <div className="popUpDel__info-desc">
          Welches Produkt oder welche Produkte möchten Sie löschen? Bitte
          auswählen:
        </div>
        {/* <div className='popUpDel__info-elementsWrap'>
        Images
      </div> */}
        <div className="popUpDel__info-btnWrap">
          <button
            type="button"
            className="popUpDel__info-btnWrap-btn"
            onClick={() => {
              setOpenDelPopup(false);
              // setSideFilled(prevState => ({
              //   ...prevState,
              //   [selectedSideModel]: false
              // }));
              setSideFilled(selectedSideModel, false);
              dispatch(setElementRes(undefined));
              source?.funcToggleWall(undefined, false);
              setSideWall(false);
              source.delEl(item.side);
            }}
          >
            Löschen
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopUpDel;
