import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import Iframe from 'react-iframe';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { setShowIframeModal } from '../../../store/reducers/User/UserSlice';

import './style.scss';

const IframeModal = () => {
  const dispatch = useDispatch();

  const { showIframeModal } = useSelector((state) => state.user);

  const handleClose = () => {
    dispatch(setShowIframeModal(false));
  };

  return (
    <ModalWrapper
      show={showIframeModal}
      close={handleClose}
      customClass="wrapper-iframe-modal"
    >
      <ModalHeader title="Danke!" close={handleClose} />

      <div className="container-iframe-modal">
        <iframe
          name="iframe"
          title="thermogreen"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          id="my-iframe"
          className="iframe-danke"
          display="initial"
          src="https://www.thermogreen.ch/danke/"
        />

        {/* <Iframe
          allow-same-origin
          url="https://www.thermogreen.ch/danke/"
          id="my-iframe"
          className="iframe-danke"
          display="initial"
          position="relative"
        /> */}
      </div>
    </ModalWrapper>
  );
};

export default IframeModal;
