import './style.scss';

const ButtonOpt2 = ({ text, icon, opt, handleClick, disable }) => (
    <button
      type='button'
      className={`buttonOpt2 ${opt || ''} ${disable ? 'disable' : ''}`}
      onClick={handleClick}
      disabled={disable}
    >
      {text}
      <img className='buttonOpt2__icon' src={icon} alt={icon} />
    </button>
  );

export default ButtonOpt2;
