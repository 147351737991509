import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/ui/Button';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import {
  setShowTypeLamellendachModal,
  setShowWarningModalRestart,
} from '../../../store/reducers/User/UserSlice';
import {
  // resetSideFilled,
  setSelectedTypeModel,
  setTypeModel,
  // setTypeModelRemove,
} from '../../../store/reducers/Project/ProjectSlice';

import skl100 from '../../../assets/SKL-100.png';
import skl50 from '../../../assets/SKL-50.png';
import './style.scss';

const TypeLamellendachModal = () => {
  const dispatch = useDispatch();

  const { source, typeModel } = useSelector((state) => state.project);

  const { showTypeLamellendachModal } = useSelector((state) => state.user);

  const selectType = useCallback(
    (type) => {
      if (!type) {
        toast.warn('Produkt auswählen');
        return;
      }
      if (typeModel === null) {
        source?.funcSelectType(type);
        dispatch(setSelectedTypeModel(type));
        dispatch(setTypeModel(type));
      } else if (typeModel !== type) {
        // source.clearGlobalValues();
        // dispatch(resetSideFilled());
        dispatch(setShowWarningModalRestart(type));
      }
      // dispatch(setSelectedTypeModel(type));
      // dispatch(setTypeModelRemove(type));
      // dispatch(setTypeModel(type));
      dispatch(setShowTypeLamellendachModal(false));
    },
    [dispatch, source, typeModel]
  );

  const handleClose = () => dispatch(setShowTypeLamellendachModal(false));

  return (
    <ModalWrapper
      show={showTypeLamellendachModal}
      close={handleClose}
      customClass="wrapper-type-lamellendach-modal"
    >
      <ModalHeader title="LAMELLENDACH" close={handleClose} />

      <div className="container-type-lamellendach-modal">
        <div className="title-amellendach-modal">Ausgewähltes Produkt</div>

        <div className="items-lamellendach-modal">
          <div
            className={
              typeModel === 'louver'
                ? 'item-lamellendach-modal active'
                : 'item-lamellendach-modal'
            }
          >
            <img
              src={skl100}
              alt=""
              role="presentation"
              onClick={() => selectType('louver')}
            />
            <div className="title-item-lamellendach-modal">skl-100</div>
            <div className="list-item-lamellendach-modal">
              <ul>
                <li>
                  <span>Bis zu 33m² Schattenplatz.</span>
                </li>
                <li>
                  <span>Integrierte Verglasung:</span> Seitenverglasung möglich.
                </li>
                <li>
                  <span>Einfache Integration:</span> Nachträglich erweiterbar
                </li>
                <li>
                  <span>Geräuschdämpfung:</span> Antiakustische Behandlung.
                </li>
              </ul>
            </div>

            <Button
              className="btn-type-lamellendach-modal"
              onClick={() => selectType('louver')}
            >
              Weiter
            </Button>
          </div>

          <div
            className={
              typeModel === 'louver2'
                ? 'item-lamellendach-modal active'
                : 'item-lamellendach-modal'
            }
          >
            <img
              src={skl50}
              role="presentation"
              alt=""
              onClick={() => selectType('louver2')}
            />
            <div className="title-item-lamellendach-modal">skl-50</div>
            <div className="list-item-lamellendach-modal">
              <ul>
                <li>
                  <span> Bis zu 21m² Schattenplatz.</span>
                </li>
                <li>
                  <span>Kompaktes Design:</span> Schlanke, filigrane Profile.
                </li>
                <li>
                  <span>Einfache Integration:</span> Nachträglich erweiterbar.
                </li>
                <li>
                  <span>Einfache Integration:</span> Lässt sich in bestehende
                  Konstruktionen einbauen
                </li>
              </ul>
            </div>
            <Button
              className="btn-type-lamellendach-modal"
              onClick={() => selectType('louver2')}
            >
              Weiter
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TypeLamellendachModal;
