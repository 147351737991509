import { useEffect, useState } from 'react';
import { api } from '../store/api';
import axios from '../utils/axios';

export const quotesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllQuotes: builder.query({
      query: () => ({
        url: '/api/v1/quote/allQuotes',
        method: 'GET',
      }),
      providesTags: (result = []) => [
        ...result.map(({ _id }) => ({ type: 'Quotes', id: _id })),
        { type: 'Quotes', id: 'LIST' },
      ],
    }),
    addQuote: builder.mutation({
      query: (data) => ({
        url: `/api/v1/quote`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        { type: 'Quotes', id: 'LIST' },
        { type: 'Projects', id: 'LIST' },
      ],
    }),
    addQuoteWithoutAuth: builder.mutation({
      query: (data) => ({
        url: `/api/v1/quote/without-auth`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [
        { type: 'Quotes', id: 'LIST' },
        { type: 'Projects', id: 'LIST' },
      ],
    }),
    updateQuote: builder.mutation({
      query(data) {
        return {
          url: `/api/v1/quote?quoteID=${data._id}`,
          method: 'PATCH',
          data,
        };
      },
      invalidatesTags: (project) => [{ type: 'Quotes', id: project?._id }],
    }),
  }),
});

export const useGenerateNumber = ({ skip } = { skip: false }) => {
  const [number, setNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const generate = async () => {
    if (skip) return null;

    try {
      setIsLoading(true);
      const response = await axios.get('/api/v1/quote/number');
      setIsLoading(false);
      setNumber(response.data);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
      setError(true);
    }

    return null;
  };

  useEffect(() => {
    generate();
  }, [skip]);

  return {
    number,
    isLoading,
    error,
    refresh: generate,
  };
};

export const {
  useGetAllQuotesQuery,
  useGetNumberQuoteQuery,
  useAddQuoteMutation,
  useAddQuoteWithoutAuthMutation,
  useUpdateQuoteMutation,
} = quotesApi;
