export default class SolarELCommand {
  constructor(side, data, funcToggleSolarPerformance, funcUpdatePrice) {
    this.side = side;
    this.data = data;
    this.funcToggleSolarPerformance = funcToggleSolarPerformance;
    this.funcUpdatePrice = funcUpdatePrice;
  }
  execute() {
    this.funcToggleSolarPerformance(this.side, this.data.next);

    this.funcUpdatePrice();
  }
  undo() {
    this.funcToggleSolarPerformance(this.side, this.data.prev);

    this.funcUpdatePrice();
  }
}
