import React from 'react';
import './style.scss';
import animation from '../../assets/spinner.svg';

export const Spinner = () => (
  <div className="lds-spinner">
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

const Loading = () => (
  <div className="spinner-wrapper">
    <img src={animation} alt="spinner" />
  </div>
);

export default Loading;
