import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setComment } from 'store/reducers/User/UserSlice';

const Comments = () => {
  const { comment } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleCommentChange = (e) => {
    dispatch(setComment(e.target.value));
  };

  return (
    <div>
      <div className="comment_order">
        <div className="title_comment_order">Hinterlasse einen Kommentar:</div>
        <textarea
          value={comment}
          onChange={handleCommentChange}
          className="comment_comment_order"
          placeholder="Ihr Kommentar..."
        />
      </div>
    </div>
  );
};

export default Comments;
