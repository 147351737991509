import React from 'react';
import './style.scss';

const PopUpInfo = ({ title, text, link, position, img }) => (
  <div
    className={img ? 'wrapper-popup-info img' : 'wrapper-popup-info'}
    style={{ left: position.x, top: position.y }}
  >
    <div className="triangle">.</div>
    <div className="header-popup-info">
      <div className="title-popup-info">{title}</div>
    </div>
    <div className="body-popup-info">
      <div className="description-popup-info">{text}</div>
      <a className="link-popup-info" href={link}>
        {link}
      </a>
    </div>
  </div>
);

export default PopUpInfo;
