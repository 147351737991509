export default class WindowElCommant {
  constructor(side, data, funcToggleWindowPerformance, funcUpdatePrice) {
    this.side = side;
    this.data = data;
    this.funcToggleWindowPerformance = funcToggleWindowPerformance;
    this.funcUpdatePrice = funcUpdatePrice;
  }

  execute() {
    this.funcToggleWindowPerformance(
      this.side,
      this.data.next,
      this.data.next,
      this.data.nextcolSectionWindow
    );
    this.funcUpdatePrice();
  }

  undo() {
    this.funcToggleWindowPerformance(
      this.side,
      this.data.prev,
      this.data.prev,
      this.data.prevcolSectionWindow
    );
    this.funcUpdatePrice();
  }
}
