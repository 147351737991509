class History {
  undos = [];
  redos = [];
  idCounter = 0;
  lastCmdTime = new Date();

  constructor(debug = false) {
    this.debug = debug;
  }

  reset() {
    this.undos = [];
    this.redos = [];
    this.idCounter = 0;
    this.lastCmdTime = new Date();
  }

  execute(cmd) {
    this.undos.push(cmd);
    cmd.id = ++this.idCounter;
    this.lastCmdTime = new Date();
    this.redos = [];

    if (this.debug) {
      console.log({
        command: 'execute',
        type: cmd.type,
        undos: this.undos,
        redos: this.redos,
      });
    }
  }

  undo() {
    let cmd = undefined;

    if (this.undos.length > 0) {
      cmd = this.undos.pop();
    }

    if (cmd !== undefined) {
      cmd.undo();
      this.redos.push(cmd);
    }

    if (this.debug && cmd) {
      console.log({
        command: 'undo',
        type: cmd.type,
        undos: this.undos,
        redos: this.redos,
      });
    }

    return cmd;
  }

  redo() {
    let cmd = undefined;

    if (this.redos.length > 0) {
      cmd = this.redos.pop();
    }

    if (cmd !== undefined) {
      cmd.execute();
      this.undos.push(cmd);
    }

    if (this.debug && cmd) {
      console.log({
        command: 'redo',
        type: cmd.type,
        undos: this.undos,
        redos: this.redos,
      });
    }

    return cmd;
  }
}

export default new History(false);
