import React from 'react';
import DatePickerApi from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';
import { endOfMonth, startOfMonth } from 'date-fns';

const DatePicker = ({
  placeholder,
  value,
  onChange,
  minDate,
  maxDate,
  label,
}) => (
  <div className="date-picker">
    {label ? <div className="date-picker__label">{label}</div> : null}
    <DatePickerApi
      dateFormat="dd/MM/yyyy"
      placeholderText={placeholder}
      selected={value ?? undefined}
      isClearable={value ?? false}
      onChange={onChange}
      minDate={minDate && startOfMonth(minDate)}
      maxDate={maxDate && endOfMonth(maxDate)}
      showDisabledMonthNavigation
    />
  </div>
);

export default DatePicker;
