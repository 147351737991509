import {
  Box3,
  Object3D,
  Vector3,
  Mesh,
  BufferGeometry,
  BufferAttribute,
  MeshStandardMaterial,
  SphereGeometry,
  MeshBasicMaterial,
} from 'three';

export default class ConstructGlass extends Object3D {
  constructor(
    info,
    base,
    beam,
    column,
    roof,
    roof2,
    roofp,
    glass,
    zip,
    zipPlaneTop,
    zipColumn,
    zipTop,
    zipBottom,
    zipTop2,
    zipColumn2,
    zipBottom2,
    zipTop3,
    zipColumn3,
    zipBottom3,
    zipHolder,
    zipHolder2,
    zipCenter2,
    zipCenter3,
    windowBorder,
    windowFrame,
    windowGlass,
    windowHandle,
    windowPartition,
    windowFrameT,
    windowFrameB1,
    windowFrameB2,
    windowGlassC,
    windowGlassB,
    zipTop4,
    columnMid,
    compoundMid,
    x,
    y1,
    y2,
    z,
    colB = 0,
    colD = 0,
    colA = 0,
    colC = 0
  ) {
    super();

    this.sparrenLenght = 0;

    this.colB = colB;
    this.colD = colD;
    this.colA = colA;
    this.colC = colC;

    this.base = base;
    this.beam = beam;
    this.column = column;
    this.roof = roof;
    this.roof2 = roof2;
    this.roofp = roofp;
    this.glass = glass;
    this.columnMid = columnMid;
    this.compoundMid = compoundMid;

    this.info = info;

    this.zipRoofSeparation = 0;

    this.x = x / 1000;
    this.y1 = y1 / 1000;
    this.y2 = y2 / 1000;
    this.z = z / 1000;

    this.valueColumnMid = false;
    this.colRoof = 1;
    this.zip = zip;
    this.zipPlaneTop = zipPlaneTop;

    this.zipColumn = zipColumn;
    this.zipTop = zipTop;
    this.zipBottom = zipBottom;

    this.zipTop2 = zipTop2;

    this.zipColumn2 = zipColumn2;
    this.zipBottom2 = zipBottom2;
    this.zipTop3 = zipTop3;
    this.zipColumn3 = zipColumn3;
    this.zipBottom3 = zipBottom3;
    this.zipHolder = zipHolder;
    this.zipHolder2 = zipHolder2;

    this.zipTop4 = zipTop4;

    this.zipCenter2 = zipCenter2;
    this.zipCenter3 = zipCenter3;

    this.windowBorder = windowBorder;
    this.windowFrame = windowFrame;
    this.windowGlass = windowGlass;
    this.windowHandle = windowHandle;
    this.windowPartition = windowPartition;

    this.windowFrameT = windowFrameT;
    this.windowFrameB1 = windowFrameB1;
    this.windowFrameB2 = windowFrameB2;
    this.windowGlassC = windowGlassC;
    this.windowGlassB = windowGlassB;

    this.zipType1 = new Object3D();
    this.zipType2 = new Object3D();
    this.zipType3 = new Object3D();
    this.zipType4 = new Object3D();
    this.add(this.zipType1);
    this.add(this.zipType2);
    this.add(this.zipType3);
    this.add(this.zipType4);

    this.windowType1 = new Object3D();
    this.windowType2 = new Object3D();
    this.add(this.windowType1);
    this.add(this.windowType2);

    this.zipObj = new Object3D();
    this.add(this.zipObj);

    this.windowObj = new Object3D();
    this.add(this.windowObj);

    this.type = 'glass';

    this.init();
  }

  async init() {
    try {
      this.initColRoof(this.roof);
      this.createColumn(this.column, this.columnMid, this.compoundMid);

      this.sizeZipeEl(
        this.column,
        this.beam,
        this.base,
        this.roof,
        this.zipColumn,
        this.zipTop,
        this.zipBottom,
        this.zipTop2,
        this.zipColumn2,
        this.zip,
        this.zipPlaneTop,
        this.zipBottom2,
        this.zipTop3,
        this.zipColumn3,
        this.zipBottom3,
        this.zipHolder,
        this.zipHolder2,
        this.zipCenter2,
        this.zipCenter3,
        this.zipTop4
      );

      this.sizeWindowEl(
        this.windowBorder,
        this.windowFrame,
        this.windowGlass,
        this.windowHandle,
        this.windowPartition,
        this.column,
        this.beam,
        this.base,
        this.roof,
        this.windowFrameT,
        this.windowFrameB1,
        this.windowFrameB2,
        this.windowGlassC,
        this.windowGlassB
      );
    } catch (error) {
      console.error(error);
    }
  }

  initColRoof(roof) {
    if (!this.info) return;

    const bb = new Box3();
    bb.setFromObject(roof, true);
    const sizeRoof = new Vector3();
    bb.getSize(sizeRoof);

    const parse = this.info.ExpressionEvaluationParameters.split('|').map(
      (item) => +item.split(':')[1]
    );

    if (parse[0] === 3) {
      this.valueColumnMid = true;
    }
    this.colRoof = parse[1] + 1;
    const colSectionRoof = this.x / (this.colRoof - 1);

    if (this.colRoof % 2 === 0) {
      this.zipRoofSeparation = colSectionRoof * Math.ceil(this.colRoof / 2);
    } else {
      this.zipRoofSeparation =
        colSectionRoof * Math.ceil((this.colRoof - 1) / 2);
    }
  }

  createColumn(column, columnMid, compoundMid) {
    const bb = new Box3();
    bb.setFromObject(column, true);
    const size = new Vector3();
    bb.getSize(size);

    const bbBeam = new Box3();
    bbBeam.setFromObject(this.beam, true);
    const sizeBeam = new Vector3();
    bbBeam.getSize(sizeBeam);

    const bbCompoundMid = new Box3();
    bbCompoundMid.setFromObject(compoundMid, true);
    const sizeCompoundMid = new Vector3();
    bbCompoundMid.getSize(sizeCompoundMid);

    column.rotation.y = Math.PI / 2;

    const column1 = column.clone();
    column1.position.set(this.z / 2, 0, this.x / 2);
    column1.scale.y = this.y1 - sizeBeam.y;
    this.add(column1);

    const column2 = column.clone();
    column2.position.set(this.z / 2, 0, -this.x / 2);
    column2.scale.y = this.y1 - sizeBeam.y;
    column2.scale.x = -1;
    this.add(column2);

    const column3 = columnMid.clone();
    column3.scale.y = this.y1 - sizeBeam.y + 0.005;
    column3.position.set(
      this.z / 2 - sizeBeam.z - sizeCompoundMid.z / 2 - 0.009,
      0,
      0
    );

    const compound = compoundMid.clone();
    compound.rotation.y += Math.PI / 2;
    compound.position.set(
      this.z / 2 - sizeBeam.z - sizeCompoundMid.z / 2 - 0.009,
      this.y1 - sizeBeam.y + 0.005,
      0
    );

    if (this.valueColumnMid) {
      this.add(column3);
      this.add(compound);
    }

    this.createBeam(
      this.beam,
      this.base,
      sizeBeam,
      this.roof,
      this.roof2,
      this.roofp,
      size
    );
  }
  createBeam(beam, base, sizeBeam, roof, roof2, roofp, columnSize) {
    const beamFront = beam.clone();
    beamFront.scale.x = this.x + 0.01;
    beamFront.rotation.y = Math.PI / 2;
    beamFront.position.set(this.z / 2, this.y1 - sizeBeam.y, this.x / 2);
    this.add(beamFront);

    const bb = new Box3();
    bb.setFromObject(roof, true);
    const sizeRoof = new Vector3();
    bb.getSize(sizeRoof);

    const bbbase = new Box3();
    bbbase.setFromObject(base, true);
    const sizeBase = new Vector3();
    bbbase.getSize(sizeBase);

    const baseBack = base.clone();
    baseBack.rotation.y = -Math.PI / 2;
    baseBack.scale.z = -1;
    baseBack.scale.x = -this.x;
    baseBack.position.set(
      -this.z / 2 + sizeBase.z,
      this.y2 - 0.025,
      this.x / 2
    );

    this.add(baseBack);
    this.createRoof(
      roof,
      roof2,
      roofp,
      sizeRoof,
      columnSize,
      this.glass,
      sizeBeam,
      sizeBase
    );
  }
  createRoof(
    roofStart,
    roof2,
    roofp,
    sizeRoof,
    columnSize,
    glass,
    sizeBeam,
    sizeBase
  ) {
    const roof = roofStart.clone();
    roof.position.set(
      this.z / 2 - columnSize.z / 2,
      this.y1 - 0.01,
      -this.x / 2 + sizeRoof.z
    );

    const bb2 = new Box3();
    bb2.setFromObject(roof2, true);
    const sizeRoof2 = new Vector3();
    bb2.getSize(sizeRoof2);

    roof2.position.set(
      this.z / 2 - columnSize.z / 2,
      this.y1 - 0.01,
      -this.x / 2 + sizeRoof.z
    );

    const point1 = new Vector3(-this.z / 2, this.y2, -this.x / 2);
    const point2 = new Vector3(this.z / 2, this.y1, -this.x / 2);

    const sphere1 = new Mesh(
      new SphereGeometry(0.01, 32, 16),
      new MeshBasicMaterial({ color: 0xff0000 })
    );
    sphere1.position.copy(point1);
    const sphere2 = new Mesh(
      new SphereGeometry(0.01, 32, 16),
      new MeshBasicMaterial({ color: 0xffff00 })
    );
    sphere2.position.copy(point2);
    // this.add(sphere1);
    // this.add(sphere2);
    const distance = point1.distanceTo(point2);

    this.sparrenLenght = distance * 100;

    roof.scale.z = -1;
    roof.scale.x = distance - sizeBeam.z / 2 - sizeBase.z / 2;
    roof2.scale.x = distance - sizeBeam.z / 2 - sizeBase.z / 2;
    const direction = new Vector3().subVectors(point2, point1).normalize();
    const angle = Math.atan2(-direction.y, direction.x);
    this.globalAngle = angle;
    roof.rotation.z = -angle;
    roof2.rotation.z = -angle;
    this.add(roof);

    const col = this.colRoof;

    const interval = 1 / (col - 1);
    for (let i = 1; i < col - 1; i++) {
      const elRoof = roof2.clone();
      const t = i * interval;
      const x = this.z / 2 + t * (this.z / 2 - this.z / 2);
      const z = this.x / 2 + t * (-this.x / 2 - this.x / 2 - sizeRoof.z);
      elRoof.position.x = x - columnSize.z / 2;
      elRoof.position.z = z;
      this.add(elRoof);
    }
    const endRoof = roof.clone();
    endRoof.scale.z = 1;
    endRoof.position.set(
      this.z / 2 - columnSize.z / 2,
      this.y1 - 0.01,
      this.x / 2 - sizeRoof.z
    );
    this.add(endRoof);
    const glassRoof = glass.clone();
    glassRoof.scale.z = this.x;
    glassRoof.scale.x = distance - sizeBeam.z / 2 - sizeBase.z / 2;
    glassRoof.rotation.z = -angle;
    glassRoof.position.set(
      this.z / 2 - columnSize.z / 2,
      this.y1,
      -this.x / 2 + sizeRoof.z / 2 - 0.03
    );
    glassRoof.children[0].material.side = 2;
    glassRoof.children[0].material.color.set(0x122d78);
    glassRoof.children[0].material.opacity = 0.25;
    glassRoof.children[0].material.transparent = true;
    glassRoof.children[0].material.metalness = 0.75;
    glassRoof.children[0].material.depthWrite = false;
    glassRoof.children[0].material.roughness = 0.39;
    glassRoof.children[0].renderOrder = 2;
    this.add(glassRoof);

    const buffRoofp = new Object3D();
    buffRoofp.add(roofp);
    roofp.rotation.y = Math.PI / 2;
    roofp.scale.x = this.x;
    buffRoofp.rotation.z = -angle;
    buffRoofp.position.set(
      -0.035,
      -0.01 + (this.y1 + this.y2) / 2,
      this.x / 2 - 0.01
    );
    if (this.z >= 3.6) {
      this.add(buffRoofp);
    }
  }

  sizeZipeEl(
    column,
    beam,
    base,
    roof,
    zipColumn,
    zipTop,
    zipBottom,
    zipTop2,
    zipColumn2,
    zip,
    zipPlaneTop,
    zipBottom2,
    zipTop3,
    zipColumn3,
    zipBottom3,
    zipHolder,
    zipHolder2,
    zipCenter2,
    zipCenter3,
    zipTop4
  ) {
    const bbZipColumn = new Box3();
    bbZipColumn.setFromObject(zipColumn, true);
    const sizeZipeColumn = new Vector3();
    bbZipColumn.getSize(sizeZipeColumn);

    const bbZipTop = new Box3();
    bbZipTop.setFromObject(zipTop, true);
    const sizeZipeTop = new Vector3();
    bbZipTop.getSize(sizeZipeTop);

    const bbZipBottom = new Box3();
    bbZipBottom.setFromObject(zipBottom, true);
    const sizeZipeBottom = new Vector3();
    bbZipBottom.getSize(sizeZipeBottom);

    const bbColumn = new Box3();
    bbColumn.setFromObject(column, true);
    const sizeColumn = new Vector3();
    bbColumn.getSize(sizeColumn);

    const bbbeam = new Box3();
    bbbeam.setFromObject(beam, true);
    const sizebeam = new Vector3();
    bbbeam.getSize(sizebeam);

    const bbbase = new Box3();
    bbbase.setFromObject(base, true);
    const sizebase = new Vector3();
    bbbase.getSize(sizebase);

    const bbroof = new Box3();
    bbroof.setFromObject(roof, true);
    const sizeroof = new Vector3();
    bbroof.getSize(sizeroof);

    const bbZipCenter3 = new Box3();
    bbZipCenter3.setFromObject(zipCenter3, true);
    const sizeZipCenter3 = new Vector3();
    bbZipCenter3.getSize(sizeZipCenter3);

    const bbZipCenter2 = new Box3();
    bbZipCenter2.setFromObject(zipCenter2, true);
    const sizeZipCenter2 = new Vector3();
    bbZipCenter2.getSize(sizeZipCenter2);

    const bbZip = new Box3();
    bbZip.setFromObject(zip, true);
    const sizeZip = new Vector3();
    bbZip.getSize(sizeZip);

    const bbZipPlaneTop = new Box3();
    bbZipPlaneTop.setFromObject(zip, true);
    const sizeZipPlaneTop = new Vector3();
    bbZipPlaneTop.getSize(sizeZipPlaneTop);

    const bbZipTop2 = new Box3();
    bbZipTop2.setFromObject(zipTop2, true);
    const sizeZipTop2 = new Vector3();
    bbZipTop2.getSize(sizeZipTop2);

    const bbZipColum2 = new Box3();
    bbZipColum2.setFromObject(zipColumn2, true);
    const sizeZipColumn2 = new Vector3();
    bbZipColum2.getSize(sizeZipColumn2);

    const bbZipBottom2 = new Box3();
    bbZipBottom2.setFromObject(zipBottom2, true);
    const sizeZipBottom2 = new Vector3();
    bbZipBottom2.getSize(sizeZipBottom2);

    const bbZipTop3 = new Box3();
    bbZipTop3.setFromObject(zipTop3, true);
    const sizeZipTop3 = new Vector3();
    bbZipTop3.getSize(sizeZipTop3);

    const bbZipColum3 = new Box3();
    bbZipColum3.setFromObject(zipColumn3, true);
    const sizeZipColumn3 = new Vector3();
    bbZipColum3.getSize(sizeZipColumn3);

    const bbZipBottom3 = new Box3();
    bbZipBottom3.setFromObject(zipBottom3, true);
    const sizeZipBottom3 = new Vector3();
    bbZipBottom3.getSize(sizeZipBottom3);

    const bbZipHolder = new Box3();
    bbZipHolder.setFromObject(zipHolder, true);
    const sizeZipHolder = new Vector3();
    bbZipHolder.getSize(sizeZipHolder);

    const bbZipHolder2 = new Box3();
    bbZipHolder2.setFromObject(zipHolder2, true);
    const sizeZipHolder2 = new Vector3();
    bbZipHolder2.getSize(sizeZipHolder2);

    this.createZipType1(
      sizeColumn,
      sizebeam,
      sizebase,
      sizeroof,
      zipTop2,
      zipColumn2,
      zip,
      zipPlaneTop,
      sizeZipPlaneTop,
      zipBottom2,
      zipHolder,
      zipHolder2,
      sizeZipTop2,
      sizeZipColumn2,
      sizeZip,
      sizeZipBottom2,
      sizeZipHolder,
      sizeZipHolder2,
      zipCenter2,
      sizeZipCenter2,
      zipColumn,
      zipTop,
      zipBottom,
      sizeZipeColumn,
      sizeZipeTop,
      sizeZipeBottom,
      zipTop4
    );

    this.createZipType2(
      sizeColumn,
      sizebeam,
      sizebase,
      sizeroof,
      zipTop3,
      zipColumn3,
      zip,
      zipPlaneTop,
      sizeZipPlaneTop,
      zipBottom3,
      zipHolder,
      zipHolder2,
      sizeZipTop3,
      sizeZipColumn3,
      sizeZip,
      sizeZipBottom3,
      sizeZipHolder,
      sizeZipHolder2,
      zipCenter3,
      sizeZipCenter3,
      zipColumn,
      zipTop,
      zipBottom,
      sizeZipeColumn,
      sizeZipeTop,
      sizeZipeBottom,
      zipTop4
    );
  }
  createZipType1(
    sizeColumn,
    sizebeam,
    sizebase,
    sizeroof,
    zipTop2,
    zipColumn2,
    zip,
    zipPlaneTop,
    sizeZipPlaneTop,
    zipBottom2,
    zipHolder,
    zipHolder2,
    sizeZipTop2,
    sizeZipColumn2,
    sizeZip,
    sizeZipBottom2,
    sizeZipHolder,
    sizeZipHolder2,
    zipCenter2,
    sizeZipCenter2,
    zipColumn,
    zipTop,
    zipBottom,
    sizeZipeColumn,
    sizeZipeTop,
    sizeZipeBottom,
    zipTop4
  ) {
    let sideRoof = new Object3D();
    let sideA = new Object3D();
    let sideB = new Object3D();
    let sideC = new Object3D();
    let sideD = new Object3D();

    sideRoof.name = 'sideRoof';
    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createZipRoof = (sizeX, bias = 0, roll = false) => {
      const bufferZipRoof = new Object3D();
      sideRoof.add(bufferZipRoof);

      const point1 = new Vector3(-this.z / 2, this.y2, -this.x / 2);
      const point2 = new Vector3(this.z / 2, this.y1, -this.x / 2);

      const distance = point1.distanceTo(point2);
      zipColumn2.scale.z = distance - sizeZipTop2.z;

      bufferZipRoof.rotation.z = -this.globalAngle;

      const zipTop2sideR = zipTop2.clone();
      zipTop2sideR.rotation.y = -Math.PI / 2;
      zipTop2sideR.scale.z = -1;
      zipTop2sideR.scale.x = sizeX;

      if (roll) {
        zipTop2sideR.position.set(sizeZipTop2.z, sizeZipHolder2.y, -sizeX / 2);
      } else {
        zipTop2sideR.position.set(sizeZipTop2.z, sizeZipTop2.y, -sizeX / 2);
        zipTop2sideR.scale.y = -1;
      }
      const zipColumn2LeftsideR = zipColumn2.clone();
      zipColumn2LeftsideR.rotation.y = Math.PI / 2;
      zipColumn2LeftsideR.scale.x = -1;

      if (roll) {
        zipColumn2LeftsideR.position.set(
          sizeZipTop2.z,
          sizeZipHolder2.y,
          sizeX / 2 - sizeZipColumn2.x
        );
      } else {
        zipColumn2LeftsideR.position.set(
          sizeZipTop2.z,
          sizeZipTop2.y - sizeZipColumn2.y,
          sizeX / 2 - sizeZipColumn2.x
        );
      }

      const zipColumn2RightsideR = zipColumn2.clone();
      zipColumn2RightsideR.rotation.y = Math.PI / 2;

      if (roll) {
        zipColumn2RightsideR.position.set(
          sizeZipTop2.z,
          sizeZipHolder2.y,
          -sizeX / 2 + sizeZipColumn2.x
        );
      } else {
        zipColumn2RightsideR.position.set(
          sizeZipTop2.z,
          sizeZipTop2.y - sizeZipColumn2.y,
          -sizeX / 2 + sizeZipColumn2.x
        );
      }

      const zipCentersideR = zipCenter2.clone();

      if (roll) {
        zipCentersideR.position.set(
          this.z / 2,
          sizeZipHolder2.y - sizeZipCenter2.y,
          -sizeX / 2
        );
      } else {
        zipCentersideR.position.set(
          this.z / 2,
          sizeZipTop2.y - sizeZipColumn2.y - sizeZipCenter2.y,
          -sizeX / 2
        );
      }

      zipCentersideR.rotation.y += Math.PI / 2;
      zipCentersideR.scale.x = -sizeX;
      if (!roll) {
        bufferZipRoof.add(zipCentersideR);
      }

      const zipsideR = zipPlaneTop.clone();
      zipsideR.rotation.x = Math.PI / 2;
      zipsideR.rotation.z = Math.PI / 2;
      zipsideR.scale.x = sizeX - 0.1;
      zipsideR.scale.y = -2;

      if (roll) {
        zipsideR.position.set(
          sizeZipTop2.z,
          sizeZipHolder2.y + sizeZipColumn2.y / 2,
          sizeX / 2 - 0.05
        );
      } else {
        zipsideR.position.set(
          sizeZipTop2.z,
          sizeZipTop2.y - sizeZipColumn2.y / 2,
          sizeX / 2 - 0.05
        );
      }

      const zipBottomsideR = zipBottom2.clone();
      zipBottomsideR.rotation.x = Math.PI / 2;
      zipBottomsideR.rotation.z = Math.PI / 2;
      zipBottomsideR.rotation.y = Math.PI / 2;
      zipBottomsideR.scale.x = -sizeX + 0.08;

      if (roll) {
        zipBottomsideR.position.set(
          sizeZipTop2.z + sizeZip.y * 2 + sizeZipBottom2.z,
          sizeZipHolder2.y + sizeZipColumn2.y,
          sizeX / 2 - 0.04
        );
      } else {
        zipBottomsideR.position.set(
          sizeZipTop2.z + sizeZip.y * 2 + sizeZipBottom2.z,
          sizeZipTop2.y,
          sizeX / 2 - 0.04
        );
      }

      const col = Math.floor((distance - sizeZipTop2.z) / sizeZipHolder.z / 6);
      const interval = 1 / (col - 1);
      for (let i = 1; i < col - 1; i++) {
        let zipholder = null;
        if (roll) {
          zipholder = zipHolder2.clone();
        } else {
          zipholder = zipHolder.clone();
        }
        zipholder.rotation.y = Math.PI / 2;
        const t = i * interval;
        const x = sizeZipHolder.z + t * (distance - sizeZipTop2.z);
        zipholder.position.x = x;
        zipholder.position.z = sizeX / 2 - 0.002;
        zipholder.position.y = 0.0025;
        bufferZipRoof.add(zipholder);
      }
      for (let i = 1; i < col - 1; i++) {
        let zipholder = null;
        if (roll) {
          zipholder = zipHolder2.clone();
        } else {
          zipholder = zipHolder.clone();
        }
        zipholder.rotation.y = Math.PI / 2;
        zipholder.scale.x = -1;
        const t = i * interval;
        const x = sizeZipHolder.z + t * (distance - sizeZipTop2.z);
        zipholder.position.x = x;
        zipholder.position.z = -sizeX / 2 + 0.002;
        zipholder.position.y = 0.0025;
        bufferZipRoof.add(zipholder);
      }

      bufferZipRoof.add(zipTop2sideR);
      bufferZipRoof.add(zipColumn2LeftsideR);
      bufferZipRoof.add(zipColumn2RightsideR);
      bufferZipRoof.add(zipsideR);
      bufferZipRoof.add(zipBottomsideR);
      bufferZipRoof.position.x = sizebase.z;

      bufferZipRoof.position.z = (this.x - sizeX) / 2 - bias;

      bufferZipRoof.position.y += -0.01;
    };

    const createZipB = (sizeX, bias = 0, change = false) => {
      const bufferZipSideB = new Object3D();
      sideB.add(bufferZipSideB);

      const zipSideB = zip.clone();
      zipSideB.rotation.y = -Math.PI / 2;
      zipSideB.scale.x = sizeX - sizeColumn.z * 2 - 0.05;
      zipSideB.scale.y = -1;
      zipSideB.position.set(
        this.z / 2 - sizeZipeColumn.x / 2,
        this.y1 - sizebeam.y,
        sizeX / 2 - sizeColumn.z - 0.025
      );
      const zipColumnLeftSideB = zipColumn.clone();
      zipColumnLeftSideB.scale.y = this.y1 - sizebeam.y;
      zipColumnLeftSideB.rotation.y = -Math.PI / 2;
      zipColumnLeftSideB.position.set(
        this.z / 2,
        0,
        sizeX / 2 - sizeColumn.z + 0.01
      );
      const zipColumnRightSideB = zipColumn.clone();
      zipColumnRightSideB.rotation.y = Math.PI / 2;
      zipColumnRightSideB.scale.y = this.y1 - sizebeam.y;
      zipColumnRightSideB.position.set(
        this.z / 2 - sizeZipeColumn.z,
        0,
        -sizeX / 2 + sizeColumn.z - 0.01
      );

      let zipTopSideB = null;
      if (change) {
        zipTopSideB = zipTop4.clone();
      } else {
        zipTopSideB = zipTop.clone();
      }

      zipTopSideB.scale.x = sizeX - sizeColumn.z * 2;
      zipTopSideB.rotation.y = -Math.PI / 2;
      zipTopSideB.scale.z = -1;
      zipTopSideB.position.set(
        this.z / 2 - sizeZipeTop.z,
        this.y1 - sizebeam.y,
        sizeX / 2 - sizeColumn.z
      );
      const zipBottomSideB = zipBottom.clone();
      zipBottomSideB.rotation.y = -Math.PI / 2;
      zipBottomSideB.scale.x = sizeX - sizeColumn.z * 2;
      zipBottomSideB.scale.y = -1;
      zipBottomSideB.position.set(
        this.z / 2,
        this.y1 - sizeZip.y - sizebeam.y,
        sizeX / 2 - sizeColumn.z
      );

      bufferZipSideB.position.set(-sizeColumn.x + 0.005, 0, 0);
      bufferZipSideB.add(zipSideB);
      bufferZipSideB.add(zipColumnLeftSideB);
      bufferZipSideB.add(zipColumnRightSideB);
      bufferZipSideB.add(zipTopSideB);
      bufferZipSideB.add(zipBottomSideB);
      bufferZipSideB.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createZipA = (change = false) => {
      const zipSideA = zip.clone();
      zipSideA.scale.x = this.z - sizeColumn.x - 0.005;
      zipSideA.scale.y = -1;
      if (this.y1 > this.y2) {
        zipSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          this.x / 2 - sizeZipeColumn.x / 2
        );
      } else {
        zipSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          this.x / 2 - sizeZipeColumn.x / 2
        );
      }

      const zipColumnLeftSideA = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnLeftSideA.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnLeftSideA.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnLeftSideA.scale.x = -1;
      zipColumnLeftSideA.scale.z = -1;
      zipColumnLeftSideA.position.set(-this.z / 2, 0, this.x / 2);
      const zipColumnRightSideA = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnRightSideA.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnRightSideA.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnRightSideA.scale.z = -1;
      zipColumnRightSideA.position.set(
        this.z / 2 - sizeColumn.x,
        0,
        this.x / 2
      );
      let zipTopSideA = null;
      if (change) {
        zipTopSideA = zipTop4.clone();
      } else {
        zipTopSideA = zipTop.clone();
      }
      zipTopSideA.scale.x = this.z - sizeColumn.x;
      if (this.y1 > this.y2) {
        zipTopSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          this.x / 2 - sizeZipeTop.z
        );
      } else {
        zipTopSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          this.x / 2 - sizeZipeTop.z
        );
      }

      const zipBottomSideA = zipBottom.clone();
      zipBottomSideA.scale.x = -this.z + sizeColumn.x;
      zipBottomSideA.scale.y = -1;
      zipBottomSideA.scale.z = -1;
      if (this.y1 > this.y2) {
        zipBottomSideA.position.set(
          -this.z / 2,
          this.y2 - sizeZip.y - sizebeam.y,
          this.x / 2
        );
      } else {
        zipBottomSideA.position.set(
          -this.z / 2,
          this.y1 - sizeZip.y - sizebeam.y,
          this.x / 2
        );
      }

      sideA.position.set(0, 0, -sizeColumn.z + 0.005);
      sideA.add(zipSideA);
      sideA.add(zipColumnLeftSideA);
      sideA.add(zipColumnRightSideA);
      sideA.add(zipTopSideA);
      sideA.add(zipBottomSideA);
    };
    const createZipC = (change = false) => {
      const zipSideC = zip.clone();
      zipSideC.scale.x = this.z - sizeColumn.x - 0.005;
      zipSideC.scale.y = -1;
      if (this.y1 > this.y2) {
        zipSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          -this.x / 2 + sizeZipeColumn.x / 2
        );
      } else {
        zipSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          -this.x / 2 + sizeZipeColumn.x / 2
        );
      }

      const zipColumnLeftSideC = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnLeftSideC.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnLeftSideC.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnLeftSideC.scale.x = -1;
      zipColumnLeftSideC.position.set(-this.z / 2, 0, -this.x / 2);
      const zipColumnRightSideC = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnRightSideC.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnRightSideC.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnRightSideC.position.set(
        this.z / 2 - sizeColumn.x,
        0,
        -this.x / 2
      );
      let zipTopSideC = null;
      if (change) {
        zipTopSideC = zipTop4.clone();
      } else {
        zipTopSideC = zipTop.clone();
      }
      zipTopSideC.scale.x = this.z - sizeColumn.x;
      zipTopSideC.scale.z = -1;
      if (this.y1 > this.y2) {
        zipTopSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          -this.x / 2 + sizeZipeTop.z
        );
      } else {
        zipTopSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          -this.x / 2 + sizeZipeTop.z
        );
      }

      const zipBottomSideC = zipBottom.clone();
      zipBottomSideC.scale.x = -this.z + sizeColumn.x;
      zipBottomSideC.scale.y = -1;
      if (this.y1 > this.y2) {
        zipBottomSideC.position.set(
          -this.z / 2,
          this.y2 - sizeZip.y - sizebeam.y,
          -this.x / 2
        );
      } else {
        zipBottomSideC.position.set(
          -this.z / 2,
          this.y1 - sizeZip.y - sizebeam.y,
          -this.x / 2
        );
      }

      sideC.position.set(0, 0, sizeColumn.z - 0.005);
      sideC.add(zipSideC);
      sideC.add(zipColumnLeftSideC);
      sideC.add(zipColumnRightSideC);
      sideC.add(zipTopSideC);
      sideC.add(zipBottomSideC);
    };

    if (this.x > 6) {
      const x1 = this.zipRoofSeparation - 0.011;
      const x2 = this.x - x1;
      createZipRoof(x1);
      createZipRoof(x2, x1);
    } else {
      createZipRoof(this.x);
    }

    if (this.x > 6 + 0.2) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createZipB(x1 + sizeColumn.z);
      createZipB(x2 + sizeColumn.z, x1 - sizeColumn.z);
    } else {
      createZipB(this.x);
    }

    createZipA();
    createZipC();

    sideRoof.position.set(
      -this.z / 2 - sizebase.z / 2,
      this.y2 + sizeroof.y - 0.025,
      0
    );

    this.zipType1.userData.sideRoof = sideRoof;
    this.zipType1.userData.sideA = sideA;
    this.zipType1.userData.sideB = sideB;
    this.zipType1.userData.sideC = sideC;
    this.zipType1.userData.sideD = sideD;

    sideRoof = new Object3D();
    sideA = new Object3D();
    sideB = new Object3D();
    sideC = new Object3D();
    sideD = new Object3D();

    if (this.x > 6) {
      const x1 = this.zipRoofSeparation - 0.011;
      const x2 = this.x - x1;
      createZipRoof(x1, undefined, true);
      createZipRoof(x2, x1, true);
    } else {
      createZipRoof(this.x, undefined, true);
    }

    if (this.x > 6 + 0.2) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createZipB(x1 + sizeColumn.z, undefined, true);
      createZipB(x2 + sizeColumn.z, x1 - sizeColumn.z, true);
    } else {
      createZipB(this.x, undefined, true);
    }

    createZipA(true);
    createZipC(true);

    sideRoof.position.set(
      -this.z / 2 - sizebase.z / 2,
      this.y2 + sizeroof.y - 0.025,
      0
    );

    this.zipType3.userData.sideRoof = sideRoof;
    this.zipType3.userData.sideA = sideA;
    this.zipType3.userData.sideB = sideB;
    this.zipType3.userData.sideC = sideC;
    this.zipType3.userData.sideD = sideD;
  }
  createZipType2(
    sizeColumn,
    sizebeam,
    sizebase,
    sizeroof,
    zipTop3,
    zipColumn3,
    zip,
    zipPlaneTop,
    sizeZipPlaneTop,
    zipBottom3,
    zipHolder,
    zipHolder2,
    sizeZipTop3,
    sizeZipColumn3,
    sizeZip,
    sizeZipBottom3,
    sizeZipHolder,
    sizeZipHolder2,
    zipCenter3,
    sizeZipCenter3,
    zipColumn,
    zipTop,
    zipBottom,
    sizeZipeColumn,
    sizeZipeTop,
    sizeZipeBottom,
    zipTop4
  ) {
    let sideRoof = new Object3D();
    let sideA = new Object3D();
    let sideB = new Object3D();
    let sideC = new Object3D();
    let sideD = new Object3D();

    sideRoof.name = 'sideRoof';
    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createZipRoof = (sizeX, bias = 0, roll) => {
      const bufferZipRoof = new Object3D();
      sideRoof.add(bufferZipRoof);

      const point1 = new Vector3(-this.z / 2, this.y2, -this.x / 2);
      const point2 = new Vector3(this.z / 2, this.y1, -this.x / 2);

      const distance = point1.distanceTo(point2);
      zipColumn3.scale.z = distance - sizeZipTop3.z;
      bufferZipRoof.rotation.z = -this.globalAngle;
      const zipTop3sideR = zipTop3.clone();
      zipTop3sideR.rotation.y = -Math.PI / 2;
      if (roll) {
        zipTop3sideR.position.set(
          sizeZipTop3.z,
          0 - 0.01 + sizeZipTop3.y + sizeZipHolder2.y,
          -sizeX / 2
        );
        zipTop3sideR.scale.y = -1;
      } else {
        zipTop3sideR.position.set(sizeZipTop3.z, 0 + 0.007, -sizeX / 2);
      }
      zipTop3sideR.scale.z = -1;
      zipTop3sideR.scale.x = sizeX;
      const zipColumn3LeftsideR = zipColumn3.clone();
      zipColumn3LeftsideR.rotation.y = Math.PI / 2;
      zipColumn3LeftsideR.scale.x = -1;
      if (roll) {
        zipColumn3LeftsideR.position.set(
          sizeZipTop3.z,
          sizeZipHolder2.y,
          sizeX / 2 - sizeZipColumn3.x
        );
      } else {
        zipColumn3LeftsideR.position.set(
          sizeZipTop3.z,
          sizeZipTop3.y - sizeZipColumn3.y,
          sizeX / 2 - sizeZipColumn3.x
        );
      }
      const zipColumn3RightsideR = zipColumn3.clone();
      zipColumn3RightsideR.rotation.y = Math.PI / 2;
      if (roll) {
        zipColumn3RightsideR.position.set(
          sizeZipTop3.z,
          sizeZipHolder2.y,
          -sizeX / 2 + sizeZipColumn3.x
        );
      } else {
        zipColumn3RightsideR.position.set(
          sizeZipTop3.z,
          sizeZipTop3.y - sizeZipColumn3.y,
          -sizeX / 2 + sizeZipColumn3.x
        );
      }
      const zipCentersideR = zipCenter3.clone();
      if (roll) {
        zipCentersideR.position.set(
          this.z / 2,
          sizeZipHolder2.y - sizeZipCenter3.y,
          -sizeX / 2
        );
      } else {
        zipCentersideR.position.set(
          this.z / 2,
          sizeZipTop3.y - sizeZipColumn3.y - sizeZipCenter3.y,
          -sizeX / 2
        );
      }
      zipCentersideR.rotation.y += Math.PI / 2;
      zipCentersideR.scale.x = -sizeX;

      if (!roll) {
        bufferZipRoof.add(zipCentersideR);
      }

      const zipsideR = zipPlaneTop.clone();
      zipsideR.rotation.x = Math.PI / 2;
      zipsideR.rotation.z = Math.PI / 2;
      zipsideR.scale.x = sizeX - 0.1;
      zipsideR.scale.y = -2;

      if (roll) {
        zipsideR.position.set(
          sizeZipTop3.z,
          sizeZipHolder2.y + sizeZipColumn3.y / 2,
          sizeX / 2 - 0.05
        );
      } else {
        zipsideR.position.set(
          sizeZipTop3.z,
          sizeZipTop3.y - sizeZipColumn3.y / 2,
          sizeX / 2 - 0.05
        );
      }
      const zipBottomsideR = zipBottom3.clone();
      zipBottomsideR.rotation.x = Math.PI / 2;
      zipBottomsideR.rotation.z = Math.PI / 2;
      zipBottomsideR.rotation.y = Math.PI / 2;
      zipBottomsideR.scale.x = -sizeX + 0.08;
      if (roll) {
        zipBottomsideR.position.set(
          sizeZipTop3.z + sizeZip.y * 2 + sizeZipBottom3.z,
          sizeZipHolder2.y + sizeZipColumn3.y,
          sizeX / 2 - 0.04
        );
      } else {
        zipBottomsideR.position.set(
          sizeZipTop3.z + sizeZip.y * 2 + sizeZipBottom3.z,
          sizeZipTop3.y,
          sizeX / 2 - 0.04
        );
      }

      const col = Math.floor((distance - sizeZipTop3.z) / sizeZipHolder.z / 6);
      const interval = 1 / (col - 1);
      for (let i = 1; i < col - 1; i++) {
        let zipholder = null;
        if (roll) {
          zipholder = zipHolder2.clone();
        } else {
          zipholder = zipHolder.clone();
        }
        zipholder.scale.y = 0.85;
        zipholder.rotation.y = Math.PI / 2;
        const t = i * interval;
        const x = sizeZipHolder.z + t * (distance - sizeZipTop3.z);
        zipholder.position.x = x;
        zipholder.position.z = sizeX / 2 - 0.004;
        zipholder.position.y = 0.0025;
        bufferZipRoof.add(zipholder);
      }
      for (let i = 1; i < col - 1; i++) {
        let zipholder = null;
        if (roll) {
          zipholder = zipHolder2.clone();
        } else {
          zipholder = zipHolder.clone();
        }
        zipholder.rotation.y = Math.PI / 2;
        zipholder.scale.y = 0.85;
        zipholder.scale.x = -1;
        const t = i * interval;
        const x = sizeZipHolder.z + t * (distance - sizeZipTop3.z);
        zipholder.position.x = x;
        zipholder.position.z = -sizeX / 2 + 0.004;
        zipholder.position.y = 0.0025;
        bufferZipRoof.add(zipholder);
      }

      bufferZipRoof.add(zipTop3sideR);
      bufferZipRoof.add(zipColumn3LeftsideR);
      bufferZipRoof.add(zipColumn3RightsideR);
      bufferZipRoof.add(zipsideR);
      bufferZipRoof.add(zipBottomsideR);
      bufferZipRoof.position.x = sizebase.z;
      bufferZipRoof.position.z = (this.x - sizeX) / 2 - bias;
      bufferZipRoof.position.y += -0.01;
    };
    const createZipB = (sizeX, bias = 0, change = false) => {
      const bufferZipSideB = new Object3D();
      sideB.add(bufferZipSideB);

      const zipSideB = zip.clone();
      zipSideB.rotation.y = -Math.PI / 2;
      zipSideB.scale.x = sizeX - sizeColumn.z * 2 - 0.05;
      zipSideB.scale.y = -1;

      zipSideB.position.set(
        this.z / 2 + sizeZipeColumn.x / 2,
        this.y1 - sizebeam.y,
        sizeX / 2 - sizeColumn.z - 0.025
      );

      const zipColumnLeftSideB = zipColumn.clone();
      zipColumnLeftSideB.scale.y = this.y1 - sizebeam.y;
      zipColumnLeftSideB.rotation.y = -Math.PI / 2;
      zipColumnLeftSideB.position.set(
        this.z / 2 + sizeZipeColumn.z - 0.005,
        0,
        sizeX / 2 - sizeColumn.z + 0.005
      );
      const zipColumnRightSideB = zipColumn.clone();
      zipColumnRightSideB.rotation.y = Math.PI / 2;
      zipColumnRightSideB.scale.y = this.y1 - sizebeam.y;
      zipColumnRightSideB.position.set(
        this.z / 2 - 0.005,
        0,
        -sizeX / 2 + sizeColumn.z - 0.005
      );

      let zipTopSideB = null;
      if (change) {
        zipTopSideB = zipTop4.clone();
      } else {
        zipTopSideB = zipTop.clone();
      }

      zipTopSideB.scale.x = sizeX - sizeColumn.z * 2;
      zipTopSideB.rotation.y = -Math.PI / 2;
      zipTopSideB.position.set(
        this.z / 2 + sizeZipeTop.z,
        this.y1 - sizebeam.y,
        sizeX / 2 - sizeColumn.z
      );
      const zipBottomSideB = zipBottom.clone();
      zipBottomSideB.rotation.y = -Math.PI / 2;
      zipBottomSideB.scale.x = sizeX - sizeColumn.z * 2;
      zipBottomSideB.scale.y = -1;
      zipBottomSideB.position.set(
        this.z / 2 + sizeZipeColumn.x - 0.01,
        this.y1 - sizeZip.y - sizebeam.y,
        sizeX / 2 - sizeColumn.z
      );
      bufferZipSideB.add(zipSideB);
      bufferZipSideB.add(zipColumnLeftSideB);
      bufferZipSideB.add(zipColumnRightSideB);
      bufferZipSideB.add(zipTopSideB);
      bufferZipSideB.add(zipBottomSideB);
      bufferZipSideB.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createZipA = (change = false) => {
      const zipSideA = zip.clone();
      zipSideA.scale.x = this.z - sizeColumn.x - 0.005;
      zipSideA.scale.y = -1;

      if (this.y1 > this.y2) {
        zipSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          this.x / 2 + sizeZipeColumn.x / 2
        );
      } else {
        zipSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          this.x / 2 + sizeZipeColumn.x / 2
        );
      }

      const zipColumnLeftSideA = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnLeftSideA.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnLeftSideA.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnLeftSideA.scale.x = -1;
      zipColumnLeftSideA.position.set(-this.z / 2, 0, this.x / 2 - 0.005);
      const zipColumnRightSideA = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnRightSideA.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnRightSideA.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnRightSideA.position.set(
        this.z / 2 - sizeColumn.x + 0.001 + 0.005,
        0,
        this.x / 2 - 0.005
      );
      let zipTopSideA = null;
      if (change) {
        zipTopSideA = zipTop4.clone();
      } else {
        zipTopSideA = zipTop.clone();
      }
      zipTopSideA.scale.x = this.z - sizeColumn.x - 0.01;
      zipTopSideA.scale.z = -1;
      if (this.y1 > this.y2) {
        zipTopSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          this.x / 2 + sizeZipeTop.z
        );
      } else {
        zipTopSideA.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          this.x / 2 + sizeZipeTop.z
        );
      }

      const zipBottomSideA = zipBottom.clone();
      zipBottomSideA.scale.x = -this.z + sizeColumn.x;
      zipBottomSideA.scale.y = -1;
      if (this.y1 > this.y2) {
        zipBottomSideA.position.set(
          -this.z / 2,
          this.y2 - sizeZip.y - sizebeam.y,
          this.x / 2
        );
      } else {
        zipBottomSideA.position.set(
          -this.z / 2,
          this.y1 - sizeZip.y - sizebeam.y,
          this.x / 2
        );
      }

      sideA.add(zipSideA);
      sideA.add(zipColumnLeftSideA);
      sideA.add(zipColumnRightSideA);
      sideA.add(zipTopSideA);
      sideA.add(zipBottomSideA);
    };
    const createZipC = (change = false) => {
      const zipSideC = zip.clone();
      zipSideC.scale.x = this.z - sizeColumn.x - 0.005;
      zipSideC.scale.y = -1;
      if (this.y1 > this.y2) {
        zipSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          -this.x / 2 - sizeZipeColumn.x / 2
        );
      } else {
        zipSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          -this.x / 2 - sizeZipeColumn.x / 2
        );
      }

      const zipColumnLeftSideC = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnLeftSideC.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnLeftSideC.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnLeftSideC.scale.x = -1;
      zipColumnLeftSideC.scale.z = -1;
      zipColumnLeftSideC.position.set(-this.z / 2, 0, -this.x / 2 + 0.005);
      const zipColumnRightSideC = zipColumn.clone();
      if (this.y1 > this.y2) {
        zipColumnRightSideC.scale.y = this.y2 - sizebeam.y;
      } else {
        zipColumnRightSideC.scale.y = this.y1 - sizebeam.y;
      }

      zipColumnRightSideC.scale.z = -1;
      zipColumnRightSideC.position.set(
        this.z / 2 - sizeColumn.x + 0.001 + 0.005,
        0,
        -this.x / 2 + 0.005
      );

      let zipTopSideC = null;
      if (change) {
        zipTopSideC = zipTop4.clone();
      } else {
        zipTopSideC = zipTop.clone();
      }
      zipTopSideC.scale.x = this.z - sizeColumn.x - 0.01;
      zipTopSideC.scale.z = 1;
      if (this.y1 > this.y2) {
        zipTopSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y2 - sizebeam.y,
          -this.x / 2 - sizeZipeTop.z
        );
      } else {
        zipTopSideC.position.set(
          this.z / 2 - sizeColumn.x,
          this.y1 - sizebeam.y,
          -this.x / 2 - sizeZipeTop.z
        );
      }

      const zipBottomSideC = zipBottom.clone();
      zipBottomSideC.scale.x = -this.z + sizeColumn.x;
      zipBottomSideC.scale.z = -1;
      zipBottomSideC.scale.y = -1;
      if (this.y1 > this.y2) {
        zipBottomSideC.position.set(
          -this.z / 2,
          this.y2 - sizeZip.y - sizebeam.y,
          -this.x / 2
        );
      } else {
        zipBottomSideC.position.set(
          -this.z / 2,
          this.y1 - sizeZip.y - sizebeam.y,
          -this.x / 2
        );
      }

      sideC.add(zipSideC);
      sideC.add(zipColumnLeftSideC);
      sideC.add(zipColumnRightSideC);
      sideC.add(zipTopSideC);
      sideC.add(zipBottomSideC);
    };

    if (this.x > 5) {
      const x1 = this.zipRoofSeparation - 0.011;
      const x2 = this.x - x1;
      createZipRoof(x1);
      createZipRoof(x2, x1);
    } else {
      createZipRoof(this.x);
    }

    if (this.x > 6 + 0.2) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createZipB(x1 + sizeColumn.z);
      createZipB(x2 + sizeColumn.z, x1 - sizeColumn.z);
    } else {
      createZipB(this.x);
    }

    createZipA();
    createZipC();

    sideRoof.position.set(
      -this.z / 2 - sizebase.z / 2,
      this.y2 + sizeroof.y - 0.025,
      0
    );

    this.zipType2.userData.sideRoof = sideRoof;
    this.zipType2.userData.sideA = sideA;
    this.zipType2.userData.sideB = sideB;
    this.zipType2.userData.sideC = sideC;
    this.zipType2.userData.sideD = sideD;

    sideRoof = new Object3D();
    sideA = new Object3D();
    sideB = new Object3D();
    sideC = new Object3D();
    sideD = new Object3D();

    if (this.x > 5) {
      const x1 = this.zipRoofSeparation - 0.011;
      const x2 = this.x - x1;
      createZipRoof(x1, undefined, true);
      createZipRoof(x2, x1, true);
    } else {
      createZipRoof(this.x, undefined, true);
    }

    if (this.x > 6 + 0.2) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createZipB(x1 + sizeColumn.z, undefined, true);
      createZipB(x2 + sizeColumn.z, x1 - sizeColumn.z, true);
    } else {
      createZipB(this.x, undefined, true);
    }

    createZipA(true);
    createZipC(true);

    sideRoof.position.set(
      -this.z / 2 - sizebase.z / 2,
      this.y2 + sizeroof.y - 0.025,
      0
    );

    this.zipType4.userData.sideRoof = sideRoof;
    this.zipType4.userData.sideA = sideA;
    this.zipType4.userData.sideB = sideB;
    this.zipType4.userData.sideC = sideC;
    this.zipType4.userData.sideD = sideD;
  }
  sizeWindowEl(
    windowBorder,
    windowFrame,
    windowGlass,
    windowHandle,
    windowPartition,
    column,
    beam,
    base,
    roof,
    windowFrameT,
    windowFrameB1,
    windowFrameB2,
    windowGlassC,
    windowGlassB
  ) {
    const bbWindowFrameT = new Box3();
    bbWindowFrameT.setFromObject(windowFrameT, true);
    const sizeWindowFrameT = new Vector3();
    bbWindowFrameT.getSize(sizeWindowFrameT);

    const bbWindowFrameB1 = new Box3();
    bbWindowFrameB1.setFromObject(windowFrameB1, true);
    const sizeWindowFrameB1 = new Vector3();
    bbWindowFrameB1.getSize(sizeWindowFrameB1);

    const bbWindowFrameB2 = new Box3();
    bbWindowFrameB2.setFromObject(windowFrameB2, true);
    const sizeWindowFrameB2 = new Vector3();
    bbWindowFrameB2.getSize(sizeWindowFrameB2);

    const bbWindowGlassC = new Box3();
    bbWindowGlassC.setFromObject(windowGlassC, true);
    const sizeWindowGlassC = new Vector3();
    bbWindowGlassC.getSize(sizeWindowGlassC);

    const bbWindowGlassB = new Box3();
    bbWindowGlassB.setFromObject(windowGlassB, true);
    const sizeWindowGlassB = new Vector3();
    bbWindowGlassB.getSize(sizeWindowGlassB);

    const bbRoof = new Box3();
    bbRoof.setFromObject(roof, true);
    const sizeRoof = new Vector3();
    bbRoof.getSize(sizeRoof);

    const bbColumn = new Box3();
    bbColumn.setFromObject(column, true);
    const sizeColumnLouver = new Vector3();
    bbColumn.getSize(sizeColumnLouver);

    const bbBeam = new Box3();
    bbBeam.setFromObject(beam, true);
    const sizeBeamLouver = new Vector3();
    bbBeam.getSize(sizeBeamLouver);

    const bbBase = new Box3();
    bbBase.setFromObject(base, true);
    const sizebase = new Vector3();
    bbBase.getSize(sizebase);

    const bbWindowBorder = new Box3();
    bbWindowBorder.setFromObject(windowBorder, true);
    const sizeWindowBorder = new Vector3();
    bbWindowBorder.getSize(sizeWindowBorder);

    const bbWindowFrame = new Box3();
    bbWindowFrame.setFromObject(windowFrame, true);
    const sizeWindowFrame = new Vector3();
    bbWindowFrame.getSize(sizeWindowFrame);

    const bbWindowGlass = new Box3();
    bbWindowGlass.setFromObject(windowGlass, true);
    const sizeWindowGlass = new Vector3();
    bbWindowGlass.getSize(sizeWindowGlass);

    const bbWindowPartition = new Box3();
    bbWindowPartition.setFromObject(windowPartition, true);
    const sizeWindowPartition = new Vector3();
    bbWindowPartition.getSize(sizeWindowPartition);

    windowGlass.children[0].material.color.set(0x122d78);
    windowGlass.children[0].material.opacity = 0.15;
    windowGlass.children[0].material.transparent = true;
    windowGlass.children[0].material.metalness = 0.75;
    windowGlass.children[0].material.depthWrite = false;
    windowGlass.children[0].material.roughness = 0.39;
    windowGlass.children[0].renderOrder = 2;

    this.createWindowType1(
      windowBorder,
      windowFrame,
      windowGlass,
      windowPartition,
      sizeColumnLouver,
      sizeBeamLouver,
      sizebase,
      sizeWindowBorder,
      sizeWindowFrame,
      sizeWindowPartition,
      sizeRoof
    );

    this.createWindowType2(
      windowFrameT,
      windowFrameB1,
      windowFrameB2,
      windowGlassC,
      windowGlassB,
      windowGlass,
      windowHandle,
      sizeColumnLouver,
      sizeBeamLouver,
      sizebase,
      sizeWindowGlass,
      sizeWindowFrameT,
      sizeWindowFrameB1,
      sizeWindowFrameB2,
      sizeWindowGlassC,
      sizeWindowGlassB,
      windowFrame,
      windowPartition,
      sizeWindowFrame,
      sizeWindowPartition,
      sizeRoof
    );
  }
  createWindowType1(
    border,
    frame,
    glass,
    partition,
    sizeColumn,
    sizeBeam,
    sizebase,
    sizeBorder,
    sizeFrame,
    sizePartition,
    sizeRoof
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createWindowB = (sizeX, bias = 0) => {
      const bufferWindowSideB = new Object3D();
      sideB.add(bufferWindowSideB);

      const windowGlassSideB = glass.clone();
      windowGlassSideB.rotation.y = -Math.PI / 2;
      windowGlassSideB.scale.x = -sizeX + sizeColumn.z * 2;
      windowGlassSideB.scale.y = this.y1 - sizeBeam.y;
      windowGlassSideB.scale.z = -1;
      windowGlassSideB.position.set(
        this.z / 2 - sizeFrame.z / 6,
        0,
        sizeX / 2 - sizeColumn.z
      );
      const startFrameColumnSideB = frame.clone();
      startFrameColumnSideB.scale.y = this.y1 - sizeBeam.y;
      startFrameColumnSideB.rotation.y = Math.PI / 2;
      startFrameColumnSideB.scale.x = -1;
      startFrameColumnSideB.position.set(
        this.z / 2,
        0,
        sizeX / 2 - sizeColumn.z - sizeFrame.x
      );

      bufferWindowSideB.add(startFrameColumnSideB);

      const intervalB = 1 / this.colB;

      for (let i = 1; i < this.colB; i++) {
        const elFrameColum = partition.clone();
        elFrameColum.scale.y = this.y1 - sizeBeam.y;
        elFrameColum.scale.x = -1;
        elFrameColum.rotation.y = Math.PI / 2;

        const t = i * intervalB;
        const x = this.z / 2 + t * (this.z / 2 - this.z / 2);
        const z =
          sizeX / 2 +
          t * (-sizeX / 2 + sizeColumn.z - sizeX / 2 + sizeColumn.z) -
          sizeColumn.z;
        elFrameColum.position.x = x;
        elFrameColum.position.z = z;
        bufferWindowSideB.add(elFrameColum);
      }

      const endFrameColumnSideB = frame.clone();
      endFrameColumnSideB.scale.y = this.y1 - sizeBeam.y;
      endFrameColumnSideB.rotation.y = -Math.PI / 2;
      endFrameColumnSideB.scale.x = -1;
      endFrameColumnSideB.scale.z = -1;
      endFrameColumnSideB.position.set(
        this.z / 2,
        0,
        -sizeX / 2 + sizeColumn.z + sizeFrame.x
      );
      bufferWindowSideB.add(endFrameColumnSideB);

      const frameTopSideB = frame.clone();
      frameTopSideB.rotation.x = -Math.PI / 2;
      frameTopSideB.rotation.y = Math.PI / 2;
      frameTopSideB.scale.y = sizeX - sizeColumn.z * 2;
      frameTopSideB.scale.x = -1;
      frameTopSideB.position.set(
        this.z / 2,
        this.y1 - sizeBeam.y - sizeFrame.z,
        sizeX / 2 - sizeColumn.z
      );

      const frameBottomSideB = frame.clone();

      frameBottomSideB.rotation.x = -Math.PI / 2;
      frameBottomSideB.rotation.y = -Math.PI / 2;
      frameBottomSideB.scale.y = sizeX - sizeColumn.z * 2;
      frameBottomSideB.scale.x = -1;
      frameBottomSideB.scale.z = -1;
      frameBottomSideB.position.set(
        this.z / 2,
        sizeFrame.z,
        sizeX / 2 - sizeColumn.z
      );

      const borderSideB = border.clone();
      borderSideB.rotation.y = -Math.PI / 2;
      borderSideB.scale.x = -sizeX + sizeColumn.z * 2;
      borderSideB.scale.z = -1;
      borderSideB.position.set(this.z / 2, 0, sizeX / 2 - sizeColumn.z);

      bufferWindowSideB.add(windowGlassSideB);
      bufferWindowSideB.add(frameBottomSideB);
      bufferWindowSideB.add(frameTopSideB);
      // bufferWindowSideB.add(borderSideB);

      bufferWindowSideB.position.z = (this.x - sizeX) / 2 - bias;
    };

    if (this.x > 7 + 0.2) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createWindowB(x1 + sizeColumn.z);
      createWindowB(x2 + sizeColumn.z, x1 - sizeColumn.z);
    } else {
      createWindowB(this.x);
    }

    ///////////////////////
    const windowGlassSideD = glass.clone();
    windowGlassSideD.rotation.y = -Math.PI / 2;
    windowGlassSideD.scale.x = -this.x + sizeColumn.z * 2;
    windowGlassSideD.scale.y = this.y2 - sizeBeam.y;
    windowGlassSideD.scale.z = -1;
    windowGlassSideD.position.set(
      -this.z / 2 + sizeFrame.z / 6,
      0,
      this.x / 2 - sizeColumn.z
    );

    const startFrameColumnSideD = frame.clone();
    startFrameColumnSideD.scale.y = this.y2 - sizeBeam.y;
    startFrameColumnSideD.rotation.y = -Math.PI / 2;
    startFrameColumnSideD.position.set(
      -this.z / 2,
      0,
      this.x / 2 - sizeColumn.z - sizeFrame.x
    );
    sideD.add(startFrameColumnSideD);
    // const colD =
    //   Math.floor((this.x - +sizeColumn.z * 2) / sizePartition.x / 14) - 1;
    const intervalD = 1 / this.colD;
    for (let i = 1; i < this.colD; i++) {
      const elFrameColum = partition.clone();
      elFrameColum.scale.y = this.y2 - sizeBeam.y;
      elFrameColum.rotation.y = -Math.PI / 2;
      const t = i * intervalD;
      const x = -this.z / 2 + t * (this.z / 2 - this.z / 2);
      const z =
        this.x / 2 +
        t * (-this.x / 2 + sizeColumn.z - this.x / 2 + sizeColumn.z) -
        sizeColumn.z;
      elFrameColum.position.x = x;
      elFrameColum.position.z = z;
      sideD.add(elFrameColum);
    }
    const endFrameColumnSideD = frame.clone();
    endFrameColumnSideD.scale.y = this.y2 - sizeBeam.y;
    endFrameColumnSideD.rotation.y = Math.PI / 2;
    endFrameColumnSideD.scale.z = -1;

    endFrameColumnSideD.position.set(
      -this.z / 2,
      0,
      -this.x / 2 + sizeColumn.z + sizeFrame.x
    );
    sideD.add(endFrameColumnSideD);

    const frameTopSideD = frame.clone();
    frameTopSideD.rotation.x = -Math.PI / 2;
    frameTopSideD.rotation.y = -Math.PI / 2;
    frameTopSideD.scale.y = this.x - sizeColumn.z * 2;
    frameTopSideD.position.set(
      -this.z / 2,
      this.y2 - sizeBeam.y - sizeFrame.z,
      this.x / 2 - sizeColumn.z
    );

    const frameBottomSideD = frame.clone();
    frameBottomSideD.rotation.x = -Math.PI / 2;
    frameBottomSideD.rotation.y = Math.PI / 2;
    frameBottomSideD.scale.y = this.x - sizeColumn.z * 2;
    frameBottomSideD.scale.z = -1;
    frameBottomSideD.position.set(
      -this.z / 2,
      sizeFrame.z,
      this.x / 2 - sizeColumn.z
    );

    const borderSideD = border.clone();
    borderSideD.rotation.y = -Math.PI / 2;
    borderSideD.scale.x = -this.x + sizeColumn.z * 2;
    borderSideD.position.set(-this.z / 2, 0, this.x / 2 - sizeColumn.z);

    sideD.add(windowGlassSideD);
    sideD.add(frameBottomSideD);
    sideD.add(frameTopSideD);
    // sideD.add(borderSideD);

    //////////////////

    const point1 = new Vector3(-this.z / 2, this.y2, -this.x / 2);
    const point2 = new Vector3(this.z / 2, this.y1, -this.x / 2);

    const distance = point1.distanceTo(point2);
    const direction = new Vector3().subVectors(point2, point1).normalize();
    const angle = Math.atan2(-direction.y, direction.x);
    const geometryWindowGlass = new BufferGeometry();
    let verticesWindowGlass = null;

    if (this.y1 > this.y2) {
      verticesWindowGlass = new Float32Array([
        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        -this.z / 2,
        this.y2,
        0,
        this.z / 2 - sizeColumn.x,
        this.y2,
        0,

        this.z / 2 - sizeColumn.x,
        this.y2,
        0,
        -this.z / 2,
        this.y2,
        0,
        -this.z / 2,
        0,
        0,
        this.z / 2 - sizeColumn.x,
        this.y2,
        0,
        this.z / 2 - sizeColumn.x,
        0,
        0,
        -this.z / 2,
        0,
        0,
      ]);
    } else {
      verticesWindowGlass = new Float32Array([
        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        -this.z / 2,
        this.y1,
        0,
        -this.z / 2,
        this.y2,
        0,

        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        -this.z / 2,
        this.y1,
        0,
        -this.z / 2,
        0,
        0,
        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        this.z / 2 - sizeColumn.x,
        0,
        0,
        -this.z / 2,
        0,
        0,
      ]);
    }

    geometryWindowGlass.setAttribute(
      'position',
      new BufferAttribute(verticesWindowGlass, 3)
    );
    geometryWindowGlass.computeVertexNormals();

    ////////////////////
    const windowGlassSideA = new Mesh(
      geometryWindowGlass,
      glass.children[0].material
    );
    windowGlassSideA.position.set(0, 0, this.x / 2 - sizeFrame.z / 4);

    windowGlassSideA.material = new MeshStandardMaterial();
    windowGlassSideA.material.color.set(0x122d78);
    windowGlassSideA.material.opacity = 0.25;
    windowGlassSideA.material.transparent = true;
    windowGlassSideA.material.metalness = 0.75;
    windowGlassSideA.material.depthWrite = false;
    windowGlassSideA.material.roughness = 0.39;
    windowGlassSideA.renderOrder = 2;
    windowGlassSideA.material.side = 2;

    const startFrameColumnSideA = frame.clone();
    startFrameColumnSideA.scale.y = this.y1;
    startFrameColumnSideA.position.set(
      this.z / 2 - sizeColumn.x - sizeFrame.x,
      0,
      this.x / 2
    );
    sideA.add(startFrameColumnSideA);

    const endFrameColumnSideA = frame.clone();
    endFrameColumnSideA.scale.y = this.y2;
    endFrameColumnSideA.scale.x = -1;
    endFrameColumnSideA.position.set(-this.z / 2 + sizeFrame.x, 0, this.x / 2);
    sideA.add(endFrameColumnSideA);

    // const colA =
    //   Math.floor((this.z - sizeColumn.x) / (sizePartition.x * 12)) - 1;
    const intervalA = 1 / this.colA;
    for (let i = 1; i < this.colA; i++) {
      const elFrameColum = partition.clone();
      const height = (this.y2 - this.y1) / this.colA;
      elFrameColum.scale.y = this.y1 - sizeBeam.y;
      elFrameColum.scale.x = -1;
      elFrameColum.scale.y = this.y1 + height * i;
      const t = i * intervalA;
      const x = this.z / 2 - sizeColumn.x + t * (-this.z + sizeColumn.x);
      const z = this.x / 2;
      elFrameColum.position.x = x + sizePartition.x / 2;
      elFrameColum.position.z = z;
      sideA.add(elFrameColum);
    }

    const partitionTopSideA = partition.clone();
    partitionTopSideA.rotation.z = -Math.PI / 2;
    partitionTopSideA.scale.y = -this.z + sizeColumn.x;
    partitionTopSideA.scale.x = -1;
    if (this.y1 > this.y2) {
      partitionTopSideA.position.set(
        this.z / 2 - sizeColumn.x,
        this.y2 - sizeBeam.y - sizePartition.z / 2,
        this.x / 2
      );
    } else {
      partitionTopSideA.position.set(
        this.z / 2 - sizeColumn.x,
        this.y1 - sizeBeam.y - sizePartition.z / 2,
        this.x / 2
      );
    }

    const frameTopSideAobj = new Object3D();
    frameTopSideAobj.rotation.z = -Math.PI / 2;
    frameTopSideAobj.scale.x = -1;
    frameTopSideAobj.position.set(
      this.z / 2 - sizeColumn.z / 2,
      this.y1 - sizeFrame.x - 0.015,
      this.x / 2
    );
    const frameTopSideA = frame.clone();
    frameTopSideA.scale.y = -distance + sizeBeam.z / 2 + sizebase.z / 2;
    frameTopSideA.rotation.z = angle;

    const frameBottomSideA = frame.clone();
    frameBottomSideA.rotation.z = -Math.PI / 2;
    frameBottomSideA.scale.y = -this.z + sizeColumn.x;
    frameBottomSideA.position.set(
      this.z / 2 - sizeColumn.x,
      sizeFrame.z,
      this.x / 2
    );
    const borderSideA = border.clone();
    borderSideA.scale.x = -this.z + sizeColumn.x * 2;
    borderSideA.position.set(this.z / 2 - sizeColumn.x, 0, this.x / 2);

    sideA.add(windowGlassSideA);
    sideA.add(partitionTopSideA);
    sideA.add(frameBottomSideA);
    frameTopSideAobj.add(frameTopSideA);
    sideA.add(frameTopSideAobj);
    // sideA.add(borderSideA);

    ///////////

    const windowGlassSideC = new Mesh(
      geometryWindowGlass,
      glass.children[0].material
    );
    windowGlassSideC.position.set(0, 0, -this.x / 2 + sizeFrame.z / 4);

    windowGlassSideC.material = new MeshStandardMaterial();
    windowGlassSideC.material.color.set(0x122d78);
    windowGlassSideC.material.opacity = 0.25;
    windowGlassSideC.material.transparent = true;
    windowGlassSideC.material.metalness = 0.75;
    windowGlassSideC.material.depthWrite = false;
    windowGlassSideC.material.roughness = 0.39;
    windowGlassSideC.renderOrder = 2;
    windowGlassSideC.material.side = 2;

    const startFrameColumnSideC = frame.clone();
    startFrameColumnSideC.scale.y = this.y1;
    startFrameColumnSideC.rotation.y = Math.PI;
    startFrameColumnSideC.scale.x = -1;
    startFrameColumnSideC.position.set(
      this.z / 2 - sizeColumn.x - sizeFrame.x,
      0,
      -this.x / 2
    );
    sideC.add(startFrameColumnSideC);

    // const colC =
    //   Math.floor((this.z - sizeColumn.x) / (sizePartition.x * 12)) - 1;
    const intervalC = 1 / this.colC;
    for (let i = 1; i < this.colC; i++) {
      const elFrameColum = partition.clone();
      const height = (this.y2 - this.y1) / this.colC;
      elFrameColum.scale.z = -1;
      elFrameColum.scale.x = -1;
      const t = i * intervalC;
      elFrameColum.scale.y = this.y1 + height * i;
      const x = this.z / 2 - sizeColumn.x + t * (-this.z + sizeColumn.x);
      const z = this.x / 2;
      elFrameColum.position.x = x + sizePartition.x / 2;
      elFrameColum.position.z = -z;
      sideC.add(elFrameColum);
    }

    const endFrameColumnSideC = frame.clone();
    endFrameColumnSideC.rotation.y = -Math.PI;
    endFrameColumnSideC.scale.y = this.y2;
    endFrameColumnSideC.position.set(-this.z / 2 + sizeFrame.x, 0, -this.x / 2);
    sideC.add(endFrameColumnSideC);

    const frameTopSideCobj = new Object3D();
    frameTopSideCobj.rotation.z = -Math.PI / 2;
    frameTopSideCobj.scale.z = -1;
    frameTopSideCobj.scale.x = -1;
    frameTopSideCobj.position.set(
      this.z / 2 - sizeColumn.x / 2,
      this.y1 - sizeFrame.x - 0.015,
      -this.x / 2
    );

    const frameTopSideC = frame.clone();
    frameTopSideC.scale.y = -distance + sizeBeam.z / 2 + sizebase.z / 2;
    frameTopSideC.rotation.z = angle;

    const partitionTopSideC = partition.clone();
    partitionTopSideC.rotation.z = -Math.PI / 2;
    partitionTopSideC.scale.z = -1;
    partitionTopSideC.scale.x = -1;
    partitionTopSideC.scale.y = -this.z + sizeColumn.x;
    if (this.y1 > this.y2) {
      partitionTopSideC.position.set(
        this.z / 2 - sizeColumn.x,
        this.y2 - sizeBeam.y - sizePartition.z / 2,
        -this.x / 2
      );
    } else {
      partitionTopSideC.position.set(
        this.z / 2 - sizeColumn.x,
        this.y1 - sizeBeam.y - sizePartition.z / 2,
        -this.x / 2
      );
    }

    const frameBottomSideC = frame.clone();
    frameBottomSideC.rotation.z = -Math.PI / 2;
    frameBottomSideC.scale.y = -this.z + sizeColumn.x;

    frameBottomSideC.scale.z = -1;
    frameBottomSideC.position.set(
      this.z / 2 - sizeColumn.x,
      sizeFrame.z,
      -this.x / 2
    );
    const borderSideC = border.clone();
    borderSideC.scale.x = -this.z + sizeColumn.x * 2;
    borderSideC.scale.z = -1;
    borderSideC.position.set(this.z / 2 - sizeColumn.x, 0, -this.x / 2);

    sideC.add(windowGlassSideC);
    sideC.add(frameBottomSideC);
    sideC.add(partitionTopSideC);
    frameTopSideCobj.add(frameTopSideC);
    sideC.add(frameTopSideCobj);
    // sideC.add(borderSideC);

    this.windowType1.userData.sideA = sideA;
    this.windowType1.userData.sideB = sideB;
    this.windowType1.userData.sideC = sideC;
    this.windowType1.userData.sideD = sideD;
  }
  createWindowType2(
    frameT,
    frameB1,
    frameB2,
    glassC,
    glassB,
    glass,
    handle,
    sizeColumn,
    sizeBeam,
    sizebase,
    sizeGlass,
    sizeFrameT,
    sizeFrameB1,
    sizeFrameB2,
    sizeGlassC,
    sizeGlassB,
    frame,
    partition,
    sizeFrame,
    sizepartition,
    sizeRoof
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    glass.children[0].material.map = null;

    const createWindowB = (sizeX, bias = 0) => {
      const bufferWindowSideB = new Object3D();
      sideB.add(bufferWindowSideB);

      const leftHandleSideB = handle.clone();

      const windowGlassFrameBSideB = glassB.clone();
      bufferWindowSideB.add(windowGlassFrameBSideB);
      const windowGlassB = glass.clone();
      windowGlassB.rotation.y = Math.PI / 2;
      windowGlassB.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
      windowGlassB.scale.y = this.y1 - sizeBeam.y - sizeFrameT.y;
      windowGlassFrameBSideB.add(windowGlassB);
      windowGlassFrameBSideB.scale.z = (sizeX - sizeColumn.z * 2) / this.colB;
      windowGlassFrameBSideB.scale.x = -1.1;
      windowGlassFrameBSideB.position.set(
        this.z / 2 - sizeFrameB1.x,
        sizeFrameB2.y - 0.005,
        sizeX / 2 - sizeColumn.z
      );
      for (let i = 1; i < this.colB; i++) {
        const elFrameB = windowGlassFrameBSideB.clone();
        bufferWindowSideB.add(elFrameB);
        const x = i * sizeGlassB.x * 1.21;
        elFrameB.position.x += -x;
        const z = (i * (sizeX - sizeColumn.z * 2)) / this.colB;
        elFrameB.position.z += -z;

        if (i === this.colB - 1) {
          const posLeftHandleSideB = elFrameB.getWorldPosition(new Vector3());
          if (this.y1 > this.y2) {
            leftHandleSideB.position.set(
              posLeftHandleSideB.x + 0.025,
              posLeftHandleSideB.y + this.y2 / 2,
              posLeftHandleSideB.z +
                sizeColumn.x -
                (sizeX - sizeColumn.z * 2) / this.colB
            );
          } else {
            leftHandleSideB.position.set(
              posLeftHandleSideB.x + 0.025,
              posLeftHandleSideB.y + this.y1 / 2,
              posLeftHandleSideB.z +
                sizeColumn.x -
                (sizeX - sizeColumn.z * 2) / this.colB
            );
          }
        }
      }

      const startFrameColumnSideB = glassC.clone();
      startFrameColumnSideB.scale.y = this.y1 - sizeBeam.y;
      startFrameColumnSideB.position.set(
        this.z / 2 - sizeGlassC.x,
        0,
        sizeX / 2 - sizeColumn.z
      );
      bufferWindowSideB.add(startFrameColumnSideB);
      const endFrameColumnSideB = glassC.clone();
      endFrameColumnSideB.scale.y = this.y1 - sizeBeam.y;
      endFrameColumnSideB.position.set(
        this.z / 2 - sizeGlassC.x * (this.colB * 0.9),
        0,
        -sizeX / 2 + sizeColumn.z
      );
      endFrameColumnSideB.scale.z = -1;
      bufferWindowSideB.add(endFrameColumnSideB);

      const rightHandleSideB = handle.clone();
      rightHandleSideB.scale.set(0.1, 0.1, 0.3);
      rightHandleSideB.rotation.y = -Math.PI / 2;
      const posRightHandleSideB = windowGlassB.getWorldPosition(new Vector3());

      if (this.y1 > this.y2) {
        rightHandleSideB.position.set(
          posRightHandleSideB.x + 0.04,
          posRightHandleSideB.y + this.y2 / 2,
          posRightHandleSideB.z - sizeColumn.x
        );
      } else {
        rightHandleSideB.position.set(
          posRightHandleSideB.x + 0.04,
          posRightHandleSideB.y + this.y1 / 2,
          posRightHandleSideB.z - sizeColumn.x
        );
      }

      leftHandleSideB.scale.set(0.1, 0.1, 0.3);
      leftHandleSideB.rotation.y = -Math.PI / 2;

      const frameTopSideB = frameT.clone();
      frameTopSideB.scale.z = sizeX - sizeColumn.z * 2;
      frameTopSideB.scale.x = -1;
      frameTopSideB.position.set(
        this.z / 2 - sizeFrameB1.x,
        this.y1 - sizeBeam.y - sizeFrameT.y,
        sizeX / 2 - sizeColumn.z
      );

      for (let i = 0; i < this.colB; i++) {
        const elFrameTop = frameTopSideB.clone();
        bufferWindowSideB.add(elFrameTop);
        const x = i * sizeFrameT.x;
        elFrameTop.position.x += -x * 0.85;
      }

      const frameBottomSideB = frameB1.clone();
      frameBottomSideB.scale.z = sizeX - sizeColumn.z * 2;
      frameBottomSideB.scale.x = -1;
      frameBottomSideB.position.set(this.z / 2, 0, sizeX / 2 - sizeColumn.z);
      for (let i = 0; i < this.colB; i++) {
        const elFrameBotoom = frameB2.clone();
        elFrameBotoom.scale.z = sizeX - sizeColumn.z * 2;
        bufferWindowSideB.add(elFrameBotoom);
        elFrameBotoom.position.set(
          this.z / 2 - sizeFrameB2.x - sizeFrameB1.x,
          0,
          sizeX / 2 - sizeColumn.z
        );
        const x = i * sizeFrameB2.x;
        elFrameBotoom.position.x += -x;
      }
      bufferWindowSideB.add(frameBottomSideB);
      bufferWindowSideB.add(rightHandleSideB);
      bufferWindowSideB.add(leftHandleSideB);

      bufferWindowSideB.position.z = (this.x - sizeX) / 2 - bias;
    };

    if (this.x > 7.5 + 0.2) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createWindowB(x1 + sizeColumn.z);
      createWindowB(x2 + sizeColumn.z, x1 - sizeColumn.z);
    } else {
      createWindowB(this.x);
    }

    ///////////////////////

    const leftHandleSideD = handle.clone();

    const windowGlassFrameBSideD = glassB.clone();
    sideD.add(windowGlassFrameBSideD);

    const windowGlassD = glass.clone();
    windowGlassD.rotation.y = Math.PI / 2;
    windowGlassD.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
    windowGlassD.scale.y = this.y2 - sizeBeam.y - sizeFrameT.y;
    windowGlassFrameBSideD.add(windowGlassD);

    windowGlassFrameBSideD.scale.z = (this.x - sizeColumn.z * 2) / this.colD;
    windowGlassFrameBSideD.scale.x = 1.1;
    windowGlassFrameBSideD.position.set(
      -this.z / 2 + sizeFrameB1.x,
      sizeFrameB2.y - 0.005,
      this.x / 2 - sizeColumn.z
    );
    for (let i = 1; i < this.colD; i++) {
      const elFrameB = windowGlassFrameBSideD.clone();
      sideD.add(elFrameB);
      const x = i * sizeGlassB.x * 1.21;
      elFrameB.position.x += x;
      const z = (i * (this.x - sizeColumn.z * 2)) / this.colD;
      elFrameB.position.z += -z;

      if (i === this.colD - 1) {
        const posLeftHandleSideD = elFrameB.getWorldPosition(new Vector3());

        if (this.y1 > this.y2) {
          leftHandleSideD.position.set(
            posLeftHandleSideD.x - 0.025,
            posLeftHandleSideD.y + this.y2 / 2,
            posLeftHandleSideD.z +
              sizeColumn.x -
              (this.x - sizeColumn.z * 2) / this.colD
          );
        } else {
          leftHandleSideD.position.set(
            posLeftHandleSideD.x - 0.025,
            posLeftHandleSideD.y + this.y1 / 2,
            posLeftHandleSideD.z +
              sizeColumn.x -
              (this.x - sizeColumn.z * 2) / this.colD
          );
        }
      }
    }

    const startFrameColumnSideD = glassC.clone();
    startFrameColumnSideD.scale.y = this.y2 - sizeBeam.y;
    startFrameColumnSideD.position.set(
      -this.z / 2 + sizeGlassC.x - sizeGlassC.x,
      0,
      this.x / 2 - sizeColumn.z
    );
    sideD.add(startFrameColumnSideD);
    const endFrameColumnSideD = glassC.clone();
    endFrameColumnSideD.scale.y = this.y2 - sizeBeam.y;
    endFrameColumnSideD.scale.z = -1;
    endFrameColumnSideD.position.set(
      -this.z / 2 + sizeGlassC.x * 0.9 * this.colD - sizeGlassC.x,
      0,
      -this.x / 2 + sizeColumn.z
    );
    sideD.add(endFrameColumnSideD);

    const rightHandleSideD = handle.clone();
    rightHandleSideD.scale.set(0.1, 0.1, 0.3);
    rightHandleSideD.rotation.y = -Math.PI / 2;

    const posRightHandleSideD = windowGlassD.getWorldPosition(new Vector3());
    if (this.y1 > this.y2) {
      rightHandleSideD.position.set(
        posRightHandleSideD.x + 0.04,
        posRightHandleSideD.y + this.y2 / 2,
        posRightHandleSideD.z - sizeColumn.x
      );
    } else {
      rightHandleSideD.position.set(
        posRightHandleSideD.x + 0.04,
        posRightHandleSideD.y + this.y1 / 2,
        posRightHandleSideD.z - sizeColumn.x
      );
    }

    leftHandleSideD.scale.set(0.1, 0.1, -0.3);
    leftHandleSideD.rotation.y = -Math.PI / 2;

    const frameTopSideD = frameT.clone();
    frameTopSideD.scale.z = this.x - sizeColumn.z * 2;
    frameTopSideD.position.set(
      -this.z / 2,
      this.y2 - sizeBeam.y - sizeFrameT.y,
      this.x / 2 - sizeColumn.z
    );
    for (let i = 0; i < this.colD; i++) {
      const elFrameTop = frameTopSideD.clone();
      sideD.add(elFrameTop);
      const x = i * sizeFrameT.x;
      elFrameTop.position.x += x * 0.85;
    }

    const frameBottomSideD = frameB1.clone();
    frameBottomSideD.scale.z = this.x - sizeColumn.z * 2;
    frameBottomSideD.position.set(-this.z / 2, 0, this.x / 2 - sizeColumn.z);
    for (let i = 0; i < this.colD; i++) {
      const elFrameBotoom = frameB2.clone();
      elFrameBotoom.scale.z = this.x - sizeColumn.z * 2;
      sideD.add(elFrameBotoom);
      elFrameBotoom.position.set(
        -this.z / 2 + sizeFrameB1.x,
        0,
        this.x / 2 - sizeColumn.z
      );
      const x = i * sizeFrameB2.x;
      elFrameBotoom.position.x += x;
    }

    sideD.add(frameBottomSideD);
    sideD.add(leftHandleSideD);
    sideD.add(rightHandleSideD);

    //////////

    const point1 = new Vector3(-this.z / 2, this.y2, -this.x / 2);
    const point2 = new Vector3(this.z / 2, this.y1, -this.x / 2);

    const distance = point1.distanceTo(point2);
    const direction = new Vector3().subVectors(point2, point1).normalize();
    const angle = Math.atan2(-direction.y, direction.x);

    const geometryWindowGlass = new BufferGeometry();
    let verticesWindowGlass = null;

    if (this.y1 > this.y2) {
      verticesWindowGlass = new Float32Array([
        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        -this.z / 2,
        this.y2,
        0,
        this.z / 2 - sizeColumn.x,
        this.y2,
        0,

        this.z / 2 - sizeColumn.x,
        this.y2,
        0,
        -this.z / 2,
        this.y2,
        0,
        -this.z / 2,
        this.y2 - sizeBeam.y,
        0,

        this.z / 2 - sizeColumn.x,
        this.y2,
        0,
        this.z / 2 - sizeColumn.x,
        this.y2 - sizeBeam.y,
        0,
        -this.z / 2,
        this.y2 - sizeBeam.y,
        0,
      ]);
    } else {
      verticesWindowGlass = new Float32Array([
        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        -this.z / 2,
        this.y1,
        0,
        -this.z / 2,
        this.y2,
        0,

        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        -this.z / 2,
        this.y1,
        0,
        -this.z / 2,
        this.y1 - sizeBeam.y,
        0,

        this.z / 2 - sizeColumn.x,
        this.y1,
        0,
        this.z / 2 - sizeColumn.x,
        this.y1 - sizeBeam.y,
        0,
        -this.z / 2,
        this.y1 - sizeBeam.y,
        0,
      ]);
    }

    geometryWindowGlass.setAttribute(
      'position',
      new BufferAttribute(verticesWindowGlass, 3)
    );
    geometryWindowGlass.computeVertexNormals();

    ////////////////////
    const windowGlassSideA = new Mesh(
      geometryWindowGlass,
      glass.children[0].material
    );
    windowGlassSideA.position.set(0, 0, this.x / 2 - 0.011);

    windowGlassSideA.material = new MeshStandardMaterial();
    windowGlassSideA.material.color.set(0x122d78);
    windowGlassSideA.material.opacity = 0.25;
    windowGlassSideA.material.transparent = true;
    windowGlassSideA.material.metalness = 0.75;
    windowGlassSideA.material.depthWrite = false;
    windowGlassSideA.material.roughness = 0.39;
    windowGlassSideA.renderOrder = 2;
    windowGlassSideA.material.side = 2;
    sideA.add(windowGlassSideA);

    const frameTopSideAobj = new Object3D();
    frameTopSideAobj.rotation.z = -Math.PI / 2;
    frameTopSideAobj.scale.x = -1;
    frameTopSideAobj.position.set(
      this.z / 2 - sizeColumn.z / 2,
      this.y1 - sizeFrame.x - 0.015,
      this.x / 2
    );
    const frameTopSideAmini = frame.clone();
    frameTopSideAmini.scale.y = -distance + sizeBeam.z / 2 + sizebase.z / 2;
    frameTopSideAmini.rotation.z = angle;

    frameTopSideAobj.add(frameTopSideAmini);
    sideA.add(frameTopSideAobj);

    const startFrameColumnSideAmini = frame.clone();

    if (this.y1 > this.y2) {
      startFrameColumnSideAmini.scale.y = sizeBeam.y + this.y1 - this.y2;
      startFrameColumnSideAmini.position.set(
        this.z / 2 - sizeColumn.x - sizeFrame.x,
        this.y2 - sizeBeam.y,
        this.x / 2
      );
    } else {
      startFrameColumnSideAmini.scale.y = sizeBeam.y;
      startFrameColumnSideAmini.position.set(
        this.z / 2 - sizeColumn.x - sizeFrame.x,
        this.y1 - sizeBeam.y,
        this.x / 2
      );
    }
    sideA.add(startFrameColumnSideAmini);

    const endFrameColumnSideAmini = frame.clone();
    endFrameColumnSideAmini.scale.x = -1;
    if (this.y1 > this.y2) {
      endFrameColumnSideAmini.scale.y = sizeBeam.y;
      endFrameColumnSideAmini.position.set(
        -this.z / 2 + sizeFrame.x,
        this.y2 - sizeBeam.y,
        this.x / 2
      );
    } else {
      endFrameColumnSideAmini.scale.y = sizeBeam.y + this.y2 - this.y1;
      endFrameColumnSideAmini.position.set(
        -this.z / 2 + sizeFrame.x,
        this.y1 - sizeBeam.y,
        this.x / 2
      );
    }
    sideA.add(endFrameColumnSideAmini);

    const frameBottomSideAmini = frame.clone();
    frameBottomSideAmini.rotation.z = -Math.PI / 2;
    frameBottomSideAmini.scale.y = -this.z + sizeColumn.x;

    if (this.y1 > this.y2) {
      frameBottomSideAmini.position.set(
        this.z / 2 - sizeColumn.x,
        this.y2 + sizeFrame.z - sizeBeam.y,
        this.x / 2
      );
    } else {
      frameBottomSideAmini.position.set(
        this.z / 2 - sizeColumn.x,
        this.y1 + sizeFrame.z - sizeBeam.y,
        this.x / 2
      );
    }
    sideA.add(frameBottomSideAmini);

    const rightHandleSideA = handle.clone();
    const windowGlassFrameBSideA = glassB.clone();
    windowGlassFrameBSideA.rotation.y = -Math.PI / 2;
    sideA.add(windowGlassFrameBSideA);

    const windowGlassA = glass.clone();
    windowGlassA.rotation.y = Math.PI / 2;
    windowGlassA.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
    if (this.y1 > this.y2) {
      windowGlassA.scale.y = this.y2 - sizeBeam.y - sizeFrameT.y;
    } else {
      windowGlassA.scale.y = this.y1 - sizeBeam.y - sizeFrameT.y;
    }

    windowGlassFrameBSideA.add(windowGlassA);
    windowGlassFrameBSideA.scale.z = (-this.z + sizeColumn.x) / this.colA;
    windowGlassFrameBSideA.scale.x = 1.1;
    windowGlassFrameBSideA.position.set(
      this.z / 2 - sizeColumn.x,
      sizeFrameB2.y - 0.005,
      this.x / 2 - sizeFrameB1.x - sizeGlassB.x * 1.21
    );
    for (let i = 1; i < this.colA; i++) {
      const elFrameB = windowGlassFrameBSideA.clone();
      sideA.add(elFrameB);
      const x = i * sizeGlassB.x * 1.21;
      const z = (i * (this.z - sizeColumn.x)) / this.colA;
      elFrameB.position.x += -z;
      elFrameB.position.z += -x;

      if (i === this.colA - 1) {
        const posRightHandleSideA = elFrameB.getWorldPosition(new Vector3());

        if (this.y1 > this.y2) {
          rightHandleSideA.position.set(
            posRightHandleSideA.x +
              sizeColumn.x -
              (this.z - sizeColumn.x) / this.colA,
            posRightHandleSideA.y + this.y2 / 2,
            posRightHandleSideA.z + 0.04
          );
        } else {
          rightHandleSideA.position.set(
            posRightHandleSideA.x +
              sizeColumn.x -
              (this.z - sizeColumn.x) / this.colA,
            posRightHandleSideA.y + this.y1 / 2,
            posRightHandleSideA.z + 0.04
          );
        }
      }
    }

    const startFrameColumnSideA = glassC.clone();
    if (this.y1 > this.y2) {
      startFrameColumnSideA.scale.y = this.y2 - sizeBeam.y;
    } else {
      startFrameColumnSideA.scale.y = this.y1 - sizeBeam.y;
    }
    startFrameColumnSideA.rotation.y = -Math.PI / 2;
    startFrameColumnSideA.scale.x = -1;
    startFrameColumnSideA.scale.z = -1;
    startFrameColumnSideA.position.set(
      this.z / 2 - sizeColumn.x,
      0,
      this.x / 2
    );
    sideA.add(startFrameColumnSideA);
    const endFrameColumnSideA = glassC.clone();
    endFrameColumnSideA.rotation.y = -Math.PI / 2;
    if (this.y1 > this.y2) {
      endFrameColumnSideA.scale.y = this.y2 - sizeBeam.y;
    } else {
      endFrameColumnSideA.scale.y = this.y1 - sizeBeam.y;
    }

    endFrameColumnSideA.scale.x = -1;

    endFrameColumnSideA.position.set(
      -this.z / 2,
      0,
      this.x / 2 - sizeGlassC.x * 0.9 * this.colA + sizeGlassC.x
    );
    sideA.add(endFrameColumnSideA);

    rightHandleSideA.scale.set(0.1, 0.1, -0.3);

    const leftHandleSideA = handle.clone();
    leftHandleSideA.scale.set(0.1, 0.1, -0.3);
    const posLeftHandleSideA = windowGlassA.getWorldPosition(new Vector3());

    if (this.y1 > this.y2) {
      leftHandleSideA.position.set(
        posLeftHandleSideA.x - sizeColumn.x,
        posLeftHandleSideA.y + this.y2 / 2,
        posLeftHandleSideA.z + 0.025
      );
    } else {
      leftHandleSideA.position.set(
        posLeftHandleSideA.x - sizeColumn.x,
        posLeftHandleSideA.y + this.y1 / 2,
        posLeftHandleSideA.z + 0.025
      );
    }

    const frameTopSideA = frameT.clone();
    frameTopSideA.scale.z = -this.z + sizeColumn.x;
    frameTopSideA.rotation.y = -Math.PI / 2;
    frameTopSideA.scale.x = -1;
    if (this.y1 > this.y2) {
      frameTopSideA.position.set(
        this.z / 2 - sizeColumn.x,
        this.y2 - sizeBeam.y - sizeFrameT.y,
        this.x / 2
      );
    } else {
      frameTopSideA.position.set(
        this.z / 2 - sizeColumn.x,
        this.y1 - sizeBeam.y - sizeFrameT.y,
        this.x / 2
      );
    }

    for (let i = 0; i < this.colA; i++) {
      const elFrameTop = frameTopSideA.clone();
      sideA.add(elFrameTop);
      const x = i * sizeFrameT.x;
      elFrameTop.position.z -= x * 0.85;
    }
    const frameBottomSideA = frameB1.clone();
    frameBottomSideA.rotation.y = -Math.PI / 2;
    frameBottomSideA.scale.z = -this.z + sizeColumn.x;
    frameBottomSideA.scale.x = -1;
    frameBottomSideA.position.set(this.z / 2 - sizeColumn.x, 0, this.x / 2);
    for (let i = 0; i < this.colA; i++) {
      const elFrameBotoom = frameB2.clone();
      elFrameBotoom.scale.z = -this.z + sizeColumn.x;
      elFrameBotoom.rotation.y = -Math.PI / 2;
      elFrameBotoom.scale.x = -1;
      sideA.add(elFrameBotoom);
      elFrameBotoom.position.set(this.z / 2 - sizeColumn.x, 0, this.x / 2);
      const x = i * sizeFrameB2.x;
      elFrameBotoom.position.z += -x;
    }

    let newcolA = +this.colA + 1;
    const intervalA = 1 / (newcolA - 1);
    for (let i = 1; i < newcolA - 1; i++) {
      const elFrameColum = partition.clone();
      const height = (this.y2 - this.y1) / (newcolA - 1);
      elFrameColum.scale.y = this.y1 - sizeBeam.y;
      elFrameColum.scale.x = -1;
      elFrameColum.scale.y = height * i + sizeBeam.y;
      if (this.y1 > this.y2) {
        elFrameColum.position.y = this.y2 - sizeBeam.y;
      } else {
        elFrameColum.position.y = this.y1 - sizeBeam.y;
      }
      const t = i * intervalA;
      const x = this.z / 2 - sizeColumn.x + t * (-this.z + sizeColumn.x);
      const z = this.x / 2;
      elFrameColum.position.x = x + sizepartition.x / 2;
      elFrameColum.position.z = z;
      sideA.add(elFrameColum);
    }

    sideA.add(frameBottomSideA);
    sideA.add(leftHandleSideA);
    sideA.add(rightHandleSideA);

    ///////////
    const windowGlassSideC = new Mesh(
      geometryWindowGlass,
      glass.children[0].material
    );
    windowGlassSideC.position.set(0, 0, -this.x / 2 + 0.011);

    windowGlassSideC.material = new MeshStandardMaterial();
    windowGlassSideC.material.color.set(0x122d78);
    windowGlassSideC.material.opacity = 0.25;
    windowGlassSideC.material.transparent = true;
    windowGlassSideC.material.metalness = 0.75;
    windowGlassSideC.material.depthWrite = false;
    windowGlassSideC.material.roughness = 0.39;
    windowGlassSideC.renderOrder = 2;
    windowGlassSideC.material.side = 2;
    sideC.add(windowGlassSideC);

    const startFrameColumnSideCmini = frame.clone();
    if (this.y1 > this.y2) {
      startFrameColumnSideCmini.scale.y = sizeBeam.y + this.y1 - this.y2;
      startFrameColumnSideCmini.position.set(
        this.z / 2 - sizeColumn.x - sizeFrame.x,
        this.y2 - sizeBeam.y,
        -this.x / 2
      );
    } else {
      startFrameColumnSideCmini.scale.y = sizeBeam.y;
      startFrameColumnSideCmini.position.set(
        this.z / 2 - sizeColumn.x - sizeFrame.x,
        this.y1 - sizeBeam.y,
        -this.x / 2
      );
    }
    startFrameColumnSideCmini.rotation.y = Math.PI;
    startFrameColumnSideCmini.scale.x = -1;
    sideC.add(startFrameColumnSideCmini);

    const endFrameColumnSideCmini = frame.clone();
    endFrameColumnSideCmini.rotation.y = -Math.PI;
    if (this.y1 > this.y2) {
      endFrameColumnSideCmini.scale.y = sizeBeam.y;
      endFrameColumnSideCmini.position.set(
        -this.z / 2 + sizeFrame.x,
        this.y2 - sizeBeam.y,
        -this.x / 2
      );
    } else {
      endFrameColumnSideCmini.scale.y = sizeBeam.y + this.y2 - this.y1;
      endFrameColumnSideCmini.position.set(
        -this.z / 2 + sizeFrame.x,
        this.y1 - sizeBeam.y,
        -this.x / 2
      );
    }
    sideC.add(endFrameColumnSideCmini);

    const frameBottomSideCmini = frame.clone();
    frameBottomSideCmini.rotation.z = -Math.PI / 2;
    frameBottomSideCmini.scale.y = -this.z + sizeColumn.x;

    frameBottomSideCmini.scale.z = -1;
    if (this.y1 > this.y2) {
      frameBottomSideCmini.position.set(
        this.z / 2 - sizeColumn.x,
        this.y2 + sizeFrame.z - sizeBeam.y,
        -this.x / 2
      );
    } else {
      frameBottomSideCmini.position.set(
        this.z / 2 - sizeColumn.x,
        this.y1 + sizeFrame.z - sizeBeam.y,
        -this.x / 2
      );
    }
    sideC.add(frameBottomSideCmini);

    const frameTopSideCobj = new Object3D();
    frameTopSideCobj.rotation.z = -Math.PI / 2;
    frameTopSideCobj.scale.z = -1;
    frameTopSideCobj.scale.x = -1;
    frameTopSideCobj.position.set(
      this.z / 2 - sizeColumn.x / 2,
      this.y1 - sizeFrame.x - 0.015,
      -this.x / 2
    );

    const frameTopSideCmini = frame.clone();
    frameTopSideCmini.scale.y = -distance + sizeBeam.z / 2 + sizebase.z / 2;
    frameTopSideCmini.rotation.z = angle;

    frameTopSideCobj.add(frameTopSideCmini);
    sideC.add(frameTopSideCobj);

    const rightHandleSideC = handle.clone();
    const windowGlassFrameBSideC = glassB.clone();
    windowGlassFrameBSideC.rotation.y = -Math.PI / 2;
    sideC.add(windowGlassFrameBSideC);

    const windowGlassC = glass.clone();
    windowGlassC.rotation.y = Math.PI / 2;
    windowGlassC.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
    if (this.y1 > this.y2) {
      windowGlassC.scale.y = this.y2 - sizeBeam.y - sizeFrameT.y;
    } else {
      windowGlassC.scale.y = this.y1 - sizeBeam.y - sizeFrameT.y;
    }

    windowGlassFrameBSideC.add(windowGlassC);

    windowGlassFrameBSideC.scale.z = (-this.z + sizeColumn.x) / this.colC;
    windowGlassFrameBSideC.scale.x = -1.1;
    windowGlassFrameBSideC.position.set(
      this.z / 2 - sizeColumn.x,
      sizeFrameB2.y - 0.005,
      -this.x / 2 + sizeFrameB1.x + sizeGlassB.x * 1.21
    );
    for (let i = 1; i < this.colC; i++) {
      const elFrameB = windowGlassFrameBSideC.clone();
      sideC.add(elFrameB);
      const x = i * sizeGlassB.x * 1.21;
      const z = (i * (this.z - sizeColumn.x)) / this.colC;
      elFrameB.position.x += -z;
      elFrameB.position.z += x;

      if (i === this.colC - 1) {
        const posRightHandleSideC = elFrameB.getWorldPosition(new Vector3());
        if (this.y1 > this.y2) {
          rightHandleSideC.position.set(
            posRightHandleSideC.x +
              sizeColumn.x -
              (this.z - sizeColumn.x) / this.colC,
            posRightHandleSideC.y + this.y2 / 2,
            posRightHandleSideC.z - 0.04
          );
        } else {
          rightHandleSideC.position.set(
            posRightHandleSideC.x +
              sizeColumn.x -
              (this.z - sizeColumn.x) / this.colC,
            posRightHandleSideC.y + this.y1 / 2,
            posRightHandleSideC.z - 0.04
          );
        }
      }
    }

    const startFrameColumnSideC = glassC.clone();
    if (this.y1 > this.y2) {
      startFrameColumnSideC.scale.y = this.y2 - sizeBeam.y;
    } else {
      startFrameColumnSideC.scale.y = this.y1 - sizeBeam.y;
    }

    startFrameColumnSideC.rotation.y = -Math.PI / 2;
    startFrameColumnSideC.scale.z = -1;
    startFrameColumnSideC.position.set(
      this.z / 2 - sizeColumn.x,
      0,
      -this.x / 2
    );
    sideC.add(startFrameColumnSideC);
    const endFrameColumnSideC = glassC.clone();
    endFrameColumnSideC.rotation.y = -Math.PI / 2;
    if (this.y1 > this.y2) {
      endFrameColumnSideC.scale.y = this.y2 - sizeBeam.y;
    } else {
      endFrameColumnSideC.scale.y = this.y1 - sizeBeam.y;
    }

    endFrameColumnSideC.position.set(
      -this.z / 2,
      0,
      -this.x / 2 + sizeGlassC.x * 0.9 * this.colC - sizeGlassC.x
    );
    sideC.add(endFrameColumnSideC);

    rightHandleSideC.scale.set(0.1, 0.1, 0.3);

    const leftHandleSideC = handle.clone();
    leftHandleSideC.scale.set(0.1, 0.1, 0.3);
    const posLeftHandleSideC = windowGlassC.getWorldPosition(new Vector3());
    if (this.y1 > this.y2) {
      leftHandleSideC.position.set(
        posLeftHandleSideC.x - sizeColumn.x,
        posLeftHandleSideC.y + this.y2 / 2,
        posLeftHandleSideC.z - 0.025
      );
    } else {
      leftHandleSideC.position.set(
        posLeftHandleSideC.x - sizeColumn.x,
        posLeftHandleSideC.y + this.y1 / 2,
        posLeftHandleSideC.z - 0.025
      );
    }

    const frameTopSideC = frameT.clone();
    frameTopSideC.rotation.y = -Math.PI / 2;
    frameTopSideC.scale.z = -this.z + sizeColumn.x;
    if (this.y1 > this.y2) {
      frameTopSideC.position.set(
        this.z / 2 - sizeColumn.x,
        this.y2 - sizeBeam.y - sizeFrameT.y,
        -this.x / 2
      );
    } else {
      frameTopSideC.position.set(
        this.z / 2 - sizeColumn.x,
        this.y1 - sizeBeam.y - sizeFrameT.y,
        -this.x / 2
      );
    }

    for (let i = 0; i < this.colC; i++) {
      const elFrameTop = frameTopSideC.clone();
      sideC.add(elFrameTop);
      const x = i * sizeFrameT.x;
      elFrameTop.position.z += x * 0.85;
    }
    const frameBottomSideC = frameB1.clone();
    frameBottomSideC.rotation.y = Math.PI / 2;
    frameBottomSideC.scale.x = -1;
    frameBottomSideC.scale.z = this.z - sizeColumn.x;
    frameBottomSideC.position.set(this.z / 2 - sizeColumn.x, 0, -this.x / 2);
    for (let i = 0; i < this.colC; i++) {
      const elFrameBotoom = frameB2.clone();
      elFrameBotoom.scale.z = this.z - sizeColumn.x;
      elFrameBotoom.rotation.y = Math.PI / 2;
      elFrameBotoom.scale.x = -1;
      sideC.add(elFrameBotoom);
      elFrameBotoom.position.set(this.z / 2 - sizeColumn.x, 0, -this.x / 2);
      const x = i * sizeFrameB2.x;
      elFrameBotoom.position.z += x;
    }

    let newColC = +this.colC + 1;

    const intervalC = 1 / (newColC - 1);
    for (let i = 1; i < newColC - 1; i++) {
      const elFrameColum = partition.clone();
      const height = (this.y2 - this.y1) / (newColC - 1);
      elFrameColum.scale.z = -1;
      elFrameColum.scale.x = -1;
      const t = i * intervalC;
      elFrameColum.scale.y = height * i + sizeBeam.y;
      if (this.y1 > this.y2) {
        elFrameColum.position.y = this.y2 - sizeBeam.y;
      } else {
        elFrameColum.position.y = this.y1 - sizeBeam.y;
      }
      const x = this.z / 2 - sizeColumn.x + t * (-this.z + sizeColumn.x);
      const z = this.x / 2;
      elFrameColum.position.x = x + sizepartition.x / 2;
      elFrameColum.position.z = -z;
      sideC.add(elFrameColum);
    }

    sideC.add(frameBottomSideC);
    sideC.add(rightHandleSideC);
    sideC.add(leftHandleSideC);

    this.windowType2.userData.sideA = sideA;
    this.windowType2.userData.sideB = sideB;
    this.windowType2.userData.sideC = sideC;
    this.windowType2.userData.sideD = sideD;
  }
}
