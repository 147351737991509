import './style.scss';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CustomSimpleBar from 'components/CustomSimpleBar';
import arrowRight from '../../../assets/arrow-right.svg';
import arrowRightBlack from '../../../assets/arrow-right-black.svg';
import imgModel1 from '../../../assets/imgModel1.jpg';
import imgModel2 from '../../../assets/imgModel2.jpg';
import imgModel3 from '../../../assets/imgModel3.jpg';
import skl100 from '../../../assets/SKL-100.png';
import skl50 from '../../../assets/SKL-50.png';
import ecl130 from '../../../assets/ECL-130.png';
import ButtonOpt2 from '../../../components/ui/ButtonOpt2';
import {
  // resetSideFilled,
  setSelectedTypeModel,
  setTypeModel,
  // setTypeModelRemove,
} from '../../../store/reducers/Project/ProjectSlice';
import {
  decrementStep,
  incrementStep,
  setShowSearchModalProduct,
  setShowWarningModalRestart,
  setShowTypeLamellendachModal,
} from '../../../store/reducers/User/UserSlice';
import SidePanelFooter from '../SidePanelFooter';

const Step1 = ({ totalVal, isLoading }) => {
  const { activeStep, source, typeModel } = useSelector((state) => ({
    activeStep: state.user.activeStep,
    source: state.project.source,
    typeModel: state.project.typeModel,
  }));
  const dispatch = useDispatch();

  const [stepPage, setStepPage] = useState(0);

  useEffect(() => {
    setStepPage(0);
  }, [activeStep]);

  const handleNext = useCallback(() => {
    dispatch(incrementStep());
    source?.updEl();
  }, [dispatch, source]);

  const handleBack = useCallback(() => {
    dispatch(decrementStep());
  }, [dispatch]);

  const selectType = useCallback(
    (type) => {
      if (type === 'lamellendach' && window.innerWidth <= 1024) {
        setStepPage(1);
        return;
      }
      if (type === 'lamellendach' && window.innerWidth > 1024) {
        dispatch(setShowTypeLamellendachModal(true));
        return;
      }

      if (!type) {
        toast.warn('Produkt auswählen');
        return;
      }
      if (typeModel === null) {
        source?.funcSelectType(type);
        dispatch(setSelectedTypeModel(type));
        dispatch(setTypeModel(type));
      } else if (typeModel !== type) {
        // source.clearGlobalValues();
        // dispatch(resetSideFilled());
        dispatch(setShowWarningModalRestart(type));
      }
      // dispatch(setSelectedTypeModel(type));
      // dispatch(setTypeModelRemove(type));
      // dispatch(setTypeModel(type));
    },
    [dispatch, source, typeModel]
  );

  const handleOpenModal = useCallback(() => {
    dispatch(setShowSearchModalProduct(true));
  }, [dispatch]);

  const modelChoices = useMemo(
    () => [
      {
        id: 'lamellendach',
        image: imgModel1,
        title: 'Lamellendach',
        active: typeModel === 'louver' || typeModel === 'louver2',
      },
      {
        id: 'glass',
        image: imgModel2,
        title: 'Glasdach',
        active: typeModel === 'glass',
      },
      {
        id: 'louver3',
        image: imgModel3,
        title: 'Festdach',
        active: typeModel === 'louver3',
      },
    ],
    [typeModel]
  );

  return (
    <>
      {stepPage === 0 && (
        <div className="step1">
          <CustomSimpleBar style={{ maxHeight: '100%', width: '100%' }}>
            <div className="step1__content">
              <div className="step1__text">
                Wenn Sie wissen welcher Dachtyp Sie wünschen, dann wählen Sie
                unten aus. Falls Sie nicht wissen was für Sie passt können Sie
                den Produktefinder verwenden um die ideale Überdachungslösung zu
                finden.
              </div>
              <div className="step1__line">
                <span> </span>
              </div>

              <ButtonOpt2
                icon={arrowRight}
                text="Produktfinder"
                handleClick={handleOpenModal}
              />
              {modelChoices.map(({ id, image, title, active }) => (
                <button
                  key={id}
                  type="button"
                  className={`step1__content-choiceModel ${
                    active ? 'choiceModelActive' : ''
                  }`}
                  onClick={() => selectType(id)}
                >
                  <img
                    className="step1__content-choiceModel-image"
                    src={image}
                    alt={title}
                  />
                  <div>
                    {title}
                    <img src={arrowRightBlack} alt="arrowRightBlack" />
                  </div>
                </button>
              ))}
            </div>
          </CustomSimpleBar>
        </div>
      )}

      {stepPage === 1 && window.innerWidth <= 1024 && (
        <div className="step1_1">
          <CustomSimpleBar style={{ maxHeight: '100%', width: '100%' }}>
            <div className="step1_1__content">
              <div className="title-amellendach-modal">
                Ausgewähltes Produkt
              </div>

              <div className="items-lamellendach">
                <div
                  className={
                    typeModel === 'louver'
                      ? 'item-lamellendach active'
                      : 'item-lamellendach'
                  }
                >
                  <img
                    src={skl100}
                    alt=""
                    role="presentation"
                    onClick={() => selectType('louver')}
                  />
                  <div className="title-item-lamellendach">skl-100</div>
                  <div className="list-item-lamellendach">
                    <ul>
                      <li>
                        <span>Bis zu 33m² Schattenplatz.</span>
                      </li>
                      <li>
                        <span>Integrierte Verglasung:</span> Seitenverglasung
                        möglich.
                      </li>
                      <li>
                        <span>Einfache Integration:</span> Nachträglich
                        erweiterbar
                      </li>
                      <li>
                        <span>Geräuschdämpfung:</span> Antiakustische
                        Behandlung.
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className={
                    typeModel === 'louver2'
                      ? 'item-lamellendach active'
                      : 'item-lamellendach'
                  }
                >
                  <img
                    src={skl50}
                    role="presentation"
                    alt=""
                    onClick={() => selectType('louver2')}
                  />
                  <div className="title-item-lamellendach">skl-50</div>
                  <div className="list-item-lamellendach">
                    <ul>
                      <li>
                        <span> Bis zu 21m² Schattenplatz.</span>
                      </li>
                      <li>
                        <span>Kompaktes Design:</span> Schlanke, filigrane
                        Profile.
                      </li>
                      <li>
                        <span>Einfache Integration:</span> Nachträglich
                        erweiterbar.
                      </li>
                      <li>
                        <span>Einfache Integration:</span> Lässt sich in
                        bestehende Konstruktionen einbauen
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  className={
                    typeModel === 'louver3'
                      ? 'item-lamellendach active'
                      : 'item-lamellendach'
                  }
                >
                  <img
                    src={ecl130}
                    role="presentation"
                    alt=""
                    onClick={() => selectType('louver3')}
                  />
                  <div className="title-item-lamellendach">elc-130</div>
                  <div className="list-item-lamellendach">
                    <ul>
                      <li>
                        <span> Bis zu 21m² Schattenplatz.</span>
                      </li>
                      <li>
                        <span>Kompaktes Design:</span> Schlanke, filigrane
                        Profile.
                      </li>
                      <li>
                        <span>Einfache Integration:</span> Nachträglich
                        erweiterbar.
                      </li>
                      <li>
                        <span>Einfache Integration:</span> Lässt sich in
                        bestehende Konstruktionen einbauen
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </CustomSimpleBar>
        </div>
      )}

      <SidePanelFooter
        isLoading={isLoading}
        totalVal={totalVal}
        handleNext={handleNext}
        handleBack={handleBack}
        activeStep={activeStep}
        disableBtn={typeModel === null}
      />
    </>
  );
};

export default Step1;
