import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import Button from '../../ui/Button';
import {
  setSelectedQuote,
  setShowAccountModal,
  signOut,
} from '../../../store/reducers/User/UserSlice';
import Profile from './content/Profile';
import Projects from './content/Projects';
import Quotes from './content/Quotes';

const AccountModal = () => {
  const dispatch = useDispatch();
  const { profile, showAccountModal } = useSelector((state) => state.user);
  const [activeTab, setActiveTab] = useState(showAccountModal);

  useEffect(() => {
    const page =
      profile?.role === 'admin' && showAccountModal === 'projects'
        ? 'quotes'
        : showAccountModal;

    setActiveTab(page);
  }, [showAccountModal]);

  const navList = useMemo(() => {
    if (profile?.role === 'admin') {
      return [
        {
          title: 'Profil',
          slug: 'profile',
        },
        {
          title: 'Aufträge',
          slug: 'quotes',
        },
      ];
    }

    return [
      {
        title: 'Profil',
        slug: 'profile',
      },
      {
        title: 'Gespeicherte Projekte',
        slug: 'projects',
      },
      {
        title: 'Aufträge',
        slug: 'quotes',
      },
    ];
  }, [profile]);
  const content = useMemo(() => {
    switch (activeTab) {
      case 'profile':
        return <Profile />;
      case 'projects':
        return <Projects />;
      case 'quotes':
        return <Quotes />;
      default:
        return null;
    }
  }, [activeTab]);

  const handleSetActiveTab = (slug) => () => {
    if (activeTab === slug) {
      dispatch(setSelectedQuote(null));
      return null;
    }

    return setActiveTab(slug);
  };
  const handleClose = () => {
    dispatch(setShowAccountModal(false));
  };

  return (
    <ModalWrapper
      show={showAccountModal}
      close={handleClose}
      customClass="account-modal"
    >
      <ModalHeader title="Benutzerkonto" close={handleClose} />
      <div className="account-modal__body">
        <div className="wrapper-logout-mob">
          <Button
            className="logout-mob"
            onClick={() => {
              handleClose();
              dispatch(signOut());
            }}
          >
            Ausloggen
          </Button>
        </div>

        <div className="account-modal__nav">
          <div className="account-modal__nav-list">
            {navList.map((el) => (
              <Button
                key={el.slug}
                className={`account-modal__nav-list__item ${
                  el.slug === activeTab ? 'active' : ''
                }`}
                onClick={handleSetActiveTab(el.slug)}
              >
                {el.title}
              </Button>
            ))}
          </div>
          <Button
            className="logout"
            onClick={() => {
              handleClose();
              dispatch(signOut());
            }}
          >
            Ausloggen
          </Button>
        </div>
        <div className="account-modal__content">{content}</div>
      </div>
    </ModalWrapper>
  );
};

export default AccountModal;
