import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  project: {},
  order: [],
  orderSolar: [],
  source: null,
  typeModel: null,
  typeModelRemove: null,
  selectedTypeModel: null,
  selectedSideModel: null,
  openingProject: true,
  sideFilled: {
    sideA: false,
    sideB: false,
    sideC: false,
    sideD: false,
    sideRoof: false,
  },
  prevSizes: {
    width: null,
    depth: null,
    height1: null,
    height2: null,
  },
  elementRes: undefined,
  steeringTypes: [],
  isLoading: '',
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    savePrevSizes(state, action) {
      state.prevSizes = action.payload;
    },
    setProject(state, action) {
      state.data = action.payload;
    },
    setSource(state, action) {
      state.source = action.payload;
    },
    setTypeModel(state, action) {
      state.typeModel = action.payload;
    },
    setTypeModelRemove(state, action) {
      state.typeModelRemove = action.payload;
    },
    setSelectedTypeModel(state, action) {
      state.selectedTypeModel = action.payload;
    },
    setSelectedSideModel(state, action) {
      state.selectedSideModel = action.payload;
    },
    setElementRes(state, action) {
      state.elementRes = action.payload;
    },
    setOpeningProject(state, action) {
      state.openingProject = action.payload;
    },
    setOrder(state, action) {
      if (!action.payload) {
        state.order = [];
      } else {
        const { order } = state;
        const index = order.findIndex(
          (el) =>
            el.id === action.payload.id &&
            el.side === action.payload.side &&
            el.section === action.payload.section &&
            el.squar === action.payload.squar &&
            el.sectionLenght === action.payload.sectionLenght
        );

        if (index >= 0) {
          const qty = order[index].qty + action.payload.qty;

          if (qty > 0) {
            order[index] = {
              side: action.payload.side,
              qty,
              id: action.payload.id,
              Variables:
                action.payload.Variables || order[index].Variables
                  ? [
                      ...(action.payload.Variables
                        ? action.payload.Variables
                        : order[index].Variables),
                    ]
                  : undefined,
            };
          } else {
            order.splice(index, 1);
          }
        } else {
          order.push(action.payload);
        }
        state.order = order;
      }
    },

    setOrderSolar(state, action) {
      if (!action.payload) {
        state.order = [];
      } else {
        const { orderSolar } = state;

        orderSolar.push(action.payload);

        state.orderSolar = orderSolar;
      }
    },

    clearOrder(state) {
      state.order = [];
    },
    clearOrderSolar(state) {
      state.orderSolar = [];
    },
    setSideFilled(state, action) {
      const { key, value } = action.payload;
      state.sideFilled[key] = value;
    },
    setSideFilledBulk(state, action) {
      state.sideFilled = action.payload;
    },
    resetSideFilled(state) {
      state.sideFilled = {
        sideA: false,
        sideB: false,
        sideC: false,
        sideD: false,
        sideRoof: false,
      };
    },
    addSteeringType(state, action) {
      const newType = action.payload;
      if (!state.steeringTypes.includes(newType)) {
        state.steeringTypes.push(newType);
      }
    },
    clearSteeringTypes(state) {
      state.steeringTypes = [];
    },
  },
});

const {
  setProject,
  setSource,
  setTypeModel,
  setTypeModelRemove,
  setSelectedTypeModel,
  setSelectedSideModel,
  setOpeningProject,
  setOrder,
  clearOrder,
  setOrderSolar,
  clearOrderSolar,
  setSideFilled,
  resetSideFilled,
  setElementRes,
  addSteeringType,
  clearSteeringTypes,
  setSideFilledBulk,
} = projectSlice.actions;
export {
  setProject,
  setSource,
  setTypeModel,
  setTypeModelRemove,
  setSelectedTypeModel,
  setSelectedSideModel,
  setOpeningProject,
  setOrder,
  clearOrder,
  setOrderSolar,
  clearOrderSolar,
  setSideFilled,
  resetSideFilled,
  setElementRes,
  addSteeringType,
  clearSteeringTypes,
  setSideFilledBulk,
};

export const updateSideFilled = (key, value) => (dispatch) => {
  dispatch(setSideFilled({ key, value }));
};
export const savePrevSizes = (prevSizes) => ({
  type: 'project/savePrevSizes',
  payload: prevSizes,
});

export default projectSlice.reducer;
