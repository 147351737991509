import './style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import logo from '../../assets/thermogreen.png';
import loginIcon from '../../assets/loginBtn.svg';
import Steps from './Steps';
// eslint-disable-next-line import/named
import {
  setExpertMode,
  setShowAccountModal,
  setShowLoginModal,
} from '../../store/reducers/User/UserSlice';
import StepsMob from './StepsMob';

const Header = () => {
  const dispatch = useDispatch();
  const { profile, activeStep, expertMode } = useSelector(
    (state) => state.user
  );
  const { source } = useSelector((state) => state.project);
  const [expMode, setExpMode] = useState(true);
  const handleExpertMode = (value) => {
    setExpMode(!expMode);
    dispatch(setExpertMode(!expertMode));
    source?.changeExpertMode(value);
  };

  const handleShowAccount = () => {
    if (profile) {
      dispatch(setShowAccountModal('profile'));
    } else {
      dispatch(setShowLoginModal(true));
    }
  };

  return (
    <div
      className={
        activeStep === 1 || activeStep === 2 || activeStep === 3
          ? 'header'
          : 'header none'
      }
    >
      <div className="header__content">
        <div className="header__logoWrap">
          <img src={logo} alt="logo" />
        </div>
        <Steps />
        <div className="header__controls">
          <button
            type="button"
            onClick={() => handleExpertMode(!expMode)}
            className="header__controls-switchWrap"
          >
            <div className="header__controls-switchWrap-switch">
              <div className="header__controls-switchWrap-switch-indicator" />
            </div>
            <div className="header__controls-switchWrap-name">Easy Mode</div>
          </button>
          <button
            type="button"
            className="header__controls-loginBtn"
            onClick={() => handleShowAccount()}
          >
            <img src={loginIcon} alt="loginIcon" />
            {/* <div className='header__controls-loginBtn-name'>{profile?.firstName || profile2?.firstName || 'name'}</div> */}
            <div className="header__controls-loginBtn-name">Konto</div>
          </button>
        </div>
      </div>
      <div className="header-steps-mob">
        <StepsMob />
      </div>
    </div>
  );
};

export default Header;
