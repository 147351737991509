import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import userReducer from './reducers/User/UserSlice';
import projectReducer from './reducers/Project/ProjectSlice';
import config from '../config';
import { api } from './api';
import queryStatus from './middleware/queryStatus';

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  [api.reducerPath]: api.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    let middleware = getDefaultMiddleware({
      serializableCheck: false,
    });

    if (config.debug) {
      middleware = middleware.concat(logger);
    }

    return middleware.concat(api.middleware, queryStatus);
  },
});
