import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import './style.scss';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import InputText from '../../ui/Input';
import Button from '../../ui/Button';
import {
  changePassword,
  resetPassword,
} from '../../../store/reducers/User/ActionCreators';

const ChangePasswordModal = ({ token, show, close }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    password: undefined,
    retypePassword: undefined,
  });

  const handleSubmit = () => {
    if (!values.password || !values.retypePassword) {
      return toast.warn('Passwords is required!');
    }

    if (values.password) {
      if (
        values.password.length < 6 ||
        !/\d/.test(values.password) ||
        !/[a-z]|[A-Z]/.test(values.password)
      ) {
        return toast.error(
          'Please, create password at least 6 characters long, containing at least 1 letter and 1 number.'
        );
      }

      if (values.password !== values.retypePassword) {
        return toast.warn('Password does not match!');
      }
    }

    toast
      .promise(
        dispatch(
          token
            ? resetPassword({ token, password: values.password })
            : changePassword({
                password: values.password,
              })
        ),
        {
          pending: 'Loading...',
          error: 'Error!',
        }
      )
      .then((state) => {
        if (!state?.error) {
          toast.success('Done!');
        }
        close();
      });

    return null;
  };

  return (
    <ModalWrapper show={show} close={close} customClass="change-password-modal">
      <ModalHeader title="Passwort ändern" close={close} />
      <div className="change-password-modal__content">
        <InputText
          type="password"
          placeholder="Passwort eingeben"
          autoComplete="on"
          onChange={(e) => setValues({ ...values, password: e.target.value })}
        />
        <InputText
          type="password"
          placeholder="Passwort wiederholen"
          autoComplete="on"
          onChange={(e) =>
            setValues({ ...values, retypePassword: e.target.value })
          }
        />
        <Button className="big green" onClick={handleSubmit}>
          OK
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default ChangePasswordModal;
