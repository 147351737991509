import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';

import Button from '../../../components/ui/Button';
import PopUpInfo from '../../../components/ui/PopUpInfo';

import {
  setShowTypeZipModal,
  setShowTypeZipRoofModal,
  setShowModelSizeModal,
  setShowTypeLamellenModal,
  setShowVerglasungModal,
  setShowInfoModal,
} from '../../../store/reducers/User/UserSlice';

import options2 from '../../../assets/options/dachoptionen-2.png';
import options3 from '../../../assets/options/dachoptionen-3.png';
import options4 from '../../../assets/options/verglasung-1.jpg';
import options5 from '../../../assets/options/verglasung-2.jpg';
import options7 from '../../../assets/options/zip.jpg';
import options8 from '../../../assets/options/lamellen.jpg';

import infoIcon from '../../../assets/info.svg';
import options11 from '../../../assets/options/dachbeschattung.jpg';
import InfoButton from './InfoButton';
import {
  setElementRes,
  updateSideFilled,
} from '../../../store/reducers/Project/ProjectSlice';

const Elemente = ({ setSideFilled, setSideWall2 }) => {
  const dispatch = useDispatch();
  const { source, typeModel, elementRes } = useSelector(
    (state) => state.project
  );
  const { expertMode } = useSelector((state) => state.user);
  const [sideCurrent, setSideCurrent] = useState(null);
  const [colWall, setColWall] = useState(0);
  const { selectedSideModel } = useSelector((state) => state.project);
  const [sideWall, setSideWall] = useState(false);

  useEffect(() => {
    if (elementRes !== undefined && expertMode) {
      setSideFilled(selectedSideModel, true);
    }
  }, [elementRes]);

  const [typeWindow, setTypeWindow] = useState(null);
  const [typeZip, setTypeZip] = useState(null);
  const [typeLamellen, setTypeLamellen] = useState(null);

  const [position, setPosition] = useState(null);
  const [popup, setPopup] = useState(null);
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const ref6 = useRef();
  const ref7 = useRef();

  const clickInfo = (number) => {
    let x = 0;
    let y = 0;
    setPopup(number);

    if (number === 1) {
      x = ref1.current.getBoundingClientRect().x;
      y = ref1.current.getBoundingClientRect().y;
    }
    if (number === 2) {
      x = ref2.current.getBoundingClientRect().x;
      y = ref2.current.getBoundingClientRect().y;
    }
    if (number === 3) {
      x = ref3.current.getBoundingClientRect().x;
      y = ref3.current.getBoundingClientRect().y;
    }
    if (number === 4) {
      x = ref4.current.getBoundingClientRect().x;
      y = ref4.current.getBoundingClientRect().y;
    }
    if (number === 5) {
      x = ref5.current.getBoundingClientRect().x;
      y = ref5.current.getBoundingClientRect().y;
    }
    if (number === 6) {
      x = ref6.current.getBoundingClientRect().x;
      y = ref6.current.getBoundingClientRect().y;
    }
    if (number === 7) {
      x = ref7.current.getBoundingClientRect().x;
      y = ref7.current.getBoundingClientRect().y;
    }

    setPosition({ x, y });

    let closeScroll = null;

    const close = (e) => {
      if (e.target.classList.value.includes('popup-info')) {
        return;
      }
      setPosition(null);
      document.body.removeEventListener('mousedown', close);
      window.removeEventListener('scroll', closeScroll);
    };

    closeScroll = () => {
      setPosition(null);
      document.body.removeEventListener('mousedown', close);
      window.removeEventListener('scroll', closeScroll);
    };

    window.addEventListener('scroll', closeScroll, true);
    document.body.addEventListener('mousedown', close);
  };

  const currentSide = expertMode ? selectedSideModel : sideCurrent?.name;

  const addEl = () => {
    if (!sideCurrent) {
      toast.warn('Seite muss ausgweählt sein.');
      return;
    }
    if (sideWall) {
      toast.warn('Objekt können nicht hinzugefügt werden ');
      return;
    }
    if (typeModel === 'glass') return;
    source.funcAddEl();
  };
  const toggleWall = (side, show) => {
    if (!sideCurrent) {
      toast.warn('Seite muss ausgweählt sein.');
      return;
    }
    if (
      sideCurrent.name === 'sideRoof' ||
      (typeModel === 'glass' && sideCurrent.name === 'sideB')
    ) {
      // toast.warn('es ist verboten');
      return;
    }

    if (typeWindow || typeZip || typeLamellen) {
      toast.warn('Entfernen Sie alle Elemente von dieser Seite');
      return;
    }

    if (colWall >= 3 && !sideWall) {
      toast.warn('Mit diesem Modell nicht möglich');
      return;
    }

    if (
      typeModel === 'glass' &&
      (sideCurrent.name === 'sideD' || sideCurrent.name === 'sideB')
    )
      return;

    if (!expertMode) {
      const dataWall = source?.funcToggleWall(side, show);
      setSideWall(dataWall);
      dispatch(updateSideFilled(sideCurrent.name, dataWall));
    } else {
      setSideFilled(selectedSideModel, true);
      setSideWall2(source?.funcToggleWall(undefined, true));
    }
  };

  const toggleWindow = (type) => {
    if (!sideCurrent) {
      toast.warn('Seite muss ausgweählt sein.');
      return;
    }
    if (sideCurrent.name === 'sideRoof') {
      // toast.warn('es ist verboten');
      return;
    }
    const windowExists = source.currentModel.arrWindow.some(
      (window) => window.type === type && window.side === selectedSideModel
    );
    if (windowExists && expertMode) {
      return;
    }
    (async () => {
      try {
        const data = await source.funcToggleWindow(undefined, undefined, type);
        if (!expertMode) {
          if (data.value === true) {
            dispatch(updateSideFilled(sideCurrent.name, true));
          } else {
            dispatch(updateSideFilled(sideCurrent.name, false));
          }
        }
        if (data === undefined) {
          toast.warn('Dach zu Hoch');
        }
        dispatch(setElementRes(data));
      } catch (error) {
        console.log('Ошибка выполнения');
      }
    })();
  };
  const handleShowTypeZip = () => {
    if (!sideCurrent) {
      toast.warn('Seite muss ausgweählt sein.');
      return;
    }

    if (sideCurrent.name === 'sideRoof') {
      // toast.warn('es ist verboten');
      return;
    }

    if (expertMode) {
      if (
        source.current.parent.parent.model.y1 < 1 &&
        sideCurrent.name !== 'sideRoof'
      ) {
        toast.warn('Dach zu Hoch');
        return;
      }
      if (typeModel === 'louver') {
        if (
          source.current.parent.parent.model.x < 1 + 0.4 &&
          (sideCurrent.name === 'sideB' || sideCurrent.name === 'sideD')
        ) {
          toast.warn('Dach zu Hoch');
          return;
        }
        if (
          source.current.parent.parent.model.z < 1 + 0.2 &&
          (sideCurrent.name === 'sideA' || sideCurrent.name === 'sideC')
        ) {
          toast.warn('Dach zu Hoch');
          return;
        }
      }
      if (typeModel === 'glass') {
        if (
          source.current.parent.parent.model.x < 1 + 0.2 &&
          (sideCurrent.name === 'sideB' || sideCurrent.name === 'sideD')
        ) {
          toast.warn('Dach zu Hoch');
          return;
        }
        if (
          source.current.parent.parent.model.z < 1 + 0.135 &&
          (sideCurrent.name === 'sideA' || sideCurrent.name === 'sideC')
        ) {
          toast.warn('Dach zu Hoch');
          return;
        }
        if (
          source.current.parent.parent.model.y1 > 4 + 0.15 &&
          sideCurrent.name !== 'sideRoof'
        ) {
          toast.warn('Dach zu Hoch');
          return;
        }
        if (
          source.current.parent.parent.model.x < 1 &&
          sideCurrent.name === 'sideRoof'
        ) {
          toast.warn('Dach zu Hoch');
          return;
        }
        if (
          source.current.parent.parent.model.z < 1 &&
          sideCurrent.name === 'sideRoof'
        ) {
          toast.warn('Dach zu Hoch');
          return;
        }
      }
    }

    const variable = {
      variable: sideCurrent.name,
    };

    source.funcModelSize();
    dispatch(setShowTypeZipModal(variable));
  };
  const handleShowTypeLamellen = () => {
    if (!sideCurrent) {
      toast.warn('Seite muss ausgweählt sein.');
      return;
    }

    if (sideCurrent.name === 'sideRoof') {
      // toast.warn('es ist verboten');
      return;
    }

    source.funcModelSize2();
    dispatch(setShowTypeLamellenModal(sideCurrent.name));
    if (expertMode) {
      setSideFilled(selectedSideModel, true);
    }
  };
  const handleShowTypeZipRoof = () => {
    if (!sideCurrent) {
      toast.warn('Dach muss ausgewählt sein.');
      return;
    }

    if (sideCurrent.name !== 'sideRoof') {
      // toast.warn('es ist verboten');
      return;
    }
    const variable = {
      variable: sideCurrent.name,
    };

    dispatch(setShowTypeZipRoofModal(variable));
    if (expertMode) {
      setSideFilled(selectedSideModel, true);
    }
  };

  useEffect(() => {
    source?.addEventListener('check-side', (data) => {
      setSideWall(data.wall);
      setSideCurrent(data.current);
      setColWall(data.colWall);
      setTypeWindow(data.window?.[0]?.type);
      setTypeZip(data.zip?.[0]?.type);
      setTypeLamellen(data.lamellen?.[0]?.type);
    });

    source?.addEventListener('edit-open', (data) => {
      dispatch(setShowModelSizeModal(data.value));
    });
    source?.addEventListener('wall-disable', () => {
      toast.warn('Sie können auf dieser Seite keine Wände hinzufügen');
    });
    source?.addEventListener('type-window', (data) => {
      source.funcModelSize();
      dispatch(setShowVerglasungModal(data.value));
    });
    source?.addEventListener('type-lamellen', (data) => {
      source.funcModelSize2();
      dispatch(setShowTypeLamellenModal(data.side));
    });
    source?.addEventListener('type-zip', (data) => {
      const variable = {
        variable: data.side,
        windowBlock: data.checkWindow,
      };
      dispatch(setShowTypeZipModal(variable));
    });
    source?.addEventListener('type-zip-roof', (data) => {
      const variable = {
        variable: data.side,
        windowBlock: data.checkWindow,
      };
      dispatch(setShowTypeZipRoofModal(variable));
    });
  }, [source]);

  return (
    <>
      <>
        <div className="accordion-item">
          <div className="accordion-item-title">
            Dachoptionen
            {typeModel === 'louver' || typeModel === 'louver2' ? (
              <>
                <InfoButton
                  title="Dachoptionen"
                  text="Fügen Sie weitere Dächer hinzu oder definieren Sie, wo bei Ihnen zuhause eine Wand steht."
                  link={undefined}
                />
                <Button
                  className="info-icon-btn"
                  onClick={() => {
                    dispatch(
                      setShowInfoModal({
                        title: 'Dachoptionen',
                        text: 'Fügen Sie weitere Dächer hinzu oder definieren Sie, wo bei Ihnen zuhause eine Wand steht.',
                        link: '',
                      })
                    );
                  }}
                >
                  <img src={infoIcon} alt="infoIcon" />
                </Button>
              </>
            ) : null}
            {typeModel === 'glass' ? (
              <>
                <InfoButton
                  title="Dachoptionen"
                  text="Definieren Sie, wo bei Ihnen zuhause eine Wand steht, falls vorhanden."
                  link={undefined}
                />

                <Button
                  className="info-icon-btn"
                  onClick={() => {
                    dispatch(
                      setShowInfoModal({
                        title: 'Dachoptionen',
                        text: 'Definieren Sie, wo bei Ihnen zuhause eine Wand steht, falls vorhanden.',
                        link: '',
                      })
                    );
                  }}
                >
                  <img src={infoIcon} alt="infoIcon" />
                </Button>
              </>
            ) : null}
          </div>
          <div className="accordion-item-content">
            {typeModel === 'glass' ? (
              <> </>
            ) : (
              !expertMode && (
                <div ref={ref1}>
                  {position && popup === 1 && (
                    <PopUpInfo
                      position={position}
                      title="Dach hinzufügen"
                      text="Um dieses Element zu platzieren, wählen Sie bitte eine Seite in der 3D-Ansicht aus."
                      img="true"
                    />
                  )}
                  <div
                    className={
                      currentSide === 'sideRoof'
                        ? 'accordion-option-img disable'
                        : 'accordion-option-img'
                    }
                  >
                    <Button
                      className="accordion-option-content"
                      onClick={() => {
                        if (currentSide === 'sideRoof') {
                          clickInfo(1);
                        } else {
                          addEl();
                        }
                      }}
                    >
                      <img src={options2} alt="" />
                    </Button>
                    <div className="accordion-option-title">
                      Dach hinzufügen
                    </div>
                  </div>
                </div>
              )
            )}

            <div ref={ref2}>
              {position && popup === 2 && (
                <PopUpInfo
                  position={position}
                  title="Wand hinzufügen"
                  text="Um dieses Element zu platzieren, wählen Sie bitte eine Seite in der 3D-Ansicht aus."
                  img="true"
                />
              )}
              <div
                className={
                  currentSide === 'sideRoof'
                    ? 'accordion-option-img disable'
                    : 'accordion-option-img'
                }
              >
                <Button
                  className={
                    sideWall
                      ? 'accordion-option-content active'
                      : 'accordion-option-content'
                  }
                  onClick={() => {
                    if (currentSide === 'sideRoof') {
                      clickInfo(2);
                    } else {
                      toggleWall();
                    }
                  }}
                >
                  <img src={options3} alt="" />
                </Button>
                <div className="accordion-option-title">Wand hinzufügen</div>
              </div>
            </div>
          </div>
        </div>
        <div className="line-or-accordion"> </div>
      </>

      {!sideWall ? (
        <>
          {typeModel !== 'louver2' ? (
            <>
              <div className="accordion-item">
                <div className="accordion-item-title">
                  Verglasung{' '}
                  {typeModel === 'louver' || typeModel === 'louver2' ? (
                    <>
                      <InfoButton
                        title="Verglasung"
                        text="Möchten Sie eine feste Verglasung oder ein Schiebesystem? Bauen Sie ihr Terrassendach mit einer Verglasung aus. Schützt Sie vor Wind und Wetter."
                        link="https://www.thermogreen.ch/lamellendach/"
                      />
                      <Button
                        className="info-icon-btn"
                        onClick={() => {
                          dispatch(
                            setShowInfoModal({
                              title: 'Verglasung',
                              text: 'Möchten Sie eine feste Verglasung oder ein Schiebesystem? Bauen Sie ihr Terrassendach mit einer Verglasung aus. Schützt Sie vor Wind und Wetter.',
                              link: 'https://www.thermogreen.ch/lamellendach/',
                            })
                          );
                        }}
                      >
                        <img src={infoIcon} alt="infoIcon" />
                      </Button>
                    </>
                  ) : null}
                  {typeModel === 'glass' ? (
                    <>
                      <InfoButton
                        title="Verglasung"
                        text="Möchten Sie eine feste Verglasung oder ein Schiebesystem? Bauen Sie ihr Terrassendach mit einer Verglasung aus. Schützt Sie vor Wind und Wetter."
                        link="https://www.thermogreen.ch/glasdach"
                      />
                      <Button
                        className="info-icon-btn"
                        onClick={() => {
                          dispatch(
                            setShowInfoModal({
                              title: 'Verglasung',
                              text: 'Möchten Sie eine feste Verglasung oder ein Schiebesystem? Bauen Sie ihr Terrassendach mit einer Verglasung aus. Schützt Sie vor Wind und Wetter.',
                              link: 'https://www.thermogreen.ch/glasdach',
                            })
                          );
                        }}
                      >
                        <img src={infoIcon} alt="infoIcon" />
                      </Button>
                    </>
                  ) : null}
                </div>
                <div className="accordion-item-content">
                  <div ref={ref3}>
                    {position && popup === 3 && (
                      <PopUpInfo
                        position={position}
                        title="Festverglasung"
                        text="Um dieses Element zu platzieren, wählen Sie bitte eine Seite in der 3D-Ansicht aus."
                        img="true"
                      />
                    )}
                    <div
                      className={
                        currentSide === 'sideRoof'
                          ? 'accordion-option-img disable'
                          : 'accordion-option-img'
                      }
                    >
                      <Button
                        className={
                          typeWindow === 'windowType1'
                            ? 'accordion-option-content active'
                            : 'accordion-option-content'
                        }
                        onClick={() => {
                          if (currentSide === 'sideRoof') {
                            clickInfo(3);
                          } else {
                            toggleWindow('windowType1');
                          }
                        }}
                      >
                        <img src={options4} alt="" />
                      </Button>
                      <div className="accordion-option-title">
                        Festverglasung
                      </div>
                    </div>
                  </div>

                  <div ref={ref4}>
                    {position && popup === 4 && (
                      <PopUpInfo
                        position={position}
                        title="Schiebesystem ohne Rahmen"
                        text="Um dieses Element zu platzieren, wählen Sie bitte eine Seite in der 3D-Ansicht aus."
                        img="true"
                      />
                    )}
                    <div
                      className={
                        currentSide === 'sideRoof'
                          ? 'accordion-option-img disable'
                          : 'accordion-option-img'
                      }
                    >
                      <Button
                        className={
                          typeWindow === 'windowType2'
                            ? 'accordion-option-content active'
                            : 'accordion-option-content'
                        }
                        onClick={() => {
                          if (currentSide === 'sideRoof') {
                            clickInfo(4);
                          } else {
                            toggleWindow('windowType2');
                          }
                        }}
                      >
                        <img src={options5} alt="" />
                      </Button>
                      <div className="accordion-option-title">
                        Schiebesystem ohne Rahmen
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line-or-accordion"> </div>
            </>
          ) : null}

          <div className="accordion-item">
            <div className="accordion-item-title">
              Beschattung{' '}
              {typeModel === 'louver' ||
              typeModel === 'louver2' ||
              typeModel === 'louver3' ? (
                <>
                  <InfoButton
                    title="Beschattung"
                    text="Wünschen Sie zusätzlichen Schutz durch Markisen oder Schiebeläden? Beide dienen als Blend-, Wind-, Sicht- und Regenschutz und sind auch nachträglich nachrüstbar."
                    link="https://www.thermogreen.ch/lamellendach/"
                  />
                  <Button
                    className="info-icon-btn"
                    onClick={() => {
                      dispatch(
                        setShowInfoModal({
                          title: 'Beschattung',
                          text: 'Wünschen Sie zusätzlichen Schutz durch Markisen oder Schiebeläden? Beide dienen als Blend-, Wind-, Sicht- und Regenschutz und sind auch nachträglich nachrüstbar.',
                          link: 'https://www.thermogreen.ch/lamellendach/',
                        })
                      );
                    }}
                  >
                    <img src={infoIcon} alt="infoIcon" />
                  </Button>
                </>
              ) : null}
              {typeModel === 'glass' ? (
                <>
                  <InfoButton
                    title="Beschattung"
                    text="Wünschen Sie zusätzlichen Schutz durch Senkrechtmarkisen und/oder eine Dachbeschattung?"
                    link="https://www.thermogreen.ch/glasdach"
                  />
                  <Button
                    className="info-icon-btn"
                    onClick={() => {
                      dispatch(
                        setShowInfoModal({
                          title: 'Beschattung',
                          text: 'Wünschen Sie zusätzlichen Schutz durch Senkrechtmarkisen und/oder eine Dachbeschattung?',
                          link: 'https://www.thermogreen.ch/glasdach',
                        })
                      );
                    }}
                  >
                    <img src={infoIcon} alt="infoIcon" />
                  </Button>
                </>
              ) : null}
            </div>
            <div className="accordion-item-content">
              {typeModel === 'glass' ? (
                <div ref={ref5}>
                  {position && popup === 5 && (
                    <PopUpInfo
                      position={position}
                      title="Dachbeschattung"
                      text="Um dieses Element zu platzieren, wählen Sie bitte ein Dach in der 3D-Ansicht aus."
                      img="true"
                    />
                  )}
                  <div
                    className={
                      currentSide === 'sideA' ||
                      currentSide === 'sideB' ||
                      currentSide === 'sideC' ||
                      currentSide === 'sideD'
                        ? 'accordion-option-img disable'
                        : 'accordion-option-img'
                    }
                  >
                    <Button
                      className={
                        typeZip && currentSide === 'sideRoof'
                          ? 'accordion-option-content active'
                          : 'accordion-option-content'
                      }
                      onClick={() => {
                        if (
                          currentSide === 'sideA' ||
                          currentSide === 'sideB' ||
                          currentSide === 'sideC' ||
                          currentSide === 'sideD'
                        ) {
                          clickInfo(5);
                        } else {
                          handleShowTypeZipRoof();
                        }
                      }}
                    >
                      <img src={options11} style={{ width: '94px' }} alt="" />
                    </Button>
                    <div className="accordion-option-title">
                      Dachbeschattung
                    </div>
                  </div>
                </div>
              ) : null}

              <div ref={ref6}>
                {position && popup === 6 && (
                  <PopUpInfo
                    position={position}
                    title="ZIP Screen"
                    text="Um dieses Element zu platzieren, wählen Sie bitte eine Seite in der 3D-Ansicht aus."
                    img="true"
                  />
                )}
                <div
                  className={
                    currentSide === 'sideRoof'
                      ? 'accordion-option-img disable'
                      : 'accordion-option-img'
                  }
                >
                  <Button
                    className={
                      typeZip && currentSide !== 'sideRoof'
                        ? 'accordion-option-content active'
                        : 'accordion-option-content'
                    }
                    onClick={() => {
                      if (currentSide === 'sideRoof') {
                        clickInfo(6);
                      } else {
                        handleShowTypeZip();
                      }
                    }}
                  >
                    <img src={options7} alt="" style={{ width: '94px' }} />
                  </Button>
                  <div className="accordion-option-title">ZIP Screen</div>
                </div>
              </div>

              {typeModel === 'louver' || typeModel === 'louver2' ? (
                <div ref={ref7}>
                  {position && popup === 7 && (
                    <PopUpInfo
                      position={position}
                      title="Schiebeläden"
                      text="Um dieses Element zu platzieren, wählen Sie bitte eine Seite in der 3D-Ansicht aus."
                      img="true"
                    />
                  )}
                  <div
                    className={
                      currentSide === 'sideRoof'
                        ? 'accordion-option-img disable'
                        : 'accordion-option-img'
                    }
                  >
                    <Button
                      className={
                        typeLamellen
                          ? 'accordion-option-content active'
                          : 'accordion-option-content'
                      }
                      onClick={() => {
                        if (currentSide === 'sideRoof') {
                          clickInfo(7);
                        } else {
                          handleShowTypeLamellen();
                        }
                      }}
                    >
                      <img src={options8} alt="" style={{ width: '94px' }} />
                    </Button>
                    <div className="accordion-option-title">Schiebeläden</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="line-or-accordion"> </div>
        </>
      ) : (
        <div className="accordion-item text">
          Sie haben eine Wand auf dieser Seite des Daches.
        </div>
      )}
    </>
  );
};

export default Elemente;
