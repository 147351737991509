export default class ReplaceCommand {
  constructor(prev, next, funcUpdatePrice) {
    this.prev = prev;
    this.next = next;
    this.funcUpdatePrice = funcUpdatePrice;
  }
  execute() {
    this.next();
    this.prev();
    this.funcUpdatePrice();
  }
  undo() {
    this.next();
    this.prev();
    this.funcUpdatePrice();
  }
}
