import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import {
  setSelectedProject,
  setSelectedQuote,
  setShowAccountModal,
} from 'store/reducers/User/UserSlice';
import Button from 'components/ui/Button';
import Select from 'components/ui/Select';
import {
  useGenerateNumber,
  useUpdateQuoteMutation,
} from 'services/quotes.service';
import { statusOptions } from 'components/Modals/AccountModal/content/Quotes/Filter';

const QuoteSummaryContent = () => {
  const dispatch = useDispatch();
  const { profile, selectedQuote, selectedProject } = useSelector(
    (state) => state.user
  );
  const { number, isLoading } = useGenerateNumber({
    skip: !selectedProject || !selectedQuote || selectedQuote?.number,
  });
  const [updateQuote, { isLoading: isUpdating }] = useUpdateQuoteMutation();
  const [comment, setComment] = useState(selectedQuote?.comment ?? '');
  const [status, setStatus] = useState(selectedQuote?.status ?? '');

  const quote = useMemo(() => {
    if (selectedQuote?.number) {
      return selectedQuote;
    }
    return {
      number,
      project: selectedProject,
    };
  }, [selectedQuote, selectedProject, number]);

  const handleEditProject = () => {
    dispatch(setSelectedProject(quote.project._id));
    dispatch(setSelectedQuote(null));
  };
  const handleUpdate = () => {
    updateQuote({
      ...quote,
      status,
      comment,
    });
    dispatch(setSelectedQuote(null));
    dispatch(setShowAccountModal('quotes'));
  };

  useEffect(() => {
    const containerPrice = document.getElementById('container-prices');
    containerPrice.innerHTML = quote.innerprice;
  }, [quote]);

  return (
    <>
      <div className="quote-modal__header">
        <span>Zusammenfassung</span>
        <div className="quote-modal__header-number">
          #{isLoading ? '...' : quote.number}
        </div>
      </div>
      <div className="quote-modal__grid">
        <div className="quote-modal__table-title">Kundeninformation</div>
        <div className="quote-modal__table-title">Konfigurationsdetails</div>
        <div className="quote-modal__select">
          <Select
            label="Change Quote Status:"
            options={statusOptions.filter((el) => el.value)}
            value={status}
            onChange={(value) => setStatus(value)}
          />
        </div>
        <div className="quote-modal__content-client-info">
          <div>
            <b>Vorname: </b> {quote?.client?.firstName}
          </div>
          <div>
            <b>Nachname: </b> {quote?.client?.lastName}
          </div>
          <div>
            <b>PLZ / Ort: </b> {quote?.client?.postcode}{' '}
            {quote.client.postcode && quote.client.location ? '/' : null}
            {quote?.client?.location}
          </div>
          <div>
            <b>Email: </b> {quote?.client?.email}
          </div>
          <div>
            <b>Phone: </b> {quote?.client?.phone}
          </div>
        </div>

        <div id="container-prices" className="container-prices">
          {' '}
        </div>
      </div>
      <div className="quote-modal__footer">
        <div className="quote-modal__footer-comment">
          <textarea
            placeholder=""
            readOnly={quote._id && profile?.role === 'user'}
            onChange={(e) => setComment(e.target.value)}
            defaultValue={comment}
          />
        </div>
        <div className="quote-modal__footer-actions">
          <div className="quote-modal__footer-total">
            {/* <strong>Preis exkl. MwSt. Fr. {quote.total}</strong> */}
          </div>
          <div className="quote-modal__footer-btn">
            <Button className="black-border big" onClick={handleEditProject}>
              Offerte anlegen
            </Button>
            <Button
              className="green big"
              onClick={handleUpdate}
              disabled={isUpdating}
            >
              Öffnen
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteSummaryContent;
