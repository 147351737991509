export default class LamellenElCommand {
  constructor(side, data, funcToggleeLamellenPerformance, funcUpdatePrice) {
    this.side = side;
    this.data = data;
    this.funcToggleeLamellenPerformance = funcToggleeLamellenPerformance;
    this.funcUpdatePrice = funcUpdatePrice;
  }
  execute() {
    this.funcToggleeLamellenPerformance(
      this.side,
      this.data.next,
      this.data.next,
      +this.data.nextcolSectionLamellen[0],
      +this.data.nextcolSectionLamellen[1],
      +this.data.nextcolLamellenWidth
    );
    this.funcUpdatePrice();
  }
  undo() {
    this.funcToggleeLamellenPerformance(
      this.side,
      this.data.prev,
      this.data.prev,
      +this.data.prevcolSectionLamellen[0],
      +this.data.prevcolSectionLamellen[1],
      +this.data.prevLamellenWidth
    );
    this.funcUpdatePrice();
  }
}
