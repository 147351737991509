import { Box3, Object3D, Vector3 } from 'three';

export default class ConstructLouver3 extends Object3D {
  constructor(
    solar,
    base,
    beam,
    column,
    roof,
    roofClip1,
    roofClip2,
    x,
    y,
    z,
    zip,
    zipColumn,
    zipTop,
    zipBottom,
    windowBorder,
    windowFrame,
    windowGlass,
    windowHandle,
    windowPartition,
    windowFrameT,
    windowFrameB1,
    windowFrameB2,
    windowGlassC,
    windowGlassB,
    lamellen,
    lamellenRailTop,
    lamellenRailBottom,
    lamellenFrameColumn,
    lamellenFrameBase,
    colB = 0,
    colD = 0,
    colA = 0,
    colC = 0,
    newColLeftB = 0,
    newColLeftD = 0,
    newColLeftA = 0,
    newColLeftC = 0,
    newColRightB = 0,
    newColRightD = 0,
    newColRightA = 0,
    newColRightC = 0,
    newWidthB = 1,
    newWidthD = 1,
    newWidthA = 1,
    newWidthC = 1
  ) {
    super();

    this.newColLeftB = newColLeftB;
    this.newColLeftD = newColRightD;
    this.newColLeftA = newColLeftA;
    this.newColLeftC = newColRightC;
    this.newColRightB = newColRightB;
    this.newColRightD = newColLeftD;
    this.newColRightA = newColRightA;
    this.newColRightC = newColLeftC;

    this.newWidthB = newWidthB;
    this.newWidthD = newWidthD;
    this.newWidthA = newWidthA;
    this.newWidthC = newWidthC;

    this.colB = colB;
    this.colD = colD;
    this.colA = colA;
    this.colC = colC;

    this.solar = solar;
    this.base = base;
    this.beam = beam;
    this.column = column;
    this.roof = roof;

    this.roofClip1 = roofClip1;
    this.roofClip2 = roofClip2;

    this.lamellen = lamellen;
    this.lamellenRailTop = lamellenRailTop;
    this.lamellenRailBottom = lamellenRailBottom;
    this.lamellenFrameColumn = lamellenFrameColumn;
    this.lamellenFrameBase = lamellenFrameBase;

    this.zip = zip;
    this.zipColumn = zipColumn;
    this.zipTop = zipTop;
    this.zipBottom = zipBottom;

    this.windowBorder = windowBorder;
    this.windowFrame = windowFrame;
    this.windowGlass = windowGlass;
    this.windowHandle = windowHandle;
    this.windowPartition = windowPartition;

    this.windowFrameT = windowFrameT;
    this.windowFrameB1 = windowFrameB1;
    this.windowFrameB2 = windowFrameB2;
    this.windowGlassC = windowGlassC;
    this.windowGlassB = windowGlassB;

    this.windowType1 = new Object3D();
    this.windowType2 = new Object3D();
    this.add(this.windowType1);
    this.add(this.windowType2);

    this.solarPanels = new Object3D();
    this.add(this.solarPanels);

    this.solarObj = new Object3D();
    this.add(this.solarObj);

    this.zipType1 = new Object3D();
    this.zipType2 = new Object3D();
    this.add(this.zipType1);
    this.add(this.zipType2);

    this.lamellenType1 = new Object3D();
    this.add(this.lamellenType1);

    this.lamellenObj = new Object3D();
    this.add(this.lamellenObj);

    this.zipObj = new Object3D();
    this.add(this.zipObj);

    this.windowObj = new Object3D();
    this.add(this.windowObj);

    this.x = x / 1000;
    this.y1 = this.y = y / 1000;
    this.z = z / 1000;

    this.type = 'louver3';

    this.bias = {
      z: 0.131,
      x: 0.131,
    };

    this.init();
  }
  async init() {
    try {
      this.createColumn(this.column, this.base);
      this.createRoof(this.roof, this.roofClip1, this.roofClip2, this.beam);

      this.sizeLamellenEl(
        this.lamellen,
        this.lamellenRailTop,
        this.lamellenRailBottom,
        this.lamellenFrameColumn,
        this.lamellenFrameBase,
        this.column,
        this.beam
      );
      this.sizeZipeEl(
        this.zip,
        this.zipColumn,
        this.zipTop,
        this.zipBottom,
        this.column,
        this.beam
      );
      this.sizeWindowEl(
        this.windowBorder,
        this.windowFrame,
        this.windowGlass,
        this.windowHandle,
        this.windowPartition,
        this.column,
        this.beam,

        this.windowFrameT,
        this.windowFrameB1,
        this.windowFrameB2,
        this.windowGlassC,
        this.windowGlassB
      );
      this.createSolar(this.solar);
    } catch (error) {
      console.error(error);
    }
  }

  createColumn(column, base) {
    column.scale.y = this.y;
    const bb = new Box3();
    bb.setFromObject(column, true);
    const size = new Vector3();
    bb.getSize(size);

    const bbbase = new Box3();
    bbbase.setFromObject(base, true);
    const sizebase = new Vector3();
    bbbase.getSize(sizebase);

    const column1 = column.clone();
    column1.rotation.y = Math.PI / 2;
    column1.position.set(this.z / 2, 0, this.x / 2);
    this.add(column1);
    const base1 = base.clone();
    base1.position.copy(column1.position);
    base1.position.y = size.y;
    base1.rotation.y = Math.PI / 2;
    base1.position.z += (sizebase.x - size.x) / 2 - 0.025;
    this.add(base1);

    const column2 = column.clone();
    column2.rotation.y = Math.PI / 2;
    column2.scale.x = -1;
    column2.position.set(this.z / 2, 0, -this.x / 2);
    this.add(column2);
    const base2 = base.clone();
    base2.position.copy(column2.position);
    base2.position.y = size.y;
    base2.rotation.y = Math.PI / 2;
    base2.scale.x = -1;
    base2.position.z -= (sizebase.x - size.x) / 2 - 0.025;
    this.add(base2);

    const column3 = column.clone();
    column3.rotation.y = Math.PI / 2;
    column3.scale.z = -1;
    column3.position.set(-this.z / 2, 0, this.x / 2);
    this.add(column3);
    const base3 = base.clone();
    base3.position.copy(column3.position);
    base3.position.y = size.y;
    base3.rotation.y = Math.PI / 2;
    base3.scale.z = -1;
    base3.position.z += (sizebase.x - size.x) / 2 - 0.025;
    this.add(base3);

    const column4 = column.clone();
    column4.rotation.y = Math.PI / 2;
    column4.scale.x = -1;
    column4.scale.z = -1;
    column4.position.set(-this.z / 2, 0, -this.x / 2);
    this.add(column4);
    const base4 = base.clone();
    base4.position.copy(column4.position);
    base4.position.y = size.y;
    base4.rotation.y = Math.PI / 2;
    base4.scale.z = -1;
    base4.scale.x = -1;
    base4.position.z -= (sizebase.x - size.x) / 2 - 0.025;
    this.add(base4);

    this.createBeam(this.beam);
  }
  createBeam(beam) {
    const bbbeam = new Box3();
    bbbeam.setFromObject(beam, true);
    const sizebeam = new Vector3();
    bbbeam.getSize(sizebeam);

    const beamFront = beam.clone();
    beamFront.scale.z = this.x - 0.1;
    beamFront.scale.x = -1;
    beamFront.position.set(this.z / 2, this.y - sizebeam.y, this.x / 2 - 0.05);
    this.add(beamFront);

    const beamBack = beam.clone();
    beamBack.scale.z = -this.x + 0.1;
    beamBack.position.set(-this.z / 2, this.y - sizebeam.y, -this.x / 2 + 0.05);
    this.add(beamBack);

    const beamRight = beam.clone();
    beamRight.rotation.y = -Math.PI / 2;
    beamRight.scale.z = -this.z + 0.1;
    beamRight.position.set(this.z / 2 - 0.05, this.y - sizebeam.y, -this.x / 2);
    this.add(beamRight);

    const beamLeft = beam.clone();
    beamLeft.rotation.y = Math.PI / 2;
    beamLeft.scale.z = -this.z + 0.1;
    beamLeft.position.set(-this.z / 2 + 0.05, this.y - sizebeam.y, this.x / 2);
    this.add(beamLeft);
  }
  createRoof(roof, roofClip1, roofClip2, beam) {
    const bb = new Box3();
    bb.setFromObject(roof, true);
    const size = new Vector3();
    bb.getSize(size);

    const bbeam = new Box3();
    bbeam.setFromObject(beam, true);
    const sizebeam = new Vector3();
    bbeam.getSize(sizebeam);

    const roofEl = roof.clone();
    roofEl.scale.z = this.x - 0.26;
    roofEl.position.z = this.x / 2 - sizebeam.x;
    roofEl.position.y = this.y - size.y - 0.01;
    roofEl.position.x = -this.z / 2 + 0.13;
    this.add(roofEl);

    const col = Math.floor((this.z - sizebeam.x * 2) / size.x);
    for (let i = 1; i < col; i++) {
      const elRoof = roofEl.clone();
      elRoof.position.x = roofEl.position.x + i * size.x;
      this.add(elRoof);
    }

    // console.log(col * size.x, this.z - 0.26, col * size.x < this.z - 0.26);
    if (col * size.x < this.z - 0.26) {
      const elroofClip1 = roofClip1.clone();
      elroofClip1.scale.z = this.x - 0.26;
      elroofClip1.position.z = this.x / 2 - sizebeam.x;
      elroofClip1.position.y = this.y - size.y - 0.01;
      elroofClip1.position.x = -this.z / 2 + 0.13 + col * size.x;
      this.add(elroofClip1);
    }
    if (this.z - 0.26 - col * size.x > 0.09435) {
      const elroofClip2 = roofClip2.clone();
      elroofClip2.scale.z = this.x - 0.26;
      elroofClip2.position.z = this.x / 2 - sizebeam.x;
      elroofClip2.position.y = this.y - size.y - 0.01;
      elroofClip2.position.x = -this.z / 2 + 0.13 + col * size.x + 0.09435;
      this.add(elroofClip2);
    }

    if (this.z - 0.26 - col * size.x > 0.09435 + 0.079) {
      const elroofClip2 = roofClip2.clone();
      elroofClip2.scale.z = this.x - 0.26;
      elroofClip2.position.z = this.x / 2 - sizebeam.x;
      elroofClip2.position.y = this.y - size.y - 0.01;
      elroofClip2.position.x =
        -this.z / 2 + 0.13 + col * size.x + 0.09435 + 0.079;
      this.add(elroofClip2);
    }
  }

  sizeZipeEl(zip, zipColumn, zipTop, zipBottom, column, beam) {
    const bbColumn = new Box3();
    bbColumn.setFromObject(column, true);
    const sizeColumnLouver = new Vector3();
    bbColumn.getSize(sizeColumnLouver);

    const bbBeam = new Box3();
    bbBeam.setFromObject(beam, true);
    const sizeBeamLouver = new Vector3();
    bbBeam.getSize(sizeBeamLouver);

    const bbZip = new Box3();
    bbZip.setFromObject(zip, true);
    const sizeZipe = new Vector3();
    bbZip.getSize(sizeZipe);

    const bbZipColumn = new Box3();
    bbZipColumn.setFromObject(zipColumn, true);
    const sizeZipeColumn = new Vector3();
    bbZipColumn.getSize(sizeZipeColumn);

    const bbZipTop = new Box3();
    bbZipTop.setFromObject(zipTop, true);
    const sizeZipeTop = new Vector3();
    bbZipTop.getSize(sizeZipeTop);

    const bbZipBottom = new Box3();
    bbZipBottom.setFromObject(zipBottom, true);
    const sizeZipeBottom = new Vector3();
    bbZipBottom.getSize(sizeZipeBottom);

    this.createZipType1(
      zip,
      zipColumn,
      zipTop,
      zipBottom,
      sizeColumnLouver,
      sizeBeamLouver,
      sizeZipeColumn,
      sizeZipeTop,
      sizeZipe,
      sizeZipeBottom
    );

    this.createZipType2(
      zip,
      zipColumn,
      zipTop,
      zipBottom,
      sizeColumnLouver,
      sizeBeamLouver,
      sizeZipeColumn,
      sizeZipeTop,
      sizeZipe,
      sizeZipeBottom
    );
  }
  createZipType1(
    zip,
    zipColumn,
    zipTop,
    zipBottom,
    sizeColumn,
    sizeBeam,
    sizeZipeColumn,
    sizeZipeTop,
    sizeZipe,
    sizeZipeBottom
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createZipB = (sizeX, bias = 0) => {
      const bufferZipSideB = new Object3D();
      sideB.add(bufferZipSideB);

      const zipSideB = zip.clone();
      zipSideB.rotation.y = -Math.PI / 2;
      zipSideB.scale.x = sizeX - sizeColumn.x * 2 - 0.05 + sizeZipeColumn.x * 2;
      zipSideB.scale.y = -1;

      zipSideB.position.set(
        this.z / 2 + sizeZipeColumn.x / 2,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x - 0.025 + sizeZipeColumn.x
      );

      const zipColumnLeftSideB = zipColumn.clone();
      zipColumnLeftSideB.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideB.rotation.y = -Math.PI / 2;
      zipColumnLeftSideB.position.set(
        this.z / 2 + sizeZipeColumn.z,
        0,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );
      const zipColumnRightSideB = zipColumn.clone();
      zipColumnRightSideB.rotation.y = Math.PI / 2;
      zipColumnRightSideB.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideB.position.set(
        this.z / 2,
        0,
        -sizeX / 2 + sizeColumn.x - sizeZipeColumn.x
      );

      const zipTopSideB = zipTop.clone();
      zipTopSideB.scale.x = sizeX - sizeColumn.x * 2 + sizeZipeColumn.x * 2;
      zipTopSideB.rotation.y = -Math.PI / 2;
      zipTopSideB.position.set(
        this.z / 2 + sizeZipeTop.z,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );

      const zipBottomSideB = zipBottom.clone();
      zipBottomSideB.rotation.y = -Math.PI / 2;
      zipBottomSideB.scale.x = sizeX - sizeColumn.x * 2;
      zipBottomSideB.scale.y = -1;
      zipBottomSideB.position.set(
        this.z / 2 + sizeZipeColumn.x,
        this.y - sizeZipe.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x
      );

      bufferZipSideB.add(zipSideB);
      bufferZipSideB.add(zipColumnLeftSideB);
      bufferZipSideB.add(zipColumnRightSideB);
      bufferZipSideB.add(zipTopSideB);
      bufferZipSideB.add(zipBottomSideB);

      bufferZipSideB.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createZipD = (sizeX, bias = 0) => {
      const bufferZipSideD = new Object3D();
      sideD.add(bufferZipSideD);

      const zipSideD = zip.clone();
      zipSideD.rotation.y = -Math.PI / 2;
      zipSideD.scale.x = sizeX - sizeColumn.x * 2 - 0.05 + sizeZipeColumn.x * 2;
      zipSideD.scale.y = -1;
      zipSideD.position.set(
        -this.z / 2 - sizeZipeColumn.x / 2,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x - 0.025 + sizeZipeColumn.x
      );
      const zipColumnLeftSideD = zipColumn.clone();
      zipColumnLeftSideD.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideD.rotation.y = -Math.PI / 2;
      zipColumnLeftSideD.position.set(
        -this.z / 2,
        0,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );
      const zipColumnRightSideD = zipColumn.clone();
      zipColumnRightSideD.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideD.rotation.y = Math.PI / 2;
      zipColumnRightSideD.position.set(
        -this.z / 2 - sizeZipeColumn.z,
        0,
        -sizeX / 2 + sizeColumn.x - sizeZipeColumn.x
      );
      const zipTopSideD = zipTop.clone();
      zipTopSideD.scale.x = sizeX - sizeColumn.x * 2 + sizeZipeColumn.x * 2;
      zipTopSideD.scale.z = -1;
      zipTopSideD.rotation.y = -Math.PI / 2;
      zipTopSideD.position.set(
        -this.z / 2 - sizeZipeTop.z,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );
      const zipBottomSideD = zipBottom.clone();
      zipBottomSideD.rotation.y = -Math.PI / 2;
      zipBottomSideD.scale.x = sizeX - sizeColumn.x * 2;
      zipBottomSideD.scale.y = -1;
      zipBottomSideD.position.set(
        -this.z / 2,
        this.y - sizeZipe.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x
      );
      bufferZipSideD.add(zipSideD);
      bufferZipSideD.add(zipColumnLeftSideD);
      bufferZipSideD.add(zipColumnRightSideD);
      bufferZipSideD.add(zipTopSideD);
      bufferZipSideD.add(zipBottomSideD);

      bufferZipSideD.position.z = (this.x - sizeX) / 2 - bias;
    };

    const createZipA = (sizeZ, bias = 0) => {
      const bufferZipSideA = new Object3D();
      sideA.add(bufferZipSideA);

      const zipSideA = zip.clone();
      zipSideA.scale.x = sizeZ - sizeColumn.x - 0.005 + sizeZipeColumn.z * 2;
      zipSideA.scale.y = -1;
      zipSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + sizeZipeColumn.z,
        this.y - sizeBeam.y,
        this.x / 2 + sizeZipeColumn.x / 2
      );
      const zipColumnLeftSideA = zipColumn.clone();
      zipColumnLeftSideA.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideA.scale.x = -1;
      zipColumnLeftSideA.position.set(
        -sizeZ / 2 + sizeColumn.x / 2 - sizeZipeColumn.z,
        0,
        this.x / 2
      );
      const zipColumnRightSideA = zipColumn.clone();
      zipColumnRightSideA.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + 0.001 + sizeZipeColumn.z,
        0,
        this.x / 2
      );
      const zipTopSideA = zipTop.clone();
      zipTopSideA.scale.x = sizeZ - sizeColumn.x + sizeZipeColumn.z * 2;
      zipTopSideA.scale.z = -1;
      zipTopSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + sizeZipeColumn.z,
        this.y - sizeBeam.y,
        this.x / 2 + sizeZipeTop.z
      );
      const zipBottomSideA = zipBottom.clone();
      zipBottomSideA.scale.x = -sizeZ + sizeColumn.x;
      zipBottomSideA.scale.y = -1;
      zipBottomSideA.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        this.y - sizeZipe.y - sizeBeam.y,
        this.x / 2
      );
      bufferZipSideA.add(zipSideA);
      bufferZipSideA.add(zipColumnLeftSideA);
      bufferZipSideA.add(zipColumnRightSideA);
      bufferZipSideA.add(zipTopSideA);
      bufferZipSideA.add(zipBottomSideA);

      bufferZipSideA.position.x = (this.z - sizeZ) / 2 - bias;
    };

    const createZipC = (sizeZ, bias = 0) => {
      const bufferZipSideC = new Object3D();
      sideC.add(bufferZipSideC);

      const zipSideC = zip.clone();
      zipSideC.scale.x = sizeZ - sizeColumn.x - 0.005 + sizeZipeColumn.z * 2;
      zipSideC.scale.y = -1;
      zipSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + +sizeZipeColumn.z,
        this.y - sizeBeam.y,
        -this.x / 2 - sizeZipeColumn.x / 2
      );

      const zipColumnLeftSideC = zipColumn.clone();
      zipColumnLeftSideC.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideC.scale.x = -1;
      zipColumnLeftSideC.scale.z = -1;
      zipColumnLeftSideC.position.set(
        -sizeZ / 2 + sizeColumn.x / 2 - sizeZipeColumn.z,
        0,
        -this.x / 2
      );
      const zipColumnRightSideC = zipColumn.clone();
      zipColumnRightSideC.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideC.scale.z = -1;
      zipColumnRightSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + 0.001 + sizeZipeColumn.z,
        0,
        -this.x / 2
      );

      const zipTopSideC = zipTop.clone();
      zipTopSideC.scale.x = sizeZ - sizeColumn.x + sizeZipeColumn.z * 2;
      zipTopSideC.scale.z = 1;
      zipTopSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + sizeZipeColumn.z,
        this.y - sizeBeam.y,
        -this.x / 2 - sizeZipeTop.z
      );
      const zipBottomSideC = zipBottom.clone();
      zipBottomSideC.scale.x = -sizeZ + sizeColumn.x;
      zipBottomSideC.scale.z = -1;
      zipBottomSideC.scale.y = -1;
      zipBottomSideC.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        this.y - sizeZipe.y - sizeBeam.y,
        -this.x / 2
      );
      bufferZipSideC.add(zipSideC);
      bufferZipSideC.add(zipColumnLeftSideC);
      bufferZipSideC.add(zipColumnRightSideC);
      bufferZipSideC.add(zipTopSideC);
      bufferZipSideC.add(zipBottomSideC);

      bufferZipSideC.position.x = (this.z - sizeZ) / 2 - bias;
    };

    if (this.x > 6.082 + 0.4 - 0.08) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createZipB(x1 + sizeColumn.x);
      createZipB(x2 + sizeColumn.x, x1 - sizeColumn.x);
      createZipD(x1 + sizeColumn.x);
      createZipD(x2 + sizeColumn.x, x1 - sizeColumn.x);
    } else {
      createZipB(this.x);
      createZipD(this.x);
    }

    if (this.z > 6.082 + 0.2 - 0.08) {
      const z1 = this.z / 2;
      const z2 = this.z - z1;
      createZipA(z1 + sizeColumn.z);
      createZipA(z2 + sizeColumn.z, z1 - sizeColumn.z);
      createZipC(z1 + sizeColumn.z);
      createZipC(z2 + sizeColumn.z, z1 - sizeColumn.z);
    } else {
      createZipA(this.z);
      createZipC(this.z);
    }

    this.zipType1.userData.sideA = sideA;
    this.zipType1.userData.sideB = sideB;
    this.zipType1.userData.sideC = sideC;
    this.zipType1.userData.sideD = sideD;
  }
  createZipType2(
    zip,
    zipColumn,
    zipTop,
    zipBottom,
    sizeColumn,
    sizeBeam,
    sizeZipeColumn,
    sizeZipeTop,
    sizeZipe,
    sizeZipeBottom
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createZipB = (sizeX, bias = 0) => {
      const bufferZipSideB = new Object3D();
      sideB.add(bufferZipSideB);

      const zipSideB = zip.clone();
      zipSideB.rotation.y = -Math.PI / 2;
      zipSideB.scale.x = sizeX - sizeColumn.x * 2 - 0.05;
      zipSideB.scale.y = -1;

      zipSideB.position.set(
        this.z / 2 - sizeZipeColumn.x / 2,
        this.y - sizeZipeTop.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x - 0.025
      );

      const zipColumnLeftSideB = zipColumn.clone();
      zipColumnLeftSideB.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnLeftSideB.rotation.y = -Math.PI / 2;
      zipColumnLeftSideB.position.set(this.z / 2, 0, sizeX / 2 - sizeColumn.x);
      const zipColumnRightSideB = zipColumn.clone();
      zipColumnRightSideB.rotation.y = Math.PI / 2;
      zipColumnRightSideB.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnRightSideB.position.set(
        this.z / 2 - sizeZipeColumn.z,
        0,
        -sizeX / 2 + sizeColumn.x
      );

      const zipTopSideB = zipTop.clone();
      zipTopSideB.scale.x = sizeX - sizeColumn.x * 2;
      zipTopSideB.rotation.y = -Math.PI / 2;
      zipTopSideB.scale.z = -1;
      zipTopSideB.position.set(
        this.z / 2 - sizeZipeTop.z,
        this.y - sizeBeam.y - sizeZipeTop.y,
        sizeX / 2 - sizeColumn.x
      );

      const zipBottomSideB = zipBottom.clone();
      zipBottomSideB.rotation.y = -Math.PI / 2;
      zipBottomSideB.scale.x = sizeX - sizeColumn.x * 2;
      zipBottomSideB.scale.y = -1;
      zipBottomSideB.position.set(
        this.z / 2,
        this.y - sizeZipeTop.y - sizeZipe.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x
      );

      bufferZipSideB.add(zipSideB);
      bufferZipSideB.add(zipColumnLeftSideB);
      bufferZipSideB.add(zipColumnRightSideB);
      bufferZipSideB.add(zipTopSideB);
      bufferZipSideB.add(zipBottomSideB);
      bufferZipSideB.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createZipD = (sizeX, bias = 0) => {
      const bufferZipSideD = new Object3D();
      sideD.add(bufferZipSideD);

      const zipSideD = zip.clone();
      zipSideD.rotation.y = -Math.PI / 2;
      zipSideD.scale.x = sizeX - sizeColumn.x * 2 - 0.05;
      zipSideD.scale.y = -1;
      zipSideD.position.set(
        -this.z / 2 + sizeZipeColumn.x / 2,
        this.y - sizeZipeTop.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x - 0.025
      );
      const zipColumnLeftSideD = zipColumn.clone();
      zipColumnLeftSideD.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnLeftSideD.rotation.y = -Math.PI / 2;
      zipColumnLeftSideD.position.set(
        -this.z / 2 + sizeZipeColumn.z,
        0,
        sizeX / 2 - sizeColumn.x
      );
      const zipColumnRightSideD = zipColumn.clone();
      zipColumnRightSideD.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnRightSideD.rotation.y = Math.PI / 2;
      zipColumnRightSideD.position.set(
        -this.z / 2,
        0,
        -sizeX / 2 + sizeColumn.x
      );
      const zipTopSideD = zipTop.clone();
      zipTopSideD.scale.x = sizeX - sizeColumn.x * 2;
      zipTopSideD.rotation.y = -Math.PI / 2;
      zipTopSideD.position.set(
        -this.z / 2 + sizeZipeTop.z,
        this.y - sizeBeam.y - sizeZipeTop.y,
        sizeX / 2 - sizeColumn.x
      );
      const zipBottomSideD = zipBottom.clone();
      zipBottomSideD.rotation.y = -Math.PI / 2;
      zipBottomSideD.scale.x = sizeX - sizeColumn.x * 2;
      zipBottomSideD.scale.z = -1;
      zipBottomSideD.scale.y = -1;
      zipBottomSideD.position.set(
        -this.z / 2,
        this.y - sizeZipeTop.y - sizeZipe.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x
      );
      bufferZipSideD.add(zipSideD);
      bufferZipSideD.add(zipColumnLeftSideD);
      bufferZipSideD.add(zipColumnRightSideD);
      bufferZipSideD.add(zipTopSideD);
      bufferZipSideD.add(zipBottomSideD);

      bufferZipSideD.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createZipA = (sizeZ, bias = 0) => {
      const bufferZipSideA = new Object3D();
      sideA.add(bufferZipSideA);

      const zipSideA = zip.clone();
      zipSideA.scale.x = sizeZ - sizeColumn.x - 0.005;
      zipSideA.scale.y = -1;
      zipSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2,
        this.y - sizeZipeTop.y - sizeBeam.y,
        this.x / 2 - sizeZipeColumn.x / 2
      );
      const zipColumnLeftSideA = zipColumn.clone();
      zipColumnLeftSideA.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnLeftSideA.scale.x = -1;
      zipColumnLeftSideA.scale.z = -1;
      zipColumnLeftSideA.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        0,
        this.x / 2
      );
      const zipColumnRightSideA = zipColumn.clone();
      zipColumnRightSideA.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnRightSideA.scale.z = -1;
      zipColumnRightSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + 0.001,
        0,
        this.x / 2
      );
      const zipTopSideA = zipTop.clone();
      zipTopSideA.scale.x = sizeZ - sizeColumn.x;
      zipTopSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2,
        this.y - sizeBeam.y - sizeZipeTop.y,
        this.x / 2 - sizeZipeTop.z
      );
      const zipBottomSideA = zipBottom.clone();
      zipBottomSideA.scale.x = -sizeZ + sizeColumn.x;
      zipBottomSideA.scale.y = -1;
      zipBottomSideA.scale.z = -1;
      zipBottomSideA.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        this.y - sizeZipeTop.y - sizeZipe.y - sizeBeam.y,
        this.x / 2
      );
      bufferZipSideA.add(zipSideA);
      bufferZipSideA.add(zipColumnLeftSideA);
      bufferZipSideA.add(zipColumnRightSideA);
      bufferZipSideA.add(zipTopSideA);
      bufferZipSideA.add(zipBottomSideA);

      bufferZipSideA.position.x = (this.z - sizeZ) / 2 - bias;
    };
    const createZipC = (sizeZ, bias = 0) => {
      const bufferZipSideC = new Object3D();
      sideC.add(bufferZipSideC);

      const zipSideC = zip.clone();
      zipSideC.scale.x = sizeZ - sizeColumn.x - 0.005;
      zipSideC.scale.y = -1;
      zipSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2,
        this.y - sizeZipeTop.y - sizeBeam.y,
        -this.x / 2 + sizeZipeColumn.x / 2
      );

      const zipColumnLeftSideC = zipColumn.clone();
      zipColumnLeftSideC.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnLeftSideC.scale.x = -1;
      zipColumnLeftSideC.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        0,
        -this.x / 2
      );
      const zipColumnRightSideC = zipColumn.clone();
      zipColumnRightSideC.scale.y = this.y - sizeBeam.y - sizeZipeTop.y / 2;
      zipColumnRightSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2,
        0,
        -this.x / 2
      );

      const zipTopSideC = zipTop.clone();
      zipTopSideC.scale.x = sizeZ - sizeColumn.x;
      zipTopSideC.scale.z = -1;
      zipTopSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2,
        this.y - sizeBeam.y - sizeZipeTop.y,
        -this.x / 2 + sizeZipeTop.z
      );
      const zipBottomSideC = zipBottom.clone();
      zipBottomSideC.scale.x = -sizeZ + sizeColumn.x;
      zipBottomSideC.scale.y = -1;
      zipBottomSideC.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        this.y - sizeZipeTop.y - sizeZipe.y - sizeBeam.y,
        -this.x / 2
      );
      bufferZipSideC.add(zipSideC);
      bufferZipSideC.add(zipColumnLeftSideC);
      bufferZipSideC.add(zipColumnRightSideC);
      bufferZipSideC.add(zipTopSideC);
      bufferZipSideC.add(zipBottomSideC);

      bufferZipSideC.position.x = (this.z - sizeZ) / 2 - bias;
    };
    if (this.x > 6.082 + 0.4) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createZipB(x1 + sizeColumn.x);
      createZipB(x2 + sizeColumn.x, x1 - sizeColumn.x);
      createZipD(x1 + sizeColumn.x);
      createZipD(x2 + sizeColumn.x, x1 - sizeColumn.x);
    } else {
      createZipB(this.x);
      createZipD(this.x);
    }
    if (this.z > 6.082 + 0.2) {
      const z1 = this.z / 2;
      const z2 = this.z - z1;
      createZipA(z1 + sizeColumn.z);
      createZipA(z2 + sizeColumn.z, z1 - sizeColumn.z);
      createZipC(z1 + sizeColumn.z);
      createZipC(z2 + sizeColumn.z, z1 - sizeColumn.z);
    } else {
      createZipA(this.z);
      createZipC(this.z);
    }
    this.zipType2.userData.sideA = sideA;
    this.zipType2.userData.sideB = sideB;
    this.zipType2.userData.sideC = sideC;
    this.zipType2.userData.sideD = sideD;
  }
  sizeWindowEl(
    windowBorder,
    windowFrame,
    windowGlass,
    windowHandle,
    windowPartition,
    column,
    beam,
    windowFrameT,
    windowFrameB1,
    windowFrameB2,
    windowGlassC,
    windowGlassB
  ) {
    const bbWindowFrameT = new Box3();
    bbWindowFrameT.setFromObject(windowFrameT, true);
    const sizeWindowFrameT = new Vector3();
    bbWindowFrameT.getSize(sizeWindowFrameT);

    const bbWindowFrameB1 = new Box3();
    bbWindowFrameB1.setFromObject(windowFrameB1, true);
    const sizeWindowFrameB1 = new Vector3();
    bbWindowFrameB1.getSize(sizeWindowFrameB1);

    const bbWindowFrameB2 = new Box3();
    bbWindowFrameB2.setFromObject(windowFrameB2, true);
    const sizeWindowFrameB2 = new Vector3();
    bbWindowFrameB2.getSize(sizeWindowFrameB2);

    const bbWindowGlassC = new Box3();
    bbWindowGlassC.setFromObject(windowGlassC, true);
    const sizeWindowGlassC = new Vector3();
    bbWindowGlassC.getSize(sizeWindowGlassC);

    const bbWindowGlassB = new Box3();
    bbWindowGlassB.setFromObject(windowGlassB, true);
    const sizeWindowGlassB = new Vector3();
    bbWindowGlassB.getSize(sizeWindowGlassB);

    const bbColumn = new Box3();
    bbColumn.setFromObject(column, true);
    const sizeColumnLouver = new Vector3();
    bbColumn.getSize(sizeColumnLouver);

    const bbBeam = new Box3();
    bbBeam.setFromObject(beam, true);
    const sizeBeamLouver = new Vector3();
    bbBeam.getSize(sizeBeamLouver);

    const bbWindowBorder = new Box3();
    bbWindowBorder.setFromObject(windowBorder, true);
    const sizeWindowBorder = new Vector3();
    bbWindowBorder.getSize(sizeWindowBorder);

    const bbWindowFrame = new Box3();
    bbWindowFrame.setFromObject(windowFrame, true);
    const sizeWindowFrame = new Vector3();
    bbWindowFrame.getSize(sizeWindowFrame);

    const bbWindowGlass = new Box3();
    bbWindowGlass.setFromObject(windowGlass, true);
    const sizeWindowGlass = new Vector3();
    bbWindowGlass.getSize(sizeWindowGlass);

    const bbWindowPartition = new Box3();
    bbWindowPartition.setFromObject(windowPartition, true);
    const sizeWindowPartition = new Vector3();
    bbWindowPartition.getSize(sizeWindowPartition);

    windowGlass.children[0].material.color.set(0x122d78);
    windowGlass.children[0].material.opacity = 0.15;
    windowGlass.children[0].material.transparent = true;
    windowGlass.children[0].material.metalness = 0.75;
    windowGlass.children[0].material.depthWrite = true;
    windowGlass.children[0].material.roughness = 0.39;
    windowGlass.children[0].renderOrder = 2;

    this.createWindowType1(
      windowBorder,
      windowFrame,
      windowGlass,
      windowPartition,
      sizeColumnLouver,
      sizeBeamLouver,
      sizeWindowBorder,
      sizeWindowFrame,
      sizeWindowPartition
    );

    this.createWindowType2(
      windowFrameT,
      windowFrameB1,
      windowFrameB2,
      windowGlassC,
      windowGlassB,
      windowGlass,
      windowHandle,
      sizeColumnLouver,
      sizeBeamLouver,
      sizeWindowGlass,
      sizeWindowFrameT,
      sizeWindowFrameB1,
      sizeWindowFrameB2,
      sizeWindowGlassC,
      sizeWindowGlassB
    );
  }
  createWindowType1(
    border,
    frame,
    glass,
    partition,
    sizeColumn,
    sizeBeam,
    sizeBorder,
    sizeFrame,
    sizePartition
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createWindowB = (sizeX, bias = 0) => {
      const bufferWindowSideB = new Object3D();
      sideB.add(bufferWindowSideB);

      const windowGlassSideB = glass.clone();
      windowGlassSideB.rotation.y = -Math.PI / 2;
      windowGlassSideB.scale.x = -sizeX + sizeColumn.x * 2;
      windowGlassSideB.scale.y = this.y - sizeBeam.y;
      windowGlassSideB.scale.z = -1;
      windowGlassSideB.position.set(
        this.z / 2 - sizeFrame.z / 6,
        0,
        sizeX / 2 - sizeColumn.x
      );
      const startFrameColumnSideB = frame.clone();
      startFrameColumnSideB.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideB.rotation.y = Math.PI / 2;
      startFrameColumnSideB.scale.x = -1;
      startFrameColumnSideB.position.set(
        this.z / 2,
        0,
        sizeX / 2 - sizeColumn.x - sizeFrame.x
      );

      bufferWindowSideB.add(startFrameColumnSideB);

      const intervalB = 1 / this.colB;
      for (let i = 1; i < this.colB; i++) {
        const elFrameColum = partition.clone();
        elFrameColum.scale.y = this.y - sizeBeam.y;
        elFrameColum.scale.x = -1;
        elFrameColum.rotation.y = Math.PI / 2;

        const t = i * intervalB;
        const x = this.z / 2 + t * (this.z / 2 - this.z / 2);
        const z =
          sizeX / 2 +
          t * (-sizeX / 2 + sizeColumn.x - sizeX / 2 + sizeColumn.x) -
          sizeColumn.x;
        elFrameColum.position.x = x;
        elFrameColum.position.z = z;
        bufferWindowSideB.add(elFrameColum);
      }
      const endFrameColumnSideB = frame.clone();
      endFrameColumnSideB.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideB.rotation.y = -Math.PI / 2;
      endFrameColumnSideB.scale.x = -1;
      endFrameColumnSideB.scale.z = -1;
      endFrameColumnSideB.position.set(
        this.z / 2,
        0,
        -sizeX / 2 + sizeColumn.x + sizeFrame.x
      );
      bufferWindowSideB.add(endFrameColumnSideB);

      const frameTopSideB = frame.clone();
      frameTopSideB.rotation.x = -Math.PI / 2;
      frameTopSideB.rotation.y = Math.PI / 2;
      frameTopSideB.scale.y = sizeX - sizeColumn.x * 2;
      frameTopSideB.scale.x = -1;
      frameTopSideB.position.set(
        this.z / 2,
        this.y - sizeBeam.y - sizeFrame.z,
        sizeX / 2 - sizeColumn.x
      );

      const frameBottomSideB = frame.clone();

      frameBottomSideB.rotation.x = -Math.PI / 2;
      frameBottomSideB.rotation.y = -Math.PI / 2;
      frameBottomSideB.scale.y = sizeX - sizeColumn.x * 2;
      frameBottomSideB.scale.x = -1;
      frameBottomSideB.scale.z = -1;
      frameBottomSideB.position.set(
        this.z / 2,
        sizeFrame.z,
        sizeX / 2 - sizeColumn.x
      );

      const borderSideB = border.clone();
      borderSideB.rotation.y = -Math.PI / 2;
      borderSideB.scale.x = -sizeX + sizeColumn.x * 2;
      borderSideB.scale.z = -1;
      borderSideB.position.set(this.z / 2, 0, sizeX / 2 - sizeColumn.x);

      bufferWindowSideB.add(windowGlassSideB);
      bufferWindowSideB.add(frameBottomSideB);
      bufferWindowSideB.add(frameTopSideB);
      // bufferWindowSideB.add(borderSideB);

      bufferWindowSideB.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createWindowD = (sizeX, bias = 0) => {
      const bufferWindowSideD = new Object3D();
      sideD.add(bufferWindowSideD);

      const windowGlassSideD = glass.clone();
      windowGlassSideD.rotation.y = -Math.PI / 2;
      windowGlassSideD.scale.x = -sizeX + sizeColumn.x * 2;
      windowGlassSideD.scale.y = this.y - sizeBeam.y;
      windowGlassSideD.scale.z = -1;
      windowGlassSideD.position.set(
        -this.z / 2 + sizeFrame.z / 6,
        0,
        sizeX / 2 - sizeColumn.x
      );

      const startFrameColumnSideD = frame.clone();
      startFrameColumnSideD.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideD.rotation.y = -Math.PI / 2;
      startFrameColumnSideD.position.set(
        -this.z / 2,
        0,
        sizeX / 2 - sizeColumn.x - sizeFrame.x
      );
      bufferWindowSideD.add(startFrameColumnSideD);

      const intervalD = 1 / this.colD;
      for (let i = 1; i < this.colD; i++) {
        const elFrameColum = partition.clone();
        elFrameColum.scale.y = this.y - sizeBeam.y;
        elFrameColum.rotation.y = -Math.PI / 2;
        const t = i * intervalD;
        const x = -this.z / 2 + t * (this.z / 2 - this.z / 2);
        const z =
          sizeX / 2 +
          t * (-sizeX / 2 + sizeColumn.x - sizeX / 2 + sizeColumn.x) -
          sizeColumn.x;
        elFrameColum.position.x = x;
        elFrameColum.position.z = z;
        bufferWindowSideD.add(elFrameColum);
      }
      const endFrameColumnSideD = frame.clone();
      endFrameColumnSideD.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideD.rotation.y = Math.PI / 2;
      endFrameColumnSideD.scale.z = -1;

      endFrameColumnSideD.position.set(
        -this.z / 2,
        0,
        -sizeX / 2 + sizeColumn.x + sizeFrame.x
      );
      bufferWindowSideD.add(endFrameColumnSideD);

      const frameTopSideD = frame.clone();
      frameTopSideD.rotation.x = -Math.PI / 2;
      frameTopSideD.rotation.y = -Math.PI / 2;
      frameTopSideD.scale.y = sizeX - sizeColumn.x * 2;
      frameTopSideD.position.set(
        -this.z / 2,
        this.y - sizeBeam.y - sizeFrame.z,
        sizeX / 2 - sizeColumn.x
      );

      const frameBottomSideD = frame.clone();
      frameBottomSideD.rotation.x = -Math.PI / 2;
      frameBottomSideD.rotation.y = Math.PI / 2;
      frameBottomSideD.scale.y = sizeX - sizeColumn.x * 2;
      frameBottomSideD.scale.z = -1;
      frameBottomSideD.position.set(
        -this.z / 2,
        sizeFrame.z,
        sizeX / 2 - sizeColumn.x
      );

      const borderSideD = border.clone();
      borderSideD.rotation.y = -Math.PI / 2;
      borderSideD.scale.x = -sizeX + sizeColumn.x * 2;
      borderSideD.position.set(-this.z / 2, 0, sizeX / 2 - sizeColumn.x);

      bufferWindowSideD.add(windowGlassSideD);
      bufferWindowSideD.add(frameBottomSideD);
      bufferWindowSideD.add(frameTopSideD);
      // bufferWindowSideD.add(borderSideD);

      bufferWindowSideD.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createWindowA = (sizeZ, bias = 0) => {
      const bufferWindowSideA = new Object3D();
      sideA.add(bufferWindowSideA);

      const windowGlassSideA = glass.clone();
      windowGlassSideA.scale.x = -sizeZ + sizeColumn.z * 2;
      windowGlassSideA.scale.y = this.y - sizeBeam.y;
      windowGlassSideA.scale.z = -1;

      windowGlassSideA.position.set(
        sizeZ / 2 - sizeColumn.z,
        0,
        this.x / 2 - sizeFrame.z / 4
      );
      const startFrameColumnSideA = frame.clone();
      startFrameColumnSideA.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideA.position.set(
        sizeZ / 2 - sizeColumn.z - sizeFrame.x,
        0,
        this.x / 2
      );
      bufferWindowSideA.add(startFrameColumnSideA);

      const endFrameColumnSideA = frame.clone();
      endFrameColumnSideA.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideA.scale.x = -1;
      endFrameColumnSideA.position.set(
        -sizeZ / 2 + sizeColumn.z + sizeFrame.x,
        0,
        this.x / 2
      );
      bufferWindowSideA.add(endFrameColumnSideA);

      const intervalA = 1 / this.colA;
      for (let i = 1; i < this.colA; i++) {
        const elFrameColum = partition.clone();
        elFrameColum.scale.y = this.y - sizeBeam.y;
        elFrameColum.scale.x = -1;
        const t = i * intervalA;

        const x =
          sizeZ / 2 -
          sizeColumn.x * 2 +
          t * (-sizeZ / 2 + sizeColumn.z - sizeZ / 2 + sizeColumn.z) +
          sizeColumn.z;

        const z = this.x / 2 + t * (this.x / 2 - this.x / 2);
        elFrameColum.position.x = x;
        elFrameColum.position.z = z;
        bufferWindowSideA.add(elFrameColum);
      }

      const frameTopSideA = frame.clone();
      frameTopSideA.rotation.z = -Math.PI / 2;
      frameTopSideA.scale.y = -sizeZ + sizeColumn.z * 2;
      frameTopSideA.scale.x = -1;
      frameTopSideA.position.set(
        sizeZ / 2 - sizeColumn.z,
        this.y - sizeBeam.y - sizeFrame.z,
        this.x / 2
      );

      const frameBottomSideA = frame.clone();

      frameBottomSideA.rotation.z = -Math.PI / 2;
      frameBottomSideA.scale.y = -sizeZ + sizeColumn.z * 2;
      frameBottomSideA.position.set(
        sizeZ / 2 - sizeColumn.z,
        sizeFrame.z,
        this.x / 2
      );
      const borderSideA = border.clone();
      borderSideA.scale.x = -sizeZ + sizeColumn.z * 2;
      borderSideA.position.set(sizeZ / 2 - sizeColumn.z, 0, this.x / 2);

      bufferWindowSideA.add(windowGlassSideA);
      bufferWindowSideA.add(frameBottomSideA);
      bufferWindowSideA.add(frameTopSideA);
      // bufferWindowSideA.add(borderSideA);

      bufferWindowSideA.position.x = (this.z - sizeZ) / 2 - bias;
    };
    const createWindowC = (sizeZ, bias = 0) => {
      const bufferWindowSideC = new Object3D();
      sideC.add(bufferWindowSideC);

      const windowGlassSideC = glass.clone();
      windowGlassSideC.scale.x = -sizeZ + sizeColumn.z * 2;
      windowGlassSideC.scale.y = this.y - sizeBeam.y;
      windowGlassSideC.scale.z = -1;
      windowGlassSideC.position.set(
        sizeZ / 2 - sizeColumn.z,
        0,
        -this.x / 2 + sizeFrame.z / 4
      );

      const startFrameColumnSideC = frame.clone();
      startFrameColumnSideC.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideC.rotation.y = Math.PI;
      startFrameColumnSideC.scale.x = -1;
      startFrameColumnSideC.position.set(
        sizeZ / 2 - sizeColumn.z - sizeFrame.x,
        0,
        -this.x / 2
      );
      bufferWindowSideC.add(startFrameColumnSideC);

      const intervalC = 1 / this.colC;
      for (let i = 1; i < this.colC; i++) {
        const elFrameColum = partition.clone();
        elFrameColum.scale.y = this.y - sizeBeam.y;
        elFrameColum.scale.z = -1;
        const t = i * intervalC;
        const x =
          sizeZ / 2 -
          sizeColumn.x * 2 +
          t * (-sizeZ / 2 + sizeColumn.z - sizeZ / 2 + sizeColumn.z) +
          sizeColumn.z;
        const z = this.x / 2 + t * (this.x / 2 - this.x / 2);

        elFrameColum.position.x = x;
        elFrameColum.position.z = -z;
        bufferWindowSideC.add(elFrameColum);
      }

      const endFrameColumnSideC = frame.clone();
      endFrameColumnSideC.rotation.y = -Math.PI;
      endFrameColumnSideC.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideC.position.set(
        -sizeZ / 2 + sizeColumn.z + sizeFrame.x,
        0,
        -this.x / 2
      );
      bufferWindowSideC.add(endFrameColumnSideC);

      const frameTopSideC = frame.clone();

      frameTopSideC.rotation.z = -Math.PI / 2;
      frameTopSideC.scale.z = -1;
      frameTopSideC.scale.x = -1;

      frameTopSideC.scale.y = -sizeZ + sizeColumn.z * 2;
      frameTopSideC.position.set(
        sizeZ / 2 - sizeColumn.z,
        this.y - sizeBeam.y - sizeFrame.z,
        -this.x / 2
      );
      const frameBottomSideC = frame.clone();
      frameBottomSideC.rotation.z = -Math.PI / 2;
      frameBottomSideC.scale.y = -sizeZ + sizeColumn.z * 2;

      frameBottomSideC.scale.z = -1;
      frameBottomSideC.position.set(
        sizeZ / 2 - sizeColumn.z,
        sizeFrame.z,
        -this.x / 2
      );
      const borderSideC = border.clone();
      borderSideC.scale.x = -sizeZ + sizeColumn.z * 2;
      borderSideC.scale.z = -1;
      borderSideC.position.set(sizeZ / 2 - sizeColumn.z, 0, -this.x / 2);

      bufferWindowSideC.add(windowGlassSideC);
      bufferWindowSideC.add(frameBottomSideC);
      bufferWindowSideC.add(frameTopSideC);
      // bufferWindowSideC.add(borderSideC);

      bufferWindowSideC.position.x = (this.z - sizeZ) / 2 - bias;
    };

    if (this.x > 7 + 0.26) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createWindowB(x1 + sizeColumn.x);
      createWindowB(x2 + sizeColumn.x, x1 - sizeColumn.x);
      createWindowD(x1 + sizeColumn.x);
      createWindowD(x2 + sizeColumn.x, x1 - sizeColumn.x);
    } else {
      createWindowB(this.x);
      createWindowD(this.x);
    }

    if (this.z > 7 + 0.26) {
      const z1 = this.z / 2;
      const z2 = this.z - z1;
      createWindowA(z1 + sizeColumn.z);
      createWindowA(z2 + sizeColumn.z, z1 - sizeColumn.z);
      createWindowC(z1 + sizeColumn.z);
      createWindowC(z2 + sizeColumn.z, z1 - sizeColumn.z);
    } else {
      createWindowA(this.z);
      createWindowC(this.z);
    }

    this.windowType1.userData.sideA = sideA;
    this.windowType1.userData.sideB = sideB;
    this.windowType1.userData.sideC = sideC;
    this.windowType1.userData.sideD = sideD;
  }
  createWindowType2(
    frameT,
    frameB1,
    frameB2,
    glassC,
    glassB,
    glass,
    handle,
    sizeColumn,
    sizeBeam,
    sizeGlass,
    sizeFrameT,
    sizeFrameB1,
    sizeFrameB2,
    sizeGlassC,
    sizeGlassB
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createWindowB = (sizeX, bias = 0) => {
      const bufferWindowSideB = new Object3D();
      sideB.add(bufferWindowSideB);

      const leftHandleSideB = handle.clone();

      const windowGlassFrameBSideB = glassB.clone();
      bufferWindowSideB.add(windowGlassFrameBSideB);
      const windowGlassB = glass.clone();
      windowGlassB.rotation.y = Math.PI / 2;
      windowGlassB.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
      windowGlassB.scale.y = this.y - sizeBeam.y;
      windowGlassFrameBSideB.add(windowGlassB);
      windowGlassFrameBSideB.scale.z = (sizeX - sizeColumn.x * 2) / this.colB;
      windowGlassFrameBSideB.scale.x = -1.1;
      windowGlassFrameBSideB.position.set(
        this.z / 2 - sizeFrameB1.x,
        sizeFrameB2.y - 0.005,
        sizeX / 2 - sizeColumn.x
      );
      for (let i = 1; i < this.colB; i++) {
        const elFrameB = windowGlassFrameBSideB.clone();
        bufferWindowSideB.add(elFrameB);
        const x = i * sizeGlassB.x * 1.21;
        elFrameB.position.x += -x;
        const z = (i * (sizeX - sizeColumn.x * 2)) / this.colB;
        elFrameB.position.z += -z;

        if (i === this.colB - 1) {
          const posLeftHandleSideB = elFrameB.getWorldPosition(new Vector3());
          leftHandleSideB.position.set(
            posLeftHandleSideB.x + 0.025,
            posLeftHandleSideB.y + this.y / 2,
            posLeftHandleSideB.z +
              sizeColumn.x -
              (sizeX - sizeColumn.x * 2) / this.colB
          );
        }
      }

      const startFrameColumnSideB = glassC.clone();
      startFrameColumnSideB.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideB.position.set(
        this.z / 2 - sizeGlassC.x,
        0,
        sizeX / 2 - sizeColumn.x
      );
      bufferWindowSideB.add(startFrameColumnSideB);
      const endFrameColumnSideB = glassC.clone();
      endFrameColumnSideB.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideB.position.set(
        this.z / 2 - sizeGlassC.x * (this.colB * 0.9),
        0,
        -sizeX / 2 + sizeColumn.x
      );
      endFrameColumnSideB.scale.z = -1;
      bufferWindowSideB.add(endFrameColumnSideB);

      const rightHandleSideB = handle.clone();
      rightHandleSideB.scale.set(0.1, 0.1, 0.3);
      rightHandleSideB.rotation.y = -Math.PI / 2;
      const posRightHandleSideB = windowGlassB.getWorldPosition(new Vector3());
      rightHandleSideB.position.set(
        posRightHandleSideB.x + 0.04,
        posRightHandleSideB.y + this.y / 2,
        posRightHandleSideB.z - sizeColumn.x
      );

      leftHandleSideB.scale.set(0.1, 0.1, 0.3);
      leftHandleSideB.rotation.y = -Math.PI / 2;

      const frameTopSideB = frameT.clone();
      frameTopSideB.scale.z = sizeX - sizeColumn.x * 2;
      frameTopSideB.scale.x = -1;
      frameTopSideB.position.set(
        this.z / 2 - sizeFrameB1.x,
        this.y - sizeBeam.y - sizeFrameT.y,
        sizeX / 2 - sizeColumn.x
      );
      for (let i = 0; i < this.colB; i++) {
        const elFrameTop = frameTopSideB.clone();
        bufferWindowSideB.add(elFrameTop);
        const x = i * sizeFrameT.x;
        elFrameTop.position.x += -x * 0.85;
      }

      const frameBottomSideB = frameB1.clone();
      frameBottomSideB.scale.z = sizeX - sizeColumn.x * 2;
      frameBottomSideB.scale.x = -1;
      frameBottomSideB.position.set(this.z / 2, 0, sizeX / 2 - sizeColumn.x);
      for (let i = 0; i < this.colB; i++) {
        const elFrameBotoom = frameB2.clone();
        elFrameBotoom.scale.z = sizeX - sizeColumn.x * 2;
        bufferWindowSideB.add(elFrameBotoom);
        elFrameBotoom.position.set(
          this.z / 2 - sizeFrameB2.x - sizeFrameB1.x,
          0,
          sizeX / 2 - sizeColumn.x
        );
        const x = i * sizeFrameB2.x;
        elFrameBotoom.position.x += -x;
      }
      bufferWindowSideB.add(frameBottomSideB);
      bufferWindowSideB.add(rightHandleSideB);
      bufferWindowSideB.add(leftHandleSideB);

      bufferWindowSideB.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createWindowD = (sizeX, bias = 0) => {
      const bufferWindowSideD = new Object3D();
      sideD.add(bufferWindowSideD);

      const leftHandleSideD = handle.clone();

      const windowGlassFrameBSideD = glassB.clone();
      bufferWindowSideD.add(windowGlassFrameBSideD);

      const windowGlassD = glass.clone();
      windowGlassD.rotation.y = Math.PI / 2;
      windowGlassD.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
      windowGlassD.scale.y = this.y - sizeBeam.y;
      windowGlassFrameBSideD.add(windowGlassD);

      windowGlassFrameBSideD.scale.z = (sizeX - sizeColumn.x * 2) / this.colD;
      windowGlassFrameBSideD.scale.x = 1.1;
      windowGlassFrameBSideD.position.set(
        -this.z / 2 + sizeFrameB1.x,
        sizeFrameB2.y - 0.005,
        sizeX / 2 - sizeColumn.x
      );
      for (let i = 1; i < this.colD; i++) {
        const elFrameB = windowGlassFrameBSideD.clone();
        bufferWindowSideD.add(elFrameB);
        const x = i * sizeGlassB.x * 1.21;
        elFrameB.position.x += x;
        const z = (i * (sizeX - sizeColumn.x * 2)) / this.colD;
        elFrameB.position.z += -z;

        if (i === this.colD - 1) {
          const posLeftHandleSideD = elFrameB.getWorldPosition(new Vector3());
          leftHandleSideD.position.set(
            posLeftHandleSideD.x - 0.025,
            posLeftHandleSideD.y + this.y / 2,
            posLeftHandleSideD.z +
              sizeColumn.x -
              (sizeX - sizeColumn.x * 2) / this.colD
          );
        }
      }

      const startFrameColumnSideD = glassC.clone();
      startFrameColumnSideD.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideD.position.set(
        -this.z / 2 + sizeGlassC.x - sizeGlassC.x,
        0,
        sizeX / 2 - sizeColumn.x
      );
      bufferWindowSideD.add(startFrameColumnSideD);
      const endFrameColumnSideD = glassC.clone();
      endFrameColumnSideD.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideD.scale.z = -1;
      endFrameColumnSideD.position.set(
        -this.z / 2 + sizeGlassC.x * 0.9 * this.colD - sizeGlassC.x,
        0,
        -sizeX / 2 + sizeColumn.x
      );
      bufferWindowSideD.add(endFrameColumnSideD);

      const rightHandleSideD = handle.clone();
      rightHandleSideD.scale.set(0.1, 0.1, 0.3);
      rightHandleSideD.rotation.y = -Math.PI / 2;

      const posRightHandleSideD = windowGlassD.getWorldPosition(new Vector3());
      rightHandleSideD.position.set(
        posRightHandleSideD.x + 0.04,
        posRightHandleSideD.y + this.y / 2,
        posRightHandleSideD.z - sizeColumn.x
      );

      leftHandleSideD.scale.set(0.1, 0.1, -0.3);
      leftHandleSideD.rotation.y = -Math.PI / 2;

      const frameTopSideD = frameT.clone();
      frameTopSideD.scale.z = sizeX - sizeColumn.x * 2;
      frameTopSideD.position.set(
        -this.z / 2,
        this.y - sizeBeam.y - sizeFrameT.y,
        sizeX / 2 - sizeColumn.x
      );
      for (let i = 0; i < this.colD; i++) {
        const elFrameTop = frameTopSideD.clone();
        bufferWindowSideD.add(elFrameTop);
        const x = i * sizeFrameT.x;
        elFrameTop.position.x += x * 0.85;
      }

      const frameBottomSideD = frameB1.clone();
      frameBottomSideD.scale.z = sizeX - sizeColumn.x * 2;
      frameBottomSideD.position.set(-this.z / 2, 0, sizeX / 2 - sizeColumn.x);
      for (let i = 0; i < this.colD; i++) {
        const elFrameBotoom = frameB2.clone();
        elFrameBotoom.scale.z = sizeX - sizeColumn.x * 2;
        bufferWindowSideD.add(elFrameBotoom);
        elFrameBotoom.position.set(
          -this.z / 2 + sizeFrameB1.x,
          0,
          sizeX / 2 - sizeColumn.x
        );
        const x = i * sizeFrameB2.x;
        elFrameBotoom.position.x += x;
      }

      bufferWindowSideD.add(frameBottomSideD);
      bufferWindowSideD.add(leftHandleSideD);
      bufferWindowSideD.add(rightHandleSideD);

      bufferWindowSideD.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createWindowA = (sizeZ, bias = 0) => {
      const bufferWindowSideA = new Object3D();
      sideA.add(bufferWindowSideA);

      const rightHandleSideA = handle.clone();
      const windowGlassFrameBSideA = glassB.clone();
      windowGlassFrameBSideA.rotation.y = -Math.PI / 2;
      bufferWindowSideA.add(windowGlassFrameBSideA);

      const windowGlassA = glass.clone();
      windowGlassA.rotation.y = Math.PI / 2;
      windowGlassA.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
      windowGlassA.scale.y = this.y - sizeBeam.y;
      windowGlassFrameBSideA.add(windowGlassA);

      windowGlassFrameBSideA.scale.z = (-sizeZ + sizeColumn.z * 2) / this.colA;
      windowGlassFrameBSideA.scale.x = 1.1;
      windowGlassFrameBSideA.position.set(
        sizeZ / 2 - sizeColumn.z,
        sizeFrameB2.y - 0.005,
        this.x / 2 - sizeFrameB1.x - sizeGlassB.x * 1.21
      );
      for (let i = 1; i < this.colA; i++) {
        const elFrameB = windowGlassFrameBSideA.clone();
        bufferWindowSideA.add(elFrameB);
        const x = i * sizeGlassB.x * 1.21;
        const z = (i * (sizeZ - sizeColumn.z * 2)) / this.colA;
        elFrameB.position.x += -z;
        elFrameB.position.z += -x;

        if (i === this.colA - 1) {
          const posRightHandleSideA = elFrameB.getWorldPosition(new Vector3());
          rightHandleSideA.position.set(
            posRightHandleSideA.x +
              sizeColumn.x -
              (sizeZ - sizeColumn.z * 2) / this.colA,
            posRightHandleSideA.y + this.y / 2,
            posRightHandleSideA.z + 0.04
          );
        }
      }

      const startFrameColumnSideA = glassC.clone();
      startFrameColumnSideA.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideA.rotation.y = -Math.PI / 2;
      startFrameColumnSideA.scale.x = -1;
      startFrameColumnSideA.scale.z = -1;
      startFrameColumnSideA.position.set(
        sizeZ / 2 - sizeColumn.z,
        0,
        this.x / 2
      );
      bufferWindowSideA.add(startFrameColumnSideA);
      const endFrameColumnSideA = glassC.clone();
      endFrameColumnSideA.rotation.y = -Math.PI / 2;
      endFrameColumnSideA.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideA.scale.x = -1;

      endFrameColumnSideA.position.set(
        -sizeZ / 2 + sizeColumn.z,
        0,
        this.x / 2 - sizeGlassC.x * 0.9 * this.colA + sizeGlassC.x
      );
      bufferWindowSideA.add(endFrameColumnSideA);

      rightHandleSideA.scale.set(0.1, 0.1, -0.3);

      const leftHandleSideA = handle.clone();
      leftHandleSideA.scale.set(0.1, 0.1, -0.3);
      const posLeftHandleSideA = windowGlassA.getWorldPosition(new Vector3());
      leftHandleSideA.position.set(
        posLeftHandleSideA.x - sizeColumn.x,
        posLeftHandleSideA.y + this.y / 2,
        posLeftHandleSideA.z + 0.025
      );

      const frameTopSideA = frameT.clone();
      frameTopSideA.scale.z = -sizeZ + sizeColumn.z * 2;
      frameTopSideA.rotation.y = -Math.PI / 2;
      frameTopSideA.scale.x = -1;
      frameTopSideA.position.set(
        sizeZ / 2 - sizeColumn.z,
        this.y - sizeBeam.y - sizeFrameT.y,
        this.x / 2
      );
      for (let i = 0; i < this.colA; i++) {
        const elFrameTop = frameTopSideA.clone();
        bufferWindowSideA.add(elFrameTop);
        const x = i * sizeFrameT.x;
        elFrameTop.position.z -= x * 0.85;
      }
      const frameBottomSideA = frameB1.clone();
      frameBottomSideA.rotation.y = -Math.PI / 2;
      frameBottomSideA.scale.z = -sizeZ + sizeColumn.z * 2;
      frameBottomSideA.scale.x = -1;
      frameBottomSideA.position.set(sizeZ / 2 - sizeColumn.z, 0, this.x / 2);
      for (let i = 0; i < this.colA; i++) {
        const elFrameBotoom = frameB2.clone();
        elFrameBotoom.scale.z = -sizeZ + sizeColumn.z * 2;
        elFrameBotoom.rotation.y = -Math.PI / 2;
        elFrameBotoom.scale.x = -1;
        bufferWindowSideA.add(elFrameBotoom);
        elFrameBotoom.position.set(sizeZ / 2 - sizeColumn.z, 0, this.x / 2);
        const x = i * sizeFrameB2.x;
        elFrameBotoom.position.z += -x;
      }

      bufferWindowSideA.add(frameBottomSideA);
      bufferWindowSideA.add(leftHandleSideA);
      bufferWindowSideA.add(rightHandleSideA);

      bufferWindowSideA.position.x = (this.z - sizeZ) / 2 - bias;
    };
    const createWindowC = (sizeZ, bias = 0) => {
      const bufferWindowSideC = new Object3D();
      sideC.add(bufferWindowSideC);

      const rightHandleSideC = handle.clone();
      const windowGlassFrameBSideC = glassB.clone();
      windowGlassFrameBSideC.rotation.y = -Math.PI / 2;
      bufferWindowSideC.add(windowGlassFrameBSideC);

      const windowGlassC = glass.clone();
      windowGlassC.rotation.y = Math.PI / 2;
      windowGlassC.position.x = sizeGlassB.x / 2 + sizeGlass.z / 2;
      windowGlassC.scale.y = this.y - sizeBeam.y;
      windowGlassFrameBSideC.add(windowGlassC);

      windowGlassFrameBSideC.scale.z = (-sizeZ + sizeColumn.z * 2) / this.colC;
      windowGlassFrameBSideC.scale.x = -1.1;
      windowGlassFrameBSideC.position.set(
        sizeZ / 2 - sizeColumn.z,
        sizeFrameB2.y - 0.005,
        -this.x / 2 + sizeFrameB1.x + sizeGlassB.x * 1.21
      );
      for (let i = 1; i < this.colC; i++) {
        const elFrameB = windowGlassFrameBSideC.clone();
        bufferWindowSideC.add(elFrameB);
        const x = i * sizeGlassB.x * 1.21;
        const z = (i * (sizeZ - sizeColumn.z * 2)) / this.colC;
        elFrameB.position.x += -z;
        elFrameB.position.z += x;

        if (i === this.colC - 1) {
          const posRightHandleSideC = elFrameB.getWorldPosition(new Vector3());
          rightHandleSideC.position.set(
            posRightHandleSideC.x +
              sizeColumn.x -
              (sizeZ - sizeColumn.z * 2) / this.colC,
            posRightHandleSideC.y + this.y / 2,
            posRightHandleSideC.z - 0.04
          );
        }
      }

      const startFrameColumnSideC = glassC.clone();
      startFrameColumnSideC.scale.y = this.y - sizeBeam.y;
      startFrameColumnSideC.rotation.y = -Math.PI / 2;
      startFrameColumnSideC.scale.z = -1;
      startFrameColumnSideC.position.set(
        sizeZ / 2 - sizeColumn.z,
        0,
        -this.x / 2
      );
      bufferWindowSideC.add(startFrameColumnSideC);
      const endFrameColumnSideC = glassC.clone();
      endFrameColumnSideC.rotation.y = -Math.PI / 2;
      endFrameColumnSideC.scale.y = this.y - sizeBeam.y;
      endFrameColumnSideC.position.set(
        -sizeZ / 2 + sizeColumn.z,
        0,
        -this.x / 2 + sizeGlassC.x * 0.9 * this.colA - sizeGlassC.x
      );
      bufferWindowSideC.add(endFrameColumnSideC);

      rightHandleSideC.scale.set(0.1, 0.1, 0.3);

      const leftHandleSideC = handle.clone();
      leftHandleSideC.scale.set(0.1, 0.1, 0.3);
      const posLeftHandleSideC = windowGlassC.getWorldPosition(new Vector3());
      leftHandleSideC.position.set(
        posLeftHandleSideC.x - sizeColumn.x,
        posLeftHandleSideC.y + this.y / 2,
        posLeftHandleSideC.z - 0.025
      );

      const frameTopSideC = frameT.clone();
      frameTopSideC.rotation.y = -Math.PI / 2;
      frameTopSideC.scale.z = -sizeZ + sizeColumn.z * 2;
      frameTopSideC.position.set(
        sizeZ / 2 - sizeColumn.z,
        this.y - sizeBeam.y - sizeFrameT.y,
        -this.x / 2
      );
      for (let i = 0; i < this.colC; i++) {
        const elFrameTop = frameTopSideC.clone();
        bufferWindowSideC.add(elFrameTop);
        const x = i * sizeFrameT.x;
        elFrameTop.position.z += x * 0.85;
      }
      const frameBottomSideC = frameB1.clone();
      frameBottomSideC.rotation.y = Math.PI / 2;
      frameBottomSideC.scale.x = -1;
      frameBottomSideC.scale.z = sizeZ - sizeColumn.z * 2;
      frameBottomSideC.position.set(sizeZ / 2 - sizeColumn.z, 0, -this.x / 2);
      for (let i = 0; i < this.colC; i++) {
        const elFrameBotoom = frameB2.clone();
        elFrameBotoom.scale.z = sizeZ - sizeColumn.z * 2;
        elFrameBotoom.rotation.y = Math.PI / 2;
        elFrameBotoom.scale.x = -1;
        bufferWindowSideC.add(elFrameBotoom);
        elFrameBotoom.position.set(sizeZ / 2 - sizeColumn.z, 0, -this.x / 2);
        const x = i * sizeFrameB2.x;
        elFrameBotoom.position.z += x;
      }

      bufferWindowSideC.add(frameBottomSideC);
      bufferWindowSideC.add(rightHandleSideC);
      bufferWindowSideC.add(leftHandleSideC);

      bufferWindowSideC.position.x = (this.z - sizeZ) / 2 - bias;
    };

    if (this.x > 6.98 + 0.26) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createWindowB(x1 + sizeColumn.x);
      createWindowB(x2 + sizeColumn.x, x1 - sizeColumn.x);
      createWindowD(x1 + sizeColumn.x);
      createWindowD(x2 + sizeColumn.x, x1 - sizeColumn.x);
    } else {
      createWindowB(this.x);
      createWindowD(this.x);
    }

    if (this.z > 6.98 + 0.26) {
      const z1 = this.z / 2;
      const z2 = this.z - z1;
      createWindowA(z1 + sizeColumn.z);
      createWindowA(z2 + sizeColumn.z, z1 - sizeColumn.z);
      createWindowC(z1 + sizeColumn.z);
      createWindowC(z2 + sizeColumn.z, z1 - sizeColumn.z);
    } else {
      createWindowA(this.z);
      createWindowC(this.z);
    }

    ///////////////////////

    //////////////////

    ///////////

    this.windowType2.userData.sideA = sideA;
    this.windowType2.userData.sideB = sideB;
    this.windowType2.userData.sideC = sideC;
    this.windowType2.userData.sideD = sideD;
  }
  sizeLamellenEl(
    lamellen,
    lamellenRailTop,
    lamellenRailBottom,
    lamellenFrameColumn,
    lamellenFrameBase,
    column,
    beam
  ) {
    const bbLamellen = new Box3();
    bbLamellen.setFromObject(lamellen, true);
    const sizeLamellen = new Vector3();
    bbLamellen.getSize(sizeLamellen);

    const bbLamellenRailTop = new Box3();
    bbLamellenRailTop.setFromObject(lamellenRailTop, true);
    const sizeLamellenRailTop = new Vector3();
    bbLamellenRailTop.getSize(sizeLamellenRailTop);

    const bbLamellenRailBottom = new Box3();
    bbLamellenRailBottom.setFromObject(lamellenRailBottom, true);
    const sizeLamellenRailBottom = new Vector3();
    bbLamellenRailBottom.getSize(sizeLamellenRailBottom);

    const bbLamellenFrameColumn = new Box3();
    bbLamellenFrameColumn.setFromObject(lamellenFrameColumn, true);
    const sizeLamellenFrameColumn = new Vector3();
    bbLamellenFrameColumn.getSize(sizeLamellenFrameColumn);

    const bbLamellenFrameBase = new Box3();
    bbLamellenFrameBase.setFromObject(lamellenFrameBase, true);
    const sizeLamellenFrameBase = new Vector3();
    bbLamellenFrameBase.getSize(sizeLamellenFrameBase);

    const bbColumn = new Box3();
    bbColumn.setFromObject(column, true);
    const sizeColumnLouver = new Vector3();
    bbColumn.getSize(sizeColumnLouver);

    const bbBeam = new Box3();
    bbBeam.setFromObject(beam, true);
    const sizeBeamLouver = new Vector3();
    bbBeam.getSize(sizeBeamLouver);

    this.createLamellenType1(
      lamellen,
      lamellenRailTop,
      lamellenRailBottom,
      lamellenFrameColumn,
      lamellenFrameBase,
      sizeLamellen,
      sizeLamellenRailTop,
      sizeLamellenRailBottom,
      sizeLamellenFrameColumn,
      sizeLamellenFrameBase,
      sizeColumnLouver,
      sizeBeamLouver
    );
  }
  createLamellenType1(
    lamellen,
    lamellenRailTop,
    lamellenRailBottom,
    lamellenFrameColumn,
    lamellenFrameBase,
    sizeLamellen,
    sizeLamellenRailTop,
    sizeLamellenRailBottom,
    sizeLamellenFrameColumn,
    sizeLamellenFrameBase,
    sizeColumn,
    sizeBeam
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createLamellenSection = (width) => {
      const lamellenSection = new Object3D();

      const lamellenFrameTop = lamellenFrameBase.clone();
      lamellenFrameTop.scale.x = width;
      lamellenFrameTop.position.set(
        0,
        this.y - sizeBeam.y - sizeLamellenFrameBase.y,
        0
      );
      const lamellenFrameBottom = lamellenFrameBase.clone();
      lamellenFrameBottom.scale.x = width;
      lamellenFrameBottom.scale.y = -1;
      lamellenFrameBottom.position.set(
        0,
        0 + sizeLamellenFrameBase.y + sizeLamellenRailBottom.y / 2,
        0
      );

      const lamellenFrameL = lamellenFrameColumn.clone();
      lamellenFrameL.scale.y =
        this.y - sizeBeam.y - sizeLamellenFrameBase.y * 2;
      lamellenFrameL.scale.x = -1;
      lamellenFrameL.scale.z = -1;
      lamellenFrameL.position.set(
        0.0015,
        sizeLamellenFrameBase.y,
        -sizeLamellenFrameColumn.z + 0.002
      );

      const lamellenFrameR = lamellenFrameColumn.clone();
      lamellenFrameR.scale.z = -1;
      lamellenFrameR.scale.y =
        this.y - sizeBeam.y - sizeLamellenFrameBase.y * 2;
      lamellenFrameR.position.set(
        -width - 0.002,
        sizeLamellenFrameBase.y,
        -sizeLamellenFrameColumn.z + 0.002
      );

      const lamellenEl = lamellen.clone();
      lamellenEl.scale.x = width;
      lamellenEl.position.y = sizeLamellenFrameBase.y;
      lamellenSection.add(lamellenEl);

      const col = Math.floor(
        (this.y - sizeBeam.y - sizeLamellenFrameBase.y * 2) /
          (sizeLamellen.y * 1.25)
      );

      const interval = 1 / (col - 1);
      for (let i = 1; i < col; i++) {
        const elLamellen = lamellenEl.clone();
        const t = i * interval;
        const y =
          sizeLamellenFrameBase.y +
          t * (this.y - sizeBeam.y - sizeLamellenFrameBase.y * 3);
        elLamellen.position.y = y;
        lamellenSection.add(elLamellen);
      }

      lamellenSection.add(lamellenFrameL);
      lamellenSection.add(lamellenFrameR);
      lamellenSection.add(lamellenFrameBottom);
      lamellenSection.add(lamellenFrameTop);

      return lamellenSection;
    };

    for (let i = 0; i < this.newColLeftB; i++) {
      const lam = createLamellenSection(this.newWidthB);
      lam.rotation.y = -Math.PI / 2;
      lam.position.set(
        this.z / 2 - sizeLamellenFrameBase.z,
        0,
        this.x / 2 - sizeColumn.x - i * this.newWidthB
      );
      sideB.add(lam);
    }

    for (let i = 0; i < this.newColRightB; i++) {
      const lam = createLamellenSection(this.newWidthB);
      lam.rotation.y = -Math.PI / 2;
      lam.position.set(
        this.z / 2 - sizeLamellenRailBottom.z,
        0,
        -this.x / 2 + sizeColumn.x + this.newWidthB + i * this.newWidthB
      );
      sideB.add(lam);
    }

    for (let i = 0; i < this.newColLeftD; i++) {
      const lam = createLamellenSection(this.newWidthD);
      lam.rotation.y = -Math.PI / 2;
      lam.scale.z = -1;
      lam.position.set(
        -this.z / 2 + sizeLamellenRailBottom.z,
        0,
        this.x / 2 - sizeColumn.x - i * this.newWidthD
      );
      sideD.add(lam);
    }

    for (let i = 0; i < this.newColRightD; i++) {
      const lam = createLamellenSection(this.newWidthD);
      lam.rotation.y = -Math.PI / 2;
      lam.scale.z = -1;
      lam.position.set(
        -this.z / 2 + sizeLamellenFrameBase.z,
        0,
        -this.x / 2 + sizeColumn.x + this.newWidthD + i * this.newWidthD
      );
      sideD.add(lam);
    }

    for (let i = 0; i < this.newColLeftA; i++) {
      const lam = createLamellenSection(this.newWidthA);
      lam.scale.z = -1;
      lam.position.set(
        -this.z / 2 + sizeColumn.z + this.newWidthA + i * this.newWidthA,
        0,
        this.x / 2 - sizeLamellenRailBottom.z
      );
      sideA.add(lam);
    }

    for (let i = 0; i < this.newColRightA; i++) {
      const lam = createLamellenSection(this.newWidthA);
      lam.scale.z = -1;
      lam.position.set(
        this.z / 2 - sizeColumn.z - i * this.newWidthA,
        0,
        this.x / 2 - sizeLamellenFrameBase.z
      );
      sideA.add(lam);
    }

    for (let i = 0; i < this.newColLeftC; i++) {
      const lam = createLamellenSection(this.newWidthC);

      lam.position.set(
        -this.z / 2 + sizeColumn.z + this.newWidthC + i * this.newWidthC,
        0,
        -this.x / 2 + sizeLamellenFrameBase.z
      );
      sideC.add(lam);
    }

    for (let i = 0; i < this.newColRightC; i++) {
      const lam = createLamellenSection(this.newWidthC);
      lam.position.set(
        this.z / 2 - sizeColumn.z - i * this.newWidthC,
        0,
        -this.x / 2 + sizeLamellenRailBottom.z
      );
      sideC.add(lam);
    }

    /////////////

    const lamellenRailBottomSideB = lamellenRailBottom.clone();
    lamellenRailBottomSideB.rotation.y = -Math.PI / 2;
    lamellenRailBottomSideB.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailBottomSideB.scale.z = -1;
    lamellenRailBottomSideB.position.set(
      this.z / 2,
      0,
      this.x / 2 - sizeColumn.x
    );
    const lamellenRailTopSideB = lamellenRailTop.clone();
    lamellenRailTopSideB.rotation.y = -Math.PI / 2;
    lamellenRailTopSideB.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailTopSideB.scale.z = -1;
    lamellenRailTopSideB.position.set(
      this.z / 2,
      this.y - sizeBeam.y,
      this.x / 2 - sizeColumn.x
    );

    sideB.add(lamellenRailTopSideB);
    sideB.add(lamellenRailBottomSideB);

    const lamellenRailBottomSideD = lamellenRailBottom.clone();
    lamellenRailBottomSideD.rotation.y = -Math.PI / 2;
    lamellenRailBottomSideD.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailBottomSideD.position.set(
      -this.z / 2,
      0,
      this.x / 2 - sizeColumn.x
    );

    const lamellenRailTopSideD = lamellenRailTop.clone();
    lamellenRailTopSideD.rotation.y = -Math.PI / 2;
    lamellenRailTopSideD.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailTopSideD.position.set(
      -this.z / 2,
      this.y - sizeBeam.y,
      this.x / 2 - sizeColumn.x
    );

    sideB.add(lamellenRailTopSideD);
    sideD.add(lamellenRailBottomSideD);

    const lamellenRailBottomSideA = lamellenRailBottom.clone();
    lamellenRailBottomSideA.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailBottomSideA.position.set(
      this.z / 2 - sizeColumn.z,
      0,
      this.x / 2
    );

    const lamellenRailTopSideA = lamellenRailTop.clone();
    lamellenRailTopSideA.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailTopSideA.position.set(
      this.z / 2 - sizeColumn.z,
      this.y - sizeBeam.y,
      this.x / 2
    );

    sideA.add(lamellenRailTopSideA);
    sideA.add(lamellenRailBottomSideA);

    const lamellenRailBottomSideC = lamellenRailBottom.clone();
    lamellenRailBottomSideC.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailBottomSideC.scale.z = -1;
    lamellenRailBottomSideC.position.set(
      this.z / 2 - sizeColumn.z,
      0,
      -this.x / 2
    );

    const lamellenRailTopSideC = lamellenRailTop.clone();
    lamellenRailTopSideC.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailTopSideC.scale.z = -1;
    lamellenRailTopSideC.position.set(
      this.z / 2 - sizeColumn.z,
      this.y - sizeBeam.y,
      -this.x / 2
    );

    sideC.add(lamellenRailTopSideC);
    sideC.add(lamellenRailBottomSideC);

    this.lamellenType1.userData.sideA = sideA;
    this.lamellenType1.userData.sideB = sideB;
    this.lamellenType1.userData.sideC = sideC;
    this.lamellenType1.userData.sideD = sideD;
  }
  createSolar(solar) {
    solar.position.y = this.y;

    const solar2 = new Object3D();
    const solar4 = new Object3D();
    const solar6 = new Object3D();
    const solar9 = new Object3D();

    if(this.x < 4 || this.z < 1.5) {
      solar2.rotation.y = Math.PI/2
    }
    if(this.x < 4 || this.z < 3) {
      solar4.rotation.y = Math.PI/2
    }
    if(this.x < 6 || this.z < 3) {
      solar6.rotation.y = Math.PI/2
    }
    if(this.x < 6 || this.z < 4.5) {
      solar9.rotation.y = Math.PI/2
    }

    for (let i = 0; i < 2; i++) {
      const sol = solar.clone();
      sol.position.z = (i % 2) * 2 - 2;
      sol.position.x = Math.floor(i / 2) * 1.5 + -0.75;
      solar2.add(sol);
    }

    for (let i = 0; i < 4; i++) {
      const sol = solar.clone();
      sol.position.z = (i % 2) * 2 - 2;
      sol.position.x = Math.floor(i / 2) * 1.5 + -1.5;
      solar4.add(sol);
    }

    for (let i = 0; i < 6; i++) {
      const sol = solar.clone();
      sol.position.z = (i % 3) * 2 - 3;
      sol.position.x = Math.floor(i / 3) * 1.5 - 1.5;
      solar6.add(sol);
    }

    for (let i = 0; i < 9; i++) {
      const sol = solar.clone();
      sol.position.z = (i % 3) * 2 - 3;
      sol.position.x = Math.floor(i / 3) * 1.5 - 2.25;
      solar9.add(sol);
    }

    this.solarPanels.userData.solar2 = solar2;
    this.solarPanels.userData.solar4 = solar4;
    this.solarPanels.userData.solar6 = solar6;
    this.solarPanels.userData.solar9 = solar9;
  }
}
