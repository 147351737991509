import React, { useState } from 'react';
import './style.scss';
import InputText from '../../../../../ui/Input';
import Button from '../../../../../ui/Button';
import DatePicker from '../../../../../ui/DatePicker';
import Select from '../../../../../ui/Select';

export const statusOptions = [
  { value: '', label: 'All' },
  { value: 'Draft', label: 'Draft' },
  { value: 'In Progress', label: 'In Progress' },
  { value: 'Finalized', label: 'Finalized' },
  { value: 'Canceled', label: 'Canceled' },
];

const QuotesFilter = ({ filter, onChange }) => {
  const [search, setSearch] = useState('');

  return (
    <div className="quotes-filter">
      <div className="quotes-filter__row">
        <div className="quotes-filter__col">
          <div className="quotes-filter__search">
            <InputText
              placeholder="Search by number, name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              className="green big"
              onClick={() => onChange({ ...filter, search })}
            >
              Suchen
            </Button>
          </div>
        </div>
        <div className="quotes-filter__col">
          <div className="quotes-filter__actions">
            <p>Filter:</p>
            <DatePicker
              placeholder="Most recent"
              value={filter.date}
              onChange={(date) => onChange({ ...filter, date })}
            />
            <Select
              options={statusOptions}
              value={filter.status}
              onChange={(status) => onChange({ ...filter, status })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotesFilter;
