import React, { useEffect, useMemo, useState } from 'react';
import CustomSimpleBar from 'components/CustomSimpleBar';
import './style.scss';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as EditSvg } from 'assets/edit.svg';
import { ReactComponent as CheckSvg } from 'assets/check-white.svg';
import Button from '../../../../ui/Button';
import { useGetAllQuotesQuery } from '../../../../../services/quotes.service';
import {
  setOrder,
  setOpeningProject,
} from '../../../../../store/reducers/Project/ProjectSlice';
import Loading from '../../../../Loading';
import Empty from '../../../../Empty';
import QuotesFilter from './Filter';
import filterHelper from './Filter/helpre';
import {
  setSelectedProject,
  setSelectedQuote,
  setShowAccountModal,
} from '../../../../../store/reducers/User/UserSlice';
import QuoteSummaryContent from './QuoteSummary';

import { useGetProjectMutation } from '../../../../../services/projects.service';


const columnHelper = createColumnHelper();

const initialFilter = {
  search: '',
  status: '',
  date: '',
};

const filterFn = (row, columnId, filterValue) =>
  filterHelper(filterValue, row.original);

const Quotes = () => {
  const dispatch = useDispatch();
  const { source } = useSelector((state) => state.project);
  const { profile, selectedQuote } = useSelector((state) => state.user);
  const { data, isLoading: isLoadingQuery } = useGetAllQuotesQuery();
  const [filter, setFilter] = useState(initialFilter);
  const [sorting, setSorting] = useState([]);
  const [getProject] = useGetProjectMutation();
  const isAdmin = profile?.role === 'admin';

  useEffect(() => () => dispatch(setSelectedQuote(null)), []);

  const handleEdit = (quote) => {
    dispatch(setSelectedQuote(quote));
  };

  const handleOpen = async (id, isAdminVersion) => {
    try {
      const res = await getProject(id);
      const project = res.data;
      dispatch(setSelectedProject(project));
      dispatch(setOrder());
      dispatch(setOpeningProject(false));
      source?.funcOpenProject(
        isAdminVersion
          ? { ...project.adminVersion, paint: project.paint }
          : project
      );
      setTimeout(() => {
        source?.funcUpdatePrice();
      }, 500);

      setTimeout(() => {
        dispatch(setOpeningProject(true));
      }, 3000);

      dispatch(setShowAccountModal(false));
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useMemo(() => {
    const number = columnHelper.accessor((row) => row.number, {
      id: 'number',
      enableSorting: false,
      header: <span>{isAdmin ? 'ID' : 'Angebotsnummer'}</span>,
      cell: (info) => `#${info.getValue()}`,
    });
    const projectName = columnHelper.accessor((row) => row.projectName, {
      id: 'projectName',
      enableSorting: false,
      header: <span>{isAdmin ? 'Projektname' : 'Namen zitieren'}</span>,
      cell: (info) => info.getValue(),
    });

    const clientName = columnHelper.accessor((row) => row.client?.firstName, {
      id: 'clientName',
      enableSorting: false,
      header: <span>Kundenname</span>,
      cell: (info) => info.getValue(),
    });
    const date = columnHelper.accessor((row) => row.createdAt, {
      id: 'date',
      header: <span>{isAdmin ? 'Datum' : 'Date'}</span>,
      cell: (info) => format(new Date(info.getValue()), 'H:mm dd-MM-yyyy'),
    });
    const status = columnHelper.accessor((row) => row.status, {
      id: 'status',
      header: <span>{isAdmin ? 'Status bearbeiten' : 'Status'}</span>,
      cell: (info) => (
        <span
          className={`quotes-table__status ${info
            .getValue()
            .replace(/\s/g, '')}`}
        >
          {info.getValue()}
        </span>
      ),
    });
    const actions = columnHelper.accessor((row) => row, {
      id: '_id',
      enableSorting: false,
      header: () => 'Aktionen',
      cell: (info) => {
        const row = info.getValue();
        return (
          <div className="projects-table__actions">
            <Button
              className="x-small green"
              onClick={() => handleEdit(row)}
              title="Edit User Project"
            >
              <EditSvg />
            </Button>
            <Button
              disabled={info.row.original.status === 'Finalized'}
              className="x-small green"
              onClick={() => handleOpen(row.project._id)}
              title="Open User Project"
            >
              <CheckSvg />
            </Button>
            {row.project.adminVersion ? (
              <Button
                disabled={info.row.original.status === 'Finalized'}
                className="x-small grey"
                onClick={() => handleOpen(row.project._id, true)}
                title="Open Admin Project"
              >
                <CheckSvg />
              </Button>
            ) : null}
          </div>
        );
      },
    });

    if (isAdmin) {
      return [number, projectName, clientName, date, status, actions];
    }

    return [number, projectName, date, status];
  }, []);

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: {
        pageSize: 10,
      },
    },
    state: {
      sorting,
      globalFilter: filter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFilter,
    globalFilterFn: filterFn,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  if (isLoadingQuery) {
    return <Loading />;
  }

  if (!data || !data.length) {
    return <Empty />;
  }

  if (selectedQuote) {
    return <QuoteSummaryContent />;
  }

  return (
    <>
      <div className="quotes-table">
        {isAdmin ? (
          <QuotesFilter
            {...{
              filter,
              onChange: setFilter,
            }}
          />
        ) : null}
        <CustomSimpleBar style={{ maxHeight: 'calc(90dvh - 350px)', width: '100%' }}>
          <table className={isAdmin ? 'admin' : ''}>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id}>
                      <div
                        role="presentation"
                        {...{
                          className: header.column.getCanSort()
                            ? 'can-sort'
                            : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel()?.rows?.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </CustomSimpleBar>
      </div>
      {table.getRowModel()?.rows?.length ? (
        <div className="quotes-table__pagination">
          <button
            type="button"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            {'<'}
          </button>
          <strong>
            {table.getState().pagination.pageIndex + 1} ...{' '}
            {table.getPageCount()}
          </strong>
          <button
            type="button"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            {'>'}
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Quotes;
