import React from 'react';
import './style.scss';
import image from '../../assets/empty.svg';

const EmptyData = () => (
  <div className="empty-wrapper">
    <img src={image} alt="empty" />
    <p>No Data</p>
  </div>
);

export default EmptyData;
