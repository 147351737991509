import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { useGetProductInfoQuery } from '../../../services/projects.service';
import { setShowModelSizeModal } from '../../../store/reducers/User/UserSlice';
import './style.scss';
import { Spinner } from '../../Loading';
// import { savePrevSizes } from '../../../store/reducers/Project/ProjectSlice';

const step = 1;

const ModelSizeModal = () => {
  const dispatch = useDispatch();
  const { showModelSizeModal } = useSelector((state) => state.user);
  const { source, typeModel } = useSelector((state) => state.project);
  const { data: products, isLoading } = useGetProductInfoQuery({
    products: ['1061', '1001', '1064', '1484'],
  });
  const [sizeWidth, setSizeWidth] = useState(0);
  const [sizeDepth, setSizeDepth] = useState(0);
  const [sizeHeight1, setSizeHeight1] = useState(0);
  const [sizeHeight2, setSizeHeight2] = useState(0);

  const [angle, setAngle] = useState(0);

  useEffect(() => {
    const a = sizeHeight2 - sizeHeight1;
    const b = sizeDepth; // -95
    const c = Math.sqrt(a * a + b * b); // длина гипотенузы
    const alpha = Math.asin(a / c); // угол alpha в радианах

    const res = (alpha * 180) / Math.PI;
    const resNumber = Math.round(res * 10) / 10;

    setAngle(resNumber);
  }, [sizeDepth, sizeHeight1, sizeHeight2]);

  useEffect(() => {
    if (!source) return;
    source?.addEventListener('edit-open', (data) => {
      setSizeWidth(data.size.w * 1000);
      setSizeDepth(data.size.d * 1000);
      setSizeHeight1(data.size.h1 * 1000);
      setSizeHeight2(data.size.h2 * 1000);
    });
  }, [source]);

  const handleClose = () => dispatch(setShowModelSizeModal(false));
  const sizeLouver = useMemo(() => {
    if (!products) {
      return {
        width: {
          min: 0,
          max: 0,
        },
        depth: {
          min: 0,
          max: 0,
        },
        height1: {
          min: 0,
          max: 0,
        },
      };
    }

    const product = products.find((item) => item.ProductNo === '1061');
    const width = product.WS_ProductVariables.find(
      (item) => item.Name === 'Breite'
    );
    const depth = product.WS_ProductVariables.find(
      (item) => item.Name === 'Tiefe'
    );
    const height1 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheOKT'
    );
    return {
      width: {
        min: width.MinValue * 10,
        max: width.MaxValue * 10,
      },
      depth: {
        min: depth.MinValue * 10,
        max: depth.MaxValue * 10,
      },
      height1: {
        min: height1.MinValue * 10,
        max: height1.MaxValue * 10,
      },
    };
  }, [products]);

  const sizeLouver2 = useMemo(() => {
    if (!products) {
      return {
        width: {
          min: 0,
          max: 0,
        },
        depth: {
          min: 0,
          max: 0,
        },
        height1: {
          min: 0,
          max: 0,
        },
      };
    }

    const product = products.find((item) => item.ProductNo === '1064');
    const width = product.WS_ProductVariables.find(
      (item) => item.Name === 'Breite'
    );
    const depth = product.WS_ProductVariables.find(
      (item) => item.Name === 'Tiefe'
    );
    const height1 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheOKT'
    );
    return {
      width: {
        min: width.MinValue * 10,
        max: width.MaxValue * 10,
      },
      depth: {
        min: depth.MinValue * 10,
        max: depth.MaxValue * 10,
      },
      height1: {
        min: height1.MinValue * 10,
        max: height1.MaxValue * 10,
      },
    };
  }, [products]);

  const sizeLouver3 = useMemo(() => {
    if (!products) {
      return {
        width: {
          min: 0,
          max: 0,
        },
        depth: {
          min: 0,
          max: 0,
        },
        height1: {
          min: 0,
          max: 0,
        },
      };
    }

    const product = products.find((item) => item.ProductNo === '1484');
    const width = product.WS_ProductVariables.find(
      (item) => item.Name === 'Breite'
    );
    const depth = product.WS_ProductVariables.find(
      (item) => item.Name === 'Tiefe'
    );
    const height1 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheOKT'
    );
    return {
      width: {
        min: width.MinValue * 10,
        max: width.MaxValue * 10,
      },
      depth: {
        min: depth.MinValue * 10,
        max: depth.MaxValue * 10,
      },
      height1: {
        min: height1.MinValue * 10,
        max: height1.MaxValue * 10,
      },
    };
  }, [products]);

  const sizeGlass = useMemo(() => {
    if (!products) {
      return {
        width: {
          min: 0,
          max: 0,
        },
        depth: {
          min: 0,
          max: 0,
        },
        height1: {
          min: 0,
          max: 0,
        },
        height2: {
          min: 0,
          max: 0,
        },
      };
    }

    const product = products.find((item) => item.ProductNo === '1001');
    const width = product.WS_ProductVariables.find(
      (item) => item.Name === 'Breite'
    );
    const depth = product.WS_ProductVariables.find(
      (item) => item.Name === 'Tiefe'
    );
    const height1 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheUKT'
    );
    const height2 = product.WS_ProductVariables.find(
      (item) => item.Name === 'HoeheOKW'
    );
    return {
      width: {
        min: width.MinValue * 10,
        max: width.MaxValue * 10,
      },
      depth: {
        min: depth.MinValue * 10,
        max: depth.MaxValue * 10,
      },
      height1: {
        min: height1.MinValue * 10,
        max: height1.MaxValue * 10,
      },
      height2: {
        min: height2.MinValue * 10,
        max: height2.MaxValue * 10,
      },
    };
  }, [products]);

  const editSize = () => {
    if (typeModel === 'louver') {
      source.funcEditEl(
        sizeWidth < sizeLouver.width.min ? sizeLouver.width.min : sizeWidth,
        sizeDepth < sizeLouver.depth.min ? sizeLouver.depth.min : sizeDepth,
        sizeHeight1 < sizeLouver.height1.min
          ? sizeLouver.height1.min
          : sizeHeight1,
        sizeHeight2
        // order
      );
      // const prevSizesMod = {
      //   width:
      //     sizeWidth < sizeLouver.width.min ? sizeLouver.width.min : sizeWidth,
      //   depth:
      //     sizeDepth < sizeLouver.depth.min ? sizeLouver.depth.min : sizeDepth,
      //   height1:
      //     sizeHeight1 < sizeLouver.height1.min
      //       ? sizeLouver.height1.min
      //       : sizeHeight1,
      //   height2: sizeHeight2,
      // };
      // dispatch(savePrevSizes(prevSizesMod));
    }

    if (typeModel === 'louver2') {
      source.funcEditEl(
        sizeWidth < sizeLouver2.width.min ? sizeLouver2.width.min : sizeWidth,
        sizeDepth < sizeLouver2.depth.min ? sizeLouver2.depth.min : sizeDepth,
        sizeHeight1 < sizeLouver2.height1.min
          ? sizeLouver2.height1.min
          : sizeHeight1,
        sizeHeight2
        // order
      );
      // const prevSizesMod = {
      //   width:
      //     sizeWidth < sizeLouver2.width.min ? sizeLouver2.width.min : sizeWidth,
      //   depth:
      //     sizeDepth < sizeLouver2.depth.min ? sizeLouver2.depth.min : sizeDepth,
      //   height1:
      //     sizeHeight1 < sizeLouver2.height1.min
      //       ? sizeLouver2.height1.min
      //       : sizeHeight1,
      //   height2: sizeHeight2,
      // };
      // dispatch(savePrevSizes(prevSizesMod));
    }

    if (typeModel === 'louver3') {
      source.funcEditEl(
        sizeWidth < sizeLouver3.width.min ? sizeLouver3.width.min : sizeWidth,
        sizeDepth < sizeLouver3.depth.min ? sizeLouver3.depth.min : sizeDepth,
        sizeHeight1 < sizeLouver3.height1.min
          ? sizeLouver3.height1.min
          : sizeHeight1,
        sizeHeight2
        // order
      );
      // const prevSizesMod = {
      //   width:
      //     sizeWidth < sizeLouver3.width.min ? sizeLouver3.width.min : sizeWidth,
      //   depth:
      //     sizeDepth < sizeLouver3.depth.min ? sizeLouver3.depth.min : sizeDepth,
      //   height1:
      //     sizeHeight1 < sizeLouver.height1.min
      //       ? sizeLouver3.height1.min
      //       : sizeHeight1,
      //   height2: sizeHeight2,
      // };
      // dispatch(savePrevSizes(prevSizesMod));
    }

    if (typeModel === 'glass') {
      source.funcEditEl(
        sizeWidth < sizeGlass.width.min ? sizeGlass.width.min : sizeWidth,
        sizeDepth < sizeGlass.depth.min ? sizeGlass.depth.min : sizeDepth,
        sizeHeight1 < sizeGlass.height1.min
          ? sizeGlass.height1.min
          : sizeHeight1,
        sizeHeight2 < sizeGlass.height2.min
          ? sizeGlass.height2.min
          : sizeHeight2
        // order
      );
      // const prevSizesMod = {
      //   width:
      //     sizeWidth < sizeGlass.width.min ? sizeGlass.width.min : sizeWidth,
      //   depth:
      //     sizeDepth < sizeGlass.depth.min ? sizeGlass.depth.min : sizeDepth,
      //   height1:
      //     sizeHeight1 < sizeGlass.height1.min
      //       ? sizeGlass.height1.min
      //       : sizeHeight1,
      //   height2:
      //     sizeHeight2 < sizeGlass.height2.min
      //       ? sizeGlass.height2.min
      //       : sizeHeight2,
      // };
      // dispatch(savePrevSizes(prevSizesMod));
    }
    handleClose();
  };

  return (
    <ModalWrapper
      show={showModelSizeModal}
      close={handleClose}
      customClass="wrapper-model-size-modal"
    >
      <ModalHeader title="Masse" close={handleClose} />
      <div className="container-model-size-modal">
        {isLoading ? <Spinner /> : null}
        {typeModel === 'louver' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Breite</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize1"
                  min={sizeLouver.width.min}
                  max={sizeLouver.width.max}
                  step={step}
                  onChange={(e) => {
                    setSizeWidth(e.target.value);
                  }}
                  style={{
                    backgroundSize: `${
                      sizeWidth <= sizeLouver.width.min
                        ? 0
                        : ((sizeWidth - sizeLouver.width.min) /
                            (sizeLouver.width.max - sizeLouver.width.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeWidth}
                />

                <label
                  htmlFor="rangeSize1"
                  style={{
                    transform: `translateX(${
                      sizeWidth <= sizeLouver.width.min
                        ? 0
                        : ((sizeWidth - sizeLouver.width.min) /
                            (sizeLouver.width.max - sizeLouver.width.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver.width.min) {
                          setSizeWidth(sizeLouver.width.min);
                        }
                      }}
                      value={sizeWidth}
                      onChange={(e) =>
                        setSizeWidth(
                          e.target.value >= sizeLouver.width.max
                            ? sizeLouver.width.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver.width.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver.width.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Tiefe</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize2"
                  min={sizeLouver.depth.min}
                  max={sizeLouver.depth.max}
                  step={step}
                  onChange={(e) => setSizeDepth(e.target.value)}
                  style={{
                    backgroundSize: `${
                      sizeDepth <= sizeLouver.depth.min
                        ? 0
                        : ((sizeDepth - sizeLouver.depth.min) /
                            (sizeLouver.depth.max - sizeLouver.depth.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeDepth}
                />

                <label
                  htmlFor="rangeSize2"
                  style={{
                    transform: `translateX(${
                      sizeDepth <= sizeLouver.depth.min
                        ? 0
                        : ((sizeDepth - sizeLouver.depth.min) /
                            (sizeLouver.depth.max - sizeLouver.depth.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver.depth.min) {
                          setSizeDepth(sizeLouver.depth.min);
                        }
                      }}
                      value={sizeDepth}
                      onChange={(e) =>
                        setSizeDepth(
                          e.target.value >= sizeLouver.depth.max
                            ? sizeLouver.depth.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver.depth.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver.depth.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Höhe</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize3"
                  min={sizeLouver.height1.min}
                  max={sizeLouver.height1.max}
                  step={step}
                  onChange={(e) => setSizeHeight1(e.target.value)}
                  style={{
                    backgroundSize: `${
                      sizeHeight1 <= sizeLouver.height1.min
                        ? 0
                        : ((sizeHeight1 - sizeLouver.height1.min) /
                            (sizeLouver.height1.max - sizeLouver.height1.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeHeight1}
                />
                <label
                  htmlFor="rangeSize3"
                  style={{
                    transform: `translateX(${
                      sizeHeight1 <= sizeLouver.height1.min
                        ? 0
                        : ((sizeHeight1 - sizeLouver.height1.min) /
                            (sizeLouver.height1.max - sizeLouver.height1.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver.height1.min) {
                          setSizeHeight1(sizeLouver.height1.min);
                        }
                      }}
                      value={sizeHeight1}
                      onChange={(e) =>
                        setSizeHeight1(
                          e.target.value >= sizeLouver.height1.max
                            ? sizeLouver.height1.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>
              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver.height1.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver.height1.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver2' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Breite</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize1"
                  min={sizeLouver2.width.min}
                  max={sizeLouver2.width.max}
                  step={step}
                  onChange={(e) => {
                    setSizeWidth(e.target.value);
                  }}
                  style={{
                    backgroundSize: `${
                      sizeWidth <= sizeLouver2.width.min
                        ? 0
                        : ((sizeWidth - sizeLouver2.width.min) /
                            (sizeLouver2.width.max - sizeLouver2.width.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeWidth}
                />

                <label
                  htmlFor="rangeSize1"
                  style={{
                    transform: `translateX(${
                      sizeWidth <= sizeLouver2.width.min
                        ? 0
                        : ((sizeWidth - sizeLouver2.width.min) /
                            (sizeLouver2.width.max - sizeLouver2.width.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver2.width.min) {
                          setSizeWidth(sizeLouver2.width.min);
                        }
                      }}
                      value={sizeWidth}
                      onChange={(e) =>
                        setSizeWidth(
                          e.target.value >= sizeLouver2.width.max
                            ? sizeLouver2.width.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver2.width.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver2.width.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver2' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Tiefe</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize2"
                  min={sizeLouver2.depth.min}
                  max={sizeLouver2.depth.max}
                  step={step}
                  onChange={(e) => setSizeDepth(e.target.value)}
                  style={{
                    backgroundSize: `${
                      sizeDepth <= sizeLouver2.depth.min
                        ? 0
                        : ((sizeDepth - sizeLouver2.depth.min) /
                            (sizeLouver2.depth.max - sizeLouver2.depth.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeDepth}
                />

                <label
                  htmlFor="rangeSize2"
                  style={{
                    transform: `translateX(${
                      sizeDepth <= sizeLouver2.depth.min
                        ? 0
                        : ((sizeDepth - sizeLouver2.depth.min) /
                            (sizeLouver2.depth.max - sizeLouver2.depth.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver2.depth.min) {
                          setSizeDepth(sizeLouver2.depth.min);
                        }
                      }}
                      value={sizeDepth}
                      onChange={(e) =>
                        setSizeDepth(
                          e.target.value >= sizeLouver2.depth.max
                            ? sizeLouver2.depth.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver2.depth.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver2.depth.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver2' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Höhe</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize3"
                  min={sizeLouver2.height1.min}
                  max={sizeLouver2.height1.max}
                  step={step}
                  onChange={(e) => setSizeHeight1(e.target.value)}
                  style={{
                    backgroundSize: `${
                      sizeHeight1 <= sizeLouver2.height1.min
                        ? 0
                        : ((sizeHeight1 - sizeLouver2.height1.min) /
                            (sizeLouver2.height1.max -
                              sizeLouver2.height1.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeHeight1}
                />
                <label
                  htmlFor="rangeSize3"
                  style={{
                    transform: `translateX(${
                      sizeHeight1 <= sizeLouver2.height1.min
                        ? 0
                        : ((sizeHeight1 - sizeLouver2.height1.min) /
                            (sizeLouver2.height1.max -
                              sizeLouver2.height1.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver2.height1.min) {
                          setSizeHeight1(sizeLouver2.height1.min);
                        }
                      }}
                      value={sizeHeight1}
                      onChange={(e) =>
                        setSizeHeight1(
                          e.target.value >= sizeLouver2.height1.max
                            ? sizeLouver2.height1.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>
              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver2.height1.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver2.height1.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver3' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Breite</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize1"
                  min={sizeLouver3.width.min}
                  max={sizeLouver3.width.max}
                  step={step}
                  onChange={(e) => {
                    setSizeWidth(e.target.value);
                  }}
                  style={{
                    backgroundSize: `${
                      sizeWidth <= sizeLouver3.width.min
                        ? 0
                        : ((sizeWidth - sizeLouver3.width.min) /
                            (sizeLouver3.width.max - sizeLouver3.width.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeWidth}
                />

                <label
                  htmlFor="rangeSize1"
                  style={{
                    transform: `translateX(${
                      sizeWidth <= sizeLouver3.width.min
                        ? 0
                        : ((sizeWidth - sizeLouver3.width.min) /
                            (sizeLouver3.width.max - sizeLouver3.width.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver3.width.min) {
                          setSizeWidth(sizeLouver3.width.min);
                        }
                      }}
                      value={sizeWidth}
                      onChange={(e) =>
                        setSizeWidth(
                          e.target.value >= sizeLouver3.width.max
                            ? sizeLouver3.width.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver3.width.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver3.width.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver3' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Tiefe</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize2"
                  min={sizeLouver3.depth.min}
                  max={sizeLouver3.depth.max}
                  step={step}
                  onChange={(e) => setSizeDepth(e.target.value)}
                  style={{
                    backgroundSize: `${
                      sizeDepth <= sizeLouver3.depth.min
                        ? 0
                        : ((sizeDepth - sizeLouver3.depth.min) /
                            (sizeLouver3.depth.max - sizeLouver3.depth.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeDepth}
                />

                <label
                  htmlFor="rangeSize2"
                  style={{
                    transform: `translateX(${
                      sizeDepth <= sizeLouver3.depth.min
                        ? 0
                        : ((sizeDepth - sizeLouver3.depth.min) /
                            (sizeLouver3.depth.max - sizeLouver3.depth.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver3.depth.min) {
                          setSizeDepth(sizeLouver3.depth.min);
                        }
                      }}
                      value={sizeDepth}
                      onChange={(e) =>
                        setSizeDepth(
                          e.target.value >= sizeLouver3.depth.max
                            ? sizeLouver3.depth.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver3.depth.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver3.depth.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'louver3' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Höhe</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize3"
                  min={sizeLouver3.height1.min}
                  max={sizeLouver3.height1.max}
                  step={step}
                  onChange={(e) => setSizeHeight1(e.target.value)}
                  style={{
                    backgroundSize: `${
                      sizeHeight1 <= sizeLouver3.height1.min
                        ? 0
                        : ((sizeHeight1 - sizeLouver3.height1.min) /
                            (sizeLouver3.height1.max -
                              sizeLouver3.height1.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeHeight1}
                />
                <label
                  htmlFor="rangeSize3"
                  style={{
                    transform: `translateX(${
                      sizeHeight1 <= sizeLouver3.height1.min
                        ? 0
                        : ((sizeHeight1 - sizeLouver3.height1.min) /
                            (sizeLouver3.height1.max -
                              sizeLouver3.height1.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeLouver3.height1.min) {
                          setSizeHeight1(sizeLouver3.height1.min);
                        }
                      }}
                      value={sizeHeight1}
                      onChange={(e) =>
                        setSizeHeight1(
                          e.target.value >= sizeLouver3.height1.max
                            ? sizeLouver3.height1.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>
              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeLouver3.height1.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeLouver3.height1.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'glass' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Breite</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize1"
                  min={sizeGlass.width.min}
                  max={sizeGlass.width.max}
                  step={step}
                  onChange={(e) => {
                    setSizeWidth(e.target.value);
                  }}
                  style={{
                    backgroundSize: `${
                      sizeWidth <= sizeGlass.width.min
                        ? 0
                        : ((sizeWidth - sizeGlass.width.min) /
                            (sizeGlass.width.max - sizeGlass.width.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeWidth}
                />

                <label
                  htmlFor="rangeSize1"
                  style={{
                    transform: `translateX(${
                      sizeWidth <= sizeGlass.width.min
                        ? 0
                        : ((sizeWidth - sizeGlass.width.min) /
                            (sizeGlass.width.max - sizeGlass.width.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeGlass.width.min) {
                          setSizeWidth(sizeGlass.width.min);
                        }
                      }}
                      value={sizeWidth}
                      onChange={(e) =>
                        setSizeWidth(
                          e.target.value >= sizeGlass.width.max
                            ? sizeGlass.width.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeGlass.width.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeGlass.width.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'glass' ? (
          <div className="section-model-size-modal">
            <div className="title-model-size-modal">Tiefe</div>
            <div className="input-model-size-modal">
              <div className="content-input-model-size-modal">
                <input
                  type="range"
                  id="rangeSize2"
                  min={sizeGlass.depth.min}
                  max={sizeGlass.depth.max}
                  step={step}
                  onChange={(e) => setSizeDepth(e.target.value)}
                  style={{
                    backgroundSize: `${
                      sizeDepth <= sizeGlass.depth.min
                        ? 0
                        : ((sizeDepth - sizeGlass.depth.min) /
                            (sizeGlass.depth.max - sizeGlass.depth.min)) *
                          100
                    }% 100%`,
                  }}
                  value={sizeDepth}
                />

                <label
                  htmlFor="rangeSize2"
                  style={{
                    transform: `translateX(${
                      sizeDepth <= sizeGlass.depth.min
                        ? 0
                        : ((sizeDepth - sizeGlass.depth.min) /
                            (sizeGlass.depth.max - sizeGlass.depth.min)) *
                          100
                    }%)`,
                  }}
                >
                  <span
                    style={{
                      pointerEvents: 'auto',
                    }}
                  >
                    <input
                      required
                      type="number"
                      onBlur={(e) => {
                        if (e.target.value < sizeGlass.depth.min) {
                          setSizeDepth(sizeGlass.depth.min);
                        }
                      }}
                      value={sizeDepth}
                      onChange={(e) =>
                        setSizeDepth(
                          e.target.value >= sizeGlass.depth.max
                            ? sizeGlass.depth.max
                            : e.target.value
                        )
                      }
                    />
                  </span>
                </label>
              </div>

              <div className="titles-model-size-modal">
                <span className="input-model-size-modal-start">
                  {sizeGlass.depth.min}
                </span>
                <span className="input-model-size-modal-end">
                  {sizeGlass.depth.max}
                </span>
              </div>
            </div>
            <div className="title-model-size-modal">mm</div>
          </div>
        ) : null}
        {typeModel === 'glass' ? (
          <>
            <div className="section-model-size-modal">
              <div className="title-model-size-modal">Höhe</div>
              <div className="input-model-size-modal">
                <div className="content-input-model-size-modal">
                  <input
                    type="range"
                    id="rangeSize5"
                    min={sizeGlass.height2.min}
                    max={sizeGlass.height2.max}
                    step={step}
                    onChange={(e) => setSizeHeight2(e.target.value)}
                    style={{
                      backgroundSize: `${
                        sizeHeight2 <= sizeGlass.height2.min
                          ? 0
                          : ((sizeHeight2 - sizeGlass.height2.min) /
                              (sizeGlass.height2.max - sizeGlass.height2.min)) *
                            100
                      }% 100%`,
                    }}
                    value={sizeHeight2}
                  />
                  <label
                    htmlFor="rangeSize5"
                    style={{
                      transform: `translateX(${
                        sizeHeight2 <= sizeGlass.height2.min
                          ? 0
                          : ((sizeHeight2 - sizeGlass.height2.min) /
                              (sizeGlass.height2.max - sizeGlass.height2.min)) *
                            100
                      }%)`,
                    }}
                  >
                    <span
                      style={{
                        pointerEvents: 'auto',
                      }}
                    >
                      <input
                        required
                        type="number"
                        onBlur={(e) => {
                          if (e.target.value < sizeGlass.height2?.min) {
                            setSizeHeight2(sizeGlass.height2?.min);
                          }
                        }}
                        value={sizeHeight2}
                        onChange={(e) =>
                          setSizeHeight2(
                            e.target.value >= sizeGlass.height2.max
                              ? sizeGlass.height2.max
                              : e.target.value
                          )
                        }
                      />
                    </span>
                  </label>
                </div>

                <div className="titles-model-size-modal">
                  <span className="input-model-size-modal-start">
                    {sizeGlass.height2.min}
                  </span>
                  <span className="input-model-size-modal-end">
                    {sizeGlass.height2.max}
                  </span>
                </div>
              </div>
              <div className="title-model-size-modal">mm</div>
            </div>
            <div className="section-model-size-modal">
              <div className="title-model-size-modal">Höhe</div>
              <div className="input-model-size-modal">
                <div className="content-input-model-size-modal">
                  <input
                    id="rangeSize4"
                    type="range"
                    min={sizeGlass.height1.min}
                    max={sizeGlass.height1.max}
                    step={step}
                    onChange={(e) => setSizeHeight1(e.target.value)}
                    style={{
                      backgroundSize: `${
                        sizeHeight1 <= sizeGlass.height1.min
                          ? 0
                          : ((sizeHeight1 - sizeGlass.height1.min) /
                              (sizeGlass.height1.max - sizeGlass.height1.min)) *
                            100
                      }% 100%`,
                    }}
                    value={sizeHeight1}
                  />
                  <label
                    htmlFor="rangeSize4"
                    style={{
                      transform: `translateX(${
                        sizeHeight1 <= sizeGlass.height1.min
                          ? 0
                          : ((sizeHeight1 - sizeGlass.height1.min) /
                              (sizeGlass.height1.max - sizeGlass.height1.min)) *
                            100
                      }%)`,
                    }}
                  >
                    <span
                      style={{
                        pointerEvents: 'auto',
                      }}
                    >
                      <input
                        required
                        type="number"
                        onBlur={(e) => {
                          if (e.target.value < sizeGlass.height1?.min) {
                            setSizeHeight1(sizeGlass.height1?.min);
                          }
                        }}
                        value={sizeHeight1}
                        onChange={(e) =>
                          setSizeHeight1(
                            e.target.value >= sizeGlass.height1.max
                              ? sizeGlass.height1.max
                              : e.target.value
                          )
                        }
                      />
                    </span>
                  </label>
                </div>
                <div className="titles-model-size-modal">
                  <span className="input-model-size-modal-start">
                    {sizeGlass.height1.min}
                  </span>
                  <span className="input-model-size-modal-end">
                    {sizeGlass.height1.max}
                  </span>
                </div>
              </div>
              <div className="title-model-size-modal">mm</div>
            </div>
          </>
        ) : null}
        {typeModel === 'glass' && (angle < 4.5 || angle > 20) ? (
          <div className="container-angle">
            {typeModel === 'glass' && (angle < 4.5 || angle > 20) ? (
              <div>
                {angle > 10
                  ? `Die Dachneigung darf nicht weniger sein als 20, aktuell  ${angle}°.`
                  : `Die Dachneigung darf nicht höher sein als 4.5, aktuell ${angle}°.`}
              </div>
            ) : null}
          </div>
        ) : null}
      </div>

      <Button
        className={
          typeModel === 'glass' && (angle < 4.5 || angle > 20)
            ? 'btn-model-size-modal disable'
            : 'btn-model-size-modal'
        }
        onClick={() => {
          editSize();
        }}
      >
        OK
      </Button>
    </ModalWrapper>
  );
};

export default ModelSizeModal;
