import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import InputText from '../../ui/Input';
import Button from '../../ui/Button';
import { validateEmail } from '../../../utils/validation';
import { sendResetPasswordMail } from '../../../store/reducers/User/ActionCreators';
import { setShowResetPasswordModal } from '../../../store/reducers/User/UserSlice';

const initState = {
  email: undefined,
  password: undefined,
  newPassword: undefined,
};

const ResetPasswordModal = () => {
  const dispatch = useDispatch();
  const { showResetPasswordModal } = useSelector((state) => state.user);
  const [values, setValues] = useState(initState);

  const handleClose = () => {
    dispatch(setShowResetPasswordModal(false));
  };
  const handleSubmit = () => {
    if (!validateEmail(values.email)) {
      return toast.error('Email not valid!');
    }

    return toast
      .promise(
        dispatch(
          sendResetPasswordMail({
            email: values.email,
          })
        ),
        {
          pending: 'Loading...',
          error: 'Error!',
        }
      )
      .then((state) => {
        if (!state?.error) {
          toast.success('Done!');
          setValues(initState);
          handleClose();
        }
      });
  };

  return (
    <ModalWrapper
      show={showResetPasswordModal}
      close={handleClose}
      customClass="reset-password-modal"
    >
      <ModalHeader title="Passwort-Wiederherstellung" close={handleClose} />
      <div className="reset-password-modal__content">
        <div className="reset-password-modal__input">
          <InputText
            required
            type="email"
            placeholder="E-Mail-Adresse"
            autoComplete="on"
            onChange={(e) => setValues({ ...values, email: e.target.value })}
          />
        </div>
        <div className="reset-password-modal__footer">
          <Button className="big green" onClick={handleSubmit}>
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default ResetPasswordModal;
