import './style.scss';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'components/ui/Button';
import InfoButton from 'Layouts/Accordion/Elemente/InfoButton';
import { useMemo } from 'react';
import infoIcon from '../../../assets/info.svg';
import arrowRight from '../../../assets/arrow-right.svg';
import arrowLeft from '../../../assets/arrow-left.svg';
import sendIcon from '../../../assets/send.svg';
import ButtonOpt2 from '../../../components/ui/ButtonOpt2';
import { Spinner } from '../../../components/Loading';
import { setShowInfoModal } from '../../../store/reducers/User/UserSlice';

const SidePanelFooter = ({
  handleBack,
  handleNext,
  activeStep,
  disableBtn,
  totalVal,
  hasChanged,
  arrWarn,
  isLoading,
}) => {
  const { profile, profile2 } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const infoTextClass = useMemo(
    () => (profile || profile2 ? '' : 'infoTextBlur'),
    [profile, profile2]
  );
  const buttonText = useMemo(() => {
    if (hasChanged || arrWarn?.length > 0) return 'Update';
    return activeStep < 6 ? 'Weiter' : 'Anfragen';
  }, [activeStep, hasChanged, arrWarn]);

  return (
    <div className="sidePanelFooter">
      <div
        className={`sidePanelFooter__info ${
          profile || profile2 ? 'anPadd' : ''
        }`}
      >
        <div className="sidePanelFooter__info-text">Kostenschätzung</div>
        <div className={`sidePanelFooter__info-text ${infoTextClass} end`}>
          {isLoading ? (
            <Spinner />
          ) : profile || profile2 ? (
            ` Fr. ${totalVal}.`
          ) : (
            '00000.-'
          )}
          -
        </div>
        {!profile && !profile2 && (
          <div className="sidePanelFooter__info-iconWrap">
            <InfoButton
              title="Kostenschätzung"
              text="Preis wird ab Schritt 4 “Ausstattung” angezeigt."
              link={undefined}
            />

            <Button
              className="info-icon-btn"
              onClick={() => {
                dispatch(
                  setShowInfoModal({
                    title: 'Kostenschätzung',
                    text: 'Preis wird ab Schritt 4 “Ausstattung” angezeigt.',
                    link: '',
                  })
                );
              }}
            >
              <img src={infoIcon} alt="infoIcon" />
            </Button>
          </div>
        )}
      </div>
      <div className="sidePanelFooter__btnFooter">
        {activeStep === 1 ? (
          <ButtonOpt2
            text="Weiter"
            icon={arrowRight}
            handleClick={handleNext}
            disable={disableBtn}
          />
        ) : (
          <div className="sidePanelFooter__btnFooter-btnWrap">
            <ButtonOpt2
              icon={arrowLeft}
              text="Zurück"
              opt="opt2"
              handleClick={handleBack}
            />
            <ButtonOpt2
              icon={activeStep < 6 ? arrowRight : sendIcon}
              text={buttonText}
              handleClick={handleNext}
              opt={activeStep < 6 ? '' : 'opt3'}
              disable={disableBtn}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SidePanelFooter;
