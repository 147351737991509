import React, { useState, useEffect } from 'react';
import CustomSimpleBar from 'components/CustomSimpleBar';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/ui/Button';
// import InfoButton from 'Layouts/Accordion/Elemente/InfoButton';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { setShowSolarModal } from '../../../store/reducers/User/UserSlice';

import solarImg from '../../../assets/options/solar.png';

import './style.scss';

const SolarModal = () => {
  const dispatch = useDispatch();

  const { showSolarModal } = useSelector((state) => state.user);
  const { source } = useSelector((state) => state.project);

  const [colSolar, setColSolar] = useState(0);
  const [sizeX, setSizeX] = useState(0);
  const [sizeZ, setSizeZ] = useState(0);

  useEffect(() => {
    source?.addEventListener('solar-variable', (data) => {
      setColSolar(data.colSolar);
      setSizeZ(data.sizeZ);
      setSizeX(data.sizeX);
    });
  }, [source]);

  const handleClose = () => {
    dispatch(setShowSolarModal(false));
  };

  const select = () => {
    if (!colSolar) {
      toast.warn('Optionen wählen');
    }
    dispatch(setShowSolarModal(false));
    source.funcToggleSolar(undefined, colSolar);
  };

  const cancel = () => {
    dispatch(setShowSolarModal(false));
    source.funcToggleSolar(undefined, 0);
  };

  return (
    <ModalWrapper
      show={showSolarModal}
      close={handleClose}
      customClass="wrapper-solar-modal"
    >
      <ModalHeader title="Sonnenkollektoren" close={handleClose} />

      <div className="container-solar-modal">
        <CustomSimpleBar style={{ maxHeight: '50dvh', width: '100%' }}>
          <div className="section-solar-modal">
            <div className="title-section-solar-modal">
              Anzahl Solar Paneelen
              {/* <InfoButton
                title="Sonnenkollektoren"
                text="Sonnenkollektoren"
                link={undefined}
              /> */}
            </div>
            <div className="content-section-solar-modal">
              <div
                className={
                  (sizeX < 4 || sizeZ < 1.5) && (sizeX < 1.5 || sizeZ < 4)
                    ? 'solar-modal-options-img disable'
                    : 'solar-modal-options-img'
                }
              >
                <Button
                  className={
                    colSolar === 2
                      ? 'solar-modal-options-content active'
                      : 'solar-modal-options-content'
                  }
                  onClick={() => {
                    setColSolar(2);
                  }}
                >
                  <img src={solarImg} alt="" />
                </Button>
                <div className="solar-modal-options-title">
                  2 Solar Paneelen
                </div>
              </div>

              <div
                className={
                  (sizeX < 4 || sizeZ < 3) && (sizeX < 3 || sizeZ < 4)
                    ? 'solar-modal-options-img disable'
                    : 'solar-modal-options-img'
                }
              >
                <Button
                  className={
                    colSolar === 4
                      ? 'solar-modal-options-content active'
                      : 'solar-modal-options-content'
                  }
                  onClick={() => {
                    setColSolar(4);
                  }}
                >
                  <img src={solarImg} alt="" />
                </Button>
                <div className="solar-modal-options-title">
                  4 Solar Paneelen
                </div>
              </div>

              <div
                className={
                  (sizeX < 6 || sizeZ < 3) && (sizeX < 3 || sizeZ < 6)
                    ? 'solar-modal-options-img disable'
                    : 'solar-modal-options-img'
                }
              >
                <Button
                  className={
                    colSolar === 6
                      ? 'solar-modal-options-content active'
                      : 'solar-modal-options-content'
                  }
                  onClick={() => {
                    setColSolar(6);
                  }}
                >
                  <img src={solarImg} alt="" />
                </Button>
                <div className="solar-modal-options-title">
                  6 Solar Paneelen
                </div>
              </div>

              <div
                className={
                  (sizeX < 6 || sizeZ < 4.5) && (sizeX < 4.5 || sizeZ < 6)
                    ? 'solar-modal-options-img disable'
                    : 'solar-modal-options-img'
                }
              >
                <Button
                  className={
                    colSolar === 9
                      ? 'solar-modal-options-content active'
                      : 'solar-modal-options-content'
                  }
                  onClick={() => {
                    setColSolar(9);
                  }}
                >
                  <img src={solarImg} alt="" />
                </Button>
                <div className="solar-modal-options-title">
                  9 Solar Paneelen
                </div>
              </div>
            </div>
          </div>
        </CustomSimpleBar>

        <div className="container-btnStart-solar-modal">
          <Button
            className="btn-solar-modal"
            style={{ backgroundColor: '#bb0303' }}
            onClick={cancel}
          >
            löschen
          </Button>
          <Button
            className="btn-solar-modal"
            style={{ backgroundColor: '#61c38b' }}
            onClick={select}
          >
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SolarModal;
