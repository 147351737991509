import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/ui/Button';

import { setShowInfoModal } from 'store/reducers/User/UserSlice';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import './style.scss';

const InfoModal = () => {
  const dispatch = useDispatch();
  const { showInfoModal } = useSelector((state) => state.user);

  const handleClose = () => dispatch(setShowInfoModal(false));

  return (
    <ModalWrapper
      show={showInfoModal}
      close={handleClose}
      customClass="wrapper-info-modal"
    >
      <ModalHeader
        title={showInfoModal.title}
        close={handleClose}
        color="#fff"
        bg="#61c38b"
        customClass="header-info-modal"
      />
      <div className="container-info-modal">
        <div className="description-container-info-modal">
          {showInfoModal.text}
        </div>
        <a href="link">{showInfoModal.link}</a>
        <div className="container-btnStart-info-modal">
          <Button className="btn-info-modal" onClick={handleClose}>
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default InfoModal;
