import { api } from '../store/api';
import axios from '../utils/axios';
import { setProject } from '../store/reducers/Project/ProjectSlice';

export const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllProjects: builder.query({
      query: () => ({
        url: '/api/v1/project/allProjects',
        method: 'GET',
      }),
      providesTags: (result = []) => [
        ...result.map(({ _id }) => ({ type: 'Projects', id: _id })),
        { type: 'Projects', id: 'LIST' },
      ],
    }),
    getProductInfo: builder.query({
      query: (data) => ({
        url: '/api/v1/crm/getInfo',
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.products,
      providesTags: () => [{ type: 'Product', id: 'INFO' }],
    }),
    getProductsPrice: builder.query({
      query: (data) => ({
        url: '/api/v1/crm/getPrice',
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.prices,
      providesTags: () => [{ type: 'Product', id: 'PRICE' }],
    }),
    addProject: builder.mutation({
      query: (data) => ({
        url: `/api/v1/project`,
        method: 'POST',
        data,
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;
        dispatch(setProject(response.data));
      },
    }),
    addProjectWithoutAuth: builder.mutation({
      query: (data) => ({
        url: `/api/v1/project/without-auth`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'Projects', id: 'LIST' }],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;
        dispatch(setProject(response.data));
      },
    }),

    updateProject: builder.mutation({
      query(data) {
        const id = data._id;
        return {
          url: `/api/v1/project?projectID=${id}`,
          method: 'PATCH',
          data,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      invalidatesTags: (project) => [
        { type: 'Projects', id: project?._id },
        { type: 'Quotes', id: 'LIST' },
      ],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        const response = await queryFulfilled;
        dispatch(setProject(response.data));
      },
    }),
    deleteProject: builder.mutation({
      query(id) {
        return {
          url: `/api/v1/project?projectID=${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: () => ['Projects'],
    }),
    getProject: builder.mutation({
      query(id) {
        return {
          url: `/api/v1/project?projectID=${id}`,
          method: 'GET',
        };
      },
      invalidatesTags: () => ['Projects'],
    }),
  }),
});

export const {
  useGetAllProjectsQuery,
  useGetProductInfoQuery,
  useGetProductsPriceQuery,
  useAddProjectMutation,
  useAddProjectWithoutAuthMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useGetProjectMutation,
} = projectsApi;

const ProjectService = {
  async getProducts(body) {
    const response = await axios.post(`/api/v1/crm/getPrice`, body);
    return response.data;
  },
};

export default ProjectService;
