import { Box3, Object3D, Vector3 } from 'three';

export default class ConstructLouver2 extends Object3D {
  constructor(
    base,
    beam,
    column,
    roof,
    x,
    y,
    z,
    zip,
    zipColumn,
    zipTop,
    zipBottom,
    lamellen,
    lamellenRailTop,
    lamellenRailBottom,
    lamellenFrameColumn,
    lamellenFrameBase,
    newColLeftB = 0,
    newColLeftD = 0,
    newColLeftA = 0,
    newColLeftC = 0,
    newColRightB = 0,
    newColRightD = 0,
    newColRightA = 0,
    newColRightC = 0,
    newWidthB = 1,
    newWidthD = 1,
    newWidthA = 1,
    newWidthC = 1
  ) {
    super();

    this.newColLeftB = newColLeftB;
    this.newColLeftD = newColRightD;
    this.newColLeftA = newColLeftA;
    this.newColLeftC = newColRightC;
    this.newColRightB = newColRightB;
    this.newColRightD = newColLeftD;
    this.newColRightA = newColRightA;
    this.newColRightC = newColLeftC;

    this.newWidthB = newWidthB;
    this.newWidthD = newWidthD;
    this.newWidthA = newWidthA;
    this.newWidthC = newWidthC;

    this.base = base;
    this.beam = beam;
    this.column = column;
    this.roof = roof;

    this.lamellen = lamellen;
    this.lamellenRailTop = lamellenRailTop;
    this.lamellenRailBottom = lamellenRailBottom;
    this.lamellenFrameColumn = lamellenFrameColumn;
    this.lamellenFrameBase = lamellenFrameBase;

    this.zip = zip;
    this.zipColumn = zipColumn;
    this.zipTop = zipTop;
    this.zipBottom = zipBottom;

    this.zipType1 = new Object3D();
    this.add(this.zipType1);

    this.lamellenType1 = new Object3D();
    this.add(this.lamellenType1);

    this.lamellenObj = new Object3D();
    this.add(this.lamellenObj);

    this.zipObj = new Object3D();
    this.add(this.zipObj);

    this.windowObj = new Object3D();
    this.add(this.windowObj);

    this.x = x / 1000;
    this.y1 = this.y = y / 1000;
    this.z = z / 1000;

    this.type = 'louver2';

    this.bias = {
      z: 0.131,
      x: 0.105,
    };

    this.init();
  }
  async init() {
    try {
      this.createColumn(this.column, this.base);
      this.createRoof(this.roof, this.beam);

      this.sizeLamellenEl(
        this.lamellen,
        this.lamellenRailTop,
        this.lamellenRailBottom,
        this.lamellenFrameColumn,
        this.lamellenFrameBase,
        this.column,
        this.beam
      );
      this.sizeZipeEl(
        this.zip,
        this.zipColumn,
        this.zipTop,
        this.zipBottom,
        this.column,
        this.beam
      );
    } catch (error) {
      console.error(error);
    }
  }

  createColumn(column, base) {
    column.scale.y = this.y;
    const bb = new Box3();
    bb.setFromObject(column, true);
    const size = new Vector3();
    bb.getSize(size);

    const bbbase = new Box3();
    bbbase.setFromObject(base, true);
    const sizebase = new Vector3();
    bbbase.getSize(sizebase);

    const column1 = column.clone();
    column1.rotation.y = Math.PI / 2;
    column1.position.set(this.z / 2, 0, this.x / 2);
    this.add(column1);
    const base1 = base.clone();
    base1.position.copy(column1.position);
    base1.rotation.y = Math.PI / 2;
    this.add(base1);

    const column2 = column.clone();
    column2.rotation.y = Math.PI / 2;
    column2.scale.x = -1;
    column2.position.set(this.z / 2, 0, -this.x / 2);
    this.add(column2);
    const base2 = base.clone();
    base2.position.copy(column2.position);
    base2.rotation.y = Math.PI / 2;
    base2.scale.x = -1;
    this.add(base2);

    const column3 = column.clone();
    column3.rotation.y = Math.PI / 2;
    column3.scale.z = -1;
    column3.position.set(-this.z / 2, 0, this.x / 2);
    this.add(column3);
    const base3 = base.clone();
    base3.position.copy(column3.position);
    base3.rotation.y = Math.PI / 2;
    base3.scale.z = -1;
    this.add(base3);

    const column4 = column.clone();
    column4.rotation.y = Math.PI / 2;
    column4.scale.x = -1;
    column4.scale.z = -1;
    column4.position.set(-this.z / 2, 0, -this.x / 2);
    this.add(column4);
    const base4 = base.clone();
    base4.position.copy(column4.position);
    base4.rotation.y = Math.PI / 2;
    base4.scale.z = -1;
    base4.scale.x = -1;
    this.add(base4);

    this.createBeam(this.beam);
  }
  createBeam(beam) {
    const bbbeam = new Box3();
    bbbeam.setFromObject(beam, true);
    const sizebeam = new Vector3();
    bbbeam.getSize(sizebeam);

    const beamFront = beam.clone();
    beamFront.scale.z = this.x - 0.1;
    beamFront.scale.x = -1;
    beamFront.position.set(this.z / 2, this.y - sizebeam.y, this.x / 2 - 0.05);
    this.add(beamFront);

    const beamBack = beam.clone();
    beamBack.scale.z = -this.x + 0.1;
    beamBack.position.set(-this.z / 2, this.y - sizebeam.y, -this.x / 2 + 0.05);
    this.add(beamBack);

    const beamRight = beam.clone();
    beamRight.rotation.y = -Math.PI / 2;
    beamRight.scale.z = -this.z + 0.1;
    beamRight.position.set(this.z / 2 - 0.05, this.y - sizebeam.y, -this.x / 2);
    this.add(beamRight);

    const beamLeft = beam.clone();
    beamLeft.rotation.y = Math.PI / 2;
    beamLeft.scale.z = -this.z + 0.1;
    beamLeft.position.set(-this.z / 2 + 0.05, this.y - sizebeam.y, this.x / 2);
    this.add(beamLeft);
  }
  createRoof(roof, beam) {
    const bb = new Box3();
    bb.setFromObject(roof, true);
    const size = new Vector3();
    bb.getSize(size);

    const bbeam = new Box3();
    bbeam.setFromObject(beam, true);
    const sizebeam = new Vector3();
    bbeam.getSize(sizebeam);

    const roofEl = roof.clone();
    roofEl.scale.z = this.z - 0.2;
    roofEl.rotation.y = Math.PI / 2;
    roofEl.position.z = this.x / 2 - sizebeam.x / 2;
    roofEl.position.y = this.y - size.y + 0.01;
    roofEl.position.x = this.z / 2 - 0.1;
    this.add(roofEl);
    const col = Math.floor((this.x - sizebeam.x) / (size.x * 1.25));
    const interval = 1 / (col - 1);
    for (let i = 1; i < col; i++) {
      const elRoof = roofEl.clone();
      const t = i * interval;
      const z = this.x / 2 - sizebeam.x / 2 - t * (this.x - sizebeam.x * 2);
      elRoof.position.z = z;
      this.add(elRoof);
    }
  }
  sizeZipeEl(zip, zipColumn, zipTop, zipBottom, column, beam) {
    const bbColumn = new Box3();
    bbColumn.setFromObject(column, true);
    const sizeColumnLouver = new Vector3();
    bbColumn.getSize(sizeColumnLouver);

    const bbBeam = new Box3();
    bbBeam.setFromObject(beam, true);
    const sizeBeamLouver = new Vector3();
    bbBeam.getSize(sizeBeamLouver);

    const bbZip = new Box3();
    bbZip.setFromObject(zip, true);
    const sizeZipe = new Vector3();
    bbZip.getSize(sizeZipe);

    const bbZipColumn = new Box3();
    bbZipColumn.setFromObject(zipColumn, true);
    const sizeZipeColumn = new Vector3();
    bbZipColumn.getSize(sizeZipeColumn);

    const bbZipTop = new Box3();
    bbZipTop.setFromObject(zipTop, true);
    const sizeZipeTop = new Vector3();
    bbZipTop.getSize(sizeZipeTop);

    const bbZipBottom = new Box3();
    bbZipBottom.setFromObject(zipBottom, true);
    const sizeZipeBottom = new Vector3();
    bbZipBottom.getSize(sizeZipeBottom);

    this.createZipType1(
      zip,
      zipColumn,
      zipTop,
      zipBottom,
      sizeColumnLouver,
      sizeBeamLouver,
      sizeZipeColumn,
      sizeZipeTop,
      sizeZipe,
      sizeZipeBottom
    );
  }
  createZipType1(
    zip,
    zipColumn,
    zipTop,
    zipBottom,
    sizeColumn,
    sizeBeam,
    sizeZipeColumn,
    sizeZipeTop,
    sizeZipe,
    sizeZipeBottom
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createZipB = (sizeX, bias = 0) => {
      const bufferZipSideB = new Object3D();
      sideB.add(bufferZipSideB);

      const zipSideB = zip.clone();
      zipSideB.rotation.y = -Math.PI / 2;
      zipSideB.scale.x = sizeX - sizeColumn.x * 2 - 0.05 + sizeZipeColumn.x * 2;
      zipSideB.scale.y = -1;

      zipSideB.position.set(
        this.z / 2 + sizeZipeColumn.x / 2,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x - 0.025 + sizeZipeColumn.x
      );

      const zipColumnLeftSideB = zipColumn.clone();
      zipColumnLeftSideB.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideB.rotation.y = -Math.PI / 2;
      zipColumnLeftSideB.position.set(
        this.z / 2 + sizeZipeColumn.z,
        0,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );
      const zipColumnRightSideB = zipColumn.clone();
      zipColumnRightSideB.rotation.y = Math.PI / 2;
      zipColumnRightSideB.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideB.position.set(
        this.z / 2,
        0,
        -sizeX / 2 + sizeColumn.x - sizeZipeColumn.x
      );

      const zipTopSideB = zipTop.clone();
      zipTopSideB.scale.x = sizeX - sizeColumn.x * 2 + sizeZipeColumn.x * 2;
      zipTopSideB.rotation.y = -Math.PI / 2;
      zipTopSideB.position.set(
        this.z / 2 + sizeZipeTop.z,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );

      const zipBottomSideB = zipBottom.clone();
      zipBottomSideB.rotation.y = -Math.PI / 2;
      zipBottomSideB.scale.x = sizeX - sizeColumn.x * 2;
      zipBottomSideB.scale.y = -1;
      zipBottomSideB.position.set(
        this.z / 2 + sizeZipeColumn.x,
        this.y - sizeZipe.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x
      );

      bufferZipSideB.add(zipSideB);
      bufferZipSideB.add(zipColumnLeftSideB);
      bufferZipSideB.add(zipColumnRightSideB);
      bufferZipSideB.add(zipTopSideB);
      bufferZipSideB.add(zipBottomSideB);

      bufferZipSideB.position.z = (this.x - sizeX) / 2 - bias;
    };
    const createZipD = (sizeX, bias = 0) => {
      const bufferZipSideD = new Object3D();
      sideD.add(bufferZipSideD);

      const zipSideD = zip.clone();
      zipSideD.rotation.y = -Math.PI / 2;
      zipSideD.scale.x = sizeX - sizeColumn.x * 2 - 0.05 + sizeZipeColumn.x * 2;
      zipSideD.scale.y = -1;
      zipSideD.position.set(
        -this.z / 2 - sizeZipeColumn.x / 2,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x - 0.025 + sizeZipeColumn.x
      );
      const zipColumnLeftSideD = zipColumn.clone();
      zipColumnLeftSideD.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideD.rotation.y = -Math.PI / 2;
      zipColumnLeftSideD.position.set(
        -this.z / 2,
        0,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );
      const zipColumnRightSideD = zipColumn.clone();
      zipColumnRightSideD.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideD.rotation.y = Math.PI / 2;
      zipColumnRightSideD.position.set(
        -this.z / 2 - sizeZipeColumn.z,
        0,
        -sizeX / 2 + sizeColumn.x - sizeZipeColumn.x
      );
      const zipTopSideD = zipTop.clone();
      zipTopSideD.scale.x = sizeX - sizeColumn.x * 2 + sizeZipeColumn.x * 2;
      zipTopSideD.scale.z = -1;
      zipTopSideD.rotation.y = -Math.PI / 2;
      zipTopSideD.position.set(
        -this.z / 2 - sizeZipeTop.z,
        this.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x + sizeZipeColumn.x
      );
      const zipBottomSideD = zipBottom.clone();
      zipBottomSideD.rotation.y = -Math.PI / 2;
      zipBottomSideD.scale.x = sizeX - sizeColumn.x * 2;
      zipBottomSideD.scale.y = -1;
      zipBottomSideD.position.set(
        -this.z / 2,
        this.y - sizeZipe.y - sizeBeam.y,
        sizeX / 2 - sizeColumn.x
      );
      bufferZipSideD.add(zipSideD);
      bufferZipSideD.add(zipColumnLeftSideD);
      bufferZipSideD.add(zipColumnRightSideD);
      bufferZipSideD.add(zipTopSideD);
      bufferZipSideD.add(zipBottomSideD);

      bufferZipSideD.position.z = (this.x - sizeX) / 2 - bias;
    };

    const createZipA = (sizeZ, bias = 0) => {
      const bufferZipSideA = new Object3D();
      sideA.add(bufferZipSideA);

      const zipSideA = zip.clone();
      zipSideA.scale.x = sizeZ - sizeColumn.x - 0.005 + sizeZipeColumn.z * 2;
      zipSideA.scale.y = -1;
      zipSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + sizeZipeColumn.z,
        this.y - sizeBeam.y,
        this.x / 2 + sizeZipeColumn.x / 2
      );
      const zipColumnLeftSideA = zipColumn.clone();
      zipColumnLeftSideA.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideA.scale.x = -1;
      zipColumnLeftSideA.position.set(
        -sizeZ / 2 + sizeColumn.x / 2 - sizeZipeColumn.z,
        0,
        this.x / 2
      );
      const zipColumnRightSideA = zipColumn.clone();
      zipColumnRightSideA.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + 0.001 + sizeZipeColumn.z,
        0,
        this.x / 2
      );
      const zipTopSideA = zipTop.clone();
      zipTopSideA.scale.x = sizeZ - sizeColumn.x + sizeZipeColumn.z * 2;
      zipTopSideA.scale.z = -1;
      zipTopSideA.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + sizeZipeColumn.z,
        this.y - sizeBeam.y,
        this.x / 2 + sizeZipeTop.z
      );
      const zipBottomSideA = zipBottom.clone();
      zipBottomSideA.scale.x = -sizeZ + sizeColumn.x;
      zipBottomSideA.scale.y = -1;
      zipBottomSideA.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        this.y - sizeZipe.y - sizeBeam.y,
        this.x / 2
      );
      bufferZipSideA.add(zipSideA);
      bufferZipSideA.add(zipColumnLeftSideA);
      bufferZipSideA.add(zipColumnRightSideA);
      bufferZipSideA.add(zipTopSideA);
      bufferZipSideA.add(zipBottomSideA);

      bufferZipSideA.position.x = (this.z - sizeZ) / 2 - bias;
    };

    const createZipC = (sizeZ, bias = 0) => {
      const bufferZipSideC = new Object3D();
      sideC.add(bufferZipSideC);

      const zipSideC = zip.clone();
      zipSideC.scale.x = sizeZ - sizeColumn.x - 0.005 + sizeZipeColumn.z * 2;
      zipSideC.scale.y = -1;
      zipSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + +sizeZipeColumn.z,
        this.y - sizeBeam.y,
        -this.x / 2 - sizeZipeColumn.x / 2
      );

      const zipColumnLeftSideC = zipColumn.clone();
      zipColumnLeftSideC.scale.y = this.y - sizeZipeTop.y;
      zipColumnLeftSideC.scale.x = -1;
      zipColumnLeftSideC.scale.z = -1;
      zipColumnLeftSideC.position.set(
        -sizeZ / 2 + sizeColumn.x / 2 - sizeZipeColumn.z,
        0,
        -this.x / 2
      );
      const zipColumnRightSideC = zipColumn.clone();
      zipColumnRightSideC.scale.y = this.y - sizeZipeTop.y;
      zipColumnRightSideC.scale.z = -1;
      zipColumnRightSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + 0.001 + sizeZipeColumn.z,
        0,
        -this.x / 2
      );

      const zipTopSideC = zipTop.clone();
      zipTopSideC.scale.x = sizeZ - sizeColumn.x + sizeZipeColumn.z * 2;
      zipTopSideC.scale.z = 1;
      zipTopSideC.position.set(
        sizeZ / 2 - sizeColumn.x / 2 + sizeZipeColumn.z,
        this.y - sizeBeam.y,
        -this.x / 2 - sizeZipeTop.z
      );
      const zipBottomSideC = zipBottom.clone();
      zipBottomSideC.scale.x = -sizeZ + sizeColumn.x;
      zipBottomSideC.scale.z = -1;
      zipBottomSideC.scale.y = -1;
      zipBottomSideC.position.set(
        -sizeZ / 2 + sizeColumn.x / 2,
        this.y - sizeZipe.y - sizeBeam.y,
        -this.x / 2
      );
      bufferZipSideC.add(zipSideC);
      bufferZipSideC.add(zipColumnLeftSideC);
      bufferZipSideC.add(zipColumnRightSideC);
      bufferZipSideC.add(zipTopSideC);
      bufferZipSideC.add(zipBottomSideC);

      bufferZipSideC.position.x = (this.z - sizeZ) / 2 - bias;
    };

    if (this.x > 6.082 + 0.3 - 0.08) {
      const x1 = this.x / 2;
      const x2 = this.x - x1;
      createZipB(x1 + sizeColumn.x);
      createZipB(x2 + sizeColumn.x, x1 - sizeColumn.x);
      createZipD(x1 + sizeColumn.x);
      createZipD(x2 + sizeColumn.x, x1 - sizeColumn.x);
    } else {
      createZipB(this.x);
      createZipD(this.x);
    }

    if (this.z > 6.082 + 0.1 - 0.08) {
      const z1 = this.z / 2;
      const z2 = this.z - z1;
      createZipA(z1 + sizeColumn.z);
      createZipA(z2 + sizeColumn.z, z1 - sizeColumn.z);
      createZipC(z1 + sizeColumn.z);
      createZipC(z2 + sizeColumn.z, z1 - sizeColumn.z);
    } else {
      createZipA(this.z);
      createZipC(this.z);
    }

    this.zipType1.userData.sideA = sideA;
    this.zipType1.userData.sideB = sideB;
    this.zipType1.userData.sideC = sideC;
    this.zipType1.userData.sideD = sideD;
  }
  sizeLamellenEl(
    lamellen,
    lamellenRailTop,
    lamellenRailBottom,
    lamellenFrameColumn,
    lamellenFrameBase,
    column,
    beam
  ) {
    const bbLamellen = new Box3();
    bbLamellen.setFromObject(lamellen, true);
    const sizeLamellen = new Vector3();
    bbLamellen.getSize(sizeLamellen);

    const bbLamellenRailTop = new Box3();
    bbLamellenRailTop.setFromObject(lamellenRailTop, true);
    const sizeLamellenRailTop = new Vector3();
    bbLamellenRailTop.getSize(sizeLamellenRailTop);

    const bbLamellenRailBottom = new Box3();
    bbLamellenRailBottom.setFromObject(lamellenRailBottom, true);
    const sizeLamellenRailBottom = new Vector3();
    bbLamellenRailBottom.getSize(sizeLamellenRailBottom);

    const bbLamellenFrameColumn = new Box3();
    bbLamellenFrameColumn.setFromObject(lamellenFrameColumn, true);
    const sizeLamellenFrameColumn = new Vector3();
    bbLamellenFrameColumn.getSize(sizeLamellenFrameColumn);

    const bbLamellenFrameBase = new Box3();
    bbLamellenFrameBase.setFromObject(lamellenFrameBase, true);
    const sizeLamellenFrameBase = new Vector3();
    bbLamellenFrameBase.getSize(sizeLamellenFrameBase);

    const bbColumn = new Box3();
    bbColumn.setFromObject(column, true);
    const sizeColumnLouver = new Vector3();
    bbColumn.getSize(sizeColumnLouver);

    const bbBeam = new Box3();
    bbBeam.setFromObject(beam, true);
    const sizeBeamLouver = new Vector3();
    bbBeam.getSize(sizeBeamLouver);

    this.createLamellenType1(
      lamellen,
      lamellenRailTop,
      lamellenRailBottom,
      lamellenFrameColumn,
      lamellenFrameBase,
      sizeLamellen,
      sizeLamellenRailTop,
      sizeLamellenRailBottom,
      sizeLamellenFrameColumn,
      sizeLamellenFrameBase,
      sizeColumnLouver,
      sizeBeamLouver
    );
  }
  createLamellenType1(
    lamellen,
    lamellenRailTop,
    lamellenRailBottom,
    lamellenFrameColumn,
    lamellenFrameBase,
    sizeLamellen,
    sizeLamellenRailTop,
    sizeLamellenRailBottom,
    sizeLamellenFrameColumn,
    sizeLamellenFrameBase,
    sizeColumn,
    sizeBeam
  ) {
    const sideA = new Object3D();
    const sideB = new Object3D();
    const sideC = new Object3D();
    const sideD = new Object3D();

    sideA.name = 'sideA';
    sideB.name = 'sideB';
    sideC.name = 'sideC';
    sideD.name = 'sideD';

    const createLamellenSection = (width) => {
      const lamellenSection = new Object3D();

      const lamellenFrameTop = lamellenFrameBase.clone();
      lamellenFrameTop.scale.x = width;
      lamellenFrameTop.position.set(
        0,
        this.y - sizeBeam.y - sizeLamellenFrameBase.y,
        0
      );
      const lamellenFrameBottom = lamellenFrameBase.clone();
      lamellenFrameBottom.scale.x = width;
      lamellenFrameBottom.scale.y = -1;
      lamellenFrameBottom.position.set(
        0,
        0 + sizeLamellenFrameBase.y + sizeLamellenRailBottom.y / 2,
        0
      );

      const lamellenFrameL = lamellenFrameColumn.clone();
      lamellenFrameL.scale.y =
        this.y - sizeBeam.y - sizeLamellenFrameBase.y * 2;
      lamellenFrameL.scale.x = -1;
      lamellenFrameL.scale.z = -1;
      lamellenFrameL.position.set(
        0.0015,
        sizeLamellenFrameBase.y,
        -sizeLamellenFrameColumn.z + 0.002
      );

      const lamellenFrameR = lamellenFrameColumn.clone();
      lamellenFrameR.scale.z = -1;
      lamellenFrameR.scale.y =
        this.y - sizeBeam.y - sizeLamellenFrameBase.y * 2;
      lamellenFrameR.position.set(
        -width - 0.002,
        sizeLamellenFrameBase.y,
        -sizeLamellenFrameColumn.z + 0.002
      );

      const lamellenEl = lamellen.clone();
      lamellenEl.scale.x = width;
      lamellenEl.position.y = sizeLamellenFrameBase.y;
      lamellenSection.add(lamellenEl);

      const col = Math.floor(
        (this.y - sizeBeam.y - sizeLamellenFrameBase.y * 2) /
          (sizeLamellen.y * 1.25)
      );

      const interval = 1 / (col - 1);
      for (let i = 1; i < col; i++) {
        const elLamellen = lamellenEl.clone();
        const t = i * interval;
        const y =
          sizeLamellenFrameBase.y +
          t * (this.y - sizeBeam.y - sizeLamellenFrameBase.y * 3);
        elLamellen.position.y = y;
        lamellenSection.add(elLamellen);
      }

      lamellenSection.add(lamellenFrameL);
      lamellenSection.add(lamellenFrameR);
      lamellenSection.add(lamellenFrameBottom);
      lamellenSection.add(lamellenFrameTop);

      return lamellenSection;
    };

    for (let i = 0; i < this.newColLeftB; i++) {
      const lam = createLamellenSection(this.newWidthB);
      lam.rotation.y = -Math.PI / 2;
      lam.position.set(
        this.z / 2 - sizeLamellenFrameBase.z,
        0,
        this.x / 2 - sizeColumn.x - i * this.newWidthB
      );
      sideB.add(lam);
    }

    for (let i = 0; i < this.newColRightB; i++) {
      const lam = createLamellenSection(this.newWidthB);
      lam.rotation.y = -Math.PI / 2;
      lam.position.set(
        this.z / 2 - sizeLamellenRailBottom.z,
        0,
        -this.x / 2 + sizeColumn.x + this.newWidthB + i * this.newWidthB
      );
      sideB.add(lam);
    }

    for (let i = 0; i < this.newColLeftD; i++) {
      const lam = createLamellenSection(this.newWidthD);
      lam.rotation.y = -Math.PI / 2;
      lam.scale.z = -1;
      lam.position.set(
        -this.z / 2 + sizeLamellenRailBottom.z,
        0,
        this.x / 2 - sizeColumn.x - i * this.newWidthD
      );
      sideD.add(lam);
    }

    for (let i = 0; i < this.newColRightD; i++) {
      const lam = createLamellenSection(this.newWidthD);
      lam.rotation.y = -Math.PI / 2;
      lam.scale.z = -1;
      lam.position.set(
        -this.z / 2 + sizeLamellenFrameBase.z,
        0,
        -this.x / 2 + sizeColumn.x + this.newWidthD + i * this.newWidthD
      );
      sideD.add(lam);
    }

    for (let i = 0; i < this.newColLeftA; i++) {
      const lam = createLamellenSection(this.newWidthA);
      lam.scale.z = -1;
      lam.position.set(
        -this.z / 2 + sizeColumn.z + this.newWidthA + i * this.newWidthA,
        0,
        this.x / 2 - sizeLamellenRailBottom.z
      );
      sideA.add(lam);
    }

    for (let i = 0; i < this.newColRightA; i++) {
      const lam = createLamellenSection(this.newWidthA);
      lam.scale.z = -1;
      lam.position.set(
        this.z / 2 - sizeColumn.z - i * this.newWidthA,
        0,
        this.x / 2 - sizeLamellenFrameBase.z
      );
      sideA.add(lam);
    }

    for (let i = 0; i < this.newColLeftC; i++) {
      const lam = createLamellenSection(this.newWidthC);

      lam.position.set(
        -this.z / 2 + sizeColumn.z + this.newWidthC + i * this.newWidthC,
        0,
        -this.x / 2 + sizeLamellenFrameBase.z
      );
      sideC.add(lam);
    }

    for (let i = 0; i < this.newColRightC; i++) {
      const lam = createLamellenSection(this.newWidthC);
      lam.position.set(
        this.z / 2 - sizeColumn.z - i * this.newWidthC,
        0,
        -this.x / 2 + sizeLamellenRailBottom.z
      );
      sideC.add(lam);
    }

    /////////////

    const lamellenRailBottomSideB = lamellenRailBottom.clone();
    lamellenRailBottomSideB.rotation.y = -Math.PI / 2;
    lamellenRailBottomSideB.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailBottomSideB.scale.z = -1;
    lamellenRailBottomSideB.position.set(
      this.z / 2,
      0,
      this.x / 2 - sizeColumn.x
    );
    const lamellenRailTopSideB = lamellenRailTop.clone();
    lamellenRailTopSideB.rotation.y = -Math.PI / 2;
    lamellenRailTopSideB.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailTopSideB.scale.z = -1;
    lamellenRailTopSideB.position.set(
      this.z / 2,
      this.y - sizeBeam.y,
      this.x / 2 - sizeColumn.x
    );

    sideB.add(lamellenRailTopSideB);
    sideB.add(lamellenRailBottomSideB);

    const lamellenRailBottomSideD = lamellenRailBottom.clone();
    lamellenRailBottomSideD.rotation.y = -Math.PI / 2;
    lamellenRailBottomSideD.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailBottomSideD.position.set(
      -this.z / 2,
      0,
      this.x / 2 - sizeColumn.x
    );

    const lamellenRailTopSideD = lamellenRailTop.clone();
    lamellenRailTopSideD.rotation.y = -Math.PI / 2;
    lamellenRailTopSideD.scale.x = this.x - sizeColumn.x * 2;
    lamellenRailTopSideD.position.set(
      -this.z / 2,
      this.y - sizeBeam.y,
      this.x / 2 - sizeColumn.x
    );

    sideB.add(lamellenRailTopSideD);
    sideD.add(lamellenRailBottomSideD);

    const lamellenRailBottomSideA = lamellenRailBottom.clone();
    lamellenRailBottomSideA.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailBottomSideA.position.set(
      this.z / 2 - sizeColumn.z,
      0,
      this.x / 2
    );

    const lamellenRailTopSideA = lamellenRailTop.clone();
    lamellenRailTopSideA.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailTopSideA.position.set(
      this.z / 2 - sizeColumn.z,
      this.y - sizeBeam.y,
      this.x / 2
    );

    sideA.add(lamellenRailTopSideA);
    sideA.add(lamellenRailBottomSideA);

    const lamellenRailBottomSideC = lamellenRailBottom.clone();
    lamellenRailBottomSideC.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailBottomSideC.scale.z = -1;
    lamellenRailBottomSideC.position.set(
      this.z / 2 - sizeColumn.z,
      0,
      -this.x / 2
    );

    const lamellenRailTopSideC = lamellenRailTop.clone();
    lamellenRailTopSideC.scale.x = this.z - sizeColumn.z * 2;
    lamellenRailTopSideC.scale.z = -1;
    lamellenRailTopSideC.position.set(
      this.z / 2 - sizeColumn.z,
      this.y - sizeBeam.y,
      -this.x / 2
    );

    sideC.add(lamellenRailTopSideC);
    sideC.add(lamellenRailBottomSideC);

    this.lamellenType1.userData.sideA = sideA;
    this.lamellenType1.userData.sideB = sideB;
    this.lamellenType1.userData.sideC = sideC;
    this.lamellenType1.userData.sideD = sideD;
  }
}
