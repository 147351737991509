import React from 'react';
import './style.scss';

const InputText = ({ className, ...props }) => (
  <div className={`app-input ${className ?? ''}`}>
    <input {...props} />
  </div>
);

export default InputText;
