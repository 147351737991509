export default class RmElCommand {
  constructor(app, object, funcUpdatePrice) {
    this.app = app;
    this.object = object;
    this.funcUpdatePrice = funcUpdatePrice;
  }
  execute() {
    this.app.funcRmEl(this.object);
    this.funcUpdatePrice();
  }
  undo() {
    this.app.elements.push(this.object);
    this.app.viewer.scene.add(this.object);
    this.app.newTargetControls();
    this.funcUpdatePrice();
  }
}
