import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from 'components/ui/Button';
import { setTypeModel } from 'store/reducers/Project/ProjectSlice';
import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import {
  setShowSelectModalProduct,
  setShowSearchModalProduct,
} from '../../../store/reducers/User/UserSlice';

import imgmodel1 from '../../../assets/imgModel1.jpg';
import imgmodel2 from '../../../assets/imgModel2.jpg';
import imgmodel3 from '../../../assets/imgModel3.jpg';
import './style.scss';

const SelectProduct = () => {
  const dispatch = useDispatch();
  const { showSelectModalProduct } = useSelector((state) => state.user);
  const { source } = useSelector((state) => state.project);

  const selectType = (type) => {
    if (!type) {
      toast.warn('Produkt auswählen');
      return;
    }

    dispatch(setTypeModel(type));
    source?.funcSelectType(type);
    dispatch(setShowSelectModalProduct(false));

    window.openVideoTutorial();
  };

  const handleShowSearchProduct = () => {
    dispatch(setShowSearchModalProduct(true));
    dispatch(setShowSelectModalProduct(false));
  };

  return (
    <ModalWrapper
      show={showSelectModalProduct}
      customClass="wrapper-select-product"
      cursor={false}
    >
      <ModalHeader
        title="HERZLICH WILLKOMMEN BEIM THERMOGREEN KONFIGURATOR"
        customClass="close-modal-none"
      />
      <div className="container-select-product">
        <div className="container-upper-select-product">
          <div className="content-upper-select-product">
            {/* <div className="title-content-select-product">
              WILLKOMMEN BEIM THERMOGREEN KONFIGURATOR
            </div> */}
            <div className="subtitle-content-select-product">
              Interessieren Sie sich für ein Glas- oder Lamellendach?
            </div>
            <div className="description-content-select-product">
              Im nächsten Schritt können Sie Ihr individuelles Terrassendach
              gestalten und erhalten im Anschluss eine Kostenschätzung.
            </div>
            {/* <div className="description-content-select-product">
              Viel spass!
            </div> */}
          </div>
          <div className="content-upper-select-product">
            <Button
              className="btn-upper-select-product"
              onClick={handleShowSearchProduct}
            >
              Produktfinder
            </Button>
          </div>
        </div>
        <div className="items-container-select-product">
          <div className="container-item-select-product">
            <Button
              className="item-select-product"
              onClick={() => {
                selectType('louver');
              }}
            >
              <img src={imgmodel1} alt="" />
            </Button>
            <div className="title-item-select-product">SKL-100</div>
          </div>
          <div className="container-item-select-product">
            <Button
              className="item-select-product"
              onClick={() => {
                selectType('glass');
              }}
            >
              <img src={imgmodel2} alt="" />
            </Button>
            <div className="title-item-select-product">Glasdach</div>
          </div>

          <div className="container-item-select-product">
            <Button
              className="item-select-product"
              onClick={() => {
                selectType('louver2');
              }}
            >
              <img src={imgmodel1} alt="" />
            </Button>
            <div className="title-item-select-product">SKL-50</div>
          </div>

          <div className="container-item-select-product">
            <Button
              className="item-select-product"
              onClick={() => {
                selectType('louver3');
              }}
            >
              <img src={imgmodel3} alt="" />
            </Button>
            <div className="title-item-select-product">ECL-130</div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SelectProduct;
