import './style.scss';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveStep } from '../../../store/reducers/User/UserSlice';

const StepItem = ({ stepNumber, title, isActive, isClickable, onClick }) => (
  <button
    type="button"
    className={`steps__item ${
      isActive ? 'itemActive' : isClickable ? 'itemOpen' : ''
    }`}
    onClick={onClick}
    disabled={!isClickable}
  >
    <div className="steps__item-count">{stepNumber}</div>
    <div className="steps__item-name">{title}</div>
  </button>
);

const Steps = () => {
  const dispatch = useDispatch();
  const { activeStep, profile, profile2 } = useSelector((state) => ({
    activeStep: state.user.activeStep,
    profile: state.user.profile,
    profile2: state.user.profile2,
  }));
  const { typeModel, source } = useSelector((state) => state.project);

  useEffect(() => {
    if (typeModel === null && activeStep !== 1) {
      dispatch(setActiveStep(1));
    }
  }, [typeModel]);

  const isStepClickable = (stepNumber) => {
    if (stepNumber <= 1) return true;
    if (stepNumber === 2 || stepNumber === 3) return typeModel !== null;
    if (stepNumber >= 4)
      return typeModel !== null && (profile2 !== null || profile !== null);
    return false;
  };

  const handleStepClick = (stepNumber) => {
    if (activeStep === 1 && isStepClickable(stepNumber)) {
      source.updEl();
      dispatch(setActiveStep(stepNumber));
    }
    if (isStepClickable(stepNumber)) {
      dispatch(setActiveStep(stepNumber));
      source.findSide(null);
    }
  };

  const stepItems = useMemo(
    () =>
      [1, 2, 3, 4, 5, 6].map((stepNumber) => (
        <StepItem
          key={stepNumber}
          stepNumber={stepNumber}
          title={
            [
              'Modell',
              'Masse',
              'Projektinfos',
              'Ausstattung',
              'Farben',
              'Kostenschätzung',
            ][stepNumber - 1]
          }
          isActive={activeStep === stepNumber}
          isClickable={isStepClickable(stepNumber)}
          onClick={() => handleStepClick(stepNumber)}
        />
      )),
    [activeStep, typeModel, profile, profile2]
  );

  return (
    <div className="steps">
      <div className="steps__line" />
      {stepItems}
    </div>
  );
};

export default Steps;
