import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomSimpleBar from 'components/CustomSimpleBar';

import { toast } from 'react-toastify';
import Button from 'components/ui/Button';
import { ReactComponent as Info } from '../../../assets/info.svg';

import ModalWrapper, { ModalHeader } from '../ModalWrapper';
import { setShowTypeZipRoofModal } from '../../../store/reducers/User/UserSlice';

import zip1 from '../../../assets/options/dachbeschattung1.jpg';
import zip2 from '../../../assets/options/dachbeschattung2.jpg';
import zip3 from '../../../assets/options/dachbeschattung3.jpg';

import './style.scss';

const TypeZipRoofModal = () => {
  const dispatch = useDispatch();

  const { showTypeZipRoofModal } = useSelector((state) => state.user);
  const { source, typeModel } = useSelector((state) => state.project);
  const [typeZip, setTypeZip] = useState(null);

  const handleClose = () => {
    dispatch(setShowTypeZipRoofModal(false));
    setTypeZip(null);
  };

  const select = () => {
    if (!typeZip) {
      toast.warn('Produkt auswählen');
      return;
    }
    const data = source.funcToggleZip(undefined, undefined, typeZip);
    if (data === undefined) {
      toast.warn('es ist verboten');
      return;
    }

    handleClose();
  };

  return (
    <ModalWrapper
      show={showTypeZipRoofModal}
      close={handleClose}
      customClass="wrapper-type-zip-modal"
    >
      <ModalHeader title="Dachbeschattung" close={handleClose} />

      <div className="container-type-zip-modal">
        <div className="section-type-zip-modal">
          <div className="title-section-type-zip-modal">
            Ausführung <Info />
          </div>

          <CustomSimpleBar style={{ maxHeight: '50dvh', width: '100%' }}>
            <div className="content-section-type-zip-modal">
              {typeModel === 'glass' &&
              showTypeZipRoofModal.variable === 'sideRoof' ? (
                <>
                  <div className="type-zip-modal-options-img">
                    <Button
                      className={
                        typeZip === 'zipType3'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {
                        setTypeZip('zipType3');
                      }}
                    >
                      <img src={zip2} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title">
                      Eckiges Design tief
                    </div>
                  </div>
                  <div className="type-zip-modal-options-img">
                    <Button
                      className={
                        typeZip === 'zipType1'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {
                        setTypeZip('zipType1');
                      }}
                    >
                      <img src={zip3} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title">
                      Eckiges Design hoch
                    </div>
                  </div>
                  <div className="type-zip-modal-options-img">
                    <Button
                      className={
                        typeZip === 'zipType2'
                          ? 'type-zip-modal-options-content active'
                          : 'type-zip-modal-options-content'
                      }
                      onClick={() => {
                        setTypeZip('zipType2');
                      }}
                    >
                      <img src={zip1} alt="" />
                    </Button>
                    <div className="type-zip-modal-options-title">
                      Abgerundetes Design hoch
                    </div>
                  </div>

                  {/* <div className="type-zip-modal-options-img">
                  <Button
                    className={
                      typeZip === 'zipType4'
                        ? 'type-zip-modal-options-content active'
                        : 'type-zip-modal-options-content'
                    }
                    onClick={() => {
                      setTypeZip('zipType4');
                    }}
                  >
                    <img
                      src=""
                      alt=""
                      style={{ width: '94px', height: '69px' }}
                    />
                  </Button>
                  <div className="type-zip-modal-options-title">WD600 up</div>
                </div> */}
                </>
              ) : null}
            </div>
          </CustomSimpleBar>
        </div>
        <div className="container-btnStart-type-zip-modal">
          <Button className="btn-type-zip-modal" onClick={select}>
            OK
          </Button>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default TypeZipRoofModal;
